export interface SyncEngineConfiguration {
  types: SyncConfigEngineType[];
  supportingTypes: SyncEngineSupportingType[];
  unions: SyncEngineConfigUnion[];
  enums: SyncEngineConfigEnum[];
}

export interface SyncEngineQuery {
  query: string;
  name: string;
  array?: boolean;
  optional?: boolean;
  // FIXME: quick hack to deal with the non-current vs user issue
  omitFromFragment?: boolean;
}

export interface SyncConfigEngineType {
  name: string;
  queries?: SyncEngineQuery[];
  properties: SyncEngineConfigProperty[];
  __hackAdditionalSchemaTypes?: Array<{ type: string; name: string; optional?: boolean }>;
  disallowClientCreation?: boolean;
  disallowClientDeletion?: boolean;
  disallowClientUpdate?: boolean;
}

export interface SyncEngineConfigProperty {
  name: string;
  alias?: string;
  type: string;
  optional?: boolean;
  index?: SyncEngineConfigPropertyIndex;
  collection?: SyncEngineConfigCollection;
  typescriptTypeHint?: string;
  disallowClientUpdate?: boolean;
  disallowServerUpdate?: boolean;
}

export interface SyncEngineSupportingType {
  name: string;
  properties: SyncEngineSupportingTypeProperty[];
  __hackAdditionalSchemaTypes?: Array<{ type: string; name: string; optional?: boolean }>;
  input?: boolean;
}

export interface SyncEngineSupportingTypeProperty {
  name: string;
  type: string;
  optional?: boolean;
}

export interface SyncEngineConfigPropertyIndex {
  name: string;
  sortedBy?: string;
  sortReversed?: boolean;
}

export interface SyncEngineConfigCollection {
  name: string;
}

export interface SyncEngineConfigUnion {
  name: string;
  types: string[];
}

export interface SyncEngineConfigEnum {
  name: string;
  options: string[];
}

export const syncEngineConfig: SyncEngineConfiguration = {
  types: [
    {
      name: 'User',
      queries: [
        { query: 'currentUser', name: 'user', optional: true },
        { query: 'organization', name: 'users', array: true, omitFromFragment: true },
      ],
      disallowClientCreation: true,
      disallowClientDeletion: true,
      properties: [
        {
          name: 'username',
          type: 'string',
        },
        {
          name: 'name',
          type: 'string',
          optional: true,
        },
        {
          name: 'primaryEmail',
          type: 'string',
        },
        {
          name: 'avatar',
          type: 'string',
          optional: true,
        },
        {
          name: 'verified',
          type: 'boolean',
          disallowClientUpdate: true,
        },
        {
          name: 'completedTutorials',
          type: 'string[]',
        },
        {
          name: 'preferences',
          type: 'json',
          typescriptTypeHint: 'Record<string, unknown>',
        },
        {
          name: 'emailNotificationDelay',
          type: 'int',
          optional: true,
        },
        {
          name: 'lastOrganizationId',
          type: 'id',
          optional: true,
        },
      ],
    },
    {
      name: 'Organization',
      queries: [{ query: 'currentUser', name: 'organizations', array: true }],
      disallowClientCreation: true,
      disallowClientDeletion: true,
      properties: [
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'slug',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'ssoDomain',
          type: 'string',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'activeProductId',
          type: 'string',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'activeProductPlanId',
          type: 'string',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'lastPaymentError',
          type: 'date',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'productTierExceeded',
          type: 'boolean',
          disallowClientUpdate: true,
        },
        {
          name: 'webhooksEnabled',
          type: 'boolean',
        },
        {
          name: 'newRoadmapsEnabled',
          type: 'boolean',
          optional: true,
        },
        {
          name: 'aiEnabled',
          type: 'boolean',
          optional: true,
        },
        {
          name: 'documentsEnabled',
          type: 'boolean',
          optional: true,
        },
        {
          name: 'customEmojis',
          type: 'json',
          typescriptTypeHint:
            'Record<string, { url: string; actorId: string; timestamp: number; }>',
        },
        { name: 'avatar', type: 'string', optional: true },
      ],
    },
    {
      name: 'OrganizationMember',
      queries: [
        { query: 'organization', name: 'members', array: true },
        { query: 'currentUser', name: 'organizationMemberships', array: true },
      ],
      disallowClientCreation: true,
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'organizationMembersByOrganization',
          },
        },
        {
          name: 'userId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'organizationMembersByUser',
          },
        },
        {
          name: 'lastSpaceId',
          type: 'id',
          optional: true,
        },
        {
          name: 'favoriteSpaceIds',
          type: 'id[]',
          optional: true,
        },
        {
          name: 'starred',
          type: 'json',
        },
        {
          name: 'snoozed',
          type: 'Snooze[]',
          optional: true,
        },
        {
          name: 'feedbackNotificationsEnabled',
          type: 'boolean',
        },

        {
          name: 'invited',
          type: 'boolean',
          disallowClientUpdate: true,
        },
        {
          name: 'guest',
          type: 'boolean',
        },
        {
          name: 'role',
          type: 'MemberRole',
        },
        {
          name: 'deactivated',
          type: 'boolean',
        },
      ],
      __hackAdditionalSchemaTypes: [
        {
          name: 'ssoEnabled',
          type: 'boolean',
          optional: true,
        },
        {
          name: 'favorites',
          type: 'json',
        },
      ],
    },
    {
      name: 'Update',
      queries: [{ query: 'currentUser', name: 'updates', array: true }],
      disallowClientCreation: true,
      disallowClientDeletion: true,
      properties: [
        {
          name: 'key',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'link',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'dependencyIds',
          type: 'id[]',
          disallowClientUpdate: true,
        },
        {
          name: 'actorIds',
          type: 'id[]',
          disallowClientUpdate: true,
        },
        {
          name: 'notification',
          type: 'boolean',
          disallowClientUpdate: true,
        },
        {
          name: 'read',
          type: 'boolean',
        },
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'userId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'organizationMemberId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'updatesByOrganizationMember',
            sortedBy: 'createdAt',
            sortReversed: true,
          },
        },
        {
          name: 'context',
          type: 'string',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'integrationType',
          type: 'IntegrationType',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'icon',
          type: 'string',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'entityId',
          type: 'id',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'commentThreadId',
          type: 'id',
          optional: true,
          disallowClientUpdate: true,
        },
        {
          name: 'updateType',
          type: 'UpdateType',
          disallowClientUpdate: true,
        },
        {
          name: 'plainTextAction',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'markdownAction',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'fallbackTitle',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'richContent',
          type: 'json',
          optional: true,
          typescriptTypeHint: 'Descendant[]',
          disallowClientUpdate: true,
        },
        {
          name: 'inlineCommentContext',
          type: 'json',
          optional: true,
          typescriptTypeHint: 'Descendant[]',
          disallowClientUpdate: true,
        },
        {
          name: 'siblingEntityIds',
          type: 'id[]',
          optional: true,
        },
        {
          name: 'breadcrumbs',
          type: 'string[]',
          optional: true,
        },
      ],
    },
    {
      name: 'IntegrationUser',
      queries: [{ query: 'organization', name: 'integrationUsers', array: true }],
      disallowClientCreation: true,
      disallowClientDeletion: true,
      disallowClientUpdate: true,
      properties: [
        {
          name: 'type',
          type: 'IntegrationType',
        },
        {
          name: 'customIntegrationType',
          type: 'string',
          optional: true,
        },
        {
          name: 'domain',
          type: 'string',
          optional: true,
        },
        {
          name: 'externalId',
          type: 'string',
        },
        {
          name: 'externalName',
          type: 'string',
        },
        {
          name: 'integrationExternalId',
          type: 'string',
          optional: true,
        },
        {
          name: 'organizationId',
          type: 'id',
        },
        {
          name: 'organizationMemberIds',
          type: 'id[]',
        },
        {
          name: 'userIds',
          type: 'id[]',
          collection: {
            name: 'integrationUsers',
          },
          index: {
            name: 'integrationUsersByUser',
          },
        },
        {
          name: 'configValid',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'Integration',
      queries: [{ query: 'organization', name: 'integrations', array: true }],
      disallowClientCreation: true,
      disallowClientDeletion: true,
      disallowClientUpdate: true,
      properties: [
        {
          name: 'type',
          type: 'IntegrationType',
        },
        {
          name: 'customIntegrationType',
          type: 'string',
          optional: true,
        },
        {
          name: 'domain',
          type: 'string',
          optional: true,
        },
        {
          name: 'config',
          type: 'string',
        },
        {
          name: 'externalId',
          type: 'string',
        },
        {
          name: 'organizationId',
          type: 'id',
          index: {
            name: 'integrationsByOrganization',
          },
        },
      ],
    },
    {
      name: 'Application',
      queries: [{ query: 'organization', name: 'applications', array: true }],
      disallowClientDeletion: true,
      properties: [
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'avatar',
          type: 'string',
          optional: true,
        },
        {
          name: 'token',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'revoked',
          type: 'boolean',
        },
        {
          name: 'internal',
          type: 'boolean',
        },
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'applicationsByOrganization',
          },
        },
      ],
    },
    {
      name: 'PersonalAccessToken',
      queries: [{ query: 'currentUser', name: 'personalAccessTokens', array: true }],
      disallowClientDeletion: true,
      properties: [
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'token',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'revoked',
          type: 'boolean',
        },
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'userId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'organizationMemberId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'personalAccessTokensByOrganizationMember',
          },
        },
      ],
    },
    {
      name: 'Space',
      queries: [{ query: 'organization', name: 'spaces', array: true }],
      properties: [
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'slug',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'key',
          type: 'string',
        },
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'spacesByOrganization',
            sortedBy: 'sort',
          },
        },
        {
          name: 'tutorial',
          type: 'boolean',
          disallowClientUpdate: true,
        },
        {
          name: 'roadmapEnabled',
          type: 'boolean',
        },
        {
          name: 'cyclesEnabled',
          type: 'boolean',
        },
        {
          name: 'publicRoadmap',
          type: 'boolean',
        },
        {
          name: 'publicRoadmapMetadata',
          type: 'boolean',
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'backgroundType',
          type: 'BackgroundType',
        },
        {
          name: 'backgroundConfig',
          type: 'string',
        },
        {
          name: 'private',
          type: 'boolean',
        },
        {
          name: 'members',
          type: 'id[]',
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'automationEvents',
          type: 'AutomationEvents',
        },
        {
          name: 'redirects',
          type: 'json',
          disallowClientUpdate: true,
        },
        {
          name: 'activeCycleId',
          type: 'string',
          optional: true,
        },
        {
          name: 'upcomingCycleId',
          type: 'string',
          optional: true,
        },
        {
          name: 'autoArchive',
          type: 'AutoArchivePeriod',
          optional: true,
        },
        {
          name: 'staleIssues',
          type: 'StaleIssuePeriod',
          optional: true,
        },
        {
          name: 'cycleDuration',
          type: 'int',
        },
        {
          name: 'cycleCooldown',
          type: 'int',
        },
        {
          name: 'cycleStartDay',
          type: 'int',
        },
        {
          name: 'addToCurrentCycle',
          type: 'boolean',
        },
        {
          name: 'utcOffset',
          type: 'int',
          optional: true, // TODO: Remove after a while
        },
        {
          name: 'timezone',
          type: 'string',
        },
        { name: 'defaultNewStatusId', type: 'id', optional: true },
        { name: 'defaultDoneStatusId', type: 'id', optional: true },
      ],
    },
    {
      name: 'SpaceRoadmap',
      queries: [
        { query: 'organization', name: 'spaceRoadmaps', array: true },
        { query: 'roadmaps', name: 'spaceRoadmaps', array: true },
      ],
      properties: [
        {
          name: 'spaceId',
          type: 'id',
          index: {
            name: 'spaceRoadmapsBySpace',
            sortedBy: 'sort',
          },
        },
        {
          name: 'roadmapId',
          type: 'id',
          index: {
            name: 'spaceRoadmapsByRoadmap',
          },
        },
        {
          name: 'sort',
          type: 'string',
        },
      ],
    },
    {
      name: 'IssueStatus',
      queries: [{ query: 'organization', name: 'issueStatuses', array: true }],
      properties: [
        {
          name: 'spaceId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'issueStatusesBySpace',
          },
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'statusType',
          type: 'IssueStatusType',
        },
        {
          name: 'sortMode',
          type: 'IssueStatusSortMode',
        },
        {
          name: 'issueLimit',
          type: 'int',
          optional: true,
        },
        {
          name: 'watcherIds',
          type: 'id[]',
          optional: true, // TODO: Make non-optional after cache has expired
          collection: {
            name: 'watchers',
          },
          index: {
            name: 'issueStatusesByWatcher',
          },
        },
      ],
    },
    {
      name: 'Board',
      queries: [{ query: 'organization', name: 'boards', array: true }],
      properties: [
        {
          name: 'spaceId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'boardsBySpace',
            sortedBy: 'sort',
          },
        },
        {
          name: 'name',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'key',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'shared',
          type: 'boolean',
          optional: true, // TODO: remove optional for compat
        },
        {
          name: 'sharedMetadata',
          type: 'boolean',
          optional: true, // TODO: remove optional for compat
        },
        {
          name: 'sharedWorkItems',
          type: 'boolean',
          optional: true, // TODO: remove optional for compat
        },
        {
          name: 'defaultStatusType',
          type: 'IssueStatusType',
          disallowClientUpdate: true,
        },
      ],
    },
    {
      name: 'BoardColumn',
      queries: [{ query: 'organization', name: 'boardColumns', array: true }],
      properties: [
        {
          name: 'boardId',
          type: 'id',
          index: {
            name: 'boardColumnsByBoard',
            sortedBy: 'sort',
          },
        },
        {
          name: 'statusId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'sort',
          type: 'string',
        },
      ],
    },
    {
      name: 'IssueLabel',
      queries: [{ query: 'organization', name: 'issueLabels', array: true }],
      properties: [
        {
          name: 'spaceId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'issueLabelsBySpace',
          },
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'color',
          type: 'string',
        },
      ],
    },
    {
      name: 'OrganizationLabel',
      queries: [{ query: 'organization', name: 'organizationLabels', array: true }],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'organizatiionLabelsByOrgnization',
          },
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'color',
          type: 'string',
        },
      ],
    },
    {
      name: 'Comment',
      queries: [
        { query: 'themes', name: 'themeComments', array: true },
        { query: 'issueByNumber', name: 'issueComments', array: true },
        { query: 'issue', name: 'issueComments', array: true },
        { query: 'issues', name: 'issueComments', array: true },
        { query: 'initiatives', name: 'initiativeComments', array: true },
        { query: 'documents', name: 'documentComments', array: true },
        { query: 'feedback', name: 'feedbackComments', array: true },
      ],
      properties: [
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'body',
          type: 'string',
        },
        {
          name: 'entityId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'commentsByEntity',
            sortedBy: 'createdAt',
            sortReversed: true,
          },
        },
        {
          name: 'threadId',
          type: 'string',
          disallowClientUpdate: true,
          index: {
            name: 'commentsByThread',
            sortedBy: 'createdAt',
          },
        },
        {
          name: 'reply',
          type: 'boolean',
          disallowClientUpdate: true,
        },
        {
          name: 'reactions',
          type: 'Reaction[]',
        },
        {
          name: 'inline',
          type: 'boolean',
          disallowClientUpdate: true,
        },
        {
          name: 'resolved',
          type: 'boolean',
        },
        {
          name: 'commentContext',
          type: 'json',
          optional: true,
        },
      ],
    },
    {
      name: 'Issue',
      queries: [
        { query: 'issueByNumber', name: 'issue' },
        { query: 'issue', name: 'issue' },
        { query: 'issues', name: 'issues', array: true },
      ],
      properties: [
        {
          name: 'number',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'spaceId',
          type: 'id',
          index: {
            name: 'issuesBySpace',
            sortedBy: 'sort',
          },
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'statusId',
          type: 'id',
        },
        {
          name: 'previousStatusId',
          type: 'id',
          optional: true,
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'assigneeIds',
          type: 'id[]',
          collection: {
            name: 'assignees',
          },
          index: {
            name: 'issuesByAssignee',
          },
        },
        {
          name: 'watcherIds',
          type: 'id[]',
          collection: {
            name: 'watchers',
          },
          index: {
            name: 'issuesByWatcher',
          },
        },
        {
          name: 'labelIds',
          type: 'id[]',
          collection: {
            name: 'labels',
          },
          index: {
            name: 'issuesByLabel',
          },
        },
        {
          name: 'partial',
          type: 'boolean',
          optional: true,
        },
        {
          name: 'effortId',
          type: 'id',
          optional: true,
          index: {
            name: 'issuesByEffort',
          },
        },
        {
          name: 'impactId',
          type: 'id',
          optional: true,
          index: {
            name: 'issuesByImpact',
          },
        },
        {
          name: 'public',
          type: 'boolean',
        },
        {
          name: 'publicMetadata',
          type: 'boolean',
        },
        {
          name: 'displayedUpdatedAt',
          type: 'date',
          disallowClientUpdate: true,
        },
        {
          name: 'lastStatusUpdate',
          type: 'date',
          disallowClientUpdate: true,
          optional: true, // TODO: Make non-optional after cache has been clared
        },
        {
          name: 'codeReviewRequestIds',
          type: 'id[]',
          collection: {
            name: 'codeReviewRequests',
          },
        },
        {
          name: 'dueDate',
          type: 'date',
          optional: true,
        },
        { name: 'closedAt', type: 'date', optional: true, disallowClientUpdate: true },
        { name: 'archivedAt', type: 'date', optional: true, disallowClientUpdate: true },
        { name: 'provenance', type: 'EntityProvenance', optional: true },
      ],
    },
    {
      name: 'Cycle',
      queries: [{ query: 'cycles', name: 'cycles', array: true }],
      properties: [
        {
          name: 'spaceId',
          type: 'id',
          index: {
            name: 'cyclesBySpace',
            sortedBy: 'number',
            sortReversed: true,
          },
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'number',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'cycleStatus',
          type: 'CycleStatus',
        },
        {
          name: 'summary',
          type: 'string',
          optional: true,
        },
        {
          name: 'startDate',
          type: 'date',
        },
        {
          name: 'endDate',
          type: 'date',
        },
        {
          name: 'activityIds',
          type: 'id[]',
          collection: {
            name: 'activities',
          },
        },
        {
          name: 'commentIds',
          type: 'id[]',
          collection: {
            name: 'comments',
          },
        },
        {
          // TODO: This should be removed as part of cleaning up cycles
          name: 'history',
          type: 'CycleHistory',
          optional: true,
          disallowClientUpdate: true,
        },
      ],
    },
    {
      name: 'CycleEntity',
      queries: [{ query: 'cycles', name: 'cycleEntities', array: true }],
      properties: [
        {
          name: 'entityId',
          type: 'id',
          index: {
            name: 'cycleEntitiesByEntity',
            sortedBy: 'createdAt',
          },
        },
        {
          name: 'cycleId',
          type: 'id',
          index: {
            name: 'cycleEntitesByCycle',
            sortedBy: 'sort',
          },
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'ghost',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'CycleTodo',
      queries: [{ query: 'cycles', name: 'cycleTodos', array: true }],
      properties: [
        {
          name: 'cycleId',
          type: 'id',
          index: {
            name: 'cycleTodosByCycle',
            sortedBy: 'sort',
          },
        },
        {
          name: 'todoId',
          type: 'id',
          index: {
            name: 'cycleTodosByTodoId',
            sortedBy: 'sort',
          },
        },
        {
          name: 'sort',
          type: 'string',
        },
      ],
    },
    {
      name: 'CycleGoal',
      queries: [{ query: 'cycles', name: 'cycleGoals', array: true }],
      properties: [
        {
          name: 'cycleId',
          type: 'id',
          index: {
            name: 'cycleGoalsByCycle',
            sortedBy: 'sort',
            sortReversed: true,
          },
        },
        {
          name: 'entityId',
          type: 'id',
          optional: true,
          index: {
            name: 'cycleGoalsByEntity',
            sortedBy: 'sort',
            sortReversed: true,
          },
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'status',
          type: 'TodoStatus',
        },
        {
          name: 'goalContents',
          type: 'json',
          typescriptTypeHint: 'Descendant[]',
        },
        {
          name: 'memberIds',
          type: 'string[]',
          collection: {
            name: 'members',
          },
          index: {
            name: 'cycleGoalsByMember',
          },
        },
      ],
    },
    {
      name: 'Dependency',
      queries: [{ query: 'issues', name: 'dependencies', array: true }],
      properties: [
        {
          name: 'enablesId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'dependenciesByEnablesId',
          },
        },
        {
          name: 'dependsOnId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'dependenciesByDependsOnId',
          },
        },
        {
          name: 'dependencyType',
          type: 'DependencyType',
          disallowClientUpdate: true,
        },
      ],
    },
    {
      name: 'Theme',
      queries: [{ query: 'themes', name: 'themes', array: true }],
      properties: [
        {
          name: 'number',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'spaceId',
          type: 'id',
          index: {
            name: 'themesBySpace',
            sortedBy: 'sort',
          },
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'horizon',
          type: 'ThemeHorizon',
        },
        {
          name: 'previousHorizon',
          type: 'ThemeHorizon',
          optional: true,
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'color',
          type: 'string',
        },
        {
          name: 'image',
          type: 'string',
          optional: true,
        },
        {
          name: 'assigneeIds',
          type: 'id[]',
          collection: {
            name: 'assignees',
          },
          index: {
            name: 'themesByAssignee',
          },
        },
        {
          name: 'watcherIds',
          type: 'id[]',
          collection: {
            name: 'watchers',
          },
          index: {
            name: 'themesByWatcher',
          },
        },
        {
          name: 'labelIds',
          type: 'id[]',
          collection: {
            name: 'labels',
          },
          index: {
            name: 'themesByLabel',
          },
        },
        {
          name: 'issueIds',
          type: 'id[]',
          index: {
            name: 'themesByIssue',
          },
          collection: {
            name: 'issues',
          },
        },
        {
          name: 'effortId',
          type: 'id',
          optional: true,
          index: {
            name: 'themesByEffort',
          },
        },
        {
          name: 'impactId',
          type: 'id',
          optional: true,
          index: {
            name: 'themesByImpact',
          },
        },
        {
          name: 'displayedUpdatedAt',
          type: 'date',
          disallowClientUpdate: true,
        },
        { name: 'archivedAt', type: 'date', optional: true, disallowClientUpdate: true },
      ],
    },
    {
      name: 'CodeReviewRequest',
      queries: [
        { query: 'issueByNumber', name: 'issueCodeReviewRequests', array: true },
        { query: 'issue', name: 'issueCodeReviewRequests', array: true },
        { query: 'issues', name: 'issueCodeReviewRequests', array: true },
        { query: 'themes', name: 'themeCodeReviewRequests', array: true },
        { query: 'initiatives', name: 'initiativeCodeReviewRequests', array: true },
        { query: 'feedback', name: 'feedbackCodeReviewRequests', array: true },
        { query: 'cycles', name: 'cycleCodeReviewRequests', array: true },
        { query: 'documents', name: 'documentCodeReviewRequests', array: true },
      ],
      disallowClientCreation: true,
      disallowClientUpdate: true,
      disallowClientDeletion: true,
      properties: [
        {
          name: 'codeReviewType',
          type: 'IntegrationType',
        },
        {
          name: 'organizationId',
          type: 'id',
        },
        {
          name: 'state',
          type: 'CodeReviewRequestState',
        },
        {
          name: 'details',
          type: 'CodeReviewRequestDetails',
        },
        {
          name: 'links',
          type: 'CodeReviewRequestLink[]',
        },
      ],
    },
    {
      name: 'CollaborativeDoc',
      queries: [
        { query: 'organization', name: 'collaborativeDocs', array: true },
        {
          query: 'issues',
          name: 'collaborativeDocs',
          array: true,
        },
        {
          query: 'issue',
          name: 'collaborativeDocs',
          array: true,
        },
        {
          query: 'issueByNumber',
          name: 'collaborativeDocs',
          array: true,
        },
        {
          query: 'themes',
          name: 'collaborativeDocs',
          array: true,
        },
        {
          query: 'initiatives',
          name: 'collaborativeDocs',
          array: true,
        },
        {
          query: 'cycles',
          name: 'collaborativeDocs',
          array: true,
        },
        {
          query: 'documents',
          name: 'collaborativeDocs',
          array: true,
        },
      ],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'content',
          type: 'json',
          typescriptTypeHint: 'Descendant[]',
          disallowClientUpdate: true,
        },
        {
          name: 'entityId',
          type: 'id',
          index: { name: 'collaborativeDocsByEntity' },
        },
      ],
    },
    {
      name: 'Feedback',
      queries: [{ query: 'feedback', name: 'feedback', array: true }],
      properties: [
        {
          name: 'number',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'feedbackByOrganization',
            sortedBy: 'createdAt',
            sortReversed: true,
          },
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'companyId',
          type: 'id',
          optional: true,
        },
        {
          name: 'ownerIds',
          type: 'id[]',
          collection: {
            name: 'owners',
          },
          index: {
            name: 'feedbackByOwner',
          },
        },
        {
          name: 'personId',
          type: 'id',
          optional: true,
        },
        {
          name: 'tagIds',
          type: 'id[]',
          collection: {
            name: 'tags',
          },
          index: {
            name: 'feedbackByTag',
          },
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'processed',
          type: 'boolean',
        },
        {
          name: 'watcherIds',
          type: 'id[]',
          collection: {
            name: 'watchers',
          },
        },
        {
          name: 'processedAt',
          type: 'date',
          optional: true,
          disallowClientUpdate: true,
        },
      ],
    },
    {
      name: 'FeedbackTag',
      queries: [{ query: 'organization', name: 'feedbackTags', array: true }],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'feedbackTagsByOrganization',
          },
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'color',
          type: 'string',
        },
      ],
    },
    {
      name: 'Company',
      queries: [{ query: 'organization', name: 'companies', array: true }],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'companiesByOrganization',
            sortedBy: 'createdAt',
            sortReversed: true,
          },
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'avatar',
          type: 'string',
          optional: true,
        },
        {
          name: 'domain',
          type: 'string',
          optional: true,
        },
      ],
    },
    {
      name: 'Person',
      queries: [{ query: 'organization', name: 'people', array: true }],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'peopleByOrganization',
            sortedBy: 'createdAt',
            sortReversed: true,
          },
        },
        {
          name: 'companyId',
          type: 'id',
          optional: true,
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'email',
          type: 'string',
          optional: true,
        },
        {
          name: 'externalId',
          type: 'string',
          optional: true,
        },
      ],
    },
    {
      name: 'Insight',
      queries: [{ query: 'feedback', name: 'insights', array: true }],
      properties: [
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'feedbackId',
          type: 'id',
          index: {
            name: 'insightsByFeedback',
          },
        },
        {
          name: 'entityIds',
          type: 'id[]',
          collection: {
            name: 'entities',
          },
          index: {
            name: 'insightsByEntity',
          },
        },
        {
          name: 'contents',
          type: 'string',
        },
        {
          name: 'orphaned',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'Snippet',
      queries: [{ query: 'organization', name: 'snippets', array: true }],
      properties: [
        {
          name: 'spaceId',
          type: 'id',
          index: {
            name: 'snippetsBySpace',
            sortedBy: 'name',
          },
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'contents',
          type: 'string',
        },
      ],
    },
    {
      name: 'Activity',
      disallowClientCreation: true,
      disallowClientDeletion: true,
      queries: [
        { query: 'themes', name: 'themeActivities', array: true },
        { query: 'issueByNumber', name: 'issueActivities', array: true },
        { query: 'issue', name: 'issueActivities', array: true },
        { query: 'issues', name: 'issueActivities', array: true },
        { query: 'initiatives', name: 'initiativeActivities', array: true },
        { query: 'documents', name: 'documentActivities', array: true },
        { query: 'feedback', name: 'feedbackActivities', array: true },
      ],
      properties: [
        {
          name: 'entityId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'activitiesByEntity',
            sortedBy: 'createdAt',
            sortReversed: true,
          },
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'activityType',
          type: 'ActivityType',
          disallowClientUpdate: true,
        },
        {
          name: 'details',
          type: 'ActivityDetails',
          disallowClientUpdate: true,
        },
        {
          name: 'reactions',
          type: 'Reaction[]',
        },
      ],
    },
    {
      name: 'Todo',
      queries: [
        { query: 'issue', name: 'issueTodos', array: true },
        { query: 'issues', name: 'issueTodos', array: true },
        { query: 'issueByNumber', name: 'issueTodos', array: true },
        { query: 'themes', name: 'themeTodos', array: true },
        { query: 'initiatives', name: 'initiativeTodos', array: true },
        { query: 'documents', name: 'documentTodos', array: true },
        // FIXME: had to name this 'cyclesTodos' because we already had a cycleTodo property that stores which todos are in a cycle.
        // WE should fix the naming of that next time we do an API breakage.
        { query: 'cycles', name: 'cyclesTodos', array: true },
        { query: 'feedback', name: 'feedbackTodos', array: true },
      ],
      properties: [
        {
          name: 'entityId',
          type: 'id',
          index: {
            name: 'todosByEntity',
            sortedBy: 'sort',
          },
        },
        {
          name: 'actorId',
          type: 'id',
        },
        {
          name: 'key',
          type: 'string',
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'todoContents',
          type: 'json',
          typescriptTypeHint: 'Descendant[]',
        },
        {
          name: 'status',
          type: 'TodoStatus',
        },
        {
          name: 'orphaned',
          type: 'boolean',
        },
        {
          name: 'indent',
          type: 'int',
        },
        {
          name: 'effortId',
          type: 'id',
          optional: true,
          index: {
            name: 'todosByEffort',
          },
        },
        {
          name: 'impactId',
          type: 'id',
          optional: true,
          index: {
            name: 'todosByImpact',
          },
        },
        {
          name: 'parentId',
          type: 'id',
          optional: true,
        },
        {
          name: 'memberIds',
          type: 'id[]',
          collection: {
            name: 'members',
          },
          index: {
            name: 'todosByMember',
          },
        },
        {
          name: 'labelIds',
          type: 'id[]',
          collection: {
            name: 'labels',
          },
        },
        {
          name: 'codeReviewRequestIds',
          type: 'id[]',
          collection: {
            name: 'codeReviewRequests',
          },
        },
        {
          name: 'connectedEntityId',
          type: 'id',
          optional: true,
          index: {
            name: 'todosByConnectedEntity',
            sortedBy: 'sort',
          },
        },
        {
          name: 'connectedExternalIssueId',
          type: 'id',
          optional: true,
        },
        {
          name: 'explicitLinkStatus',
          type: 'boolean',
        },
        {
          name: 'dueDate',
          type: 'date',
          optional: true,
        },
      ],
    },
    {
      name: 'Impact',
      queries: [{ query: 'organization', name: 'impactLevels', array: true }],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'impactLevelsByOrganization',
            sortedBy: 'level',
          },
        },
        {
          name: 'spaceId',
          type: 'id',
          optional: true,
          disallowClientUpdate: true,
          index: {
            name: 'impactLevelsBySpace',
            sortedBy: 'level',
          },
        },
        {
          name: 'level',
          type: 'int',
        },
        {
          name: 'abbrevation',
          type: 'string',
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'color',
          type: 'string',
        },
      ],
    },
    {
      name: 'Effort',
      queries: [{ query: 'organization', name: 'effortLevels', array: true }],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'effortLevelsByOrganization',
            sortedBy: 'level',
          },
        },
        {
          name: 'spaceId',
          type: 'id',
          optional: true,
          disallowClientUpdate: true,
          index: {
            name: 'effortLevelsBySpace',
            sortedBy: 'level',
          },
        },
        {
          name: 'level',
          type: 'int',
        },
        {
          name: 'abbrevation',
          type: 'string',
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'color',
          type: 'string',
        },
      ],
    },
    {
      name: 'Folder',
      queries: [{ query: 'documents', name: 'folders', array: true }],
      properties: [
        {
          name: 'lookupKey',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'organizationId',
          type: 'id',
          index: {
            name: 'foldersByOrganization',
            sortedBy: 'sort',
          },
        },
        {
          name: 'parentId',
          type: 'id',
          optional: true,
          index: {
            name: 'foldersByParent',
            sortedBy: 'sort',
          },
        },
        {
          name: 'icon',
          type: 'string',
          optional: true,
        },
        {
          name: 'sort',
          type: 'string',
        },
      ],
    },
    {
      name: 'Doc',
      queries: [{ query: 'documents', name: 'documents', array: true }],
      properties: [
        {
          name: 'number',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'folderId',
          type: 'id',
          optional: true,
          index: {
            name: 'documentsByFolder',
            sortedBy: 'sort',
          },
        },
        {
          name: 'organizationId',
          type: 'id',
          index: {
            name: 'documentsByOrganization',
            sortedBy: 'sort',
          },
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'icon',
          type: 'string',
          optional: true,
        },
        { name: 'archivedAt', type: 'date', optional: true, disallowClientUpdate: true },
        {
          name: 'watcherIds',
          type: 'id[]',
          collection: {
            name: 'watchers',
          },
        },
      ],
    },
    {
      name: 'Initiative',
      queries: [{ query: 'initiatives', name: 'initiatives', array: true }],
      properties: [
        {
          name: 'number',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'initiativesByOrganization',
            sortedBy: 'sort',
          },
        },
        {
          name: 'memberIds',
          type: 'id[]',
          collection: {
            name: 'members',
          },
          index: {
            name: 'initiativesByMember',
          },
        },
        {
          name: 'issueIds',
          type: 'id[]',
          collection: {
            name: 'issues',
          },
          index: {
            name: 'initiativesByIssue',
          },
        },
        {
          name: 'labelIds',
          type: 'id[]',
          collection: {
            name: 'labels',
          },
          index: {
            name: 'initiativesByLabel',
          },
        },
        {
          name: 'watcherIds',
          type: 'id[]',
          optional: true, // TODO: KM-1738gq Remove optionality when cache is cleared
          collection: {
            name: 'watchers',
          },
          index: {
            name: 'initiativesByWatcher',
          },
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'color',
          type: 'string',
        },
        {
          name: 'impactId',
          type: 'id',
          optional: true,
        },
        {
          name: 'effortId',
          type: 'id',
          optional: true,
        },
        { name: 'archivedAt', type: 'date', optional: true, disallowClientUpdate: true },

        {
          name: 'startDate',
          type: 'date',
          optional: true,
        },
        {
          name: 'dueDate',
          type: 'date',
          optional: true,
        },
        {
          name: 'oldTheme',
          type: 'json',
          optional: true,
          disallowClientUpdate: true,
          typescriptTypeHint: `{ spaceId: string, number: string }`,
        },
        {
          name: 'displayedUpdatedAt',
          type: 'date',
          disallowClientUpdate: true,
        },
      ],
      __hackAdditionalSchemaTypes: [
        {
          name: 'spaceIds',
          type: 'id[]',
        },
      ],
    },
    {
      name: 'InitiativeSpace',
      queries: [{ query: 'initiatives', name: 'initiativeSpaces', array: true }],
      properties: [
        {
          name: 'spaceId',
          type: 'id',
          index: {
            name: 'initiativeSpacesBySpace',
            sortedBy: 'sort',
          },
        },
        {
          name: 'initiativeId',
          index: {
            name: 'initiativeSpacesByInitiative',
          },
          type: 'id',
        },
        {
          name: 'sort',
          type: 'string',
        },
      ],
    },
    {
      name: 'Roadmap',
      queries: [
        { query: 'organization', name: 'roadmaps', array: true },
        { query: 'roadmaps', name: 'roadmaps', array: true },
      ],
      properties: [
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'number',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'organizationId',
          disallowClientUpdate: true,
          type: 'id',
          index: {
            name: 'roadmapsByOrganization',
            sortedBy: 'sort',
          },
        },
        {
          name: 'roadmapType',
          type: 'RoadmapType',
        },
        {
          name: 'shared',
          type: 'boolean',
        },
        {
          name: 'sharedMetadata',
          type: 'boolean',
        },
        {
          name: 'actorId',
          disallowClientUpdate: true,
          type: 'id',
        },
        {
          name: 'color',
          type: 'string',
        },
      ],
    },
    {
      name: 'RoadmapColumn',
      queries: [
        { query: 'organization', name: 'roadmapColumns', array: true },
        { query: 'roadmaps', name: 'roadmapColumns', array: true },
      ],
      properties: [
        {
          name: 'roadmapId',
          type: 'id',
          index: {
            name: 'roadmapColumnsByRoadmap',
            sortedBy: 'sort',
          },
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'columnType',
          type: 'RoadmapColumnStatusType',
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'sortMode',
          type: 'IssueStatusSortMode',
        },
      ],
    },
    {
      name: 'RoadmapInitiative',
      queries: [{ query: 'initiatives', name: 'roadmapInitiatives', array: true }],
      properties: [
        {
          name: 'initiativeId',
          type: 'id',
          index: {
            name: 'roadmapInitiativesByInitiative',
            sortedBy: 'sort',
          },
        },
        {
          name: 'columnId',
          type: 'id',
          index: {
            name: 'roadmapInitiativesByColumn',
            sortedBy: 'sort',
          },
        },
        {
          name: 'previousColumnId',
          type: 'id',
          optional: true,
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'lastColumnUpdate',
          type: 'date',
          disallowClientUpdate: true,
          optional: true, // TODO: Make non-optional after cache has been clared
        },
      ],
    },
    {
      name: 'ExternalIssue',
      disallowClientCreation: true,
      disallowClientUpdate: true,
      disallowClientDeletion: true,
      queries: [{ query: 'externalIssues', name: 'externalIssues', array: true }],
      properties: [
        {
          name: 'externalId',
          type: 'string',
        },
        {
          name: 'type',
          type: 'IntegrationType',
        },
        {
          name: 'organizationId',
          type: 'id',
        },
        {
          name: 'externalIssueStatus',
          type: 'ExternalIssueStatus',
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'url',
          type: 'string',
        },
        {
          name: 'number',
          type: 'string',
        },
        {
          name: 'fauxDeleted',
          type: 'boolean',
        },
        {
          name: 'statusDetails',
          type: 'json',
          optional: true,
          typescriptTypeHint:
            ' {id: string; name: string; color?: string | null; icon?: string | null; iconUrl?: string | null; }',
        },
        {
          name: 'assignees',
          type: 'json',
          typescriptTypeHint: ' {id: string; name: string; avatarUrl: string | null; }[]',
        },
        {
          name: 'labels',
          type: 'json',
          typescriptTypeHint: ' {id: string; name: string; color: string | null; }[]',
        },
        {
          name: 'project',
          type: 'json',
          typescriptTypeHint:
            ' {id: string; name: string; url: string | null; key: string | null; }',
        },
        {
          name: 'priority',
          type: 'json',
          optional: true,
          typescriptTypeHint: ' {id: string; name: string; color: string | null; }',
        },
      ],
    },
    {
      name: 'View',
      queries: [{ query: 'organization', name: 'views', array: true }],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'viewsByOrganization',
            sortedBy: 'sort',
          },
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'viewsByActor',
          },
        },
        {
          name: 'filters',
          type: 'json',
        },
        {
          name: 'sort',
          type: 'string',
        },
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'color',
          type: 'string',
        },
        {
          name: 'viewType',
          type: 'ViewType',
        },
        {
          name: 'visibility',
          type: 'ViewVisibility',
        },
      ],
    },
    {
      name: 'Release',
      queries: [{ query: 'releases', name: 'releases', array: true }],
      properties: [
        {
          name: 'organizationId',
          type: 'id',
          disallowClientUpdate: true,
          index: {
            name: 'releasesByOrganization',
            sortedBy: 'number',
            sortReversed: true,
          },
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'number',
          type: 'string',
          disallowClientUpdate: true,
        },
        {
          name: 'releaseStatus',
          type: 'ReleaseStatus',
        },
        {
          name: 'dueDate',
          type: 'date',
          optional: true,
        },
        {
          name: 'actorId',
          type: 'id',
          disallowClientUpdate: true,
        },
        {
          name: 'memberIds',
          type: 'id[]',
          collection: {
            name: 'members',
          },
          index: {
            name: 'releasesByMember',
          },
        },
        {
          name: 'watcherIds',
          type: 'id[]',
          collection: {
            name: 'watchers',
          },
          index: {
            name: 'releasesByWatcher',
          },
        },
        {
          name: 'issueIds',
          type: 'id[]',
          collection: {
            name: 'issues',
          },
          index: {
            name: 'releasesByIssue',
          },
        },
        {
          name: 'spaceIds',
          type: 'id[]',
          collection: {
            name: 'spaces',
          },
          index: {
            name: 'releasesBySpace',
          },
        },
        { name: 'archivedAt', type: 'date', optional: true },
      ],
    },
  ],
  supportingTypes: [
    {
      name: 'Reaction',
      input: true,
      properties: [
        {
          name: 'actorId',
          type: 'id',
        },
        {
          name: 'reactions',
          type: 'string[]',
        },
      ],
    },
    {
      name: 'Snooze',
      input: true,
      properties: [
        {
          name: 'id',
          type: 'id',
        },
        {
          name: 'snoozedUntil',
          type: 'date',
        },
      ],
    },
    {
      name: 'CodeReviewRequestDetails',
      properties: [
        {
          name: 'number',
          type: 'string',
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'url',
          type: 'string',
        },
        {
          name: 'commits',
          type: 'string[]',
          optional: true,
        },
        {
          name: 'fromBranch',
          type: 'string',
          optional: true,
        },
        {
          name: 'toBranch',
          type: 'string',
          optional: true,
        },
        {
          name: 'defaultBranch',
          type: 'boolean',
          optional: true,
        },
      ],
    },
    {
      name: 'CodeReviewRequestLink',
      properties: [
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'url',
          type: 'string',
        },
      ],
    },
    {
      name: 'AutomationEvent',
      input: true,
      properties: [
        {
          name: 'statusId',
          type: 'id',
        },
      ],
    },
    {
      name: 'AutomationEvents',
      input: true,
      properties: [
        {
          name: 'git_closed',
          type: 'AutomationEvent',
          optional: true,
        },
        {
          name: 'git_in_progress',
          type: 'AutomationEvent',
          optional: true,
        },
        {
          name: 'git_pull_request',
          type: 'AutomationEvent',
          optional: true,
        },
      ],
    },
    {
      name: 'IssueCreatedMetadata',
      properties: [
        {
          name: 'statusId',
          type: 'id',
        },
        {
          name: 'fallbackStatus',
          type: 'string',
        },
        {
          name: 'labels',
          type: 'LabelChange[]',
          optional: true,
        },
        {
          name: 'assigneeIds',
          type: 'id[]',
          optional: true,
        },
        {
          name: 'entities',
          type: 'EntityChange[]',
          optional: true,
        },
      ],
      __hackAdditionalSchemaTypes: [
        // legacy
        {
          name: 'themes',
          type: 'EntityChange[]',
          optional: true,
        },
      ],
    },
    {
      name: 'ThemeCreatedMetadata',
      properties: [
        {
          name: 'horizon',
          type: 'ThemeHorizon',
        },
        {
          name: 'labels',
          type: 'LabelChange[]',
          optional: true,
        },
        {
          name: 'assigneeIds',
          type: 'id[]',
          optional: true,
        },
        {
          name: 'issues',
          type: 'EntityChange[]',
          optional: true,
        },
      ],
    },
    {
      name: 'InitiativeCreatedMetadata',
      properties: [
        {
          name: 'labels',
          type: 'LabelChange[]',
          optional: true,
        },
        {
          name: 'assigneeIds',
          type: 'id[]',
          optional: true,
        },
        {
          name: 'issues',
          type: 'EntityChange[]',
          optional: true,
        },
        {
          name: 'spaces',
          type: 'SpaceChange[]',
          optional: true,
        },
        {
          name: 'roadmaps',
          type: 'RoadmapChange[]',
          optional: true,
        },
      ],
    },
    {
      name: 'StatusChangedMetadata',
      properties: [
        {
          name: 'description',
          type: 'string',
        },
        {
          name: 'link',
          type: 'string',
        },
        {
          name: 'context',
          type: 'string',
          optional: true,
        },
        {
          name: 'type',
          type: 'IntegrationType',
          optional: true,
        },
        {
          name: 'autoArchived',
          type: 'boolean',
          optional: true,
        },
      ],
    },
    {
      name: 'LabelChange',
      properties: [
        {
          name: 'labelId',
          type: 'id',
        },
        {
          name: 'fallbackLabel',
          type: 'string',
        },
      ],
    },
    {
      name: 'EntityChange',
      properties: [
        {
          name: 'entityId',
          type: 'id',
        },
        {
          name: 'fallbackSpaceKey',
          type: 'string',
        },
        {
          name: 'fallbackEntityTitle',
          type: 'string',
        },
        {
          name: 'fallbackEntityNumber',
          type: 'string',
        },
        {
          name: 'fallbackEntityType',
          type: 'string',
          optional: true,
        },
      ],
    },
    {
      name: 'SpaceChange',
      properties: [
        {
          name: 'spaceId',
          type: 'id',
        },
        {
          name: 'fallbackSpaceName',
          type: 'string',
        },
      ],
    },
    {
      name: 'RoadmapChange',
      properties: [
        {
          name: 'roadmapId',
          type: 'id',
        },
        {
          name: 'columnId',
          type: 'id',
        },
        {
          name: 'fallbackColumnName',
          type: 'string',
        },
        {
          name: 'fallbackRoadmapName',
          type: 'string',
        },
      ],
    },
    {
      name: 'CreatedActivityDetails',
      properties: [
        {
          name: 'createdMetadata',
          type: 'CreatedMetadata',
        },
      ],
    },
    {
      name: 'StatusChangedActivityDetails',
      properties: [
        {
          name: 'statusId',
          type: 'id',
        },
        {
          name: 'fallbackStatus',
          type: 'string',
        },
        {
          name: 'originalStatusId',
          type: 'id',
          optional: true,
        },
        {
          name: 'spaceId',
          type: 'id',
          optional: true,
        },
        {
          name: 'fallbackSpace',
          type: 'string',
          optional: true,
        },
        {
          name: 'statusChangedMetadata',
          type: 'StatusChangedMetadata',
          optional: true,
        },
      ],
    },
    {
      name: 'LabelsChangedActivityDetails',
      properties: [
        {
          name: 'labelsAdded',
          type: 'LabelChange[]',
        },
        {
          name: 'labelsRemoved',
          type: 'LabelChange[]',
        },
      ],
    },
    {
      name: 'ImpactChangedActivityDetails',
      properties: [
        {
          name: 'impactId',
          type: 'string',
        },
        {
          name: 'fallbackImpactName',
          type: 'string',
        },
        {
          name: 'originalImpactId',
          type: 'string',
          optional: true,
        },
      ],
    },
    {
      name: 'EffortChangedActivityDetails',
      properties: [
        {
          name: 'effortId',
          type: 'string',
        },
        {
          name: 'fallbackEffortName',
          type: 'string',
        },
        {
          name: 'originalEffortId',
          type: 'string',
          optional: true,
        },
      ],
    },
    {
      name: 'EntitiesChangedActivityDetails',
      properties: [
        {
          name: 'entitiesAdded',
          type: 'EntityChange[]',
        },
        {
          name: 'entitiesRemoved',
          type: 'EntityChange[]',
        },
      ],
    },
    {
      name: 'AssigneesChangedActivityDetails',
      properties: [
        {
          name: 'assigneesAddedIds',
          type: 'id[]',
        },
        {
          name: 'assigneesRemovedIds',
          type: 'id[]',
        },
      ],
    },
    {
      name: 'ImportedActivityDetails',
      properties: [
        {
          name: 'type',
          type: 'IntegrationType',
        },
        {
          name: 'customIntegrationType',
          type: 'string',
          optional: true,
        },
        {
          name: 'link',
          type: 'string',
        },
        {
          name: 'importType',
          type: 'string',
        },
        {
          name: 'importSource',
          type: 'string',
        },
        {
          name: 'importSourceType',
          type: 'string',
        },
      ],
    },

    {
      name: 'MentionedActivityDetails',
      properties: [
        {
          name: 'entityId',
          type: 'id',
        },
        {
          name: 'fallbackSpaceKey',
          type: 'string',
        },
        {
          name: 'fallbackEntityTitle',
          type: 'string',
        },
        {
          name: 'fallbackEntityNumber',
          type: 'string',
        },
        {
          name: 'context',
          type: 'string',
        },
        {
          name: 'commentThreadId',
          type: 'string',
          optional: true,
        },
        {
          name: 'commentId',
          type: 'id',
          optional: true,
        },
        {
          name: 'commentReply',
          type: 'boolean',
          optional: true,
        },
      ],
    },
    {
      name: 'MentionedInIntegrationActivityDetails',
      properties: [
        {
          name: 'type',
          type: 'IntegrationType',
        },
        {
          name: 'description',
          type: 'string',
        },
        {
          name: 'context',
          type: 'string',
        },
        {
          name: 'link',
          type: 'string',
        },
        {
          name: 'icon',
          type: 'string',
          optional: true,
        },
        {
          name: 'publicMetadata',
          type: 'json',
          optional: true,
        },
      ],
    },
    {
      name: 'CreatedFromActivityDetails',
      properties: [
        {
          name: 'entityId',
          type: 'id',
        },
        {
          name: 'fallbackSpaceKey',
          type: 'string',
        },
        {
          name: 'fallbackEntityTitle',
          type: 'string',
        },
        {
          name: 'fallbackEntityNumber',
          type: 'string',
        },
        {
          name: 'duplicated',
          type: 'boolean',
          optional: true,
        },
        {
          name: 'createdFromCommentId',
          type: 'id',
          optional: true,
        },
        {
          name: 'createdFromTodoId',
          type: 'id',
          optional: true,
        },
      ],
    },
    {
      name: 'HorizonChangedActivityDetails',
      properties: [
        {
          name: 'originalHorizon',
          type: 'ThemeHorizon',
        },
        {
          name: 'horizon',
          type: 'ThemeHorizon',
        },
      ],
    },
    {
      name: 'CodeReviewRequestAddedActivityDetails',
      properties: [
        {
          name: 'codeReviewRequestId',
          type: 'id',
        },
      ],
    },
    {
      name: 'DependencyActivityDetails',
      properties: [
        {
          name: 'targetId',
          type: 'id',
        },
        {
          name: 'dependencyType',
          type: 'DependencyType',
        },
        {
          name: 'inverse',
          type: 'boolean',
        },
        {
          name: 'removed',
          type: 'boolean',
        },
        {
          name: 'fallbackSpaceKey',
          type: 'string',
        },
        {
          name: 'fallbackEntityTitle',
          type: 'string',
        },
        {
          name: 'fallbackEntityNumber',
          type: 'string',
        },
      ],
    },
    {
      name: 'TitleChangedActivityDetails',
      properties: [
        {
          name: 'originalTitle',
          type: 'string',
        },
        {
          name: 'newTitle',
          type: 'string',
        },
      ],
    },
    {
      name: 'TodoChangedActivityDetails',
      properties: [
        {
          name: 'previousStatus',
          type: 'TodoStatus',
        },
        {
          name: 'newStatus',
          type: 'TodoStatus',
        },
        {
          name: 'key',
          type: 'string',
        },
        {
          name: 'todoId',
          type: 'id',
        },
        {
          name: 'context',
          type: 'string',
        },
      ],
    },
    {
      name: 'StaleIssueActivityDetails',
      properties: [
        {
          name: 'period',
          type: 'StaleIssuePeriod',
        },
      ],
    },
    {
      name: 'SpacesChangedActivityDetails',
      properties: [
        {
          name: 'spacesAdded',
          type: 'SpaceChange[]',
        },
        {
          name: 'spacesRemoved',
          type: 'SpaceChange[]',
        },
      ],
    },
    {
      name: 'RoadmapsChangedActivityDetails',
      properties: [
        {
          name: 'roadmapsAdded',
          type: 'RoadmapChange[]',
        },
        {
          name: 'roadmapsRemoved',
          type: 'RoadmapChange[]',
        },
      ],
    },
    {
      name: 'RoadmapColumnChangedActivityDetails',
      properties: [
        {
          name: 'roadmapId',
          type: 'id',
        },
        {
          name: 'columnId',
          type: 'id',
        },

        {
          name: 'originalColumnId',
          type: 'id',
        },
        {
          name: 'fallbackColumnName',
          type: 'string',
        },
        {
          name: 'fallbackRoadmapName',
          type: 'string',
        },
      ],
    },
    {
      name: 'EntityArchivedActivityDetails',
      properties: [
        {
          name: 'action',
          type: 'EntityArchivedAction',
        },
      ],
    },
    {
      name: 'DueDateChangedActivityDetails',
      properties: [
        {
          name: 'dueDate',
          type: 'date',
          optional: true,
        },
        {
          name: 'originalDueDate',
          type: 'date',
          optional: true,
        },
      ],
    },
    {
      name: 'ProcessedChangedActivityDetails',
      properties: [
        {
          name: 'processed',
          type: 'boolean',
        },
        {
          name: 'originalProcessed',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'CompanyPersonChangedActivityDetails',
      properties: [
        {
          name: 'companyId',
          type: 'id',
          optional: true,
        },
        {
          name: 'originalCompanyId',
          type: 'id',
          optional: true,
        },
        {
          name: 'personId',
          type: 'id',
          optional: true,
        },
        {
          name: 'originalPersonId',
          type: 'id',
          optional: true,
        },
      ],
    },
    {
      name: 'CycleHistory',
      input: true,
      properties: [
        { name: 'completedEntities', type: 'id[]' },
        { name: 'completedTodos', type: 'id[]' },
        { name: 'incompleteEntities', type: 'id[]' },
        { name: 'incompleteTodos', type: 'id[]' },
      ],
    },
    {
      name: 'ImportedProvenance',
      properties: [
        { name: 'id', type: 'id' },
        { name: 'link', type: 'string', optional: true },
        { name: 'integrationType', type: 'IntegrationType', optional: true },
        { name: 'importType', type: 'id' },
        { name: 'importSource', type: 'id' },
        { name: 'importSourceType', type: 'id' },
        { name: 'provenanceType', type: 'EntityProvenanceType' },
      ],
    },
    {
      name: 'DuplicatedProvenance',
      properties: [
        { name: 'entityId', type: 'id' },
        { name: 'provenanceType', type: 'EntityProvenanceType' },
      ],
    },
    {
      name: 'CreatedFromProvenance',
      properties: [
        { name: 'entityId', type: 'id' },
        { name: 'createdFromTodoId', type: 'id', optional: true },
        { name: 'createdFromCommentId', type: 'id', optional: true },
        { name: 'provenanceType', type: 'EntityProvenanceType' },
      ],
    },
  ],
  unions: [
    {
      name: 'CreatedMetadata',
      types: ['IssueCreatedMetadata', 'ThemeCreatedMetadata', 'InitiativeCreatedMetadata'],
    },
    {
      name: 'ActivityDetails',
      types: [
        'CreatedActivityDetails',
        'StatusChangedActivityDetails',
        'LabelsChangedActivityDetails',
        'ImpactChangedActivityDetails',
        'EffortChangedActivityDetails',
        'EntitiesChangedActivityDetails',
        'AssigneesChangedActivityDetails',
        'ImportedActivityDetails',
        'CreatedFromActivityDetails',
        'MentionedActivityDetails',
        'MentionedInIntegrationActivityDetails',
        'HorizonChangedActivityDetails',
        'CodeReviewRequestAddedActivityDetails',
        'DependencyActivityDetails',
        'TitleChangedActivityDetails',
        'TodoChangedActivityDetails',
        'StaleIssueActivityDetails',
        'SpacesChangedActivityDetails',
        'RoadmapsChangedActivityDetails',
        'RoadmapColumnChangedActivityDetails',
        'EntityArchivedActivityDetails',
        'DueDateChangedActivityDetails',
        'ProcessedChangedActivityDetails',
        'CompanyPersonChangedActivityDetails',
      ],
    },
    {
      name: 'Entity',
      types: ['Issue', 'Cycle', 'Feedback', 'Initiative', 'Doc', 'Release'],
    },
    {
      name: 'Actor',
      types: ['User', 'Application', 'Integration', 'IntegrationUser'],
    },
    {
      name: 'EntityProvenance',
      types: ['ImportedProvenance', 'DuplicatedProvenance', 'CreatedFromProvenance'],
    },
  ],
  enums: [
    {
      name: 'EffortSize',
      options: ['small', 'medium', 'large'],
    },
    {
      name: 'ImpactSize',
      options: ['small', 'medium', 'large'],
    },
    {
      name: 'BackgroundType',
      options: ['color', 'daily_image', 'gradient', 'image'],
    },
    {
      name: 'IntegrationType',
      options: [
        'discord',
        'email',
        'github',
        'gitlab',
        'figma',
        'slack',
        'slack2',
        'trello',
        'vanta',
        'zapier',
        'custom',
        'intercom',
        'sentry',
        'linear',
        'hubspot',
      ],
    },
    {
      name: 'ActivityType',
      options: [
        'created',
        'status_changed',
        'labels_changed',
        'impact_changed',
        'effort_changed',
        'entities_changed',
        'assignees_changed',
        'imported',
        'duplicated_to',
        'duplicated_from',
        'created_from',
        'mentioned',
        'mentioned_in_integration',
        'horizon_changed',
        'code_review_request_added',
        'dependency',
        'title_changed',
        'todo_changed',
        'stale_issue',
        'spaces_changed',
        'roadmaps_changed',
        'roadmap_column_changed',
        'entity_archived',
        'due_date_changed',
        'processed_changed',
        'company_person_changed',
      ],
    },
    {
      name: 'DependencyType',
      options: ['depends_on', 'blocked_by'],
    },
    {
      name: 'IssueStatusType',
      options: ['backlog', 'todo', 'in_progress', 'done', 'archived'],
    },
    {
      name: 'IssueStatusSortMode',
      options: [
        'manual',
        'impact_effort_asc',
        'impact_effort_desc',
        'impact_asc',
        'impact_desc',
        'effort_asc',
        'effort_desc',
        'created_asc',
        'created_desc',
        'updated_asc',
        'updated_desc',
        'last_status_asc',
        'last_status_desc',
        'cycle_asc',
        'cycle_desc',
        'due_date_asc',
        'due_date_desc',
      ],
    },
    {
      name: 'ThemeHorizon',
      options: ['now', 'next', 'later', 'archived'],
    },
    {
      name: 'RoadmapColumnStatusType',
      options: ['past', 'present', 'future'],
    },
    {
      name: 'RoadmapType',
      options: ['column', 'timeline'],
    },
    {
      name: 'CodeReviewRequestState',
      options: ['open', 'closed', 'merged', 'locked'],
    },
    {
      name: 'TodoStatus',
      options: ['not_started', 'in_progress', 'done'],
    },
    {
      name: 'ExternalIssueStatus',
      options: ['not_started', 'in_progress', 'done', 'archived'],
    },
    {
      name: 'CycleStatus',
      options: ['not_started', 'started', 'stopped'],
    },
    {
      name: 'MemberRole',
      options: ['admin', 'member', 'guest'],
    },
    {
      name: 'AutoArchivePeriod',
      options: ['day', 'week', 'month', 'quarter'],
    },
    {
      name: 'StaleIssuePeriod',
      options: ['month', 'quarter', 'semester', 'year'],
    },
    {
      name: 'UpdateType',
      options: [
        'assigned',
        'unassigned',
        'commented',
        'comment_replied',
        'label_changed',
        'status_changed',
        'comment_user_mentioned',
        'comment_reply_user_mentioned',
        'description_user_mentioned',
        'mentioned',
        'mentioned_in_integration',
        'import_completed',
        'dependency',
        'todo_assigned',
        'feedback_created',
        'collaborative_document_mentioned',
        'new_issue_in_watched_status',
        'stale_issue',
        'auto_archive',
        'due_issues',
      ],
    },
    {
      name: 'EntityProvenanceType',
      options: ['imported', 'duplicated', 'created_from'],
    },
    {
      name: 'EntityArchivedAction',
      options: ['archived', 'unarchived'],
    },
    {
      name: 'ViewType',
      options: ['feedback'],
    },
    {
      name: 'ViewVisibility',
      options: ['public'],
    },
    {
      name: 'ReleaseStatus',
      options: ['planned', 'in_development', 'released'],
    },
  ],
};
