import cn from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../shared/utils/terms';
import { Release } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { useConfiguration } from '../../contexts/configurationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useAddAssigneesToEntities } from '../../syncEngine/actions/entities';
import { useUpdateReleases } from '../../syncEngine/actions/releases';
import { orgEntityKey } from '../../syncEngine/selectors/entities';
import { issuesSelector } from '../../syncEngine/selectors/issues';
import { releasePath, releaseSelector } from '../../syncEngine/selectors/releases';
import { MetadataConfig } from '../metadataConfig';
import { Button, ButtonSize, ButtonStyle } from './button';
import { CustomCommand } from './customCommand';
import { EntityDueDate } from './dueDateMetadata';
import {
  EntityListItem,
  EntityListItemMainContents,
  EntityListItemMembers,
  EntityListItemMenu,
  EntityListItemMetadata,
  EntityListItemTitle,
} from './entityListItem';
import listItemStyles from './entityListItem.module.scss';
import { EntityMembers, EntityWatching } from './entityMetadata';
import { EntityTitleEditor } from './entityTitleEditor';
import { Icon } from './icon';
import { InitiativeRoadmaps } from './initiativeRoadmaps';
import { useHasKeyNavigationFocus, useKeyNavigationElement } from './keyNavigation';
import { MetadataSize } from './metadata/size';
import TodoCount from './metadata/todoCount';
import { ReleaseMenu } from './releaseMenu';
import { ReleaseSpaces } from './releaseSpaces';
import { MetadataTooltip } from './tooltip';

function IssueCount({ release, interactable }: { release: Release; interactable?: boolean }) {
  const issues = useRecoilValue(issuesSelector(release.issueIds));
  const total = React.useMemo(() => issues?.length ?? 0, [issues]);
  const completed = React.useMemo(
    () => issues?.filter(i => i.closedAt || i.archivedAt).length ?? 0,
    [issues]
  );

  if (total === 0) {
    return null;
  }

  const content = <TodoCount total={total} completed={completed} />;
  if (!interactable) {
    return content;
  }

  return (
    <MetadataTooltip
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
      side="bottom"
      align="start"
      content={`${completed}/${total} ${issueTerm}s done`}
    >
      {content}
    </MetadataTooltip>
  );
}

function MetadataLeft({
  release,
  focused,
}: {
  release: Release;
  focused: boolean;
  metadataConfig?: MetadataConfig;
}) {
  const { featureFlags } = useConfiguration();

  return (
    <EntityListItemMetadata
      className={cn('ml8', {
        overlappableMetadataContainer: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
        noShrink: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
      })}
    >
      <IssueCount release={release} interactable={focused} />
    </EntityListItemMetadata>
  );
}

function MetadataRight({
  release,
  focused,
  metadataConfig,
  editMode,
}: {
  release: Release;
  focused: boolean;
  metadataConfig?: MetadataConfig;
  editMode?: boolean;
}) {
  const { featureFlags } = useConfiguration();

  return (
    <EntityListItemMetadata
      className={cn('ml4', {
        overlappableMetadataContainer: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT || editMode,
        noShrink: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT && !editMode,
      })}
    >
      <div
        className={cn('row', 'metadataGap', 'overflowHidden', 'overlappableMetadata', {
          focused,
        })}
      >
        {metadataConfig?.roadmaps !== false && (
          <InitiativeRoadmaps
            initiativeId={release.id}
            size={MetadataSize.Small}
            className="overlappable"
          />
        )}
        {metadataConfig?.spaces !== false && (
          <ReleaseSpaces interactable release={release} className="overlappable" />
        )}
        {metadataConfig?.dueDates !== false && (
          <EntityDueDate item={release} interactable={focused} />
        )}
      </div>
      <EntityWatching entity={release} />
    </EntityListItemMetadata>
  );
}

export function ReleaseListItem({
  id,
  routingState,
  className,
  style,
  keyNavId,
  metadataConfig,
  onChangeTitle,
}: {
  id: string;
  routingState?: any;
  className?: string;
  style?: React.CSSProperties;
  keyNavId?: string;
  metadataConfig?: MetadataConfig;
  onChangeTitle?: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const release = useRecoilValue(releaseSelector(id));
  const organization = useOrganization();
  const focused = useHasKeyNavigationFocus(keyNavId ?? id);
  const history = useHistory();

  useKeyNavigationElement(keyNavId ?? id, ref);

  if (!release) {
    return null;
  }

  const link = {
    pathname: releasePath(organization, release),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: id,
      ...routingState,
    },
  };

  return (
    <Link to={link}>
      <EntityListItem
        ref={ref}
        entityNumber={orgEntityKey(release)}
        className={className}
        style={style}
        statusIcon={<Icon icon="release" />}
      >
        <EntityListItemMainContents>
          <div className="row ellipsis">
            <EntityListItemTitle type={'release'}>{release.title}</EntityListItemTitle>
            {metadataConfig?.todos !== false && (
              <MetadataLeft release={release} focused={focused} metadataConfig={metadataConfig} />
            )}
          </div>
          <MetadataRight release={release} focused={focused} metadataConfig={metadataConfig} />
        </EntityListItemMainContents>
        {metadataConfig?.members !== false && (
          <EntityListItemMembers>
            <EntityMembers interactable entity={release} />
          </EntityListItemMembers>
        )}
        <EntityListItemMenu
          menuContents={closeMenu => (
            <ReleaseMenu release={release} closeMenu={closeMenu} onChangeTitle={onChangeTitle} />
          )}
          date={release.updatedAt}
        />
        {focused && (
          <>
            <CustomCommand
              command={{
                id: 'open',
                hotkey: 'enter',
                group: CommandGroup.Entities,
                description: `Open`,
                priority: 100,
                handler: () => {
                  history.push(link);
                },
              }}
            />
            {onChangeTitle && (
              <CustomCommand
                command={{
                  id: 'edit-title',
                  hotkey: 't',
                  group: CommandGroup.Entities,
                  description: `Edit title`,
                  priority: 99,
                  handler: () => {
                    onChangeTitle();
                  },
                }}
              />
            )}
          </>
        )}
      </EntityListItem>
    </Link>
  );
}

export function EditReleaseListItem({
  id,
  className,
  style,
  metadataConfig,
  onDone,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  metadataConfig?: MetadataConfig;
  onDone: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const release = useRecoilValue(releaseSelector(id));

  const [title, setTitle] = React.useState(release?.title ?? '');
  useKeyNavigationElement(id, ref);

  const updateReleases = useUpdateReleases();
  const addMembers = useAddAssigneesToEntities();

  if (!release) {
    return null;
  }

  function save() {
    if (release?.title !== title && release) {
      updateReleases([release.id], { title });
    }
    onDone();
  }

  return (
    <EntityListItem
      ref={ref}
      entityNumber={orgEntityKey(release)}
      className={className}
      style={style}
      statusIcon={<Icon icon="release" />}
    >
      <EntityListItemMainContents>
        <div className="row ellipsis">
          <EntityTitleEditor
            className={cn('headingS', listItemStyles.titleEditor)}
            initialTitle={title}
            onChange={setTitle}
            onSubmit={save}
            onReset={onDone}
            autoFocus
            oneLine
            supportedMetadata={{
              users: true,
            }}
            onMetadataAdded={(type, metadataId) => {
              switch (type) {
                case 'user':
                  addMembers([release.id], [metadataId]);
                  break;
              }
            }}
            placeholder={<span className="bodyM">Enter a title, use @ for members</span>}
          />
        </div>
        <MetadataRight release={release} focused editMode metadataConfig={metadataConfig} />
      </EntityListItemMainContents>

      {metadataConfig?.members !== false && (
        <EntityListItemMembers>
          <EntityMembers interactable entity={release} />
        </EntityListItemMembers>
      )}
      <div className="rowEnd ml24">
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button size={ButtonSize.Small} onClick={save} buttonStyle={ButtonStyle.Primary}>
          Save
        </Button>
      </div>
    </EntityListItem>
  );
}
