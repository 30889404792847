import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import { ResponsiveDesignSize } from '../../hooks/useResponsiveDesign';
import { isMobileOS } from '../../utils/config';
import { renderDate } from '../../utils/datetime';
import { HideIfSmallerThan } from '../hideIfSmallerThan';
import { ButtonSize, ButtonStyle, IconButton } from './button';
import styles from './entityListItem.module.scss';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './menu/dropdownMenu';
import { Tooltip } from './tooltip';

function EntityListItemComponent(
  {
    entityNumber,
    statusIcon,
    archived,
    children,
    className,
    style,
    blocked,
    fat,
  }: {
    children: React.ReactNode;
    entityNumber: React.ReactNode | null;
    archived?: boolean;
    statusIcon?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    blocked?: boolean;
    fat?: boolean;
  },
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      className={cn(
        styles.listItem,
        {
          [styles.archived]: archived,
          [styles.blocked]: blocked,
          [styles.fat]: fat,
        },
        className
      )}
      style={style}
      ref={ref}
    >
      {entityNumber !== null && <div className={styles.number}>{entityNumber}</div>}
      {statusIcon && <div className={styles.statusIcon}>{statusIcon}</div>}
      {children}
    </div>
  );
}

export const EntityListItem = React.forwardRef(EntityListItemComponent);

export function EntityListItemTitle({
  children,
  type,
  className,
  style,
}: {
  children: string | React.ReactNode;
  type: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [toolTipEnabled, setToolTipEnabled] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && ref.current.clientWidth < ref.current.scrollWidth) {
      setToolTipEnabled(true);
    }
  }, [children]);

  const title = children || `Untitled ${type}`;
  const content = (
    <div style={style} ref={ref} className={cn(styles.title, 'fs-exclude', className)}>
      {title}
    </div>
  );

  if (!toolTipEnabled) {
    return content;
  }

  return <Tooltip content={<div className="menuLarge">{title}</div>}>{content}</Tooltip>;
}

export function EntityListItemMetadata({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
      <div className={cn('row', 'metadataGap', 'overflowHidden', styles.metadata, className)}>
        {children}
      </div>
    </HideIfSmallerThan>
  );
}

export function EntityListItemMainContents({ children }: { children: React.ReactNode }) {
  return <div className="grow rowBetween ellipsis">{children}</div>;
}

export function EntityListItemMembers({ children }: { children: React.ReactNode }) {
  return (
    <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
      <div className={styles.members}>{children}</div>
    </HideIfSmallerThan>
  );
}

export function EntityListItemMenu({
  menuContents,
  date,
  forceMenu,
  narrow,
}: {
  menuContents?: (closeMenu: () => void) => React.ReactNode;
  date?: number;
  forceMenu?: boolean;
  narrow?: boolean;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), []);
  return (
    <div
      className={cn(styles.menuContainer, {
        [styles.menuOpen]: menuOpen || forceMenu,
        [styles.mobile]: isMobileOS,
      })}
    >
      <div className={cn(styles.date, { [styles.narrow]: narrow })}>
        {date ? capitalize(renderDate(date, { compact: true })) : ''}
      </div>
      <div className={cn(styles.menu, { [styles.narrow]: narrow })}>
        <DropdownMenu onOpenChange={setMenuOpen} open={menuOpen}>
          <DropdownMenuTrigger asChild>
            <IconButton
              buttonStyle={ButtonStyle.BareSubtle}
              icon="more"
              size={narrow ? ButtonSize.ExtraSmall : undefined}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
              // we need to prevent default to stop the <a> link behaviour.
              // but, we have an inline datepicker in here, and stopping the default
              // breaks it's functionality. This is a hack to allow the datepicker to work.
              if (!((e.target as HTMLElement).nodeName === 'INPUT')) {
                e.preventDefault();
              }
            }}
            side="right"
            align="start"
          >
            {menuContents?.(closeMenu)}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
