import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  SegmentedControl,
  SegmentedControlVariant,
} from '../../../components/new/segmentedControl';
import { Setting, SettingsPage } from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { usePersistentSettings } from '../../../contexts/usePersistentSettings';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { useUpdatePreferences } from '../../../syncEngine/actions/users';
import { animationsDisabledSelector } from '../../../syncEngine/selectors/users';

export function UserSettingsScreen() {
  const { darkMode, forceDarkMode, forceLightMode, resetDarkModePreference, forced } =
    useDarkMode();
  const { openInApp, setOpenInApp } = usePersistentSettings();
  const updatePreferences = useUpdatePreferences();
  const animationsDisabled = useRecoilValue(animationsDisabledSelector);

  return (
    <SettingsPage title="Account preferences">
      <Setting title="Interface appearance" vertical>
        <SegmentedControl
          options={[
            { id: 'light', icon: 'light', label: 'Light mode' },
            { id: 'dark', icon: 'dark', label: 'Dark mode' },
            { id: 'reset', icon: 'refresh', label: 'System default' },
          ]}
          value={forced ? (darkMode ? 'dark' : 'light') : 'reset'}
          variant={SegmentedControlVariant.Both}
          onValueChanged={(value: string) => {
            switch (value) {
              case 'dark':
                forceDarkMode();
                break;
              case 'light':
                forceLightMode();
                break;
              case 'reset':
                resetDarkModePreference();
                break;
            }
          }}
        />
      </Setting>

      <Setting
        title={'Open links in app by default'}
        description={'Whether or not kitemaker links should be opened in the app by default'}
      >
        <Switch checked={openInApp} onChange={checked => setOpenInApp(checked)} />
      </Setting>
      <Setting
        title={'Disable animations'}
        description={'Turn on to disable all animations in the UI'}
      >
        <Switch
          checked={animationsDisabled}
          onChange={checked => updatePreferences({ animationsDisabled: checked })}
        />
      </Setting>
    </SettingsPage>
  );
}
