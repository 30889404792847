import { lowerCase } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { documentFromString, emptyDocument, isDocumentEmpty } from '../../../shared/slate/utils';
import { capitalize } from '../../../shared/utils/utils';
import {
  CycleStatus,
  Entity,
  IssueStatus,
  IssueStatusType,
  MemberRole,
  Space,
} from '../../../sync/__generated/models';
import { createTitle } from '../../api/ai';
import { BlinkingDots } from '../../components/blinkingDots';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../../components/modal';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import { Company, Person } from '../../components/new/companyPerson';
import { EntityTitleEditor } from '../../components/new/entityTitleEditor';
import { Hotkey } from '../../components/new/hotkey';
import { Icon } from '../../components/new/icon';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import { CycleMenu } from '../../components/new/menu/cycleMenu';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import { EffortMenu } from '../../components/new/menu/effortMenu';
import { ImpactMenu } from '../../components/new/menu/impactMenu';
import { AvatarSize } from '../../components/new/metadata/avatar';
import AvatarGroup from '../../components/new/metadata/avatarGroup';
import { CycleIcon } from '../../components/new/metadata/cycle';
import Effort from '../../components/new/metadata/effort';
import Impact from '../../components/new/metadata/impact';
import { InitiativeIcon } from '../../components/new/metadata/initiative';
import Label from '../../components/new/metadata/label';
import Pill, { PillStyle } from '../../components/new/metadata/pill';
import { MetadataSize } from '../../components/new/metadata/size';
import {
  CompanyPersonPicker,
  CompanyPersonPickerOptions,
} from '../../components/new/pickers/companyPersonPicker';
import { InitiativePicker } from '../../components/new/pickers/initiativePicker';
import { LabelPicker } from '../../components/new/pickers/labelPicker';
import { MemberPicker } from '../../components/new/pickers/memberPicker';
import { ReleasePicker } from '../../components/new/pickers/releasePicker';
import { RoadmapPicker } from '../../components/new/pickers/roadmapPicker';
import { SpacePicker } from '../../components/new/pickers/spacePicker';
import { TagPicker } from '../../components/new/pickers/tagPicker';
import { StatusIndicator } from '../../components/new/statusIndicator';
import { Tooltip } from '../../components/new/tooltip';
import { SubmitHotkeyTooltip } from '../../components/submitHotkeyTooltip';
import { useConfiguration } from '../../contexts/configurationContext';
import { useConfirmation } from '../../contexts/confirmationContext';
import { Modals, NewEntityArgs, NewEntityType, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { SpaceProvider, useMaybeSpace, useSpace } from '../../contexts/spaceContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { RoadmapIcon } from '../../screens/new/newRoadmapScreen/roadmapIcon';
import { useSerializeToMarkdown } from '../../slate/hooks/useSerializeToMarkdown';
import { TextAreaHandle } from '../../slate/textArea';
import { DocumentLike } from '../../slate/types';
import { useCreateDocument } from '../../syncEngine/actions/documents';
import { useCreateFeedback } from '../../syncEngine/actions/feedback';
import { useCreateInitiative } from '../../syncEngine/actions/intiatives';
import { useCreateIssue } from '../../syncEngine/actions/issues';
import { statusesForSpaceInBoardOrderSelector } from '../../syncEngine/selectors/boards';
import { cycleSelector } from '../../syncEngine/selectors/cycles';
import { effortSelector } from '../../syncEngine/selectors/effortLevels';
import {
  entityTypeString,
  useEntityPath,
  useUpdateRecentsCallback,
} from '../../syncEngine/selectors/entities';
import { impactSelector } from '../../syncEngine/selectors/impactLevels';
import { initiativeSelector } from '../../syncEngine/selectors/intiatives';
import { statusSelector } from '../../syncEngine/selectors/issues';
import { firstTodoStatusIdSelector } from '../../syncEngine/selectors/issueStatuses';
import { labelSelector } from '../../syncEngine/selectors/labels';
import { releaseSelector } from '../../syncEngine/selectors/releases';
import { roadmapSelector } from '../../syncEngine/selectors/roadmaps';
import {
  computedVisibilityBySpacesSelector,
  spaceNamesSelector,
  spaceSelector,
} from '../../syncEngine/selectors/spaces';
import {
  spacesForCurrentUserMembershipSelector,
  usersAndMemberSelector,
} from '../../syncEngine/selectors/users';
import {
  addToInitiativeKey,
  alternateComboKey,
  assignIssueKey,
  commandMenuKey,
  cycleFilterHotkey,
  hotkeyHelpMenuKey,
  labelIssueKey,
  mainComboKey,
  moveIssueToNewSpaceKey,
  roadmapInitiativeKey,
  setEffortKey,
  setImpactKey,
  spaceInitiativeKey,
} from '../../utils/config';
import { ActionMenuButton } from './actionMenu';
import { Description } from './description';
import styles from './newEntityModal.module.scss';

const DUMMY_ID = 'dummy';

enum CreateMode {
  Done,
  Open,
  Additional,
}

function TypeDropdown({
  type,
  onTypeChanged,
}: {
  type: NewEntityType;
  onTypeChanged: (t: NewEntityType) => void;
}) {
  const typeString = capitalize(entityTypeString(type));
  const organization = useOrganization();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Secondary}>
          {typeString}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        <DropdownMenuItem onSelect={() => onTypeChanged('Issue')}>Work item</DropdownMenuItem>
        <DropdownMenuItem onSelect={() => onTypeChanged('Initiative')}>Initiative</DropdownMenuItem>
        <DropdownMenuItem onSelect={() => onTypeChanged('Feedback')}>Feedback</DropdownMenuItem>
        {organization.documentsEnabled && (
          <DropdownMenuItem onSelect={() => onTypeChanged('Doc')}>Document</DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function SpaceDropdown({
  spaceId,
  onSpaceIdChanged,
}: {
  spaceId: string;
  onSpaceIdChanged: (spaceId: string) => void;
}) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Secondary} disabled={!space}>
          {space?.name ?? 'No spaces'}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall menuPicker"
      >
        <SpacePicker
          showAll
          state={{ DUMMY_ID: [spaceId] }}
          onSpaceAdded={(_issueIds: string[], spaceId: string) => {
            onSpaceIdChanged(spaceId);
          }}
          onSpaceRemoved={(_issueIds: string[], spaceId: string) => {
            onSpaceIdChanged(spaceId);
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={moveIssueToNewSpaceKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function ImpactDropdown({
  impactId,
  setImpactId,
  orgMode,
}: {
  impactId: string | null;
  setImpactId: (impactId: string | null) => void;
  orgMode?: boolean;
}) {
  const impact = useRecoilValue(impactSelector(impactId));
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <DropdownMenu modal={false} open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onKeyDown={e => {
          e.defaultPrevented = false;
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild={!impact}
      >
        {impact ? (
          <Impact
            color={impact.color}
            size={MetadataSize.Medium}
            longName={impact.name}
            shortName={impact.abbrevation}
          />
        ) : (
          <Pill size={MetadataSize.Medium} pillStyle={PillStyle.Empty}>
            <Icon icon="impact" />
            Impact
          </Pill>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuTiny"
      >
        <ImpactMenu
          forceOrganizationLevel={orgMode}
          onSelect={impactId => setImpactId(impactId)}
          impactId={impactId}
        />
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={setImpactKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function EffortDropdown({
  effortId,
  setEffortId,
  orgMode,
}: {
  effortId: string | null;
  setEffortId: (effortId: string | null) => void;
  orgMode?: boolean;
}) {
  const effort = useRecoilValue(effortSelector(effortId));
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild={!effort}
      >
        {effort ? (
          <Effort
            color={effort.color}
            size={MetadataSize.Medium}
            longName={effort.name}
            shortName={effort.abbrevation}
          />
        ) : (
          <Pill size={MetadataSize.Medium} pillStyle={PillStyle.Empty}>
            <Icon icon="effort" />
            Effort
          </Pill>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuTiny"
      >
        <EffortMenu
          forceOrganizationLevel={orgMode}
          onSelect={effortId => setEffortId(effortId)}
          effortId={effortId}
        />
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={setEffortKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function PlaceholderLabel({ id }: { id: string }) {
  const label = useRecoilValue(labelSelector(id));
  if (!label) {
    return null;
  }
  return <Label color={label?.color} name={label.name} size={MetadataSize.Medium} />;
}

function Labels({
  labelIds,
  orgLevel,
  setLabelIds,
  type,
}: {
  labelIds: string[];
  orgLevel: boolean;
  setLabelIds: (labelIds: string[]) => void;
  type: NewEntityType;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const content = labelIds.length ? (
    labelIds.map(labelId => <PlaceholderLabel key={labelId} id={labelId} />)
  ) : (
    <Pill size={MetadataSize.Medium} pillStyle={PillStyle.Empty}>
      <Icon icon="label" />
      {type === 'Feedback' ? 'Tag' : 'Label'}
    </Pill>
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="row headerButtonGap"
        >
          {content}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuMedium"
      >
        {type !== 'Feedback' && (
          <LabelPicker
            state={{ DUMMY_ID: labelIds }}
            orgLevel={orgLevel}
            onLabelAdded={(_issueIds, labelId) => {
              setLabelIds([...labelIds, labelId]);
            }}
            onLabelRemoved={(_issueIds, labelId) => {
              setLabelIds(labelIds.filter(l => l !== labelId));
            }}
            onDone={closeMenu}
          />
        )}
        {type === 'Feedback' && (
          <TagPicker
            state={{ DUMMY_ID: labelIds }}
            onTagAdded={(_issueIds, labelId) => {
              setLabelIds([...labelIds, labelId]);
            }}
            onTagRemoved={(_issueIds, labelId) => {
              setLabelIds(labelIds.filter(l => l !== labelId));
            }}
            onDone={closeMenu}
          />
        )}
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={labelIssueKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function Spaces({
  spaceIds,
  setSpaceIds,
}: {
  spaceIds: string[];
  setSpaceIds: (spaceIds: string[]) => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const spaceNames = useRecoilValue(spaceNamesSelector(spaceIds));
  const privateSpace = useRecoilValue(computedVisibilityBySpacesSelector(spaceIds)) === 'private';

  const spaceNameContent = spaceIds.length ? <span>{spaceNames.join(', ')}</span> : 'Space';

  const content = (
    <Button
      icon={privateSpace ? 'private' : 'workspace'}
      disabled={privateSpace}
      size={ButtonSize.Small}
      buttonStyle={spaceIds.length > 0 ? ButtonStyle.Secondary : ButtonStyle.SecondarySubtle}
    >
      {spaceNameContent}
    </Button>
  );

  if (privateSpace) {
    return content;
  }

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>{content}</DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuMedium"
      >
        <SpacePicker
          multi
          state={{ DUMMY_ID: spaceIds }}
          onSpaceAdded={(_issueIds, spaceId) => {
            setSpaceIds([...spaceIds, spaceId]);
          }}
          onSpaceRemoved={(_issueIds, spaceId) => {
            setSpaceIds(spaceIds.filter(l => l !== spaceId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={spaceInitiativeKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function PlaceholderRoadmap({ id }: { id: string }) {
  const roadmap = useRecoilValue(roadmapSelector(id));
  if (!roadmap) {
    return null;
  }
  return (
    <Tooltip
      content={
        <>
          Set roadmaps <KeyboardShortcut shortcut={roadmapInitiativeKey} />
        </>
      }
    >
      <Button
        size={ButtonSize.Small}
        buttonStyle={ButtonStyle.Secondary}
        icon={<RoadmapIcon color={roadmap.color} />}
      >
        {roadmap.name}
      </Button>
    </Tooltip>
  );
}

function Roadmaps({
  roadmapIds,
  // PRIVATE-INITIATIVES-AND-ROADMAPS
  spaceId,
  setRoadmapIds,
}: {
  roadmapIds: string[];
  spaceId?: string;
  setRoadmapIds: (roadmapIds: string[]) => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const organization = useOrganization();
  if (!organization.newRoadmapsEnabled) {
    return null;
  }

  const content = roadmapIds.length ? (
    roadmapIds.map(roadmapId => <PlaceholderRoadmap key={roadmapId} id={roadmapId} />)
  ) : (
    <Tooltip
      content={
        <>
          Set roadmaps <KeyboardShortcut shortcut={roadmapInitiativeKey} />
        </>
      }
    >
      <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.SecondarySubtle} icon="roadmap">
        Roadmap
      </Button>
    </Tooltip>
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="row headerButtonGap"
        >
          {content}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuMedium"
      >
        <RoadmapPicker
          spaceId={spaceId}
          state={{ DUMMY_ID: roadmapIds }}
          onRoadmapAdded={(_, roadmapId) => {
            setRoadmapIds([...roadmapIds, roadmapId]);
          }}
          onRoadmapRemoved={(_, roadmapId) => {
            setRoadmapIds(roadmapIds.filter(id => id !== roadmapId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={roadmapInitiativeKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function PlaceholderInitiative({ id }: { id: string }) {
  const initiative = useRecoilValue(initiativeSelector(id));
  if (!initiative) {
    return null;
  }
  return (
    <Tooltip
      content={
        <>
          Set initiatives <KeyboardShortcut shortcut={addToInitiativeKey} />
        </>
      }
    >
      <Button
        size={ButtonSize.Small}
        buttonStyle={ButtonStyle.Secondary}
        icon={<InitiativeIcon color={initiative.color} />}
      >
        {initiative.title}
      </Button>
    </Tooltip>
  );
}

function Initiatives({
  initiativeIds,
  setInitiativeIds,
}: {
  initiativeIds: string[];
  setInitiativeIds: (initiativeIds: string[]) => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const content = initiativeIds.length ? (
    initiativeIds.map(initiativeId => (
      <PlaceholderInitiative key={initiativeId} id={initiativeId} />
    ))
  ) : (
    <Tooltip
      content={
        <>
          Set initiatives <KeyboardShortcut shortcut={addToInitiativeKey} />
        </>
      }
    >
      <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.SecondarySubtle} icon="initiative">
        Initiatives
      </Button>
    </Tooltip>
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="row headerButtonGap"
        >
          {content}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuHuge"
      >
        <InitiativePicker
          state={{ DUMMY_ID: initiativeIds }}
          onInitiativeAdded={(_issueIds, initiativeId) => {
            setInitiativeIds([...initiativeIds, initiativeId]);
          }}
          onInitiativeRemoved={(_issueIds, initaitveId) => {
            setInitiativeIds(initiativeIds.filter(id => id !== initaitveId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={addToInitiativeKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function PlaceholderRelease({ id }: { id: string }) {
  const release = useRecoilValue(releaseSelector(id));
  if (!release) {
    return null;
  }
  return (
    <Tooltip content={<>Set releases</>}>
      <Button
        size={ButtonSize.Small}
        buttonStyle={ButtonStyle.Secondary}
        icon={<Icon icon="release" />}
      >
        {release.title}
      </Button>
    </Tooltip>
  );
}

function Releases({
  releaseIds,
  setReleaseIds,
}: {
  releaseIds: string[];
  setReleaseIds: (releaseIds: string[]) => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const content = releaseIds.length ? (
    releaseIds.map(releaseId => <PlaceholderRelease key={releaseId} id={releaseId} />)
  ) : (
    <Tooltip content={<>Set releases</>}>
      <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.SecondarySubtle} icon="release">
        Releases
      </Button>
    </Tooltip>
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="row headerButtonGap"
        >
          {content}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuHuge"
      >
        <ReleasePicker
          state={{ DUMMY_ID: releaseIds }}
          onReleaseAdded={(_issueIds, releaseId) => {
            setReleaseIds([...releaseIds, releaseId]);
          }}
          onReleaseRemoved={(_issueIds, initaitveId) => {
            setReleaseIds(releaseIds.filter(id => id !== initaitveId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function PlaceholderCycle({ id }: { id: string }) {
  const cycle = useRecoilValue(cycleSelector(id));
  if (!cycle) {
    return null;
  }
  return (
    <Tooltip
      content={
        <>
          Set cycle <KeyboardShortcut shortcut={cycleFilterHotkey} />
        </>
      }
    >
      <Pill size={MetadataSize.Medium}>
        <CycleIcon cycleStatus={cycle.cycleStatus} />
        {cycle.title}
      </Pill>
    </Tooltip>
  );
}
function Cycles({
  cycleId,
  statusId,
  setStatusId,
  setCycleId,
  spaceId,
}: {
  cycleId: string | null;
  statusId: string;
  setCycleId: (cycleId: string | null) => void;
  setStatusId: (statusId: string) => void;
  spaceId: string;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const cycle = useRecoilValue(cycleSelector(cycleId));
  const status = useRecoilValue(statusSelector(statusId));
  const space = useSpace();

  const firstTodoStatusId = useRecoilValue(firstTodoStatusIdSelector(spaceId));

  const content = cycleId ? (
    <PlaceholderCycle key={cycleId} id={cycleId} />
  ) : (
    <Tooltip
      content={
        <>
          Set cycles <KeyboardShortcut shortcut={cycleFilterHotkey} />
        </>
      }
    >
      <Pill size={MetadataSize.Medium} pillStyle={PillStyle.Empty}>
        <Icon icon="cycle_current" />
        Cycle
      </Pill>
    </Tooltip>
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="row headerButtonGap"
        >
          {content}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuLarge"
      >
        <CycleMenu
          inCurrentCycle={cycle?.cycleStatus === CycleStatus.Started}
          inUpcomingCycle={cycle?.cycleStatus === CycleStatus.NotStarted}
          inNoCycles={cycleId === null}
          spaceId={spaceId}
          onSelect={(cycleId: string | null) => {
            setCycleId(cycleId);
            if (
              status?.statusType === IssueStatusType.Backlog &&
              firstTodoStatusId &&
              cycleId === space.activeCycleId
            ) {
              setStatusId(firstTodoStatusId);
            }
            closeMenu();
          }}
        />
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={cycleFilterHotkey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function PersonAndCompany({
  personId,
  companyId,
  onPersonIdChanged,
  onCompanyIdChanged,
}: {
  personId: string | null;
  companyId: string | null;
  onPersonIdChanged: (personId: string | null) => void;
  onCompanyIdChanged: (companyId: string | null) => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const content =
    personId || companyId ? (
      <Button size={ButtonSize.Small} className="gap8">
        {personId ? (
          <Person personId={personId} size={AvatarSize.Size20} />
        ) : (
          <Company companyId={companyId} size={AvatarSize.Size20} />
        )}
      </Button>
    ) : (
      <Tooltip content={<>Set company/person</>}>
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.SecondarySubtle}>
          Company/Person
        </Button>
      </Tooltip>
    );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {content}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuHuge"
      >
        <CompanyPersonPicker
          state={{ DUMMY_ID: [personId ?? companyId ?? ''] }}
          onDone={closeMenu}
          onCompanyAdded={companyId => {
            onCompanyIdChanged(companyId);
            onPersonIdChanged(null);
            closeMenu();
          }}
          onCompanyRemoved={() => {
            onCompanyIdChanged(null);
            closeMenu();
          }}
          onPersonAdded={personId => {
            onPersonIdChanged(personId);
            onCompanyIdChanged(null);
            closeMenu();
          }}
          onPersonRemoved={() => {
            onPersonIdChanged(null);
            closeMenu();
          }}
          options={CompanyPersonPickerOptions.All}
          currentId={companyId ?? personId}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function Assignees({
  assigneeIds,
  setAssigneeIds,
  type,
}: {
  assigneeIds: string[];
  setAssigneeIds: (assigneeIds: string[]) => void;
  type: NewEntityType;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const organization = useOrganization();
  const userMembers = useRecoilValue(
    usersAndMemberSelector({ organizationId: organization.id, userIds: assigneeIds })
  );

  const content = assigneeIds.length ? (
    <AvatarGroup
      max={null}
      size={AvatarSize.Size20}
      avatarData={userMembers.map(user => ({
        name: user.name || user.username,
        img: user.avatar,
        id: user.id,
        deactivated: user.member?.deactivated,
        invited: user.member?.invited,
        guest: user.member?.role === MemberRole.Guest,
      }))}
    />
  ) : (
    <Tooltip
      content={
        <>
          Set assignees <KeyboardShortcut shortcut={assignIssueKey} />
        </>
      }
    >
      <IconButton
        icon={'member'}
        size={ButtonSize.Small}
        buttonStyle={ButtonStyle.SecondarySubtle}
      />
    </Tooltip>
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="row headerButtonGap"
          style={{ height: 30 }}
        >
          {content}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
        className="menuPicker menuMedium"
      >
        <MemberPicker
          filterPlaceholder={type === 'Feedback' ? 'Add owners' : undefined}
          state={{ DUMMY_ID: assigneeIds }}
          onMemberAdded={(_issueIds, assigneeId) => {
            setAssigneeIds([...assigneeIds, assigneeId]);
          }}
          onMemberRemoved={(_issueIds, assigneeId) => {
            setAssigneeIds(assigneeIds.filter(id => id !== assigneeId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
      {!menuOpen && (
        <Hotkey
          hotkey={assignIssueKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}

function calculateInitialStatusId(
  initialStatusId: string | null | undefined,
  initialCycleId: string | null | undefined,
  statuses: IssueStatus[],
  firstTodoStatusId: string | null | undefined,
  space: Space | null
) {
  // if initialStatusId is provided, use it if it's in the same space as secified
  if (
    initialStatusId &&
    (!space || statuses.find(s => s.id === initialStatusId)?.spaceId === space.id)
  ) {
    return initialStatusId;
  }

  return (
    // otherwise if cycleid is set, and it's the active cycle, use the first todo status
    (initialCycleId === space?.activeCycleId ? firstTodoStatusId : null) ??
    // otherwise use the default status
    space?.defaultNewStatusId ??
    // if the space doesn't have a defaultNewStatus for whatever reason, just grab the fisrt one
    statuses[0]?.id
  );
}

function NewEntityModalContents({
  onClose,
  onCreated,
  title: initialTitle,
  type: propType,
  spaceId: initialSpaceId,
  statusId: initialStatusId,
  assigneeIds: initialAssigneeIds,
  labelIds: initialLabelIds,
  initiativeIds: initialInitiativeIds,
  releaseIds: initialReleaseIds,
  spaceIds: initialSpaceIds,
  roadmapIds: initialRoadmapIds,
  impactId: initialImpactId,
  effortId: initialEffortId,
  cycleId: initialCycleId,
  personId: initialPersonId,
  companyId: initialCompanyId,
  folderId,
  content,
  provenance,
}: {
  onClose: () => void;
} & NewEntityArgs) {
  const organization = useOrganization();
  const { confirm } = useConfirmation();
  const { featureFlags } = useConfiguration();

  const createIssue = useCreateIssue();
  const createInitiative = useCreateInitiative();
  const createFeedback = useCreateFeedback();
  const createDocument = useCreateDocument();

  const { favoriteSpaces, nonFavoriteSpaces } = useRecoilValue(
    spacesForCurrentUserMembershipSelector(organization.id)
  );
  const history = useHistory();
  const entityPath = useEntityPath();
  const updateRecents = useUpdateRecentsCallback();
  const toMarkdown = useSerializeToMarkdown();

  const [type, setType] = React.useState(propType ?? 'Issue');

  const spaces = [...favoriteSpaces, ...nonFavoriteSpaces];
  const [spaceId, setSpaceId] = React.useState(initialSpaceId ?? spaces[0]?.id);
  const space = useRecoilValue(spaceSelector(spaceId));
  const statuses = useRecoilValue(statusesForSpaceInBoardOrderSelector(spaceId));
  const firstTodoStatusId = useRecoilValue(firstTodoStatusIdSelector(spaceId));

  const [statusId, setStatusId] = React.useState(
    calculateInitialStatusId(initialStatusId, initialCycleId, statuses, firstTodoStatusId, space)
  );
  const [title, setTitle] = React.useState(initialTitle?.trim() ?? '');
  const [generatingTitle, setGeneratingTitle] = React.useState(false);
  const [titleFocused, setTitleFocused] = React.useState(true);

  const [description, setDescription] = React.useState(content ?? emptyDocument());

  const [assigneeIds, setAssigneeIds] = React.useState<string[]>(initialAssigneeIds ?? []);
  const [labelIds, setLabelIds] = React.useState<string[]>(initialLabelIds ?? []);
  const [spaceIds, setSpaceIds] = React.useState<string[]>(initialSpaceIds ?? []);
  const [roadmapIds, setRoadmapIds] = React.useState<string[]>(initialRoadmapIds ?? []);
  const [initiativeIds, setInitiativeIds] = React.useState<string[]>(initialInitiativeIds ?? []);
  const [releaseIds, setReleaseIds] = React.useState<string[]>(initialReleaseIds ?? []);
  const [effortId, setEffortId] = React.useState<string | null>(initialEffortId ?? null);
  const [impactId, setImpactId] = React.useState<string | null>(initialImpactId ?? null);
  const [cycleId, setCycleId] = React.useState<string | null>(initialCycleId ?? null);
  const [personId, setPersonId] = React.useState<string | null>(initialPersonId ?? null);
  const [companyId, setCompanyId] = React.useState<string | null>(initialCompanyId ?? null);

  const ref = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<TextAreaHandle>(null);
  const descriptionRef = React.useRef<TextAreaHandle>(null);

  const reset = React.useCallback(
    (options?: { resetStatus?: boolean; clearTitleAndDescription?: boolean }) => {
      if (options?.resetStatus) {
        setStatusId(
          calculateInitialStatusId(
            initialStatusId,
            initialCycleId,
            statuses,
            firstTodoStatusId,
            space
          )
        );
      }
      setLabelIds(initialLabelIds ?? []);
      setSpaceIds(initialSpaceIds ?? []);
      setRoadmapIds(initialRoadmapIds ?? []);
      setInitiativeIds(initialInitiativeIds ?? []);
      setAssigneeIds(initialAssigneeIds ?? []);
      setEffortId(initialEffortId ?? null);
      setImpactId(initialImpactId ?? null);
      setCycleId(initialCycleId ?? null);
      setPersonId(initialPersonId ?? null);
      setCompanyId(initialCompanyId ?? null);

      if (options?.clearTitleAndDescription) {
        setDescription(emptyDocument());
        descriptionRef.current?.clear();
        setTitle('');
        titleRef.current?.clear();
        titleRef.current?.focus();
      }
    },
    [
      initialLabelIds,
      initialSpaceIds,
      initialRoadmapIds,
      initialInitiativeIds,
      initialAssigneeIds,
      initialEffortId,
      initialImpactId,
      initialCycleId,
      initialStatusId,
      initialPersonId,
      initialCompanyId,
      statuses,
      firstTodoStatusId,
      space,
    ]
  );

  const generateTitle = React.useCallback(
    async (content: DocumentLike) => {
      if (!titleRef.current) {
        return;
      }
      setGeneratingTitle(true);
      const generatedTitle = await createTitle(organization.id, toMarkdown(content));
      setGeneratingTitle(false);

      if (titleRef.current?.raw()?.children && isDocumentEmpty(titleRef.current.raw().children)) {
        setTitle(generatedTitle);
        titleRef.current?.replaceValue(documentFromString(generatedTitle));
        if (titleFocused) {
          titleRef.current?.selectAll();
        }
      }
    },
    [titleFocused]
  );

  React.useEffect(() => {
    reset({ resetStatus: true });
  }, [spaceId, type, reset]);

  const typeString = entityTypeString(type);
  const typeStringRef = React.useRef(typeString);
  React.useEffect(() => {
    typeStringRef.current = typeString;
  });

  const close = React.useCallback(
    async (force = false) => {
      if (
        force ||
        (titleRef.current &&
          descriptionRef.current &&
          isDocumentEmpty(titleRef.current.raw().children) &&
          isDocumentEmpty(descriptionRef.current.raw().children))
      ) {
        onClose();
        return;
      }

      const confirmed = await confirm(
        'Discard changes?',
        `This ${typeStringRef.current} will not be saved and your changes will be lost.`,
        {
          label: 'Discard',
          cancelLabel: 'Keep editing',
          destructive: true,
        }
      );
      if (confirmed) {
        onClose();
      }
    },
    [onClose, confirm]
  );

  useComponentDidMount(() => {
    ref.current?.focus();
    if (organization.aiEnabled) {
      if (content && !isDocumentEmpty(content) && initialTitle === '') {
        generateTitle(content);
      }
    }

    const overlay = document.querySelector('[data-overlay]') as HTMLDivElement | null;
    function onMouseDown(e: MouseEvent) {
      const childRects = Array.from(overlay?.children || []).map(child =>
        child.getBoundingClientRect()
      );
      for (const childRect of childRects) {
        if (
          e.clientX >= childRect.left &&
          e.clientX <= childRect.right &&
          e.clientY >= childRect.top &&
          e.clientY <= childRect.bottom
        ) {
          return;
        }
      }
      close();
    }
    if (overlay) {
      overlay.addEventListener('mousedown', onMouseDown);
    }
    return () => {
      if (overlay) {
        overlay.removeEventListener('mousedown', onMouseDown);
      }
    };
  });

  function submit(createMode: CreateMode) {
    let entity: Entity | null = null;
    if (type === 'Issue') {
      if (!spaceId) {
        return;
      }
      entity = createIssue(title, spaceId, {
        statusId,
        description,
        initiativeIds,
        releaseIds,
        labelIds,
        assigneeIds,
        impactId,
        effortId,
        cycleId,
        convertSmartTodos: true,
        provenance,
      });
    } else if (type === 'Initiative') {
      const { initiative } = createInitiative(title, {
        description,
        labelIds,
        spaceIds,
        impactId,
        effortId,
        roadmaps: roadmapIds.map(roadmapId => ({ roadmapId })),
        memberIds: assigneeIds,
      });
      entity = initiative;
    } else if (type === 'Feedback') {
      entity = createFeedback(title, {
        content: description,
        ownerIds: assigneeIds,
        tagIds: labelIds,
        personId,
        companyId,
      });
    } else if (type === 'Doc') {
      entity = createDocument(title, {
        content: description,
        folderId,
      });
    }

    if (entity) {
      onCreated?.(entity.id);
      updateRecents(entity.id);
    }

    if (createMode === CreateMode.Done || !entity) {
      close(true);
      return;
    }

    if (createMode === CreateMode.Open) {
      const path = entityPath(entity.id);
      if (path) {
        history.push({
          pathname: entityPath(entity.id),
          search: 'focusDescription=true',
          state: {
            backUrl: location.pathname,
            backSearch: location.search,
            entity: entity.id,
          },
        });
      }
      close(true);
      return;
    }

    reset({ clearTitleAndDescription: true });
  }

  return (
    <SpaceProvider spaceId={type === 'Initiative' ? spaceIds[0] : spaceId}>
      <div className={styles.newEntityModal}>
        <ModalContentWrapper>
          <div className="rowBetween fullWidth">
            <div className="row headerButtonGap">
              <TypeDropdown type={type} onTypeChanged={setType} />
              {type === 'Issue' && (
                <SpaceDropdown spaceId={spaceId} onSpaceIdChanged={setSpaceId} />
              )}
              {type === 'Initiative' && <Spaces spaceIds={spaceIds} setSpaceIds={setSpaceIds} />}
              {type === 'Issue' && (
                <StatusIndicator
                  statusId={statusId}
                  issueId={DUMMY_ID}
                  size={ButtonSize.Small}
                  onPicked={(_issueIds: string[], statusId: string) => {
                    setStatusId(statusId);
                  }}
                  hotkey
                />
              )}
              {type === 'Initiative' && (
                <Roadmaps
                  spaceId={spaceIds[0]}
                  roadmapIds={roadmapIds}
                  setRoadmapIds={setRoadmapIds}
                />
              )}
              {type === 'Issue' && (
                <Initiatives initiativeIds={initiativeIds} setInitiativeIds={setInitiativeIds} />
              )}
              {featureFlags.FEATURE_TOGGLE_RELEASES && type === 'Issue' && (
                <Releases releaseIds={releaseIds} setReleaseIds={setReleaseIds} />
              )}
              {type === 'Feedback' && (
                <PersonAndCompany
                  personId={personId}
                  companyId={companyId}
                  onPersonIdChanged={setPersonId}
                  onCompanyIdChanged={setCompanyId}
                />
              )}
            </div>
            <div className="row headerButtonGap">
              {type !== 'Doc' && (
                <>
                  <Assignees
                    type={type}
                    assigneeIds={assigneeIds}
                    setAssigneeIds={setAssigneeIds}
                  />

                  <ActionMenuButton
                    statusId={statusId}
                    setStatusId={setStatusId}
                    assigneeIds={assigneeIds}
                    setAssigneeIds={setAssigneeIds}
                    labelIds={labelIds}
                    setLabelIds={setLabelIds}
                    effortId={effortId}
                    setEffortId={setEffortId}
                    impactId={impactId}
                    setImpactId={setImpactId}
                    type={type}
                    spaceIds={spaceIds}
                    setSpaceIds={setSpaceIds}
                  />
                </>
              )}
              <Tooltip content="Expand to fullscreen">
                <IconButton
                  icon={'full_screen'}
                  size={ButtonSize.Small}
                  buttonStyle={ButtonStyle.BareSubtle}
                  onClick={() => {
                    submit(CreateMode.Open);
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="rowAlignStart relative fullWidth mt12 mb8">
            <EntityTitleEditor
              key={typeString} // force the title editor to remount to update the placeholder
              ref={titleRef}
              autoFocus
              placeholder={
                generatingTitle ? (
                  <div className="row">
                    Generating title with AI <BlinkingDots className={styles.dots} />
                  </div>
                ) : (
                  `${capitalize(typeString)} title`
                )
              }
              className={styles.title}
              initialTitle={title}
              onFocus={() => setTitleFocused(true)}
              onBlur={() => setTitleFocused(false)}
              onReset={() => {
                if (title === '' && isDocumentEmpty(description)) {
                  close();
                }
              }}
              onChange={v => {
                setTitle(v);
              }}
              onSubmit={_ => {
                descriptionRef.current?.focus();
                descriptionRef.current?.moveSelectionToEnd();
              }}
              supportedMetadata={{
                initiatives: type === 'Issue',
                labels: true,
                users: true,
              }}
              onMetadataAdded={(type, id) => {
                switch (type) {
                  case 'label':
                    setLabelIds([...labelIds, id]);
                    break;
                  case 'user':
                    setAssigneeIds([...assigneeIds, id]);
                    break;
                  case 'initiative':
                    setInitiativeIds([...initiativeIds, id]);
                    break;
                }
              }}
            />
            {organization.aiEnabled && (
              <div className="noShrink ml8">
                <Tooltip
                  content={
                    <div className="col">
                      Generate title with AI
                      {isDocumentEmpty(description) && (
                        <span className="grayed">Needs description.</span>
                      )}
                    </div>
                  }
                >
                  <IconButton
                    icon="ai"
                    disabled={generatingTitle || isDocumentEmpty(description)}
                    onClick={() => {
                      setTitle('');
                      titleRef.current?.clear();
                      generateTitle(description);
                    }}
                    buttonStyle={ButtonStyle.BareSubtle}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          {type !== 'Doc' && (
            <div className="row mb12 headerButtonGap">
              {type === 'Issue' ||
                (type === 'Initiative' && (
                  <>
                    <ImpactDropdown
                      orgMode={type === 'Initiative'}
                      impactId={impactId}
                      setImpactId={setImpactId}
                    />
                    <EffortDropdown
                      orgMode={type === 'Initiative'}
                      effortId={effortId}
                      setEffortId={setEffortId}
                    />
                  </>
                ))}
              {
                <Labels
                  type={type}
                  labelIds={labelIds}
                  setLabelIds={setLabelIds}
                  orgLevel={type === 'Initiative'}
                />
              }
              {type === 'Issue' && space?.cyclesEnabled && (
                <Cycles
                  spaceId={spaceId}
                  cycleId={cycleId}
                  setCycleId={setCycleId}
                  statusId={statusId}
                  setStatusId={setStatusId}
                />
              )}
            </div>
          )}
          <div className={styles.description}>
            <Description
              key={`editor-${space?.id}`}
              descriptionRef={descriptionRef}
              description={description}
              onDescriptionChanged={setDescription}
            />
          </div>
        </ModalContentWrapper>
        <ModalButtonWrapper>
          <div className={styles.buttonRow}>
            <Tooltip
              content={
                <>
                  <KeyboardShortcut shortcut={'esc'} /> to cancel
                </>
              }
            >
              <Button onClick={() => close()} buttonStyle={ButtonStyle.Secondary}>
                Cancel
              </Button>
            </Tooltip>
            <SubmitHotkeyTooltip needsMainComboKey entityType={type}>
              <Button
                onClick={() => submit(CreateMode.Done)}
                buttonStyle={ButtonStyle.Primary}
                disabled={type === 'Issue' && !spaces.length}
              >
                Create {lowerCase(typeString)}
              </Button>
            </SubmitHotkeyTooltip>
          </div>
        </ModalButtonWrapper>
      </div>
      <Hotkey
        hotkey={`${mainComboKey}+enter`}
        global
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          submit(CreateMode.Done);
        }}
      />
      <Hotkey
        hotkey={`${mainComboKey}+${alternateComboKey}+enter`}
        global
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          submit(CreateMode.Open);
        }}
      />
      <Hotkey
        hotkey={`${mainComboKey}+shift+enter`}
        global
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          submit(CreateMode.Additional);
        }}
      />
      <Hotkey
        hotkey={`escape`}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          //  we disabled the real escape handler and we do it on our own here in the modal
          close();
        }}
      />
      {/* FIXME: Swallow some problematic hotkeys so we don't accidentally close the modal */}
      <Hotkey
        hotkey={commandMenuKey}
        global
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
        }}
      />
      <Hotkey
        hotkey={hotkeyHelpMenuKey}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
        }}
      />
    </SpaceProvider>
  );
}

const newEntityModalState = atom<NewEntityArgs | null>({
  key: 'newEntityModalState',
  default: null,
});

export function useNewEntityModalArgs(args: NewEntityArgs) {
  const maybeSpace = useMaybeSpace();
  const set = useSetRecoilState(newEntityModalState);
  React.useEffect(() => {
    const { spaceId, spaceIds, ...rest } = args;
    set({
      spaceId: maybeSpace?.id ?? spaceId,
      spaceIds: maybeSpace ? [maybeSpace.id, ...(spaceIds ?? [])] : spaceIds,
      ...rest,
    });
    return () => set(null);
  }, [args, set, maybeSpace]);
}

export function NewEntityModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() ? (modalManager.currentArgs() as NewEntityArgs) : null;
  const fromRecoil = useRecoilValue(newEntityModalState);

  return (
    <Modal
      modalId={Modals.NewEntity}
      hideHeader
      onClose={() => {
        args?.onCreated?.(null);
      }}
      className={styles.modal}
      disableEscape
      disableInteractOutside
    >
      <NewEntityModalContents
        onCreated={args?.onCreated}
        onClose={() => modalManager.closeModal(Modals.NewEntity)}
        {...fromRecoil}
        {...args}
      />
    </Modal>
  );
}
