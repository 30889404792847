import cn from 'classnames';
import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  IntegrationType,
  MemberRole,
  OrganizationMember,
  Space,
} from '../../../../sync/__generated/models';
import BackButton from '../../../components/new/backButton';
import { Icon } from '../../../components/new/icon';
import { ScreenHeader } from '../../../components/new/screenHeader';
import {
  SidebarContainer,
  SidebarNavigationElement,
  SidebarSpaces,
} from '../../../components/new/sidebar/sidebarComponents';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { SpaceProvider } from '../../../contexts/spaceContext';
import { useHasAdminRights } from '../../../contexts/userContext';
import { isElectron } from '../../../electronIntegration';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import {
  integrationsForOrganizationSelector,
  organizationPath,
} from '../../../syncEngine/selectors/organizations';
import {
  spacesForOrganizationSelector,
  useSpaceSettingsPath,
} from '../../../syncEngine/selectors/spaces';
import { currentUserMembershipState } from '../../../syncEngine/selectors/users';
import { isMac } from '../../../utils/config';
import { scrollIntoView } from '../../../utils/scrolling';
import { NotFoundScreen } from '../../errorScreens';
import { AutomationSettingsScreen } from './automationSettingsScreen';
import { BillingScreen } from './billingScreen';
import { ConnectedAccountsScreen } from './connectedAccountsScreen';
import { CustomEmojiSettings } from './customEmojiSettings';
import { CycleSettingsScreen } from './cycleSettingsScreen';
import { DeveloperSettingsScreen } from './developerSettingsScreen';
import { DocumentsSettingsScreen } from './documentsSettingScreen';
import { EditSnippetsScreen } from './editSnippetsScreen';
import { FeedbackSettings } from './feedbackSettings';
import { ImpactEffortSettings } from './impactEffortSettings';
import { GiHhubImportScreen } from './import/githubImport';
import { TrelloImportScreen } from './import/trelloImport';
import { ImportSettingsScreen } from './importSettingsScreen';
import { IntegrationsSettingsScreen, allIntegrationTypes } from './integrationSettings';
import { LabelSettingsScreen } from './labelsSettingsScreen';
import {
  IntegrationNotFound,
  ManageIntegrationScreen,
  ManageIntegrationsScreen,
  NewIntegrationScreen,
} from './manageIntegrationScreen';
import { ManageTeamScreen } from './manageTeamScreen';
import { NotificationSettingsScreen } from './notificationSettingsScreen';
import { OrganizationSettingsScreen } from './organizationSettingsScreen';
import { ProfileScreen } from './profileScreen';
import { RoadmapSettingsScreen as NewRoadmapSettingScreen } from './roadmapAndInitiativeSettingsScreen';
import styles from './settingsScreen.module.scss';
import { SnippetsSettingsScreen } from './snippetsSettingsScreen';
import { SpaceGeneralSettingsScreen } from './spaceGeneralSettings';
import { SpaceMembersScreen } from './spaceMembersScreen';
import { StatusConfigurationScreen } from './statusConfigurationScreen';
import { UserSettingsScreen } from './userSettings';

function isFullMember(userMembersbip: OrganizationMember | null | undefined): boolean {
  if (!userMembersbip) {
    return false;
  }
  return userMembersbip?.role !== MemberRole.Guest && !userMembersbip?.deactivated;
}

const openSpaces = atom<string[]>({
  key: 'SettingsSidebarOpenSpaces',
  default: [],
});

function SettingsSidebarGroup({
  children,
  title,
  icon,
  className,
}: {
  children: React.ReactNode;
  title: string | React.ReactNode;
  icon: string;
  className?: string;
}) {
  return (
    <div className={cn(styles.settingsNavGroup, className)}>
      <div className={styles.settingsGroupTitle}>
        <div className="row">
          <Icon icon={icon} className={styles.icon} />
        </div>
        {title}
      </div>

      {children}
    </div>
  );
}

function SpaceSettingsGroup({ space }: { space: Space }) {
  const spaceSettingsPath = useSpaceSettingsPath();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [allOpenSpaces, setAllOpenSpaces] = useRecoilState(openSpaces);
  const open = allOpenSpaces.includes(space.id);

  return (
    <>
      <div
        data-settings-space={space.id}
        className={cn(styles.settingsGroupTitle, styles.interactive, styles.expandableGruop)}
        onClick={() =>
          setAllOpenSpaces(previous => {
            return previous.includes(space.id)
              ? previous.filter(id => id !== space.id)
              : [...previous, space.id];
          })
        }
        ref={ref}
      >
        <div className="row">
          <Icon icon={open ? 'expand_more' : 'chevron_right'} className={styles.icon} />
        </div>
        <span className="ellipsis">{space.name}</span>
      </div>
      {open && (
        <>
          <SidebarNavigationElement to={spaceSettingsPath(space.id, ``)}>
            General
          </SidebarNavigationElement>

          <SidebarNavigationElement to={spaceSettingsPath(space.id, `members`)}>
            Members
          </SidebarNavigationElement>

          <SidebarNavigationElement to={spaceSettingsPath(space.id, `statuses`)}>
            Workflow
          </SidebarNavigationElement>
          <SidebarNavigationElement to={spaceSettingsPath(space.id, `cycle`)}>
            Cycles
          </SidebarNavigationElement>
          <SidebarNavigationElement to={spaceSettingsPath(space.id, `labels`)}>
            Labels
          </SidebarNavigationElement>
          <SidebarNavigationElement to={spaceSettingsPath(space.id, `snippets`)}>
            Snippets
          </SidebarNavigationElement>
          <SidebarNavigationElement to={spaceSettingsPath(space.id, `impact-effort`)}>
            Impact and effort
          </SidebarNavigationElement>
          <SidebarNavigationElement to={spaceSettingsPath(space.id, `automation`)}>
            Automation
          </SidebarNavigationElement>
        </>
      )}
    </>
  );
}

function SettingsSidebar() {
  const { featureFlags } = useConfiguration();
  const organization = useOrganization();
  const userMembersbip = useRecoilValue(currentUserMembershipState(organization.id));
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const hasAdminRights = useHasAdminRights();

  const defaultPath = organizationPath(organization);

  const [allOpenSpaces, setAllOpenSpaces] = useRecoilState(openSpaces);

  useComponentDidMount(() => {
    if (allOpenSpaces.length === 0 && spaces.length) {
      setAllOpenSpaces([spaces[0].id]);
    }
  });

  const spaceSettings = spaces.map(space => (
    <SpaceSettingsGroup key={`space-settings-${space.id}`} space={space} />
  ));

  return (
    <SidebarContainer className={styles.sidebarContainer} forceVisible>
      <div
        className={cn('ml16', {
          [styles.trafficLights]: isMac && isElectron(),
        })}
      >
        <BackButton defaultPath={defaultPath}>Back</BackButton>
      </div>
      <div className={styles.sidebarContent}>
        <SidebarSpaces className={styles.spaces}>
          {isFullMember(userMembersbip) && (
            <SettingsSidebarGroup title="Organization settings" icon="org">
              <SidebarNavigationElement
                to={organizationPath(organization, 'settings/organization')}
              >
                General
              </SidebarNavigationElement>

              <SidebarNavigationElement to={organizationPath(organization, 'settings/members')}>
                Members
              </SidebarNavigationElement>

              <SidebarNavigationElement to={organizationPath(organization, 'settings/roadmaps')}>
                Roadmaps and initiatives
              </SidebarNavigationElement>

              <SidebarNavigationElement to={organizationPath(organization, 'settings/feedback')}>
                Feedback
              </SidebarNavigationElement>
              {featureFlags.FEATURE_TOGGLE_DOCUMENTS && (
                <SidebarNavigationElement to={organizationPath(organization, `settings/documents`)}>
                  Documents
                </SidebarNavigationElement>
              )}
              {hasAdminRights && (
                <SidebarNavigationElement to={organizationPath(organization, 'settings/billing')}>
                  Billing
                </SidebarNavigationElement>
              )}

              <SidebarNavigationElement to={organizationPath(organization, 'settings/import')}>
                Import
              </SidebarNavigationElement>
              <SidebarNavigationElement to={organizationPath(organization, 'settings/developer')}>
                Developer
              </SidebarNavigationElement>
              <SidebarNavigationElement
                to={organizationPath(organization, 'settings/integrations')}
              >
                Integrations
              </SidebarNavigationElement>
              <SidebarNavigationElement to={organizationPath(organization, 'settings/emojis')}>
                Custom emojis
              </SidebarNavigationElement>
            </SettingsSidebarGroup>
          )}

          <SettingsSidebarGroup title="Account settings" icon="member">
            <SidebarNavigationElement to={organizationPath(organization, 'settings/profile')}>
              Profile
            </SidebarNavigationElement>
            <SidebarNavigationElement to={organizationPath(organization, 'settings/preferences')}>
              Preferences
            </SidebarNavigationElement>
            <SidebarNavigationElement to={organizationPath(organization, 'settings/notifications')}>
              Notifications
            </SidebarNavigationElement>
            {featureFlags.FEATURE_TOGGLE_NEW_SLACK && (
              <SidebarNavigationElement
                to={organizationPath(organization, 'settings/connectedAccounts')}
              >
                Connected accounts
              </SidebarNavigationElement>
            )}
          </SettingsSidebarGroup>

          {isFullMember(userMembersbip) && spaceSettings.length > 0 && (
            <>
              <SettingsSidebarGroup title="Space settings" icon="workspace">
                {spaceSettings}
              </SettingsSidebarGroup>
            </>
          )}
        </SidebarSpaces>
      </div>
    </SidebarContainer>
  );
}

export function SettingsScreen() {
  const match = useRouteMatch();
  const organization = useOrganization();
  const hasAdminRights = useHasAdminRights();
  const userMembersbip = useRecoilValue(currentUserMembershipState(organization.id));
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const spaceSettingsPath = useSpaceSettingsPath();
  const installedIntegrations = useRecoilValue(
    integrationsForOrganizationSelector(organization.id)
  );
  const setOpenSpaces = useSetRecoilState(openSpaces);

  useComponentDidMount(() => {
    const openSpace = spaces.find(s => location.pathname.includes(s.slug));
    if (openSpace) {
      setOpenSpaces([openSpace.id]);
      const el = document.querySelector<HTMLElement>(`[data-settings-space="${openSpace.id}"]`);
      if (el) {
        scrollIntoView(el, {});
      }
    }
  });

  const spaceGeneralRoutes = spaces.map(space => (
    <Route
      key={`space--${space.id}`}
      exact
      path={spaceSettingsPath(space.id, ``)}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <SpaceGeneralSettingsScreen />
        </SpaceProvider>
      )}
    />
  ));

  const spaceMemberRoutes = spaces.map(space => (
    <Route
      key={`space-access--${space.id}`}
      exact
      path={spaceSettingsPath(space.id, `members`)}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <SpaceMembersScreen />
        </SpaceProvider>
      )}
    />
  ));

  const labelRoutes = spaces.map(space => (
    <Route
      key={`labels--${space.id}`}
      exact
      path={spaceSettingsPath(space.id, `labels`)}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <LabelSettingsScreen />
        </SpaceProvider>
      )}
    />
  ));

  const impactEffortRoutes = spaces.map(space => (
    <Route
      key={`impact-effort--${space.id}`}
      exact
      path={spaceSettingsPath(space.id, `impact-effort`)}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <ImpactEffortSettings />
        </SpaceProvider>
      )}
    />
  ));

  const snippetsRoutes = spaces.map(space => (
    <Route
      key={`snippets-${space.id}`}
      exact
      path={spaceSettingsPath(space.id, `snippets`)}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <SnippetsSettingsScreen />
        </SpaceProvider>
      )}
    />
  ));

  const editSnippetRoutes = spaces.map(space => (
    <Route
      key={`edit-snippet-${space.id}`}
      exact
      path={spaceSettingsPath(space.id, `snippets/:snippetId`)}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <EditSnippetsScreen />
        </SpaceProvider>
      )}
    />
  ));

  const cycleRoutes = spaces.map(space => (
    <Route
      key={`cycle-${space.id}`}
      exact
      path={spaceSettingsPath(space.id, `cycle`)}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <CycleSettingsScreen />
        </SpaceProvider>
      )}
    />
  ));

  const automationRoutes = spaces.map(space => (
    <Route
      key={`automation-${space.id}`}
      exact
      path={spaceSettingsPath(space.id, 'automation')}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <AutomationSettingsScreen />
        </SpaceProvider>
      )}
    />
  ));

  const statusConfigurationRoutes = spaces.map(space => (
    <Route
      key={`statuses-${space.id}`}
      exact
      path={spaceSettingsPath(space.id, 'statuses')}
      render={() => (
        <SpaceProvider spaceId={space.id}>
          <StatusConfigurationScreen />
        </SpaceProvider>
      )}
    />
  ));

  const adminRoutes = hasAdminRights
    ? [
        <Route
          key="billing"
          exact
          path={`${match.path}/billing`}
          render={() => <BillingScreen />}
        />,
      ]
    : [];

  let routes = (
    <Switch>
      <Route key="profile" exact path={`${match.path}/profile`} render={() => <ProfileScreen />} />
      <Route
        key="notifications"
        exact
        path={`${match.path}/notifications`}
        render={() => <NotificationSettingsScreen />}
      />
      <Route
        key="preferences"
        exact
        path={`${match.path}/preferences`}
        render={() => <UserSettingsScreen />}
      />
      <Route
        key="connectedAccounts"
        path={`${match.path}/connectedAccounts/:integrationType?`}
        render={() => <ConnectedAccountsScreen />}
      />

      {...adminRoutes}

      <Route
        key="general"
        exact
        path={`${match.path}/organization`}
        render={() => <OrganizationSettingsScreen />}
      />
      <Route
        key="members"
        exact
        path={`${match.path}/members`}
        render={() => <ManageTeamScreen />}
      />
      <Route
        key="roadmaps"
        exact
        path={`${match.path}/roadmaps`}
        render={() => <NewRoadmapSettingScreen />}
      />
      <Route
        key={`documents`}
        exact
        path={`${match.path}/documents`}
        render={() => <DocumentsSettingsScreen />}
      />
      <Route
        key="developer"
        exact
        path={`${match.path}/developer`}
        render={() => <DeveloperSettingsScreen />}
      />
      <Route
        key="integrations"
        exact
        path={`${match.path}/integrations`}
        render={() => <IntegrationsSettingsScreen />}
      />

      {allIntegrationTypes.map(integrationType => (
        <Route
          key={`new-integration-${integrationType.toLowerCase()}`}
          exact
          path={`${match.path}/integrations/new/${integrationType.toLowerCase()}`}
          render={() => <NewIntegrationScreen integrationType={integrationType} />}
        />
      ))}
      {installedIntegrations.map(integration => (
        <Route
          key={`manage-integration-${integration.id}`}
          exact
          path={`${match.path}/integration/${integration.id}`}
          render={() => <ManageIntegrationScreen integrationId={integration.id} />}
        />
      ))}
      <Route
        key="multiple-integrations"
        path={`${match.path}/integrations/:integrationType`}
        render={({ match }) => (
          <ManageIntegrationsScreen
            integrationType={match.params.integrationType.toUpperCase() as IntegrationType}
          />
        )}
      />
      <Route
        key={`manage-integration-not-found`}
        exact
        path={`${match.path}/integration/:integrationId`}
        render={() => <IntegrationNotFound />}
      />
      <Route
        key="emojis"
        exact
        path={`${match.path}/emojis`}
        render={() => <CustomEmojiSettings />}
      />
      <Route
        key="feedback"
        exact
        path={`${match.path}/feedback`}
        render={() => <FeedbackSettings />}
      />

      <Route
        key="import"
        exact
        path={`${match.path}/import`}
        render={() => <ImportSettingsScreen />}
      />
      <Route
        key="import-trello"
        exact
        path={`${match.path}/import/trello`}
        render={() => <TrelloImportScreen />}
      />
      <Route
        key="import-github"
        exact
        path={`${match.path}/import/github`}
        render={() => <GiHhubImportScreen />}
      />

      {spaceGeneralRoutes}
      {spaceMemberRoutes}
      {labelRoutes}
      {impactEffortRoutes}
      {snippetsRoutes}
      {editSnippetRoutes}
      {cycleRoutes}
      {automationRoutes}
      {statusConfigurationRoutes}

      <Route component={NotFoundScreen} />
    </Switch>
  );

  if (!isFullMember(userMembersbip)) {
    routes = (
      <Switch>
        <Route
          key="profile"
          exact
          path={`${match.path}/profile`}
          render={() => <ProfileScreen />}
        />
        <Route
          key="notifications"
          exact
          path={`${match.path}/notifications`}
          render={() => <NotificationSettingsScreen />}
        />
        <Route
          key="preferences"
          exact
          path={`${match.path}/preferences`}
          render={() => <UserSettingsScreen />}
        />
        <Route component={NotFoundScreen} />
      </Switch>
    );
  }

  return (
    <Screen hideSidebar>
      <TitleSetter title={`${organization.name} · Settings`} />
      <SettingsSidebar />
      <div className={styles.settingsScreen}>
        <ScreenHeader className={styles.header} hideNotifications />
        {routes}
      </div>
    </Screen>
  );
}
