import { groupBy, keys, omit, orderBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import { documentTerm, feedbackTerm, initiativeTerm, issueTerm } from '../../shared/utils/terms';
import { useConfiguration } from '../contexts/configurationContext';
import { entityTypesSelector } from '../syncEngine/selectors/entities';
import {
  CommandDefinition,
  CommandGroup,
  EntityCommandGroupContext,
  commandMenuContext,
  customCommandSelector,
  useCommandMenuContext,
} from './state';
import { useActivityCommandGroup } from './useActivityCommandGroup';
import { useCommentCommandGroup } from './useCommentCommandGroup';
import { useCycleCommandGroup } from './useCycleCommandGroup';
import { useDeveloperCommandGroup } from './useDeveloperCommandGroup';
import { useEntityCommandGroup } from './useEntityCommandGroup';
import { useFeedbackCommandGroup } from './useFeedbackCommandGroup';
import { useInitiativeCommandGroup } from './useInitiativeCommandGroup';
import { useNavigationCommandGroup } from './useNavigationCommandGroup';
import { useOrganizationsCommandGroup } from './useOrganizationsCommandGroup';
import { useRoadmapCommandGroup } from './useRoadmapCommandGroup';
import { useSettingsCommandGroup } from './useSettingsCommandGroup';
import { useSettingsNavigationCommandGroup } from './useSettingsNavigationCommandGroup';
import { useSpaceCommandGroup } from './useSpaceCommandGroup';
import { useStatusColumnCommandGroup } from './useStatusColumnCommandGroup';
import { useTodoCommandGroup } from './useTodoCommandGroup';

export { CommandGroup, useCommandMenuContext, useRegisterCustomCommand } from './state';
export type {
  ActivityGroupContext,
  CommandDefinition,
  CommandGroupContext,
  CommentGroupContext,
  EntityCommandGroupContext as IssueCommandGroupContext,
} from './state';

export function useCommandGroupTitles(): Record<CommandGroup, string> {
  const entityContext = useCommandMenuContext<EntityCommandGroupContext>(CommandGroup.Entities);
  const types = useRecoilValue(entityTypesSelector(entityContext?.entityIds ?? []));
  const allWorkItems = types.length && types.every(t => t === issueTerm);
  const allInitiatives = types.length && types.every(t => t === initiativeTerm);
  const allFeedback = types.length && types.every(t => t === feedbackTerm);
  const allDocs = types.length && types.every(t => t === documentTerm);
  const plural = types.length > 1 ? 's' : '';

  let entityTerm = '...';
  if (allWorkItems) {
    entityTerm = `${issueTerm}${plural}`;
  } else if (allInitiatives) {
    entityTerm = `${initiativeTerm}${plural}`;
  } else if (allFeedback) {
    entityTerm = `${feedbackTerm}${plural}`;
  } else if (allDocs) {
    entityTerm = `${documentTerm}${plural}`;
  }

  return {
    [CommandGroup.Todos]: 'Manage todo',
    [CommandGroup.Entities]: `Manage ${entityTerm}`,
    [CommandGroup.Settings]: `Settings`,
    [CommandGroup.Space]: `Current space`,
    [CommandGroup.EntitySearch]: 'Search',
    [CommandGroup.Organizations]: 'Organizations',
    [CommandGroup.Developer]: 'Developer options',
    [CommandGroup.Other]: 'Other',
    [CommandGroup.Navigation]: 'Navigation',
    [CommandGroup.SettingsNavigation]: `Go to settings`,
    [CommandGroup.SpaceSettingsNavigation]: `Go to space settings`,
    [CommandGroup.Status]: `Manage status column`,
    [CommandGroup.Horizon]: `Manage roadmap horizon`,
    [CommandGroup.Comment]: 'Current comment',
    [CommandGroup.Activity]: 'Current activity',
    [CommandGroup.Update]: 'Current update',
    [CommandGroup.Board]: 'Board actions',
    [CommandGroup.Roadmap]: 'Manage roadmap',
    [CommandGroup.Cycle]: 'Cycle',
  };
}

export function useCommandPriorities(): Record<CommandGroup, number> {
  // const commandMenuContext = useCommandMenuContext();
  // const contexts = commandMenuContext.getContexts();

  return {
    [CommandGroup.Todos]: 15,
    [CommandGroup.Entities]: 10,
    [CommandGroup.Update]: 10,
    [CommandGroup.Status]: 7,
    [CommandGroup.Horizon]: 7,
    [CommandGroup.Roadmap]: 5,
    [CommandGroup.Comment]: 5,
    [CommandGroup.Activity]: 5,
    [CommandGroup.Board]: 4,
    [CommandGroup.Cycle]: 3,
    [CommandGroup.Navigation]: 2,
    [CommandGroup.Organizations]: 1,
    [CommandGroup.Other]: 0,
    [CommandGroup.Space]: -2,
    [CommandGroup.Settings]: -3,
    [CommandGroup.SpaceSettingsNavigation]: -4,
    [CommandGroup.SettingsNavigation]: -5,
    [CommandGroup.Developer]: -5,
    [CommandGroup.EntitySearch]: -10,
  };
}

export function useSortCommandGroups(): (groups: CommandGroup[]) => CommandGroup[] {
  const priorities = useCommandPriorities();

  return (groups: CommandGroup[]) => {
    return orderBy(groups, g => priorities[g], 'desc');
  };
}

function useDumpCommandMenuCommand(
  commands: CommandDefinition[],
  customCommands: CommandDefinition[]
): CommandDefinition[] {
  const { production } = useConfiguration();
  const contexts = useRecoilValue(commandMenuContext);
  const sortGroups = useSortCommandGroups();

  if (production) {
    return [];
  }

  return [
    {
      id: 'cmd-menu-dump',
      description: 'Dump command menu',
      group: CommandGroup.Developer,
      handler: () => {
        /* eslint-disable no-console */
        console.log(`=== Contexts ===`);
        contexts.forEach(c => console.log(c.group, omit(c, 'group')));

        console.log(`\n\n=== Commands ===`);
        const groups = groupBy(commands, 'group');
        const sortedGroups = sortGroups(keys(groups) as CommandGroup[]);
        for (let i = 0; i < sortedGroups.length; i++) {
          const group = sortedGroups[i];
          console.log(`\n# ${group}`);
          groups[group]
            .map(c => c.description)
            .sort()
            .forEach(c => console.log(c));
        }

        console.log(`\n\n=== Custom commands ===`);
        customCommands
          .map(c => c.description)
          .sort()
          .forEach(c => console.log(c));
        /* eslint-enable no-console */
      },
    },
    {
      id: 'cmd-menu-contexts',
      description: 'Dump command menu (contexts only)',
      group: CommandGroup.Developer,
      handler: () => {
        /* eslint-disable no-console */
        console.log(`=== Contexts ===`);
        contexts.forEach(c => console.log(c.group, omit(c, 'group')));
        /* eslint-enable no-console */
      },
    },
    {
      id: 'cmd-menu-dump-custom',
      description: 'Dump command menu (custom commands only)',
      group: CommandGroup.Developer,
      handler: () => {
        /* eslint-disable no-console */
        console.log(`\n\n=== Custom commands ===`);
        customCommands
          .map(c => c.description)
          .sort()
          .forEach(c => console.log(c));
        /* eslint-enable no-console */
      },
    },
    {
      id: 'cmd-menu-dump-duplicate',
      description: 'Dump command duplicates',
      group: CommandGroup.Developer,
      handler: () => {
        const commandNames = commands.map(c => c.description);
        const duplicates = commandNames.filter(c => commandNames.filter(cn => cn === c).length > 1);

        /* eslint-disable no-console */
        console.log(`\n\n=== Duplicate commands: ${duplicates.length} ===`);
        duplicates.sort().forEach(c => console.log(c));
        /* eslint-enable no-console */
      },
    },
  ];
}

export function useAllCommandGroups(): CommandDefinition[] {
  const customCommands = useRecoilValue(customCommandSelector);

  const allCommands = [
    ...useSettingsCommandGroup(),
    ...useSettingsNavigationCommandGroup(),
    ...useNavigationCommandGroup(),
    ...useOrganizationsCommandGroup(),
    ...useDeveloperCommandGroup(),
    ...useEntityCommandGroup(),
    ...useInitiativeCommandGroup(),
    ...useTodoCommandGroup(),
    ...useFeedbackCommandGroup(),
    ...useSpaceCommandGroup(),
    ...useStatusColumnCommandGroup(),
    ...useCommentCommandGroup(),
    ...useActivityCommandGroup(),
    ...useCycleCommandGroup(),
    ...useRoadmapCommandGroup(),
    ...customCommands,
  ];

  const dumpCommandMenu = useDumpCommandMenuCommand(allCommands, customCommands);
  return orderBy([...allCommands, ...dumpCommandMenu], c => c.priority ?? 0, ['desc']).map(c =>
    c.icon ? c : { ...c, icon: c.icon ?? 'none' }
  );
}
