import { capitalize, keyBy, now, sortBy, trim } from 'lodash';
import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isURL } from 'validator';
import isEmail from 'validator/lib/isEmail';
import domainList from '../../../../shared/email/domains.json';
import { Feedback } from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import {
  useCreateCompany,
  useDeleteCompanies,
  useUpdateCompanies,
} from '../../../syncEngine/actions/companies';
import {
  useCreatePerson,
  useDeletePeople,
  useUpdatePeople,
} from '../../../syncEngine/actions/people';
import {
  TEMP_COMPANY_ID,
  companiesForOrganizationSelector,
  companySelector,
  tempCompanyState,
} from '../../../syncEngine/selectors/companies';
import {
  peopleForOrganizationSelector,
  personSelector,
} from '../../../syncEngine/selectors/people';
import { sanitizeUrl, urlHasValidTld } from '../../../utils/urls';
import { toast } from '../../toast';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../button';
import { Company, Person } from '../companyPerson';
import { FilteredListView, FilteredListViewHandle } from '../filteredListView';
import { KeyNavigationProvider } from '../keyNavigation';
import { LISTVIEW_ID, ListViewItem, NO_KEYNAV } from '../listView';
import menuStyles from '../menu/menu.module.scss';
import { PickedState, PickerItem, PickerState, mapPickerState, pickedStateToIcon } from '../picker';
import { TextInput } from '../textInput';
import styles from './companyPersonPicker.module.scss';

const domains = new Set(domainList);

const PERSON_NAME_LABEL = 'Person name';
const PERSON_NAME_PLACEHOLDER = 'John Smith';
const PERSON_EMAIL_LABEL = 'Email';
const PERSON_EMAIL_PLACEHOLDER = 'john@example.com';
const COMPANY_NAME_LABEL = 'Company name';
const COMPANY_NAME_PLACEHOLDER = 'AcmeCorp';
const COMPANY_DOMAIN_LABEL = 'Domain';
const COMPANY_DOMAIN_PLACEHOLDER = 'example.com';

enum CompanyPersonPickerMode {
  Default,
  CreateCompany,
  EditCompany,
  CreatePerson,
  EditPerson,
}

export enum CompanyPersonPickerOptions {
  All,
  People,
  Companies,
}

export function feedbackToCompanyPersonPickerState(feedback: Array<Feedback>): PickerState {
  return feedback.reduce((result, f) => {
    result[f.id] = [f.personId ?? f.companyId ?? ''];
    return result;
  }, {} as PickerState);
}

function extractNameFromEmail(email: string): string {
  const localPart = email.split('@')[0];
  const names = localPart.split('.');
  return names.map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()).join(' ');
}

function CreatePerson({
  initialName,
  onCreated,
  cancel,
}: {
  initialName: string;
  onCreated: (personId: string) => void;
  cancel: () => void;
}) {
  const organization = useOrganization();
  const createPerson = useCreatePerson();
  const createCompany = useCreateCompany();
  const nameLooksLikeEmail = isEmail(initialName);
  const [name, setName] = React.useState(
    nameLooksLikeEmail ? extractNameFromEmail(initialName) : initialName
  );
  const [email, setEmail] = React.useState(nameLooksLikeEmail ? initialName : '');
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  const [companyId, setCompanyId] = React.useState<string | null>();
  const companyIdRef = React.useRef(companyId);
  companyIdRef.current = companyId;
  const [tempCompany, setTempCompany] = useRecoilState(tempCompanyState);
  const companies = useRecoilValue(companiesForOrganizationSelector(organization.id));
  const [companyMode, setCompanyMode] = React.useState(false);

  const emailRef = React.useRef(email);
  emailRef.current = email;

  React.useEffect(() => {
    if (nameLooksLikeEmail) {
      nameInputRef.current?.select();
    }
  }, [nameLooksLikeEmail]);

  React.useEffect(() => {
    if (nameLooksLikeEmail) {
      const emailDomain = email.split('@')[1];
      if (!domains.has(emailDomain)) {
        const companyName = emailDomain.split('.')[0];
        setTempCompany({
          id: TEMP_COMPANY_ID,
          organizationId: organization.id,
          name: capitalize(companyName),
          domain: emailDomain,
          version: 0,
          deleted: false,
          updatedAt: now(),
          createdAt: now(),
          __typename: 'Company',
          avatar: null,
        });
        setCompanyId(TEMP_COMPANY_ID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isEmail(email)) {
      const emailDomain = email.split('@')[1];
      const company = companies.find(c => c.domain === emailDomain);
      if (company) {
        setCompanyId(company.id);
      }
    }
  }, [email, companies]);

  if (companyMode) {
    return (
      <CompanyPersonPicker
        state={{ DUMMY: companyId ? [companyId] : [] }}
        onDone={() => setCompanyMode(false)}
        onCompanyAdded={companyId => {
          setCompanyId(companyId);
          setCompanyMode(false);
        }}
        filterPlaceholder="Set a company"
        options={CompanyPersonPickerOptions.Companies}
        onCompanyRemoved={() => {
          throw new Error('Function not implemented.');
        }}
        onPersonAdded={() => {
          throw new Error('Function not implemented.');
        }}
        onPersonRemoved={() => {
          throw new Error('Function not implemented.');
        }}
      >
        <span className={styles.pickerTitle}>Setting company for {name}</span>
      </CompanyPersonPicker>
    );
  }

  return (
    <form
      className={styles.form}
      onSubmit={() => {
        if (!name.length) {
          toast.error('Person name is required');
        }

        try {
          let newCompany = null;
          if (companyId === TEMP_COMPANY_ID && tempCompany) {
            newCompany = createCompany(
              tempCompany.organizationId,
              tempCompany.name,
              tempCompany.domain
            );
          }
          const created = createPerson(
            organization.id,
            newCompany?.id ?? (companyId || null),
            name,
            email || null
          );
          onCreated(created.id);
        } catch (e) {
          toast.error(e.message ?? 'An unknown error occurred');
        }
      }}
    >
      <div className="col">
        <span className="oneLine mb8 headingS">{PERSON_NAME_LABEL}</span>
        <TextInput
          className="fullWidth"
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          placeholder={PERSON_NAME_PLACEHOLDER}
          ref={nameInputRef}
          autoFocus={nameLooksLikeEmail}
        />
      </div>
      <div className="col">
        <span className="oneLine mb8 headingS">{PERSON_EMAIL_LABEL}</span>
        <TextInput
          className="fullWidth"
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
          placeholder={PERSON_EMAIL_PLACEHOLDER}
          autoFocus={!nameLooksLikeEmail}
          onIdle={() => {
            const companyId = companyIdRef.current;
            if ((!companyId || companyId === TEMP_COMPANY_ID) && isEmail(emailRef.current)) {
              const emailDomain = emailRef.current.split('@')[1];
              if (!domains.has(emailDomain)) {
                const companyName = emailDomain.split('.')[0];
                setTempCompany({
                  id: TEMP_COMPANY_ID,
                  organizationId: organization.id,
                  name: capitalize(companyName),
                  domain: emailDomain,
                  version: 0,
                  deleted: false,
                  updatedAt: now(),
                  createdAt: now(),
                  __typename: 'Company',
                  avatar: null,
                });
                setCompanyId(TEMP_COMPANY_ID);
              }
            } else if (companyId === TEMP_COMPANY_ID) {
              setTempCompany(null);
              setCompanyId(null);
            }
          }}
        />
      </div>
      <div className="col">
        <span className="oneLine mb8 headingS">Company</span>
        <Button className="metadataGap" onClick={() => setCompanyMode(true)} type="button">
          <Company companyId={companyId} />
        </Button>
      </div>
      <div className="rowEnd fullWidth metadataGapL ">
        <Button
          type="button"
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Primary} type="submit">
          Create person
        </Button>
      </div>
    </form>
  );
}

function EditPerson({
  personId,
  onClose,
  cancel,
}: {
  personId: string;
  onClose: () => void;
  cancel: () => void;
}) {
  const organization = useOrganization();
  const createCompany = useCreateCompany();
  const updatePerson = useUpdatePeople();

  const person = useRecoilValue(personSelector(personId));
  const [name, setName] = React.useState(person?.name ?? '');
  const [email, setEmail] = React.useState(person?.email ?? '');
  const emailRef = React.useRef(email);
  emailRef.current = email;
  const nameInputRef = React.useRef<HTMLInputElement>(null);

  const companies = useRecoilValue(companiesForOrganizationSelector(organization.id));
  const [companyId, setCompanyId] = React.useState<string | null>(person?.companyId ?? null);
  const companyIdRef = React.useRef(companyId);
  companyIdRef.current = companyId;
  const [companyMode, setCompanyMode] = React.useState(false);
  const [tempCompany, setTempCompany] = useRecoilState(tempCompanyState);

  React.useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  React.useEffect(() => {
    if ((!companyId || companyId === TEMP_COMPANY_ID) && isEmail(email)) {
      const emailDomain = email.split('@')[1];
      const company = companies.find(c => c.domain === emailDomain);
      if (company) {
        setCompanyId(company.id);
      }
    }
  }, [email, companies, companyId]);

  if (companyMode) {
    return (
      <div className="col fullWidth">
        <span className={styles.header}>Setting company for {name} </span>
        <CompanyPersonPicker
          noEdit
          filterPlaceholder="Set a company"
          onDone={() => setCompanyMode(false)}
          onCompanyAdded={companyId => {
            setCompanyId(companyId);
            setCompanyMode(false);
          }}
          options={CompanyPersonPickerOptions.Companies}
          state={{ dummy: companyId ? [companyId] : [] }}
          onCompanyRemoved={() => {
            setCompanyId(null);
            setCompanyMode(false);
          }}
          onPersonAdded={() => null}
          onPersonRemoved={() => null}
        />
      </div>
    );
  }

  return (
    <form
      className={styles.form}
      onSubmit={() => {
        if (!name.length) {
          toast.error('Person name is required');
        }
        try {
          let newCompany = null;
          if (companyId === TEMP_COMPANY_ID && tempCompany) {
            newCompany = createCompany(
              tempCompany.organizationId,
              tempCompany.name,
              tempCompany.domain
            );
          }
          updatePerson([personId], { name, email, companyId: newCompany?.id ?? companyId });
          onClose();
        } catch (e) {
          toast.error(e.message ?? 'An unknown error occurred');
        }
      }}
    >
      <div className="col">
        <span className="oneLine mb8 headingS">{PERSON_NAME_LABEL}</span>
        <TextInput
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          placeholder={PERSON_NAME_PLACEHOLDER}
          ref={nameInputRef}
          autoFocus
        />
      </div>
      <div className="col">
        <span className="oneLine mb8 headingS">{PERSON_EMAIL_LABEL}</span>
        <TextInput
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
          placeholder={PERSON_EMAIL_PLACEHOLDER}
          onIdle={() => {
            const companyId = companyIdRef.current;
            if ((!companyId || companyId === TEMP_COMPANY_ID) && isEmail(emailRef.current)) {
              const emailDomain = emailRef.current.split('@')[1];
              if (!domains.has(emailDomain)) {
                const companyName = emailDomain.split('.')[0];
                setTempCompany({
                  id: TEMP_COMPANY_ID,
                  organizationId: organization.id,
                  name: capitalize(companyName),
                  domain: emailDomain,
                  version: 0,
                  deleted: false,
                  updatedAt: now(),
                  createdAt: now(),
                  __typename: 'Company',
                  avatar: null,
                });
                setCompanyId(TEMP_COMPANY_ID);
              }
            } else if (companyId === TEMP_COMPANY_ID) {
              setTempCompany(null);
              setCompanyId(null);
            }
          }}
        />
      </div>
      <div className="col">
        <span className="oneLine mb8 headingS">Company</span>
        <Button
          onClick={() => setCompanyMode(true)}
          className="metadataGap"
          tabIndex={-1}
          type="button"
        >
          <Company companyId={companyId} />
        </Button>
      </div>
      <div className="rowEnd fullWidth metadataGapL ">
        <Button
          type="button"
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            cancel();
          }}
        >
          Cancel
        </Button>
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Primary} type="submit">
          Save
        </Button>
      </div>
    </form>
  );
}

function CreateCompany({
  initialName,
  onCreated,
  cancel,
}: {
  initialName: string;
  onCreated: (companyId: string) => void;
  cancel: () => void;
}) {
  const organization = useOrganization();
  const createCompany = useCreateCompany();
  const [name, setName] = React.useState(initialName);
  const nameLooksLikeDomain = isURL(initialName) && urlHasValidTld(sanitizeUrl(initialName));
  const [domain, setDomain] = React.useState(nameLooksLikeDomain ? initialName : '');
  const nameInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (nameLooksLikeDomain) {
      nameInputRef.current?.select();
    }
  }, [nameLooksLikeDomain]);

  return (
    <form
      className={styles.form}
      onSubmit={() => {
        if (!name.length) {
          toast.error('Company name is required');
        }

        try {
          const created = createCompany(organization.id, name, domain || null);
          onCreated(created.id);
        } catch (e) {
          toast.error(e.message ?? 'An unknown error occurred');
        }
      }}
    >
      <span className="oneLine headingS">{COMPANY_NAME_LABEL}</span>
      <TextInput
        className="fullWidth"
        value={name}
        onChange={e => setName(e.currentTarget.value)}
        placeholder={COMPANY_NAME_PLACEHOLDER}
        ref={nameInputRef}
        autoFocus={nameLooksLikeDomain}
      />
      <span className="oneLine headingS">{COMPANY_DOMAIN_LABEL}</span>
      <TextInput
        className="fullWidth"
        value={domain}
        onChange={e => setDomain(e.currentTarget.value)}
        placeholder={COMPANY_DOMAIN_PLACEHOLDER}
        autoFocus={!nameLooksLikeDomain}
      />
      <div className="rowEnd fullWidth metadataGapL">
        <Button
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
          type="button"
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Primary}>
          Create company
        </Button>
      </div>
    </form>
  );
}

function EditCompany({
  companyId,
  onClose,
  cancel,
}: {
  companyId: string;
  onClose: () => void;
  cancel: () => void;
}) {
  const updateCompany = useUpdateCompanies();
  const company = useRecoilValue(companySelector(companyId));

  const [name, setName] = React.useState(company?.name ?? '');
  const [domain, setDomain] = React.useState(company?.domain ?? '');
  const nameInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <form
      className={styles.form}
      onSubmit={() => {
        if (!name.length) {
          toast.error('Company name is required');
        }

        try {
          updateCompany([companyId], { name, domain });
          onClose();
        } catch (e) {
          toast.error(e.message ?? 'An unknown error occurred');
        }
      }}
    >
      <div className="col">
        <span className="oneLine mb8 headingS">{COMPANY_NAME_LABEL}</span>
        <TextInput
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          placeholder={COMPANY_NAME_PLACEHOLDER}
          ref={nameInputRef}
          autoFocus
        />
      </div>
      <div className="col">
        <span className="oneLine mb8 headingS">{COMPANY_DOMAIN_LABEL}</span>
        <TextInput
          value={domain}
          onChange={e => setDomain(e.currentTarget.value)}
          placeholder={COMPANY_DOMAIN_PLACEHOLDER}
        />
      </div>
      <div className="rowEnd fullWidth metadataGapL ">
        <Button
          type="button"
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            cancel();
          }}
        >
          Cancel
        </Button>
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Primary} type="submit">
          Save
        </Button>
      </div>
    </form>
  );
}

function optionsToPlaceholder(options: CompanyPersonPickerOptions) {
  switch (options) {
    case CompanyPersonPickerOptions.All:
      return 'person or company';
    case CompanyPersonPickerOptions.People:
      return 'person';
    case CompanyPersonPickerOptions.Companies:
      return 'company';
  }
}

export function CompanyPersonPicker({
  onDone,
  onCompanyAdded,
  onCompanyRemoved,
  onPersonAdded,
  onPersonRemoved,
  state,
  currentId,
  options,
  disableCreation,
  noEdit,
  children,
  filterPlaceholder,
}: {
  onDone: () => void;
  onCompanyAdded: (companyId: string | null) => void;
  onCompanyRemoved: (companyId: string | null) => void;
  onPersonAdded: (personId: string | null) => void;
  onPersonRemoved: (personId: string | null) => void;
  state: PickerState;
  currentId?: string | null;
  options: CompanyPersonPickerOptions;
  disableCreation?: boolean;
  noEdit?: boolean;
  children?: React.ReactNode;
  filterPlaceholder?: string;
}) {
  const [mode, setMode] = React.useState(CompanyPersonPickerMode.Default);

  const [editId, setEditId] = React.useState<string | null>(null);
  const [newPersonOrCompanyName, setNewPersonOrCompanyName] = React.useState('');
  const organization = useOrganization();
  const companies = useRecoilValue(companiesForOrganizationSelector(organization.id));
  const people = useRecoilValue(peopleForOrganizationSelector(organization.id));
  const filterRef = React.useRef<FilteredListViewHandle>(null);
  const deleteCompanies = useDeleteCompanies();
  const deletePeople = useDeletePeople();

  const cancel = React.useCallback(() => setMode(CompanyPersonPickerMode.Default), []);

  if (mode === CompanyPersonPickerMode.CreateCompany && newPersonOrCompanyName) {
    return (
      <CreateCompany
        cancel={cancel}
        initialName={newPersonOrCompanyName}
        onCreated={id => onCompanyAdded(id)}
      />
    );
  }

  if (mode === CompanyPersonPickerMode.EditCompany && editId) {
    return (
      <EditCompany
        companyId={editId}
        cancel={cancel}
        onClose={() => {
          setEditId(null);
          setMode(CompanyPersonPickerMode.Default);
        }}
      />
    );
  }

  if (mode === CompanyPersonPickerMode.CreatePerson && newPersonOrCompanyName) {
    return (
      <CreatePerson
        cancel={cancel}
        initialName={newPersonOrCompanyName}
        onCreated={id => onPersonAdded(id)}
      />
    );
  }

  if (mode === CompanyPersonPickerMode.EditPerson && editId) {
    return (
      <EditPerson
        personId={editId}
        cancel={cancel}
        onClose={() => {
          setEditId(null);
          setMode(CompanyPersonPickerMode.Default);
        }}
      />
    );
  }

  const companiesById = keyBy(companies, 'id');

  let itemOptions;
  switch (options) {
    case CompanyPersonPickerOptions.All:
      itemOptions = [...companies, ...people];
      break;
    case CompanyPersonPickerOptions.People:
      itemOptions = people;
      break;
    case CompanyPersonPickerOptions.Companies:
      itemOptions = companies;
      break;
  }

  const mappedObjects = mapPickerState(state);

  const items: ListViewItem[] = sortBy(itemOptions, obj => {
    if (obj.__typename === 'Company') {
      return obj.name.toLowerCase();
    } else {
      const company = companiesById[obj.companyId ?? ''];
      return `${company?.name.toLowerCase()} - ${obj.name.toLowerCase()}`;
    }
  }).map(obj => {
    const picked: PickedState | undefined = mappedObjects[obj.id];
    return {
      ...obj,
      icon: pickedStateToIcon(picked),
      onSelected: () => {
        if (obj.__typename === 'Company') {
          if (picked) {
            onCompanyRemoved(obj.id);
          } else {
            onCompanyAdded(obj.id);
          }
        } else {
          if (picked) {
            onPersonRemoved(obj.id);
          } else {
            onPersonAdded(obj.id);
          }
        }
        onDone();
      },
      contents: () => (
        <>
          {obj.__typename === 'Company' && (
            <>
              <Company companyId={obj.id} />
              {!noEdit && (
                <IconButton
                  size={ButtonSize.Small}
                  buttonStyle={ButtonStyle.BareSubtle}
                  className={styles.editButton}
                  icon="delete"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteCompanies([obj.id]);
                  }}
                />
              )}
              {!noEdit && (
                <IconButton
                  size={ButtonSize.Small}
                  buttonStyle={ButtonStyle.BareSubtle}
                  icon="edit"
                  className={styles.editButton}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditId(obj.id);
                    setMode(CompanyPersonPickerMode.EditCompany);
                  }}
                />
              )}
            </>
          )}
          {obj.__typename === 'Person' && (
            <div className="rowBetween menuGap fullWidth noMinWidth">
              <div className="row menuGap noMinWidth">
                <Person personId={obj.id} />
              </div>
              <div className="row menuGap">
                {!noEdit && (
                  <IconButton
                    size={ButtonSize.Small}
                    buttonStyle={ButtonStyle.BareSubtle}
                    className={styles.editButton}
                    icon="delete"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      deletePeople([obj.id]);
                    }}
                  />
                )}
                {!noEdit && (
                  <IconButton
                    size={ButtonSize.Small}
                    buttonStyle={ButtonStyle.BareSubtle}
                    className={styles.editButton}
                    icon="edit"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEditId(obj.id);
                      setMode(CompanyPersonPickerMode.EditPerson);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </>
      ),
    };
  });

  if (items.length === 0) {
    items.push({
      id: NO_KEYNAV,
      contents: `Type to create a new ${optionsToPlaceholder(options)}`,
    });
  }

  return (
    <KeyNavigationProvider
      columnIds={[LISTVIEW_ID]}
      initiallyFocusedElementId={currentId ?? undefined}
    >
      <div className="col fullWidth noMinWidth">
        {children}
        <FilteredListView
          selectFirstOnItemsChanged={false}
          className={styles.list}
          itemClassName={menuStyles.item}
          filterClassName={styles.filter}
          filterPlaceholder={filterPlaceholder ?? `Add a ${optionsToPlaceholder(options)}`}
          ref={filterRef}
          items={items}
          itemRenderFunction={item => (
            <PickerItem key={item.id} scrollIntoView={currentId === item.id} item={item} />
          )}
          propertiesToSearch={['name', 'domain', 'email']}
          filter={(filterStringRaw, search) => {
            const filterString = trim(filterStringRaw);
            const looksLikeEmail = isEmail(filterString);
            let results = search.search(filterString).map(r => r.item);

            if (disableCreation) {
              return results;
            }

            if (looksLikeEmail) {
              results = results.filter(r => (r as any).__typename !== 'Company');
            }
            const exactMatch = !!companies.find(
              l => l.name.toLowerCase() === filterString.toLowerCase()
            );
            if (!exactMatch) {
              if (!looksLikeEmail && options !== CompanyPersonPickerOptions.People) {
                results.push({
                  id: 'new-company',
                  icon: 'org',
                  mouseDown: true,
                  contents: <div>Create a new company &quot;{filterString}&quot;</div>,
                  onSelected: () => {
                    setNewPersonOrCompanyName(filterString);
                    setMode(CompanyPersonPickerMode.CreateCompany);
                  },
                });
              }
              if (options !== CompanyPersonPickerOptions.Companies) {
                results.push({
                  id: 'new-person',
                  icon: 'user',
                  mouseDown: true,
                  contents: <div>Create a new person &quot;{filterString}&quot;</div>,
                  onSelected: () => {
                    setNewPersonOrCompanyName(filterString);
                    setMode(CompanyPersonPickerMode.CreatePerson);
                  },
                });
              }
            }
            return results;
          }}
        />
      </div>
    </KeyNavigationProvider>
  );
}
