import moment from 'moment';

export const isMac = window.navigator.platform.toLowerCase().includes('mac');
export const isWindows = window.navigator.platform.toLowerCase().includes('win');

export // hotkey stuff
const mainComboKey = isMac ? 'meta' : 'ctrl';
export const mainComboKeyString = isMac ? 'cmd' : 'ctrl';
export const alternateComboKey = isMac ? 'option' : 'alt';
export const commandMenuKey = `${mainComboKey}+k`;
export const hotkeyHelpMenuKey = '?';
export const submitKey = `${mainComboKey}+enter`;

export const createNewIssueKey = 'c';
export const createNewCardKey = createNewIssueKey;
export const createNewEntityFromAnywhere = `${alternateComboKey}+c`;
export const editTitleKey = 't';
export const copyIssueNumberKey = `${mainComboKey}+i`;
export const copyIssueLinkKey = `${mainComboKey}+shift+i`;
export const copyActivityLinkKey = `${alternateComboKey}+shift+i`;
export const copyIssueGitBranchKey = `${mainComboKey}+shift+g`;
export const watchIssueKey = 'w';
export const oldSelfAssignIssueKey = 's';
export const selfAssignIssueKey = `shift+s`;
export const takeOwnershipIssueKey = `${alternateComboKey}+s`;
export const vimDown = 'j';
export const vimUp = 'k';
export const vimRight = 'l';
export const vimLeft = 'h';
export const assignIssueKey = '@';
export const labelIssueKey = '#';
export const setImpactKey = 'i';
export const setEffortKey = 'e';
export const addToInitiativeKey = '!';
export const closeIssueKey = `shift+a`;
export const oldCloseIssueKey = `a`;
export const archiveIssueKey = `${mainComboKey}+shift+a`;
export const deleteKey = `${mainComboKey}+backspace`;
export const removeItemKey = `backspace`;
export const moveIssueKey = `m`;
export const moveIssueBottomKey = 'shift+m';
export const moveIssueToNewSpaceKey = `${mainComboKey}+shift+m`;
export const moveAllIssuesKey = `${alternateComboKey}+m`;
export const moveIssueToPreviousStatusKey = ',';
export const moveIssueToNextStatusKey = '.';
export const moveIssueToPreviousStatusKeyBottom = '<';
export const moveIssueToNextStatusKeyBottom = '>';
export const closeAllIssuesKey = `${alternateComboKey}+a`;
export const archiveAllIssuesKey = `shift+${alternateComboKey}+a`;
export const duplicateKey = `${mainComboKey}+d`;
export const searchKey = '/';
export const focusSearchKey = `${mainComboKey}+/`;
export const updatesKey = `${mainComboKey}+u`;
export const oldUpdatesKey = 'u';
export const updatesScreenKey = `g u`;
export const showDependencyGraphKey = 'g -';
export const myWorkKey = '=';
export const myWorkKeyAlternate = 'g m';
export const showArchiveKey = `g a`;
export const showRoadmapKey = `g shift+r`;
export const showSpaceRoadmapKey = `g r`;
export const showInitiativesKey = `g i`;
export const showActiveCycleKey = `g o`;
export const showDocumentsKey = 'g d';
export const showUpcomingCycleKey = `g shift+o`;
export const showIssueBoardKey = `g c`;
export const showBacklogBoardKey = `g p`;
export const showRecentKey = `g l`;
export const showFeedbackKey = `g f`;
export const createStatusKey = `${alternateComboKey}+c`;
export const deleteStatusKey = `${alternateComboKey}+backspace`;
export const renameStatusKey = `${alternateComboKey}+t`;
export const markNotificationReadKey = `a`;
export const markAllNotificationsReadKey = `${alternateComboKey}+a`;
export const editLabelKey = `${mainComboKey}+e`;
export const switchBoardKey = 'tab';
export const selectAndKeepFocusKey = 'shift+enter';
export const dismissTutorialKey = `shift+d`;
export const toggleListViewKey = `${alternateComboKey}+v`;
export const toggleSideBarKey = `${mainComboKey}+s`;
export const filterHotKey = 'f';
export const selfFilterHotKey = 'q';
export const cycleFilterHotkey = 'o';
export const todosAssignedFilterHotKey = 't';
export const todosAssignedToMeFilterHotKey = 'shift+t';
export const watchingHotKey = 'shift+w';
export const addDependsOnKey = '- d';
export const addEnablesKey = '- e';
export const addBlockedByKey = '- x';
export const addBlocksKey = '- b';
export const insertSnipppetKey = `${mainComboKey}+;`;
export const toggleFeedbackProcessedKey = 'p';
export const markTodoListItemCompleted = `a`;
export const markTodoListItemInProgress = `${alternateComboKey}+a`;
export const markTodoCompleted = `${mainComboKey}+enter`;
export const markTodoInProgress = `${mainComboKey}+shift+enter`;
export const todoMembers = `${alternateComboKey}+shift+2`;
export const copyTodoNumber = `${mainComboKey}+shift+i`;
export const copyTodoBranch = `${mainComboKey}+shift+g`;
export const openHistoryKey = `${alternateComboKey}+h`;
export const toggleCompactMetadataKey = `;`;
export const addInsightKey = `${mainComboKey}+shift+i`;
export const addToCurrentCycleKey = 'shift+o';
export const addToUpcomingCycleKey = `${mainComboKey}+shift+o`;
export const addTodoToCurrentCycleKey = `${alternateComboKey}+shift+o`;
export const addTodoToUpcomingCycleKey = `${mainComboKey}+${alternateComboKey}+shift+o`;
export const cycleNotesKey = `n`;
export const toggleSelectionKey = 'x';
export const reactionKey = ':';
export const resolveCommentKey = `${mainComboKey}+enter`;
export const commentKey = `${mainComboKey}+shift+m`;
export const toggleHideKey = `shift+h`;
export const showTodosKey = '[';
export const spaceInitiativeKey = 's';
export const roadmapInitiativeKey = 'p';
export const gotoTabKey = 'y';
export const gotoDescriptionTabKey = 'y d';
export const gotoIssuesTabKey = 'y w';
export const gotoActivitiesTabKey = 'y a';
export const gotoInsightsTabKey = 'y i';
export const gotoTodoListTabKey = 'y t';
export const toggleStarredKey = `${alternateComboKey}+b`;
export const gotoStarredKey = 'g ';
export const showStarredKey = 'g b';
export const addExistingKey = '+';
export const snoozeEntityKey = 'z';
export const todoDetailsKey = `${mainComboKey}+shift+d`;
export const customerKey = `${mainComboKey}+o`;

export { labelColors, paletteColors } from '../../shared/utils/colors';

export const electronDownloadUrl = 'https://dl.todesktop.com/2103113exw0uno5/';
export const electronAppleSiliconDownloadUrl =
  'https://dl.todesktop.com/2103113exw0uno5/mac/dmg/arm64';
export const shareOnTwitterURL = `https://twitter.com/intent/tweet?text=We're%20using%20@KitemakerHQ%20`;

enum MobileOS {
  Unknown,
  iOS,
  Android,
}

function getMobileOperatingSystem(): MobileOS {
  const userAgent = window.navigator.userAgent || window.navigator.vendor;

  if (/android/i.test(userAgent)) {
    return MobileOS.Android;
  }

  if (/iPhone|iPad|iPod\stouch/.test(userAgent) && window.navigator.maxTouchPoints > 0) {
    return MobileOS.iOS;
  }

  return MobileOS.Unknown;
}

export const isMobileOS = getMobileOperatingSystem() !== MobileOS.Unknown;
export const isIOS = getMobileOperatingSystem() === MobileOS.iOS && navigator.maxTouchPoints > 1;
export const isAndroid = getMobileOperatingSystem() === MobileOS.Android;

export const isFirefox = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
// @ts-expect-error safari isn't a property of window, apart from in Safari, hence the check.
export const isSafari = window.safari !== undefined;

export const tooltipDelay: [number, number] = [300, 100];

export const cursorDelay: number = moment.duration(10, 'seconds').asMilliseconds();

// how many spaces to have before always showing "My Spaces"
export const minNumSpacesForMySpaces = 2;

// how long the idle timeout is on text inputs
export const TEXT_IDLE_TIMEOUT = 1000;

export const MAX_LIST_INDENT = 6;
export const MAX_SMART_INDENT = 2;

export const roadmapAndInitiateivesGuideURL =
  'https://guide.kitemaker.co/overview/initiatives-and-roadmaps';

export const documentsGuideURL = 'https://guide.kitemaker.co/overview/documents';
