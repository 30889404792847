import * as React from 'react';
import LinkButton from '../../../components/linkButton';
import { ChangeableAvatar } from '../../../components/new/changeableAvatar';
import { Icon } from '../../../components/new/icon';
import OrganizationAvatar from '../../../components/new/metadata/organizationAvatar';
import {
  Setting,
  SettingsPage,
  SettingsSection,
  SettingsTextInput,
} from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { Tooltip } from '../../../components/new/tooltip';
import { toast } from '../../../components/toast';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useHasAdminRights } from '../../../contexts/userContext';
import { useUpdateOrganization } from '../../../syncEngine/actions/organizations';

export function OrganizationSettingsScreen() {
  const organization = useOrganization();
  const updateOrganization = useUpdateOrganization();
  const { europeanDataResidency } = useConfiguration();
  const hasAdminRights = useHasAdminRights();

  return (
    <SettingsPage title="Organization" description="Manage all the settings for your organization">
      <SettingsSection>
        <Setting title={'Organization name'} vertical>
          <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
            <div>
              <SettingsTextInput
                initialValue={organization.name}
                disabled={!hasAdminRights}
                onSave={name => {
                  updateOrganization({ name });
                  toast.success('Successfully updated organization name');
                }}
              />
            </div>
          </Tooltip>
        </Setting>
        <Setting title="Organization avatar" vertical>
          <p>
            Upload a picture (e.g. logo) for your organization, we recommend at least 128x128 pixels
            large.
          </p>
          <ChangeableAvatar
            name={organization.name}
            img={organization.avatar}
            showButtons
            onUploadCompleted={result => {
              updateOrganization({ avatar: result[0].url });
              toast.success('Organization avatar successfully changed');
            }}
            onRemove={() => updateOrganization({ avatar: null })}
            AvatarComponent={OrganizationAvatar}
            maxSize={{ width: 250, height: 250 }}
          />
        </Setting>

        <Setting
          title="AI features"
          description={
            <>
              <p>Enable AI features for your organization.</p>
              <p>
                Please note that enabling AI features means some data will be shared with Microsoft
                Azure OpenAI
                <LinkButton
                  to="https://guide.kitemaker.co/privacy-for-ai-features"
                  className="row inlineFlex ml-4"
                >
                  Read more in the Kitemaker Guide <Icon icon="external_link" className="ml4" />
                </LinkButton>
              </p>
            </>
          }
        >
          <Tooltip
            disabled={hasAdminRights && !europeanDataResidency}
            content={
              <>
                {europeanDataResidency && (
                  <>
                    AI features are not currently available when European data residency is enabled
                  </>
                )}
                {!europeanDataResidency && !hasAdminRights && (
                  <>Admin rights required to manage AI settings</>
                )}
              </>
            }
          >
            <Switch
              disabled={europeanDataResidency || !hasAdminRights}
              checked={organization.aiEnabled}
              onChange={v => updateOrganization({ aiEnabled: v })}
            />
          </Tooltip>
        </Setting>
      </SettingsSection>
    </SettingsPage>
  );
}
