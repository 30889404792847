import cn from 'classnames';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../shared/utils/terms';
import { Issue } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { useConfiguration } from '../../contexts/configurationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { SpaceProvider } from '../../contexts/spaceContext';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
} from '../../syncEngine/actions/entities';
import {
  useAddIssuesToInitiatives,
  useRemoveIssuesFromInitiatives,
} from '../../syncEngine/actions/intiatives';
import { useUpdateIssues } from '../../syncEngine/actions/issues';
import { spaceForEntitySelector } from '../../syncEngine/selectors/entities';
import {
  dependencyIdsForIssueSelector,
  issueArchivedSelector,
  issuePath,
  issueSelector,
  issuesResolvedSelector,
} from '../../syncEngine/selectors/issues';
import { removeItemKey } from '../../utils/config';
import { MetadataConfig } from '../metadataConfig';
import { Button, ButtonSize, ButtonStyle } from './button';
import { CustomCommand } from './customCommand';
import { EntityDueDate } from './dueDateMetadata';
import {
  EntityListItem,
  EntityListItemMainContents,
  EntityListItemMembers,
  EntityListItemMenu,
  EntityListItemMetadata,
  EntityListItemTitle,
} from './entityListItem';
import listItemStyles from './entityListItem.module.scss';
import {
  EntityCycleIndicator,
  EntityEffort,
  EntityImpact,
  EntityInsights,
  EntityLabels,
  EntityMembers,
  EntitySnoozed,
  EntityTodos,
  EntityWatching,
} from './entityMetadata';
import { EntityTitleEditor } from './entityTitleEditor';
import { IconSize } from './icon';
import { useHasKeyNavigationFocus, useKeyNavigationElement } from './keyNavigation';
import { MetadataSize } from './metadata/size';
import { WorkItemDependencies } from './workItemDependencies';
import styles from './workItemListItem.module.scss';
import { WorkItemMenu } from './workItemMenu';
import {
  WorkItemCodeReviewRequests,
  WorkItemInitiatives,
  WorkItemPublic,
  WorkItemReleases,
  WorkItemStatus,
} from './workItemMetadata';

function MetadataLeft({
  item,
  metadataConfig,
  ignoredInitiatives,
  focused,
}: {
  item: Issue;
  metadataConfig?: MetadataConfig;
  ignoredInitiatives?: string[];
  focused: boolean;
}) {
  const { featureFlags } = useConfiguration();

  return (
    <EntityListItemMetadata
      className={cn('ml8', {
        overlappableMetadataContainer: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
        noShrink: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
      })}
    >
      {!featureFlags.FEATURE_TOGGLE_METADATA_RIGHT && (
        <div
          className={cn('row', 'metadataGap', 'overflowHidden', 'overlappableMetadata', {
            focused,
          })}
        >
          {metadataConfig?.initiatives !== false && (
            <WorkItemInitiatives
              ignoredInitiatives={ignoredInitiatives}
              interactable={focused}
              id={item.id}
              className="overlappable"
            />
          )}
          {metadataConfig?.labels !== false && (
            <EntityLabels
              interactable={focused}
              entity={item}
              size={MetadataSize.Small}
              className="overlappable"
            />
          )}
          {metadataConfig?.releases !== false && (
            <WorkItemReleases interactable={focused} id={item.id} className="overlappable" />
          )}
        </div>
      )}
      {metadataConfig?.todos !== false && <EntityTodos interactable={focused} entity={item} />}
    </EntityListItemMetadata>
  );
}

function MetadataRight({
  item,
  metadataConfig,
  ignoredInitiatives,
  focused,
  editMode,
}: {
  item: Issue;
  metadataConfig?: MetadataConfig;
  ignoredInitiatives?: string[];
  focused: boolean;
  editMode?: boolean;
}) {
  const { featureFlags } = useConfiguration();

  return (
    <EntityListItemMetadata
      className={cn('ml4', {
        overlappableMetadataContainer: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT || editMode,
        noShrink: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT && !editMode,
      })}
    >
      {(featureFlags.FEATURE_TOGGLE_METADATA_RIGHT || editMode) && (
        <div
          className={cn('row', 'metadataGap', 'overflowHidden', 'overlappableMetadata', {
            focused,
          })}
        >
          {metadataConfig?.initiatives !== false && (
            <WorkItemInitiatives
              ignoredInitiatives={ignoredInitiatives}
              interactable={focused}
              id={item.id}
              className="overlappable"
            />
          )}
          {metadataConfig?.labels !== false && (
            <EntityLabels
              interactable={focused}
              entity={item}
              size={MetadataSize.Small}
              className="overlappable"
            />
          )}
        </div>
      )}
      <EntityWatching entity={item} />
      <EntitySnoozed entity={item} />
      <WorkItemPublic
        id={item.id}
        size={IconSize.Size16}
        style={{ marginBottom: '1.5px', fill: 'var(--grayA8)' }}
      />
      {metadataConfig?.insights !== false && (
        <EntityInsights interactable={focused} entity={item} />
      )}
      {metadataConfig?.dependencies !== false && (
        <WorkItemDependencies interactable={focused} id={item.id} />
      )}
      {metadataConfig?.codeReview !== false && (
        <WorkItemCodeReviewRequests interactable={focused} id={item.id} />
      )}
      {metadataConfig?.cycles !== false && (
        <EntityCycleIndicator interactable={focused} entity={item} />
      )}
      {metadataConfig?.impact !== false && <EntityImpact interactable={focused} entity={item} />}
      {metadataConfig?.effort !== false && <EntityEffort interactable={focused} entity={item} />}
      {metadataConfig?.dueDates !== false && <EntityDueDate item={item} interactable={focused} />}
    </EntityListItemMetadata>
  );
}

export function WorkItemListItem({
  id,
  showStatus,
  keyNavId,
  routingState,
  className,
  style,
  ignoredInitiatives,
  metadataConfig,
  onChangeTitle,
  moveToTopBottom,
  initiativeId,
}: {
  id: string;
  showStatus?: boolean;
  keyNavId?: string;
  routingState?: any;
  className?: string;
  style?: React.CSSProperties;
  ignoredInitiatives?: string[];
  metadataConfig?: MetadataConfig;
  onChangeTitle?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], statusId: string) => void;
  initiativeId?: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const item = useRecoilValue(issueSelector(id));
  const space = useRecoilValue(spaceForEntitySelector(id));
  const organization = useOrganization();
  const focused = useHasKeyNavigationFocus(keyNavId ?? id);
  const history = useHistory();
  const archived = useRecoilValue(issueArchivedSelector(id));

  const { blockedByIssueIds } = useRecoilValue(dependencyIdsForIssueSelector(id));
  const blockedByResolved = useRecoilValue(issuesResolvedSelector(blockedByIssueIds));
  const removeIssuesFromInitiatives = useRemoveIssuesFromInitiatives();

  useKeyNavigationElement(keyNavId ?? id, ref);

  if (!item || !space) {
    return null;
  }

  const link = {
    pathname: issuePath(organization, space, item),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: id,
      ...routingState,
    },
  };

  return (
    <Link to={link}>
      <SpaceProvider spaceId={space.id}>
        <EntityListItem
          ref={ref}
          entityNumber={`${space.key}-${item.number}`}
          className={className}
          style={style}
          archived={archived ?? undefined}
          statusIcon={
            showStatus ? <WorkItemStatus id={id} className={styles.workItemStatus} /> : undefined
          }
          blocked={blockedByIssueIds.length > 0 && !blockedByResolved}
        >
          <EntityListItemMainContents>
            <div className="row ellipsis">
              <EntityListItemTitle type={issueTerm}>{item.title}</EntityListItemTitle>
              <MetadataLeft
                item={item}
                metadataConfig={metadataConfig}
                ignoredInitiatives={ignoredInitiatives}
                focused={focused}
              />
            </div>
            <MetadataRight
              item={item}
              metadataConfig={metadataConfig}
              ignoredInitiatives={ignoredInitiatives}
              focused={focused}
            />
            {focused && (
              <>
                <CustomCommand
                  command={{
                    id: 'open',
                    hotkey: 'enter',
                    group: CommandGroup.Entities,
                    description: `Open`,
                    priority: 100,
                    handler: () => {
                      history.push(link);
                    },
                  }}
                />

                {!archived && (
                  <CustomCommand
                    command={{
                      id: 'edit-title',
                      hotkey: 't',
                      group: CommandGroup.Entities,
                      description: `Edit title`,
                      priority: 99,
                      handler: () => {
                        onChangeTitle?.();
                      },
                    }}
                  />
                )}

                {!archived && (
                  <CustomCommand
                    command={{
                      id: 'open-and-focus-Entities',
                      hotkey: 'd',
                      group: CommandGroup.Entities,
                      description: `Open and edit description`,
                      priority: 99,
                      handler: () => {
                        history.push({
                          ...link,
                          pathname: link.pathname,
                          search: 'focusDescription=true',
                        });
                      },
                    }}
                  />
                )}

                {initiativeId && (
                  <CustomCommand
                    command={{
                      id: 'remove-issue-from-initiative',
                      hotkey: removeItemKey,
                      group: CommandGroup.Entities,
                      description: `Remove ${issueTerm} from initiative`,
                      priority: 99,
                      handler: () => {
                        removeIssuesFromInitiatives([initiativeId], [id]);
                      },
                    }}
                  />
                )}
              </>
            )}
          </EntityListItemMainContents>
          {metadataConfig?.members !== false && (
            <EntityListItemMembers>
              <EntityMembers entity={item} />
            </EntityListItemMembers>
          )}
          <EntityListItemMenu
            date={item.displayedUpdatedAt}
            menuContents={closeMenu => (
              <WorkItemMenu
                closeMenu={closeMenu}
                item={item}
                onChangeTitle={onChangeTitle}
                initiativeId={initiativeId}
                moveToTopBottom={moveToTopBottom}
              />
            )}
          />
        </EntityListItem>
      </SpaceProvider>
    </Link>
  );
}

export function EditWorkItemListItem({
  id,
  className,
  style,
  metadataConfig,
  onDone,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  metadataConfig?: MetadataConfig;
  onDone: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const item = useRecoilValue(issueSelector(id));
  const space = useRecoilValue(spaceForEntitySelector(id));
  const [title, setTitle] = React.useState(item?.title ?? '');
  useKeyNavigationElement(id, ref);

  const updateIssues = useUpdateIssues();
  const addLabels = useAddLabelsToEntities();
  const addMembers = useAddAssigneesToEntities();
  const addInitiatives = useAddIssuesToInitiatives();

  if (!item || !space) {
    return null;
  }

  function save() {
    if (item?.title !== title) {
      updateIssues([id], { title });
    }
    onDone();
  }

  return (
    <EntityListItem
      ref={ref}
      entityNumber={`${space?.key}-${item.number}`}
      className={className}
      style={style}
    >
      <EntityListItemMainContents>
        <div className="row ellipsis">
          <EntityTitleEditor
            className={cn('headingS', listItemStyles.titleEditor)}
            initialTitle={title}
            onChange={setTitle}
            onSubmit={save}
            onReset={onDone}
            autoFocus
            oneLine
            supportedMetadata={{
              initiatives: true,
              labels: true,
              users: true,
            }}
            onMetadataAdded={(type, metadataId) => {
              switch (type) {
                case 'label':
                  addLabels([id], [metadataId]);
                  break;
                case 'user':
                  addMembers([id], [metadataId]);
                  break;
                case 'initiative':
                  addInitiatives([metadataId], [id]);
                  break;
              }
            }}
            placeholder={
              <span className="bodyM">
                Enter a title, use @ for members, # for labels and ! for initiatives
              </span>
            }
          />
        </div>
        <MetadataRight item={item} metadataConfig={metadataConfig} focused editMode />
      </EntityListItemMainContents>
      {metadataConfig?.members !== false && (
        <EntityListItemMembers>
          <EntityMembers interactable entity={item} />
        </EntityListItemMembers>
      )}
      <div className="rowEnd ml24">
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button size={ButtonSize.Small} onClick={save} buttonStyle={ButtonStyle.Primary}>
          Save
        </Button>
      </div>
    </EntityListItem>
  );
}
