import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CommandGroup } from '../../commands';
import { useOrganization } from '../../contexts/organizationContext';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
} from '../../syncEngine/actions/entities';
import { useUpdateInitiatives } from '../../syncEngine/actions/intiatives';
import { orgEntityKey } from '../../syncEngine/selectors/entities';
import {
  initiativeIdForRoadmapInitiativeSelector,
  initiativePath,
  initiativeSelector,
  roadmapInitiativeSelector,
} from '../../syncEngine/selectors/intiatives';
import { roadmapIdForColumnSelector } from '../../syncEngine/selectors/roadmaps';
import { MetadataConfig } from '../metadataConfig';
import { Button, ButtonSize, ButtonStyle } from './button';
import { CustomCommand } from './customCommand';
import { EntityDueDate } from './dueDateMetadata';
import {
  EntityCard,
  EntityCardHeader,
  EntityCardMetadataContainer,
  EntityCardTitle,
  EntityMetadataRow,
} from './entityCard';
import {
  EntityEffort,
  EntityImpact,
  EntityInsights,
  EntityLabels,
  EntityMembers,
  EntityTodos,
  EntityWatching,
} from './entityMetadata';
import { EntityTitleEditor } from './entityTitleEditor';
import { InitiativeSpaces } from './initativeSpaces';
import { InitiativeMenu } from './initiativeMenu';
import { InitiativeStatus } from './initiativeStatus';
import { useHasKeyNavigationFocus, useKeyNavigationElement } from './keyNavigation';
import { InitiativeIcon } from './metadata/initiative';
import { MetadataSize } from './metadata/size';

export function InitiativeCard({
  roadmapInitiativeId,
  routingState,
  className,
  style,
  metadataConfig,
  onChangeTitle,
  moveToTopBottom,
}: {
  roadmapInitiativeId: string;
  routingState?: any;
  className?: string;
  style?: React.CSSProperties;
  metadataConfig?: MetadataConfig;
  onChangeTitle?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], statusId: string) => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const roadmapInitaitive = useRecoilValue(roadmapInitiativeSelector(roadmapInitiativeId));
  const roadmapId = useRecoilValue(roadmapIdForColumnSelector(roadmapInitaitive?.columnId));
  const initiative = useRecoilValue(initiativeSelector(roadmapInitaitive?.initiativeId));
  const organization = useOrganization();
  const focused = useHasKeyNavigationFocus(roadmapInitiativeId);
  const history = useHistory();

  useKeyNavigationElement(roadmapInitiativeId, ref);

  if (!initiative) {
    return null;
  }

  const link = {
    pathname: initiativePath(organization, initiative),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: roadmapInitiativeId,
      ...routingState,
    },
  };

  return (
    <Link to={link}>
      <EntityCard ref={ref} className={className} style={style} focused={focused}>
        <EntityCardHeader
          entityNumber={orgEntityKey(initiative)}
          menuContents={closeMenu => (
            <InitiativeMenu
              initiative={initiative}
              closeMenu={closeMenu}
              onChangeTitle={onChangeTitle}
              roadmapId={roadmapId ?? undefined}
              moveToTopBottom={moveToTopBottom}
            />
          )}
          date={initiative.displayedUpdatedAt}
        >
          <EntityWatching entity={initiative} noBorder />
        </EntityCardHeader>
        <div className="rowAlignStart">
          <InitiativeIcon className="mt6 mr8" color={initiative.color} />
          <EntityCardTitle type={'Initiative'}>{initiative.title}</EntityCardTitle>
        </div>
        <EntityMetadataRow>
          {metadataConfig?.spaces !== false && (
            <InitiativeSpaces interactable={focused} initiative={initiative} />
          )}

          {metadataConfig?.labels !== false && (
            <EntityLabels
              interactable={focused}
              entity={initiative}
              orgLevel
              size={MetadataSize.Small}
            />
          )}
        </EntityMetadataRow>
        <EntityCardMetadataContainer
          members={
            metadataConfig?.members !== false ? (
              <EntityMembers interactable entity={initiative} />
            ) : null
          }
        >
          {metadataConfig?.impact !== false && (
            <EntityImpact interactable={focused} entity={initiative} />
          )}

          {metadataConfig?.effort !== false && (
            <EntityEffort interactable={focused} entity={initiative} />
          )}

          {metadataConfig?.todos !== false && (
            <EntityTodos interactable={focused} entity={initiative} />
          )}

          {metadataConfig?.status !== false && <InitiativeStatus initiativeId={initiative.id} />}
          {metadataConfig?.insights !== false && (
            <EntityInsights interactable={focused} entity={initiative} />
          )}
          <EntityDueDate item={initiative} interactable={focused} />
        </EntityCardMetadataContainer>
        {focused && (
          <>
            <CustomCommand
              command={{
                id: 'open',
                hotkey: 'enter',
                group: CommandGroup.Entities,
                description: `Open`,
                priority: 100,
                handler: () => {
                  history.push(link);
                },
              }}
            />
            {onChangeTitle && (
              <CustomCommand
                command={{
                  id: 'edit-title',
                  hotkey: 't',
                  group: CommandGroup.Entities,
                  description: `Edit title`,
                  priority: 99,
                  handler: () => {
                    onChangeTitle();
                  },
                }}
              />
            )}
          </>
        )}
      </EntityCard>
    </Link>
  );
}

export function EditInitiativeCard({
  id,
  className,
  style,
  metadataConfig,
  onDone,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  metadataConfig?: MetadataConfig;
  onDone: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const initiativeId = useRecoilValue(initiativeIdForRoadmapInitiativeSelector(id));
  const initiative = useRecoilValue(initiativeSelector(initiativeId ?? ''));
  const [title, setTitle] = React.useState(initiative?.title ?? '');
  const focused = useHasKeyNavigationFocus(id);

  useKeyNavigationElement(id, ref);

  const updateInitiatives = useUpdateInitiatives();
  const addLabels = useAddLabelsToEntities();
  const addMembers = useAddAssigneesToEntities();

  if (!initiative) {
    return null;
  }

  function save() {
    if (initiative?.title !== title && initiative) {
      updateInitiatives([initiative.id], { title });
    }
    onDone();
  }

  return (
    <EntityCard focused={focused} ref={ref} className={className} style={style}>
      <EntityCardHeader
        entityNumber={orgEntityKey(initiative)}
        menuContents={closeMenu => <InitiativeMenu initiative={initiative} closeMenu={closeMenu} />}
        date={initiative.displayedUpdatedAt}
      ></EntityCardHeader>
      <div className="rowAlignStart">
        <InitiativeIcon className="mt6 mr8" color={initiative.color} />
        <EntityTitleEditor
          className="headingS mt6"
          initialTitle={title}
          onChange={setTitle}
          onSubmit={save}
          onReset={onDone}
          autoFocus
          supportedMetadata={{
            orgLabels: true,
            users: true,
          }}
          onMetadataAdded={(type, metadataId) => {
            switch (type) {
              case 'label':
                addLabels([initiative.id], [metadataId]);
                break;
              case 'user':
                addMembers([initiative.id], [metadataId]);
                break;
            }
          }}
          placeholder={
            <span className="bodyM">Enter a title, use @ for members and # for labels</span>
          }
        />
      </div>
      <EntityMetadataRow>
        {metadataConfig?.spaces !== false && (
          <InitiativeSpaces interactable initiative={initiative} />
        )}
        {metadataConfig?.labels !== false && (
          <EntityLabels interactable entity={initiative} orgLevel size={MetadataSize.Small} />
        )}
      </EntityMetadataRow>
      <EntityCardMetadataContainer
        members={
          metadataConfig?.members !== false ? (
            <EntityMembers interactable entity={initiative} />
          ) : null
        }
      >
        {metadataConfig?.impact !== false && <EntityImpact interactable entity={initiative} />}
        {metadataConfig?.effort !== false && <EntityEffort interactable entity={initiative} />}
        {metadataConfig?.todos !== false && <EntityTodos interactable entity={initiative} />}
        {metadataConfig?.status !== false && <InitiativeStatus initiativeId={initiative.id} />}
        {metadataConfig?.insights !== false && <EntityInsights interactable entity={initiative} />}
      </EntityCardMetadataContainer>
      <div className="rowEnd mt12">
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button size={ButtonSize.Small} onClick={save} buttonStyle={ButtonStyle.Primary}>
          Save
        </Button>
      </div>
    </EntityCard>
  );
}
