import cn from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { capitalize } from '../../../shared/utils/utils';
import { ReleaseStatus } from '../../../sync/__generated/models';
import { HideIfSmallerThan } from '../../components/hideIfSmallerThan';
import { MetadataConfigurationButton } from '../../components/metadataConfig';
import { Breadcrumbs } from '../../components/new/breadcrumbs';
import { Button, ButtonStyle, IconButton } from '../../components/new/button';
import { EntityFilterMenu2 } from '../../components/new/entityFilters2';
import { Filters } from '../../components/new/filters2';
import { KeyNavigationProvider } from '../../components/new/keyNavigation';
import Placeholder from '../../components/new/placeholder';
import { EditReleaseListItem, ReleaseListItem } from '../../components/new/releaseListItem';
import { ScreenHeader } from '../../components/new/screenHeader';
import { Tooltip } from '../../components/new/tooltip';
import { VirtualizedListView } from '../../components/new/virtualizedListView';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { useOrganization } from '../../contexts/organizationContext';
import { OrganizationMarker } from '../../graphql/smartLoad';
import { ResponsiveDesignSize, useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { useCreateRelease } from '../../syncEngine/actions/releases';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { releasesForOrganizationByStatusSelector } from '../../syncEngine/selectors/releases';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { trackerPageLoad } from '../../tracker';
import LoadingScreen from '../loadingScreen';
import styles from './releasesScreen.module.scss';
import { releaseStatusName } from '.';

const RELEASES_FILTER_ID = 'releases-filters';

function SectionHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="listHeaderContainer">
      <div className="listHeader">{children}</div>
    </div>
  );
}

function ListItem({
  id,
  className,
  style,
  onEdit,
  onEditComplete,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onEdit?: () => void;
  onEditComplete?: () => void;
}) {
  return (
    <>
      {onEditComplete ? (
        <EditReleaseListItem id={id} onDone={onEditComplete} className={className} style={style} />
      ) : (
        <ReleaseListItem id={id} style={style} className={className} onChangeTitle={onEdit} />
      )}
    </>
  );
}

function ReleasesPlaceholder() {
  const createRelease = useCreateRelease();

  return (
    <Placeholder icon={'release'} title={'No releases yet'}>
      <span className="grayed">We'll collect all your releases here.</span>
      <Button className="mt8" onClick={() => createRelease('Untitled release')}>
        Create new release
      </Button>
    </Placeholder>
  );
}

// TODO: Make command context
function ReleasesCommandContext() {
  // const { focused, selected } = useKeyNavigationState();
  // const ids = selected ? selected : focused ? [focused] : [];
  // const focusedItem = useRecoilValue(syncEngineState(focused ?? '')) as SyncEngineObject | null;

  // const context: TodoCommandGroupContenxt | EntityCommandGroupContext =
  //   focusedItem?.__typename === 'Todo'
  //     ? { group: CommandGroup.Todos, todoId: ids[0], focusedTodoId: focused }
  //     : { group: CommandGroup.Entities, entityIds: ids, focusedEntityId: focused };

  // if (context.group === CommandGroup.Todos && !context.todoId) {
  //   return null;
  // }

  // return <CommandContext context={context} />;
  return null;
}

function TopBar() {
  const organization = useOrganization();
  const smallScreen = useIsSmallScreen();
  const history = useHistory();

  return (
    <div className={styles.topbar}>
      <EntityFilterMenu2 id={RELEASES_FILTER_ID} />
      <div className="row">
        <Link
          to={{
            pathname: organizationPath(organization, 'archive/releases'),
            state: {
              backUrl: location.pathname,
              backSearch: history.location.search,
            },
          }}
        >
          {smallScreen && (
            <Tooltip content="Archived initiatives">
              <IconButton icon="archive" buttonStyle={ButtonStyle.BareSubtle} />
            </Tooltip>
          )}
          {!smallScreen && (
            <Button icon="archive" buttonStyle={ButtonStyle.BareSubtle}>
              Archive
            </Button>
          )}
        </Link>
        <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
          <MetadataConfigurationButton boardId={RELEASES_FILTER_ID} />
        </HideIfSmallerThan>
      </div>
    </div>
  );
}

export function ReleasesScreen() {
  const organization = useOrganization();
  const items = useRecoilValue(
    releasesForOrganizationByStatusSelector({
      organizationId: organization.id,
      filterId: RELEASES_FILTER_ID,
    })
  );
  const createRelease = useCreateRelease();

  React.useEffect(() => trackerPageLoad('Releases'), []);

  const initiallySelected = React.useMemo(() => {
    return items[Object.keys(items)?.[0]]?.[0];
  }, [items]);

  const organizationLoaded = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );

  if (!organizationLoaded) {
    return <LoadingScreen />;
  }

  // TODO: Do we care about filters?
  return (
    <Screen>
      <TitleSetter title={`${organization.name} · Releases`} />
      <ScreenHeader
        showSidebarOpener
        compensateForMacOSTrafficLights="auto"
        rightSideContent={
          <Button className="mr16" icon="add" onClick={() => createRelease('Untitled release')}>
            New release
          </Button>
        }
      >
        <Breadcrumbs breadcrumbs={[{ name: 'Releases' }]} />
      </ScreenHeader>

      <TopBar />

      <Filters id={`releases-filters`} />
      {Object.keys(items).length === 0 && <ReleasesPlaceholder />}

      {Object.keys(items).length !== 0 && (
        <KeyNavigationProvider
          initiallyFocusedElementId={initiallySelected}
          columnIds={['release-entities']}
          disableEnsureVisible
        >
          <ReleasesCommandContext />
          <VirtualizedListView
            id={`release-entities`}
            className={'fullWidth grow'}
            sectionIds={Object.keys(items)}
            itemIds={items}
            sectionHeaderHeight={60}
            itemHeight={41}
            spacerHeight={32}
            renderSectionHeader={sectionId => (
              <SectionHeader>
                {capitalize(releaseStatusName(sectionId as ReleaseStatus))} releases
              </SectionHeader>
            )}
            renderItem={(id, _sectionId, isFirst, isLast, edit) => {
              return (
                <ListItem
                  id={id}
                  className={cn('listItem', {
                    first: isFirst,
                    last: isLast,
                  })}
                  onEdit={edit?.start}
                  onEditComplete={edit?.end}
                />
              );
            }}
            renderPlaceholder={() => {
              return <div className="fullWidth row grayed">No releases found</div>;
            }}
          />
        </KeyNavigationProvider>
      )}
    </Screen>
  );
}
