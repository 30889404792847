/**
* THIS FILE WAS AUTOMATICALLY GENERATED. NEVER EVER (EVER) EDIT IT BY HAND.
* MODIFY sync.json AND RE-RUN yarn generate-sync-engine
**/

/* eslint-disable quotes */
import gql from "graphql-tag";

export const userFragment = gql`
fragment UserFragment on User {
  username
  name
  primaryEmail
  avatar
  verified
  completedTutorials
  preferences
  emailNotificationDelay
  lastOrganizationId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const userAppliedTransactionPayloadFragment = gql`
fragment UserAppliedTransactionPayloadFragment on UserAppliedTransactionPayload {
  username
  name
  primaryEmail
  avatar
  verified
  completedTutorials
  preferences
  emailNotificationDelay
  lastOrganizationId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const organizationFragment = gql`
fragment OrganizationFragment on Organization {
  name
  slug
  actorId
  ssoDomain
  activeProductId
  activeProductPlanId
  lastPaymentError
  productTierExceeded
  webhooksEnabled
  newRoadmapsEnabled
  aiEnabled
  documentsEnabled
  customEmojis
  avatar
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const organizationAppliedTransactionPayloadFragment = gql`
fragment OrganizationAppliedTransactionPayloadFragment on OrganizationAppliedTransactionPayload {
  name
  slug
  actorId
  ssoDomain
  activeProductId
  activeProductPlanId
  lastPaymentError
  productTierExceeded
  webhooksEnabled
  newRoadmapsEnabled
  aiEnabled
  documentsEnabled
  customEmojis
  avatar
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const organizationMemberFragment = gql`
fragment OrganizationMemberFragment on OrganizationMember {
  organizationId
  userId
  lastSpaceId
  favoriteSpaceIds
  starred
  snoozed {
    id
    snoozedUntil
  }
  feedbackNotificationsEnabled
  invited
  guest
  role
  deactivated
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const organizationMemberAppliedTransactionPayloadFragment = gql`
fragment OrganizationMemberAppliedTransactionPayloadFragment on OrganizationMemberAppliedTransactionPayload {
  organizationId
  userId
  lastSpaceId
  favoriteSpaceIds
  starred
  snoozed {
    id
    snoozedUntil
  }
  feedbackNotificationsEnabled
  invited
  guest
  role
  deactivated
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const updateFragment = gql`
fragment UpdateFragment on Update {
  key
  link
  dependencyIds
  actorIds
  notification
  read
  organizationId
  userId
  organizationMemberId
  context
  integrationType
  icon
  entityId
  commentThreadId
  updateType
  plainTextAction
  markdownAction
  fallbackTitle
  richContent
  inlineCommentContext
  siblingEntityIds
  breadcrumbs
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const updateAppliedTransactionPayloadFragment = gql`
fragment UpdateAppliedTransactionPayloadFragment on UpdateAppliedTransactionPayload {
  key
  link
  dependencyIds
  actorIds
  notification
  read
  organizationId
  userId
  organizationMemberId
  context
  integrationType
  icon
  entityId
  commentThreadId
  updateType
  plainTextAction
  markdownAction
  fallbackTitle
  richContent
  inlineCommentContext
  siblingEntityIds
  breadcrumbs
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const integrationUserFragment = gql`
fragment IntegrationUserFragment on IntegrationUser {
  type
  customIntegrationType
  domain
  externalId
  externalName
  integrationExternalId
  organizationId
  organizationMemberIds
  userIds
  configValid
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const integrationUserAppliedTransactionPayloadFragment = gql`
fragment IntegrationUserAppliedTransactionPayloadFragment on IntegrationUserAppliedTransactionPayload {
  type
  customIntegrationType
  domain
  externalId
  externalName
  integrationExternalId
  organizationId
  organizationMemberIds
  configValid
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const integrationFragment = gql`
fragment IntegrationFragment on Integration {
  type
  customIntegrationType
  domain
  config
  externalId
  organizationId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const integrationAppliedTransactionPayloadFragment = gql`
fragment IntegrationAppliedTransactionPayloadFragment on IntegrationAppliedTransactionPayload {
  type
  customIntegrationType
  domain
  config
  externalId
  organizationId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const applicationFragment = gql`
fragment ApplicationFragment on Application {
  name
  avatar
  token
  revoked
  internal
  organizationId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const applicationAppliedTransactionPayloadFragment = gql`
fragment ApplicationAppliedTransactionPayloadFragment on ApplicationAppliedTransactionPayload {
  name
  avatar
  token
  revoked
  internal
  organizationId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const personalAccessTokenFragment = gql`
fragment PersonalAccessTokenFragment on PersonalAccessToken {
  name
  token
  revoked
  organizationId
  userId
  organizationMemberId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const personalAccessTokenAppliedTransactionPayloadFragment = gql`
fragment PersonalAccessTokenAppliedTransactionPayloadFragment on PersonalAccessTokenAppliedTransactionPayload {
  name
  token
  revoked
  organizationId
  userId
  organizationMemberId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const spaceFragment = gql`
fragment SpaceFragment on Space {
  name
  slug
  key
  organizationId
  tutorial
  roadmapEnabled
  cyclesEnabled
  publicRoadmap
  publicRoadmapMetadata
  actorId
  backgroundType
  backgroundConfig
  private
  members
  sort
  automationEvents {
    git_closed {
      statusId
    }
    git_in_progress {
      statusId
    }
    git_pull_request {
      statusId
    }
  }
  redirects
  activeCycleId
  upcomingCycleId
  autoArchive
  staleIssues
  cycleDuration
  cycleCooldown
  cycleStartDay
  addToCurrentCycle
  utcOffset
  timezone
  defaultNewStatusId
  defaultDoneStatusId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const spaceAppliedTransactionPayloadFragment = gql`
fragment SpaceAppliedTransactionPayloadFragment on SpaceAppliedTransactionPayload {
  name
  slug
  key
  organizationId
  tutorial
  roadmapEnabled
  cyclesEnabled
  publicRoadmap
  publicRoadmapMetadata
  actorId
  backgroundType
  backgroundConfig
  private
  members
  sort
  automationEvents {
    git_closed {
      statusId
    }
    git_in_progress {
      statusId
    }
    git_pull_request {
      statusId
    }
  }
  redirects
  activeCycleId
  upcomingCycleId
  autoArchive
  staleIssues
  cycleDuration
  cycleCooldown
  cycleStartDay
  addToCurrentCycle
  utcOffset
  timezone
  defaultNewStatusId
  defaultDoneStatusId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const spaceRoadmapFragment = gql`
fragment SpaceRoadmapFragment on SpaceRoadmap {
  spaceId
  roadmapId
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const spaceRoadmapAppliedTransactionPayloadFragment = gql`
fragment SpaceRoadmapAppliedTransactionPayloadFragment on SpaceRoadmapAppliedTransactionPayload {
  spaceId
  roadmapId
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const issueStatusFragment = gql`
fragment IssueStatusFragment on IssueStatus {
  spaceId
  name
  statusType
  sortMode
  issueLimit
  watcherIds
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const issueStatusAppliedTransactionPayloadFragment = gql`
fragment IssueStatusAppliedTransactionPayloadFragment on IssueStatusAppliedTransactionPayload {
  spaceId
  name
  statusType
  sortMode
  issueLimit
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const boardFragment = gql`
fragment BoardFragment on Board {
  spaceId
  name
  key
  sort
  shared
  sharedMetadata
  sharedWorkItems
  defaultStatusType
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const boardAppliedTransactionPayloadFragment = gql`
fragment BoardAppliedTransactionPayloadFragment on BoardAppliedTransactionPayload {
  spaceId
  name
  key
  sort
  shared
  sharedMetadata
  sharedWorkItems
  defaultStatusType
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const boardColumnFragment = gql`
fragment BoardColumnFragment on BoardColumn {
  boardId
  statusId
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const boardColumnAppliedTransactionPayloadFragment = gql`
fragment BoardColumnAppliedTransactionPayloadFragment on BoardColumnAppliedTransactionPayload {
  boardId
  statusId
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const issueLabelFragment = gql`
fragment IssueLabelFragment on IssueLabel {
  spaceId
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const issueLabelAppliedTransactionPayloadFragment = gql`
fragment IssueLabelAppliedTransactionPayloadFragment on IssueLabelAppliedTransactionPayload {
  spaceId
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const organizationLabelFragment = gql`
fragment OrganizationLabelFragment on OrganizationLabel {
  organizationId
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const organizationLabelAppliedTransactionPayloadFragment = gql`
fragment OrganizationLabelAppliedTransactionPayloadFragment on OrganizationLabelAppliedTransactionPayload {
  organizationId
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const commentFragment = gql`
fragment CommentFragment on Comment {
  actorId
  body
  entityId
  threadId
  reply
  reactions {
    actorId
    reactions
  }
  inline
  resolved
  commentContext
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const commentAppliedTransactionPayloadFragment = gql`
fragment CommentAppliedTransactionPayloadFragment on CommentAppliedTransactionPayload {
  actorId
  body
  entityId
  threadId
  reply
  reactions {
    actorId
    reactions
  }
  inline
  resolved
  commentContext
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const issueFragment = gql`
fragment IssueFragment on Issue {
  number
  actorId
  spaceId
  title
  statusId
  previousStatusId
  sort
  assigneeIds
  watcherIds
  labelIds
  partial
  effortId
  impactId
  public
  publicMetadata
  displayedUpdatedAt
  lastStatusUpdate
  codeReviewRequestIds
  dueDate
  closedAt
  archivedAt
  provenance {
    ... on ImportedProvenance {
      id
      link
      integrationType
      importType
      importSource
      importSourceType
      provenanceType
    }
    ... on DuplicatedProvenance {
      entityId
      provenanceType
    }
    ... on CreatedFromProvenance {
      entityId
      createdFromTodoId
      createdFromCommentId
      provenanceType
    }
  }
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const issueAppliedTransactionPayloadFragment = gql`
fragment IssueAppliedTransactionPayloadFragment on IssueAppliedTransactionPayload {
  number
  actorId
  spaceId
  title
  statusId
  previousStatusId
  sort
  partial
  effortId
  impactId
  public
  publicMetadata
  displayedUpdatedAt
  lastStatusUpdate
  dueDate
  closedAt
  archivedAt
  provenance {
    ... on ImportedProvenance {
      id
      link
      integrationType
      importType
      importSource
      importSourceType
      provenanceType
    }
    ... on DuplicatedProvenance {
      entityId
      provenanceType
    }
    ... on CreatedFromProvenance {
      entityId
      createdFromTodoId
      createdFromCommentId
      provenanceType
    }
  }
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const cycleFragment = gql`
fragment CycleFragment on Cycle {
  spaceId
  actorId
  number
  title
  cycleStatus
  summary
  startDate
  endDate
  activityIds
  commentIds
  history {
    completedEntities
    completedTodos
    incompleteEntities
    incompleteTodos
  }
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const cycleAppliedTransactionPayloadFragment = gql`
fragment CycleAppliedTransactionPayloadFragment on CycleAppliedTransactionPayload {
  spaceId
  actorId
  number
  title
  cycleStatus
  summary
  startDate
  endDate
  history {
    completedEntities
    completedTodos
    incompleteEntities
    incompleteTodos
  }
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const cycleEntityFragment = gql`
fragment CycleEntityFragment on CycleEntity {
  entityId
  cycleId
  sort
  ghost
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const cycleEntityAppliedTransactionPayloadFragment = gql`
fragment CycleEntityAppliedTransactionPayloadFragment on CycleEntityAppliedTransactionPayload {
  entityId
  cycleId
  sort
  ghost
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const cycleTodoFragment = gql`
fragment CycleTodoFragment on CycleTodo {
  cycleId
  todoId
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const cycleTodoAppliedTransactionPayloadFragment = gql`
fragment CycleTodoAppliedTransactionPayloadFragment on CycleTodoAppliedTransactionPayload {
  cycleId
  todoId
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const cycleGoalFragment = gql`
fragment CycleGoalFragment on CycleGoal {
  cycleId
  entityId
  actorId
  sort
  status
  goalContents
  memberIds
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const cycleGoalAppliedTransactionPayloadFragment = gql`
fragment CycleGoalAppliedTransactionPayloadFragment on CycleGoalAppliedTransactionPayload {
  cycleId
  entityId
  actorId
  sort
  status
  goalContents
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const dependencyFragment = gql`
fragment DependencyFragment on Dependency {
  enablesId
  dependsOnId
  dependencyType
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const dependencyAppliedTransactionPayloadFragment = gql`
fragment DependencyAppliedTransactionPayloadFragment on DependencyAppliedTransactionPayload {
  enablesId
  dependsOnId
  dependencyType
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const themeFragment = gql`
fragment ThemeFragment on Theme {
  number
  actorId
  spaceId
  title
  horizon
  previousHorizon
  sort
  color
  image
  assigneeIds
  watcherIds
  labelIds
  issueIds
  effortId
  impactId
  displayedUpdatedAt
  archivedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const themeAppliedTransactionPayloadFragment = gql`
fragment ThemeAppliedTransactionPayloadFragment on ThemeAppliedTransactionPayload {
  number
  actorId
  spaceId
  title
  horizon
  previousHorizon
  sort
  color
  image
  effortId
  impactId
  displayedUpdatedAt
  archivedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const codeReviewRequestFragment = gql`
fragment CodeReviewRequestFragment on CodeReviewRequest {
  codeReviewType
  organizationId
  state
  details {
    number
    title
    url
    commits
    fromBranch
    toBranch
    defaultBranch
  }
  links {
    name
    url
  }
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const codeReviewRequestAppliedTransactionPayloadFragment = gql`
fragment CodeReviewRequestAppliedTransactionPayloadFragment on CodeReviewRequestAppliedTransactionPayload {
  codeReviewType
  organizationId
  state
  details {
    number
    title
    url
    commits
    fromBranch
    toBranch
    defaultBranch
  }
  links {
    name
    url
  }
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const collaborativeDocFragment = gql`
fragment CollaborativeDocFragment on CollaborativeDoc {
  organizationId
  content
  entityId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const collaborativeDocAppliedTransactionPayloadFragment = gql`
fragment CollaborativeDocAppliedTransactionPayloadFragment on CollaborativeDocAppliedTransactionPayload {
  organizationId
  content
  entityId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const feedbackFragment = gql`
fragment FeedbackFragment on Feedback {
  number
  organizationId
  actorId
  companyId
  ownerIds
  personId
  tagIds
  title
  processed
  watcherIds
  processedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const feedbackAppliedTransactionPayloadFragment = gql`
fragment FeedbackAppliedTransactionPayloadFragment on FeedbackAppliedTransactionPayload {
  number
  organizationId
  actorId
  companyId
  personId
  title
  processed
  processedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const feedbackTagFragment = gql`
fragment FeedbackTagFragment on FeedbackTag {
  organizationId
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const feedbackTagAppliedTransactionPayloadFragment = gql`
fragment FeedbackTagAppliedTransactionPayloadFragment on FeedbackTagAppliedTransactionPayload {
  organizationId
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const companyFragment = gql`
fragment CompanyFragment on Company {
  organizationId
  name
  avatar
  domain
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const companyAppliedTransactionPayloadFragment = gql`
fragment CompanyAppliedTransactionPayloadFragment on CompanyAppliedTransactionPayload {
  organizationId
  name
  avatar
  domain
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const personFragment = gql`
fragment PersonFragment on Person {
  organizationId
  companyId
  name
  email
  externalId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const personAppliedTransactionPayloadFragment = gql`
fragment PersonAppliedTransactionPayloadFragment on PersonAppliedTransactionPayload {
  organizationId
  companyId
  name
  email
  externalId
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const insightFragment = gql`
fragment InsightFragment on Insight {
  actorId
  feedbackId
  entityIds
  contents
  orphaned
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const insightAppliedTransactionPayloadFragment = gql`
fragment InsightAppliedTransactionPayloadFragment on InsightAppliedTransactionPayload {
  actorId
  feedbackId
  contents
  orphaned
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const snippetFragment = gql`
fragment SnippetFragment on Snippet {
  spaceId
  name
  contents
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const snippetAppliedTransactionPayloadFragment = gql`
fragment SnippetAppliedTransactionPayloadFragment on SnippetAppliedTransactionPayload {
  spaceId
  name
  contents
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const activityFragment = gql`
fragment ActivityFragment on Activity {
  entityId
  actorId
  activityType
  details {
    ... on CreatedActivityDetails {
      createdMetadata {
        ... on IssueCreatedMetadata {
          statusId
          fallbackStatus
          labels {
            labelId
            fallbackLabel
          }
          assigneeIds
          entities {
            entityId
            fallbackSpaceKey
            fallbackEntityTitle
            fallbackEntityNumber
            fallbackEntityType
          }
        }
        ... on ThemeCreatedMetadata {
          horizon
          labels {
            labelId
            fallbackLabel
          }
          assigneeIds
          issues {
            entityId
            fallbackSpaceKey
            fallbackEntityTitle
            fallbackEntityNumber
            fallbackEntityType
          }
        }
        ... on InitiativeCreatedMetadata {
          labels {
            labelId
            fallbackLabel
          }
          assigneeIds
          issues {
            entityId
            fallbackSpaceKey
            fallbackEntityTitle
            fallbackEntityNumber
            fallbackEntityType
          }
          spaces {
            spaceId
            fallbackSpaceName
          }
          roadmaps {
            roadmapId
            columnId
            fallbackColumnName
            fallbackRoadmapName
          }
        }
      }
    }
    ... on StatusChangedActivityDetails {
      statusId
      fallbackStatus
      originalStatusId
      spaceId
      fallbackSpace
      statusChangedMetadata {
        description
        link
        context
        type
        autoArchived
      }
    }
    ... on LabelsChangedActivityDetails {
      labelsAdded {
        labelId
        fallbackLabel
      }
      labelsRemoved {
        labelId
        fallbackLabel
      }
    }
    ... on ImpactChangedActivityDetails {
      impactId
      fallbackImpactName
      originalImpactId
    }
    ... on EffortChangedActivityDetails {
      effortId
      fallbackEffortName
      originalEffortId
    }
    ... on EntitiesChangedActivityDetails {
      entitiesAdded {
        entityId
        fallbackSpaceKey
        fallbackEntityTitle
        fallbackEntityNumber
        fallbackEntityType
      }
      entitiesRemoved {
        entityId
        fallbackSpaceKey
        fallbackEntityTitle
        fallbackEntityNumber
        fallbackEntityType
      }
    }
    ... on AssigneesChangedActivityDetails {
      assigneesAddedIds
      assigneesRemovedIds
    }
    ... on ImportedActivityDetails {
      type
      customIntegrationType
      link
      importType
      importSource
      importSourceType
    }
    ... on CreatedFromActivityDetails {
      entityId
      fallbackSpaceKey
      fallbackEntityTitle
      fallbackEntityNumber
      duplicated
      createdFromCommentId
      createdFromTodoId
    }
    ... on MentionedActivityDetails {
      entityId
      fallbackSpaceKey
      fallbackEntityTitle
      fallbackEntityNumber
      context
      commentThreadId
      commentId
      commentReply
    }
    ... on MentionedInIntegrationActivityDetails {
      type
      description
      context
      link
      icon
      publicMetadata
    }
    ... on HorizonChangedActivityDetails {
      originalHorizon
      horizon
    }
    ... on CodeReviewRequestAddedActivityDetails {
      codeReviewRequestId
    }
    ... on DependencyActivityDetails {
      targetId
      dependencyType
      inverse
      removed
      fallbackSpaceKey
      fallbackEntityTitle
      fallbackEntityNumber
    }
    ... on TitleChangedActivityDetails {
      originalTitle
      newTitle
    }
    ... on TodoChangedActivityDetails {
      previousStatus
      newStatus
      key
      todoId
      context
    }
    ... on StaleIssueActivityDetails {
      period
    }
    ... on SpacesChangedActivityDetails {
      spacesAdded {
        spaceId
        fallbackSpaceName
      }
      spacesRemoved {
        spaceId
        fallbackSpaceName
      }
    }
    ... on RoadmapsChangedActivityDetails {
      roadmapsAdded {
        roadmapId
        columnId
        fallbackColumnName
        fallbackRoadmapName
      }
      roadmapsRemoved {
        roadmapId
        columnId
        fallbackColumnName
        fallbackRoadmapName
      }
    }
    ... on RoadmapColumnChangedActivityDetails {
      roadmapId
      columnId
      originalColumnId
      fallbackColumnName
      fallbackRoadmapName
    }
    ... on EntityArchivedActivityDetails {
      action
    }
    ... on DueDateChangedActivityDetails {
      dueDate
      originalDueDate
    }
    ... on ProcessedChangedActivityDetails {
      processed
      originalProcessed
    }
    ... on CompanyPersonChangedActivityDetails {
      companyId
      originalCompanyId
      personId
      originalPersonId
    }
  }
  reactions {
    actorId
    reactions
  }
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const activityAppliedTransactionPayloadFragment = gql`
fragment ActivityAppliedTransactionPayloadFragment on ActivityAppliedTransactionPayload {
  entityId
  actorId
  activityType
  details {
    ... on CreatedActivityDetails {
      createdMetadata {
        ... on IssueCreatedMetadata {
          statusId
          fallbackStatus
          labels {
            labelId
            fallbackLabel
          }
          assigneeIds
          entities {
            entityId
            fallbackSpaceKey
            fallbackEntityTitle
            fallbackEntityNumber
            fallbackEntityType
          }
        }
        ... on ThemeCreatedMetadata {
          horizon
          labels {
            labelId
            fallbackLabel
          }
          assigneeIds
          issues {
            entityId
            fallbackSpaceKey
            fallbackEntityTitle
            fallbackEntityNumber
            fallbackEntityType
          }
        }
        ... on InitiativeCreatedMetadata {
          labels {
            labelId
            fallbackLabel
          }
          assigneeIds
          issues {
            entityId
            fallbackSpaceKey
            fallbackEntityTitle
            fallbackEntityNumber
            fallbackEntityType
          }
          spaces {
            spaceId
            fallbackSpaceName
          }
          roadmaps {
            roadmapId
            columnId
            fallbackColumnName
            fallbackRoadmapName
          }
        }
      }
    }
    ... on StatusChangedActivityDetails {
      statusId
      fallbackStatus
      originalStatusId
      spaceId
      fallbackSpace
      statusChangedMetadata {
        description
        link
        context
        type
        autoArchived
      }
    }
    ... on LabelsChangedActivityDetails {
      labelsAdded {
        labelId
        fallbackLabel
      }
      labelsRemoved {
        labelId
        fallbackLabel
      }
    }
    ... on ImpactChangedActivityDetails {
      impactId
      fallbackImpactName
      originalImpactId
    }
    ... on EffortChangedActivityDetails {
      effortId
      fallbackEffortName
      originalEffortId
    }
    ... on EntitiesChangedActivityDetails {
      entitiesAdded {
        entityId
        fallbackSpaceKey
        fallbackEntityTitle
        fallbackEntityNumber
        fallbackEntityType
      }
      entitiesRemoved {
        entityId
        fallbackSpaceKey
        fallbackEntityTitle
        fallbackEntityNumber
        fallbackEntityType
      }
    }
    ... on AssigneesChangedActivityDetails {
      assigneesAddedIds
      assigneesRemovedIds
    }
    ... on ImportedActivityDetails {
      type
      customIntegrationType
      link
      importType
      importSource
      importSourceType
    }
    ... on CreatedFromActivityDetails {
      entityId
      fallbackSpaceKey
      fallbackEntityTitle
      fallbackEntityNumber
      duplicated
      createdFromCommentId
      createdFromTodoId
    }
    ... on MentionedActivityDetails {
      entityId
      fallbackSpaceKey
      fallbackEntityTitle
      fallbackEntityNumber
      context
      commentThreadId
      commentId
      commentReply
    }
    ... on MentionedInIntegrationActivityDetails {
      type
      description
      context
      link
      icon
      publicMetadata
    }
    ... on HorizonChangedActivityDetails {
      originalHorizon
      horizon
    }
    ... on CodeReviewRequestAddedActivityDetails {
      codeReviewRequestId
    }
    ... on DependencyActivityDetails {
      targetId
      dependencyType
      inverse
      removed
      fallbackSpaceKey
      fallbackEntityTitle
      fallbackEntityNumber
    }
    ... on TitleChangedActivityDetails {
      originalTitle
      newTitle
    }
    ... on TodoChangedActivityDetails {
      previousStatus
      newStatus
      key
      todoId
      context
    }
    ... on StaleIssueActivityDetails {
      period
    }
    ... on SpacesChangedActivityDetails {
      spacesAdded {
        spaceId
        fallbackSpaceName
      }
      spacesRemoved {
        spaceId
        fallbackSpaceName
      }
    }
    ... on RoadmapsChangedActivityDetails {
      roadmapsAdded {
        roadmapId
        columnId
        fallbackColumnName
        fallbackRoadmapName
      }
      roadmapsRemoved {
        roadmapId
        columnId
        fallbackColumnName
        fallbackRoadmapName
      }
    }
    ... on RoadmapColumnChangedActivityDetails {
      roadmapId
      columnId
      originalColumnId
      fallbackColumnName
      fallbackRoadmapName
    }
    ... on EntityArchivedActivityDetails {
      action
    }
    ... on DueDateChangedActivityDetails {
      dueDate
      originalDueDate
    }
    ... on ProcessedChangedActivityDetails {
      processed
      originalProcessed
    }
    ... on CompanyPersonChangedActivityDetails {
      companyId
      originalCompanyId
      personId
      originalPersonId
    }
  }
  reactions {
    actorId
    reactions
  }
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const todoFragment = gql`
fragment TodoFragment on Todo {
  entityId
  actorId
  key
  sort
  todoContents
  status
  orphaned
  indent
  effortId
  impactId
  parentId
  memberIds
  labelIds
  codeReviewRequestIds
  connectedEntityId
  connectedExternalIssueId
  explicitLinkStatus
  dueDate
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const todoAppliedTransactionPayloadFragment = gql`
fragment TodoAppliedTransactionPayloadFragment on TodoAppliedTransactionPayload {
  entityId
  actorId
  key
  sort
  todoContents
  status
  orphaned
  indent
  effortId
  impactId
  parentId
  connectedEntityId
  connectedExternalIssueId
  explicitLinkStatus
  dueDate
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const impactFragment = gql`
fragment ImpactFragment on Impact {
  organizationId
  spaceId
  level
  abbrevation
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const impactAppliedTransactionPayloadFragment = gql`
fragment ImpactAppliedTransactionPayloadFragment on ImpactAppliedTransactionPayload {
  organizationId
  spaceId
  level
  abbrevation
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const effortFragment = gql`
fragment EffortFragment on Effort {
  organizationId
  spaceId
  level
  abbrevation
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const effortAppliedTransactionPayloadFragment = gql`
fragment EffortAppliedTransactionPayloadFragment on EffortAppliedTransactionPayload {
  organizationId
  spaceId
  level
  abbrevation
  name
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const folderFragment = gql`
fragment FolderFragment on Folder {
  lookupKey
  name
  organizationId
  parentId
  icon
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const folderAppliedTransactionPayloadFragment = gql`
fragment FolderAppliedTransactionPayloadFragment on FolderAppliedTransactionPayload {
  lookupKey
  name
  organizationId
  parentId
  icon
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const docFragment = gql`
fragment DocFragment on Doc {
  number
  title
  sort
  folderId
  organizationId
  actorId
  icon
  archivedAt
  watcherIds
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const docAppliedTransactionPayloadFragment = gql`
fragment DocAppliedTransactionPayloadFragment on DocAppliedTransactionPayload {
  number
  title
  sort
  folderId
  organizationId
  actorId
  icon
  archivedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const initiativeFragment = gql`
fragment InitiativeFragment on Initiative {
  number
  title
  sort
  organizationId
  memberIds
  issueIds
  labelIds
  watcherIds
  actorId
  color
  impactId
  effortId
  archivedAt
  startDate
  dueDate
  oldTheme
  displayedUpdatedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const initiativeAppliedTransactionPayloadFragment = gql`
fragment InitiativeAppliedTransactionPayloadFragment on InitiativeAppliedTransactionPayload {
  number
  title
  sort
  organizationId
  actorId
  color
  impactId
  effortId
  archivedAt
  startDate
  dueDate
  oldTheme
  displayedUpdatedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const initiativeSpaceFragment = gql`
fragment InitiativeSpaceFragment on InitiativeSpace {
  spaceId
  initiativeId
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const initiativeSpaceAppliedTransactionPayloadFragment = gql`
fragment InitiativeSpaceAppliedTransactionPayloadFragment on InitiativeSpaceAppliedTransactionPayload {
  spaceId
  initiativeId
  sort
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const roadmapFragment = gql`
fragment RoadmapFragment on Roadmap {
  name
  number
  sort
  organizationId
  roadmapType
  shared
  sharedMetadata
  actorId
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const roadmapAppliedTransactionPayloadFragment = gql`
fragment RoadmapAppliedTransactionPayloadFragment on RoadmapAppliedTransactionPayload {
  name
  number
  sort
  organizationId
  roadmapType
  shared
  sharedMetadata
  actorId
  color
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const roadmapColumnFragment = gql`
fragment RoadmapColumnFragment on RoadmapColumn {
  roadmapId
  name
  columnType
  sort
  sortMode
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const roadmapColumnAppliedTransactionPayloadFragment = gql`
fragment RoadmapColumnAppliedTransactionPayloadFragment on RoadmapColumnAppliedTransactionPayload {
  roadmapId
  name
  columnType
  sort
  sortMode
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const roadmapInitiativeFragment = gql`
fragment RoadmapInitiativeFragment on RoadmapInitiative {
  initiativeId
  columnId
  previousColumnId
  sort
  lastColumnUpdate
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const roadmapInitiativeAppliedTransactionPayloadFragment = gql`
fragment RoadmapInitiativeAppliedTransactionPayloadFragment on RoadmapInitiativeAppliedTransactionPayload {
  initiativeId
  columnId
  previousColumnId
  sort
  lastColumnUpdate
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const externalIssueFragment = gql`
fragment ExternalIssueFragment on ExternalIssue {
  externalId
  type
  organizationId
  externalIssueStatus
  title
  url
  number
  fauxDeleted
  statusDetails
  assignees
  labels
  project
  priority
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const externalIssueAppliedTransactionPayloadFragment = gql`
fragment ExternalIssueAppliedTransactionPayloadFragment on ExternalIssueAppliedTransactionPayload {
  externalId
  type
  organizationId
  externalIssueStatus
  title
  url
  number
  fauxDeleted
  statusDetails
  assignees
  labels
  project
  priority
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const viewFragment = gql`
fragment ViewFragment on View {
  organizationId
  actorId
  filters
  sort
  name
  color
  viewType
  visibility
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const viewAppliedTransactionPayloadFragment = gql`
fragment ViewAppliedTransactionPayloadFragment on ViewAppliedTransactionPayload {
  organizationId
  actorId
  filters
  sort
  name
  color
  viewType
  visibility
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const releaseFragment = gql`
fragment ReleaseFragment on Release {
  organizationId
  title
  number
  releaseStatus
  dueDate
  actorId
  memberIds
  watcherIds
  issueIds
  spaceIds
  archivedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const releaseAppliedTransactionPayloadFragment = gql`
fragment ReleaseAppliedTransactionPayloadFragment on ReleaseAppliedTransactionPayload {
  organizationId
  title
  number
  releaseStatus
  dueDate
  actorId
  archivedAt
  id
  version
  deleted
  createdAt
  updatedAt
}
`;
export const currentUserQueryResultFragment = gql`
  fragment CurrentUserQueryResultFragment on CurrentUserQueryResult {
    user {
      ...UserFragment
    }
    organizations {
      ...OrganizationFragment
    }
    organizationMemberships {
      ...OrganizationMemberFragment
    }
    updates {
      ...UpdateFragment
    }
    personalAccessTokens {
      ...PersonalAccessTokenFragment
    }
  }
  ${userFragment}
  ${organizationFragment}
  ${organizationMemberFragment}
  ${updateFragment}
  ${personalAccessTokenFragment}
`;
export const organizationQueryResultFragment = gql`
  fragment OrganizationQueryResultFragment on OrganizationQueryResult {
    members {
      ...OrganizationMemberFragment
    }
    integrationUsers {
      ...IntegrationUserFragment
    }
    integrations {
      ...IntegrationFragment
    }
    applications {
      ...ApplicationFragment
    }
    spaces {
      ...SpaceFragment
    }
    spaceRoadmaps {
      ...SpaceRoadmapFragment
    }
    issueStatuses {
      ...IssueStatusFragment
    }
    boards {
      ...BoardFragment
    }
    boardColumns {
      ...BoardColumnFragment
    }
    issueLabels {
      ...IssueLabelFragment
    }
    organizationLabels {
      ...OrganizationLabelFragment
    }
    collaborativeDocs {
      ...CollaborativeDocFragment
    }
    feedbackTags {
      ...FeedbackTagFragment
    }
    companies {
      ...CompanyFragment
    }
    people {
      ...PersonFragment
    }
    snippets {
      ...SnippetFragment
    }
    impactLevels {
      ...ImpactFragment
    }
    effortLevels {
      ...EffortFragment
    }
    roadmaps {
      ...RoadmapFragment
    }
    roadmapColumns {
      ...RoadmapColumnFragment
    }
    views {
      ...ViewFragment
    }
  }
  ${organizationMemberFragment}
  ${integrationUserFragment}
  ${integrationFragment}
  ${applicationFragment}
  ${spaceFragment}
  ${spaceRoadmapFragment}
  ${issueStatusFragment}
  ${boardFragment}
  ${boardColumnFragment}
  ${issueLabelFragment}
  ${organizationLabelFragment}
  ${collaborativeDocFragment}
  ${feedbackTagFragment}
  ${companyFragment}
  ${personFragment}
  ${snippetFragment}
  ${impactFragment}
  ${effortFragment}
  ${roadmapFragment}
  ${roadmapColumnFragment}
  ${viewFragment}
`;
export const roadmapsQueryResultFragment = gql`
  fragment RoadmapsQueryResultFragment on RoadmapsQueryResult {
    spaceRoadmaps {
      ...SpaceRoadmapFragment
    }
    roadmaps {
      ...RoadmapFragment
    }
    roadmapColumns {
      ...RoadmapColumnFragment
    }
  }
  ${spaceRoadmapFragment}
  ${roadmapFragment}
  ${roadmapColumnFragment}
`;
export const themesQueryResultFragment = gql`
  fragment ThemesQueryResultFragment on ThemesQueryResult {
    themeComments {
      ...CommentFragment
    }
    themes {
      ...ThemeFragment
    }
    themeCodeReviewRequests {
      ...CodeReviewRequestFragment
    }
    collaborativeDocs {
      ...CollaborativeDocFragment
    }
    themeActivities {
      ...ActivityFragment
    }
    themeTodos {
      ...TodoFragment
    }
  }
  ${commentFragment}
  ${themeFragment}
  ${codeReviewRequestFragment}
  ${collaborativeDocFragment}
  ${activityFragment}
  ${todoFragment}
`;
export const issueByNumberQueryResultFragment = gql`
  fragment IssueByNumberQueryResultFragment on IssueByNumberQueryResult {
    issueComments {
      ...CommentFragment
    }
    issue {
      ...IssueFragment
    }
    issueCodeReviewRequests {
      ...CodeReviewRequestFragment
    }
    collaborativeDocs {
      ...CollaborativeDocFragment
    }
    issueActivities {
      ...ActivityFragment
    }
    issueTodos {
      ...TodoFragment
    }
  }
  ${commentFragment}
  ${issueFragment}
  ${codeReviewRequestFragment}
  ${collaborativeDocFragment}
  ${activityFragment}
  ${todoFragment}
`;
export const issueQueryResultFragment = gql`
  fragment IssueQueryResultFragment on IssueQueryResult {
    issueComments {
      ...CommentFragment
    }
    issue {
      ...IssueFragment
    }
    issueCodeReviewRequests {
      ...CodeReviewRequestFragment
    }
    collaborativeDocs {
      ...CollaborativeDocFragment
    }
    issueActivities {
      ...ActivityFragment
    }
    issueTodos {
      ...TodoFragment
    }
  }
  ${commentFragment}
  ${issueFragment}
  ${codeReviewRequestFragment}
  ${collaborativeDocFragment}
  ${activityFragment}
  ${todoFragment}
`;
export const issuesQueryResultFragment = gql`
  fragment IssuesQueryResultFragment on IssuesQueryResult {
    issueComments {
      ...CommentFragment
    }
    issues {
      ...IssueFragment
    }
    dependencies {
      ...DependencyFragment
    }
    issueCodeReviewRequests {
      ...CodeReviewRequestFragment
    }
    collaborativeDocs {
      ...CollaborativeDocFragment
    }
    issueActivities {
      ...ActivityFragment
    }
    issueTodos {
      ...TodoFragment
    }
  }
  ${commentFragment}
  ${issueFragment}
  ${dependencyFragment}
  ${codeReviewRequestFragment}
  ${collaborativeDocFragment}
  ${activityFragment}
  ${todoFragment}
`;
export const initiativesQueryResultFragment = gql`
  fragment InitiativesQueryResultFragment on InitiativesQueryResult {
    initiativeComments {
      ...CommentFragment
    }
    initiativeCodeReviewRequests {
      ...CodeReviewRequestFragment
    }
    collaborativeDocs {
      ...CollaborativeDocFragment
    }
    initiativeActivities {
      ...ActivityFragment
    }
    initiativeTodos {
      ...TodoFragment
    }
    initiatives {
      ...InitiativeFragment
    }
    initiativeSpaces {
      ...InitiativeSpaceFragment
    }
    roadmapInitiatives {
      ...RoadmapInitiativeFragment
    }
  }
  ${commentFragment}
  ${codeReviewRequestFragment}
  ${collaborativeDocFragment}
  ${activityFragment}
  ${todoFragment}
  ${initiativeFragment}
  ${initiativeSpaceFragment}
  ${roadmapInitiativeFragment}
`;
export const documentsQueryResultFragment = gql`
  fragment DocumentsQueryResultFragment on DocumentsQueryResult {
    documentComments {
      ...CommentFragment
    }
    documentCodeReviewRequests {
      ...CodeReviewRequestFragment
    }
    collaborativeDocs {
      ...CollaborativeDocFragment
    }
    documentActivities {
      ...ActivityFragment
    }
    documentTodos {
      ...TodoFragment
    }
    folders {
      ...FolderFragment
    }
    documents {
      ...DocFragment
    }
  }
  ${commentFragment}
  ${codeReviewRequestFragment}
  ${collaborativeDocFragment}
  ${activityFragment}
  ${todoFragment}
  ${folderFragment}
  ${docFragment}
`;
export const feedbackQueryResultFragment = gql`
  fragment FeedbackQueryResultFragment on FeedbackQueryResult {
    feedbackComments {
      ...CommentFragment
    }
    feedbackCodeReviewRequests {
      ...CodeReviewRequestFragment
    }
    feedback {
      ...FeedbackFragment
    }
    insights {
      ...InsightFragment
    }
    feedbackActivities {
      ...ActivityFragment
    }
    feedbackTodos {
      ...TodoFragment
    }
  }
  ${commentFragment}
  ${codeReviewRequestFragment}
  ${feedbackFragment}
  ${insightFragment}
  ${activityFragment}
  ${todoFragment}
`;
export const cyclesQueryResultFragment = gql`
  fragment CyclesQueryResultFragment on CyclesQueryResult {
    cycles {
      ...CycleFragment
    }
    cycleEntities {
      ...CycleEntityFragment
    }
    cycleTodos {
      ...CycleTodoFragment
    }
    cycleGoals {
      ...CycleGoalFragment
    }
    cycleCodeReviewRequests {
      ...CodeReviewRequestFragment
    }
    collaborativeDocs {
      ...CollaborativeDocFragment
    }
    cyclesTodos {
      ...TodoFragment
    }
  }
  ${cycleFragment}
  ${cycleEntityFragment}
  ${cycleTodoFragment}
  ${cycleGoalFragment}
  ${codeReviewRequestFragment}
  ${collaborativeDocFragment}
  ${todoFragment}
`;
export const externalIssuesQueryResultFragment = gql`
  fragment ExternalIssuesQueryResultFragment on ExternalIssuesQueryResult {
    externalIssues {
      ...ExternalIssueFragment
    }
  }
  ${externalIssueFragment}
`;
export const releasesQueryResultFragment = gql`
  fragment ReleasesQueryResultFragment on ReleasesQueryResult {
    releases {
      ...ReleaseFragment
    }
  }
  ${releaseFragment}
`;
export const allAppliedTransactionPayloadPropertiesFragment = gql`
fragment AllAppliedTransactionPayloadPropertiesFragment on AllAppliedTransactionPayloadProperties {
  ... on UserAppliedTransactionPayload {
    ...UserAppliedTransactionPayloadFragment
  }
  ... on OrganizationAppliedTransactionPayload {
    ...OrganizationAppliedTransactionPayloadFragment
  }
  ... on OrganizationMemberAppliedTransactionPayload {
    ...OrganizationMemberAppliedTransactionPayloadFragment
  }
  ... on UpdateAppliedTransactionPayload {
    ...UpdateAppliedTransactionPayloadFragment
  }
  ... on IntegrationUserAppliedTransactionPayload {
    ...IntegrationUserAppliedTransactionPayloadFragment
  }
  ... on IntegrationAppliedTransactionPayload {
    ...IntegrationAppliedTransactionPayloadFragment
  }
  ... on ApplicationAppliedTransactionPayload {
    ...ApplicationAppliedTransactionPayloadFragment
  }
  ... on PersonalAccessTokenAppliedTransactionPayload {
    ...PersonalAccessTokenAppliedTransactionPayloadFragment
  }
  ... on SpaceAppliedTransactionPayload {
    ...SpaceAppliedTransactionPayloadFragment
  }
  ... on SpaceRoadmapAppliedTransactionPayload {
    ...SpaceRoadmapAppliedTransactionPayloadFragment
  }
  ... on IssueStatusAppliedTransactionPayload {
    ...IssueStatusAppliedTransactionPayloadFragment
  }
  ... on BoardAppliedTransactionPayload {
    ...BoardAppliedTransactionPayloadFragment
  }
  ... on BoardColumnAppliedTransactionPayload {
    ...BoardColumnAppliedTransactionPayloadFragment
  }
  ... on IssueLabelAppliedTransactionPayload {
    ...IssueLabelAppliedTransactionPayloadFragment
  }
  ... on OrganizationLabelAppliedTransactionPayload {
    ...OrganizationLabelAppliedTransactionPayloadFragment
  }
  ... on CommentAppliedTransactionPayload {
    ...CommentAppliedTransactionPayloadFragment
  }
  ... on IssueAppliedTransactionPayload {
    ...IssueAppliedTransactionPayloadFragment
  }
  ... on CycleAppliedTransactionPayload {
    ...CycleAppliedTransactionPayloadFragment
  }
  ... on CycleEntityAppliedTransactionPayload {
    ...CycleEntityAppliedTransactionPayloadFragment
  }
  ... on CycleTodoAppliedTransactionPayload {
    ...CycleTodoAppliedTransactionPayloadFragment
  }
  ... on CycleGoalAppliedTransactionPayload {
    ...CycleGoalAppliedTransactionPayloadFragment
  }
  ... on DependencyAppliedTransactionPayload {
    ...DependencyAppliedTransactionPayloadFragment
  }
  ... on ThemeAppliedTransactionPayload {
    ...ThemeAppliedTransactionPayloadFragment
  }
  ... on CodeReviewRequestAppliedTransactionPayload {
    ...CodeReviewRequestAppliedTransactionPayloadFragment
  }
  ... on CollaborativeDocAppliedTransactionPayload {
    ...CollaborativeDocAppliedTransactionPayloadFragment
  }
  ... on FeedbackAppliedTransactionPayload {
    ...FeedbackAppliedTransactionPayloadFragment
  }
  ... on FeedbackTagAppliedTransactionPayload {
    ...FeedbackTagAppliedTransactionPayloadFragment
  }
  ... on CompanyAppliedTransactionPayload {
    ...CompanyAppliedTransactionPayloadFragment
  }
  ... on PersonAppliedTransactionPayload {
    ...PersonAppliedTransactionPayloadFragment
  }
  ... on InsightAppliedTransactionPayload {
    ...InsightAppliedTransactionPayloadFragment
  }
  ... on SnippetAppliedTransactionPayload {
    ...SnippetAppliedTransactionPayloadFragment
  }
  ... on ActivityAppliedTransactionPayload {
    ...ActivityAppliedTransactionPayloadFragment
  }
  ... on TodoAppliedTransactionPayload {
    ...TodoAppliedTransactionPayloadFragment
  }
  ... on ImpactAppliedTransactionPayload {
    ...ImpactAppliedTransactionPayloadFragment
  }
  ... on EffortAppliedTransactionPayload {
    ...EffortAppliedTransactionPayloadFragment
  }
  ... on FolderAppliedTransactionPayload {
    ...FolderAppliedTransactionPayloadFragment
  }
  ... on DocAppliedTransactionPayload {
    ...DocAppliedTransactionPayloadFragment
  }
  ... on InitiativeAppliedTransactionPayload {
    ...InitiativeAppliedTransactionPayloadFragment
  }
  ... on InitiativeSpaceAppliedTransactionPayload {
    ...InitiativeSpaceAppliedTransactionPayloadFragment
  }
  ... on RoadmapAppliedTransactionPayload {
    ...RoadmapAppliedTransactionPayloadFragment
  }
  ... on RoadmapColumnAppliedTransactionPayload {
    ...RoadmapColumnAppliedTransactionPayloadFragment
  }
  ... on RoadmapInitiativeAppliedTransactionPayload {
    ...RoadmapInitiativeAppliedTransactionPayloadFragment
  }
  ... on ExternalIssueAppliedTransactionPayload {
    ...ExternalIssueAppliedTransactionPayloadFragment
  }
  ... on ViewAppliedTransactionPayload {
    ...ViewAppliedTransactionPayloadFragment
  }
  ... on ReleaseAppliedTransactionPayload {
    ...ReleaseAppliedTransactionPayloadFragment
  } 
}
${userAppliedTransactionPayloadFragment}
${organizationAppliedTransactionPayloadFragment}
${organizationMemberAppliedTransactionPayloadFragment}
${updateAppliedTransactionPayloadFragment}
${integrationUserAppliedTransactionPayloadFragment}
${integrationAppliedTransactionPayloadFragment}
${applicationAppliedTransactionPayloadFragment}
${personalAccessTokenAppliedTransactionPayloadFragment}
${spaceAppliedTransactionPayloadFragment}
${spaceRoadmapAppliedTransactionPayloadFragment}
${issueStatusAppliedTransactionPayloadFragment}
${boardAppliedTransactionPayloadFragment}
${boardColumnAppliedTransactionPayloadFragment}
${issueLabelAppliedTransactionPayloadFragment}
${organizationLabelAppliedTransactionPayloadFragment}
${commentAppliedTransactionPayloadFragment}
${issueAppliedTransactionPayloadFragment}
${cycleAppliedTransactionPayloadFragment}
${cycleEntityAppliedTransactionPayloadFragment}
${cycleTodoAppliedTransactionPayloadFragment}
${cycleGoalAppliedTransactionPayloadFragment}
${dependencyAppliedTransactionPayloadFragment}
${themeAppliedTransactionPayloadFragment}
${codeReviewRequestAppliedTransactionPayloadFragment}
${collaborativeDocAppliedTransactionPayloadFragment}
${feedbackAppliedTransactionPayloadFragment}
${feedbackTagAppliedTransactionPayloadFragment}
${companyAppliedTransactionPayloadFragment}
${personAppliedTransactionPayloadFragment}
${insightAppliedTransactionPayloadFragment}
${snippetAppliedTransactionPayloadFragment}
${activityAppliedTransactionPayloadFragment}
${todoAppliedTransactionPayloadFragment}
${impactAppliedTransactionPayloadFragment}
${effortAppliedTransactionPayloadFragment}
${folderAppliedTransactionPayloadFragment}
${docAppliedTransactionPayloadFragment}
${initiativeAppliedTransactionPayloadFragment}
${initiativeSpaceAppliedTransactionPayloadFragment}
${roadmapAppliedTransactionPayloadFragment}
${roadmapColumnAppliedTransactionPayloadFragment}
${roadmapInitiativeAppliedTransactionPayloadFragment}
${externalIssueAppliedTransactionPayloadFragment}
${viewAppliedTransactionPayloadFragment}
${releaseAppliedTransactionPayloadFragment}
`;
