import * as React from 'react';
import { MINOR_VERSION, VERSION } from '../../shared/utils/version';
import { ServerVersionInformation, getServerVersion } from '../api/version';
import Modal from '../components/modal';
import { Button } from '../components/new/button';
import { Divider } from '../components/new/divider';
import { SegmentedControl } from '../components/new/segmentedControl';
import { Setting, SettingsSection } from '../components/new/settings';
import { Tab, TabStyle, Tabs } from '../components/new/tabs';
import { toast } from '../components/toast';
import { FeatureFlagMap, useConfiguration } from '../contexts/configurationContext';
import { Modals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useMaybeSpace } from '../contexts/spaceContext';
import { useCurrentUser } from '../contexts/userContext';
import styles from './diagnosticsModal.module.scss';

function VersionInformation() {
  const [serverInfo, setServerInfo] = React.useState<ServerVersionInformation | null>(null);

  React.useEffect(() => {
    async function getAndUpdateServerVersion() {
      const res = await getServerVersion();
      if (res) {
        setServerInfo(res);
      } else {
        toast.error('Failed to fetch server version');
      }
    }
    getAndUpdateServerVersion();
  }, []);

  if (!serverInfo) {
    return <div>Fetching information...</div>;
  }

  return (
    <div>
      <pre>
        Client: v{VERSION}.{MINOR_VERSION}
        <br />
        Server: v{serverInfo.current}.{serverInfo.minor}
        <br />
        Forced: v{serverInfo.force}
      </pre>
    </div>
  );
}

function Information() {
  const organization = useOrganization();
  const space = useMaybeSpace();
  const currentUser = useCurrentUser();

  return (
    <>
      <SettingsSection title="User information">
        <pre>
          Organization: {organization.id} ({organization.slug})
          <br />
          {space && (
            <>
              Space: {space.id} ({space.slug})
              <br />
            </>
          )}
          User: {currentUser.id}
        </pre>
      </SettingsSection>

      <SettingsSection title="Server information">
        <VersionInformation />
      </SettingsSection>
    </>
  );
}

function FeatureFlags() {
  const { developer } = useConfiguration();
  const [state, setState] = React.useState<FeatureFlagMap>(developer?.enabledFeatures ?? {});

  const getState = React.useCallback(
    (flag: string) => {
      const res = state[flag];
      return res !== undefined && res !== null ? `${state[flag]}` : 'default';
    },
    [state]
  );

  React.useEffect(() => {
    developer?.setFeatures(state);
  }, [state, developer]);

  if (!developer) {
    return <div className={styles.content}>Not a developer</div>;
  }

  return (
    <>
      <SettingsSection>
        {developer.allFeatures
          .filter(f => f !== 'FEATURE_TOGGLE_DEVELOPER')
          .map(feature => (
            <div key={feature} className="rowBetween mb8">
              <span className="mr32 bodyMonospaceS">{feature}</span>
              <div className="row">
                <span className="mr8 bodyS">
                  Default: {developer.defaultFeatureFlags[feature] === true && <span>enabled</span>}
                  {developer.defaultFeatureFlags[feature] === false && <span>disabled</span>}
                  {developer.defaultFeatureFlags[feature] == null && <span>null</span>}
                </span>
                <SegmentedControl
                  value={getState(feature)}
                  onValueChanged={(value: string) => {
                    switch (value) {
                      case 'true': {
                        const temp = Object.assign({}, state);
                        temp[feature] = true;
                        setState(temp);
                        break;
                      }
                      case 'false': {
                        const temp = Object.assign({}, state);
                        temp[feature] = false;
                        setState(temp);
                        break;
                      }
                      default: {
                        const temp = Object.assign({}, state);
                        delete temp[feature];
                        setState(temp);
                        break;
                      }
                    }
                  }}
                  options={[
                    { icon: 'select_checkmark', id: 'true', tooltip: 'Enable feature' },
                    { icon: 'exit', id: 'false', tooltip: 'Disable feature' },
                    { icon: 'refresh', id: 'default', tooltip: 'Reset to default' },
                  ]}
                />
              </div>
            </div>
          ))}
      </SettingsSection>
    </>
  );
}

function SystemTriggers() {
  const { developer } = useConfiguration();
  const organization = useOrganization();

  const triggers = [
    'autoArchive',
    'cron',
    'cycles',
    'overdue_check',
    'staleIssues',
    'notifications',
    'vanta',
  ] as const;
  type Trigger = typeof triggers[number];

  async function triggerCheck(trigger: Trigger) {
    const res = await fetch(`/system/developer/trigger`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ organizationId: organization.id, trigger }),
    });

    if (res.ok) {
      toast.success(`Successfully triggered ${trigger}.`);
    } else {
      toast.error(`Unable to trigger ${trigger}: ${res.status}`);
    }
  }

  if (!developer) {
    return <div className={styles.content}>Not a developer</div>;
  }

  return (
    <SettingsSection>
      <Setting title={'System cron'} description="The cron trigger for automated tasks">
        <Button onClick={() => triggerCheck('cron')}>Trigger</Button>
      </Setting>
      <Divider>Manual triggers</Divider>
      <Setting title={'Auto-archive'} description="Auto-archive issues">
        <Button onClick={() => triggerCheck('autoArchive')}>Trigger</Button>
      </Setting>
      <Setting title={'Cycles'} description="Trigger cycles automation">
        <Button onClick={() => triggerCheck('cycles')}>Trigger</Button>
      </Setting>
      <Setting
        title={'Overdue issues'}
        description="Create activities and updates for overdue issues"
      >
        <Button onClick={() => triggerCheck('overdue_check')}>Trigger</Button>
      </Setting>
      <Setting title={'Notifications'} description="Email and desktop notifications">
        <Button onClick={() => triggerCheck('notifications')}>Trigger</Button>
      </Setting>
      <Setting title={'Stale issues'} description="Create activities and updates for stale issues">
        <Button onClick={() => triggerCheck('staleIssues')}>Trigger</Button>
      </Setting>
      <Setting title={'Vanta'} description="Sync to vanta">
        <Button onClick={() => triggerCheck('vanta')}>Trigger</Button>
      </Setting>
    </SettingsSection>
  );
}

function FeatureFlagStatus() {
  const organization = useOrganization();
  const [info, setInfo] = React.useState<
    {
      flag: string;
      users: { id: string; username: string }[];
      organizations: { key: string; name: string }[];
    }[]
  >([]);

  React.useEffect(() => {
    async function getInfo() {
      const res = await fetch(`/system/developer/featureFlagInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ organizationId: organization.id }),
      });

      if (res.ok) {
        const data = await res.json();
        setInfo(data);
      } else {
        toast.error(`Unable to fetch feature flag info: ${res.status}`);
      }
    }

    getInfo();
  }, [organization.id]);

  return (
    <div style={{ overflow: 'scroll', maxHeight: '600px' }}>
      {info.map(i => (
        <div key={i.flag} className="mb12 bodyM">
          <div className="bodyMonospaceS">{i.flag}</div>
          {i.organizations.map(org => (
            <div key={org.key} className="mt4 ml8">
              <span className="inlineCode">{org.key}</span> {org.name}
            </div>
          ))}

          {i.users.map(user => (
            <div key={user.id} className="mt4 ml8">
              <span className="inlineCode">{user.id}</span> {user.username}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function DeveloperContent() {
  const { developer, production } = useConfiguration();
  const [currentTab, setCurrentTab] = React.useState('info');

  if (!developer) {
    return <div className={styles.content}>Not a developer</div>;
  }

  const tabs: Tab[] = [
    {
      id: 'info',
      name: 'Information',
    },
    {
      id: 'featureFlags',
      name: 'Feature flags',
    },
    {
      id: 'featureFlagStatus',
      name: 'Feature flag status',
    },
    {
      id: 'systemTriggers',
      name: 'System triggers',
      disabled: !!production,
    },
  ];

  return (
    <div style={{ padding: '32px', minWidth: '600px' }}>
      <Tabs
        tabs={tabs}
        tabStyle={TabStyle.Subtle}
        currentTab={currentTab}
        onTabChanged={setCurrentTab}
      />
      <Divider className="mt8 mb8" />

      <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        {currentTab === 'info' && <Information />}
        {currentTab === 'featureFlags' && <FeatureFlags />}
        {currentTab === 'featureFlagStatus' && <FeatureFlagStatus />}
        {currentTab === 'systemTriggers' && <SystemTriggers />}
      </div>
    </div>
  );
}

export default function DeveloperModal() {
  return (
    <Modal modalId={Modals.SystemSettings} title="System settings">
      <DeveloperContent />
    </Modal>
  );
}
