import cn from 'classnames';
import * as React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { GiphyElement } from '../../../shared/slate/types';
import { GiphyData, randomGif } from '../../api/giphy';
import ExternalLink from '../../components/externalLink';
import { ButtonStyle, IconButton } from '../../components/new/button';
import { CommentButton } from '../../components/new/commentButton';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import { useEnsureFocusedElementIsVisible } from '../../components/new/keyNavigation';
import { Tooltip } from '../../components/new/tooltip';
import { VoidActions } from '../../components/new/voidActions';
import { toast } from '../../components/toast';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useUnmounted } from '../../hooks/useUnmounted';
import { metaKeyDown } from '../../utils/keyEvents';
import { openExternalUrl } from '../../utils/urls';
import UploadPlaceholder from '../components/uploadPlaceholder';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useOnKeyDownHandler } from '../onKeyDownHandlers/useKeyDownHandler';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { useResizeObserver } from '../staticSlateHelpers';
import { EditorType, OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import styles from './giphy.module.scss';
import { VoidBlock } from './voidBlock';

export async function findGiphy(searchString: string): Promise<GiphyData> {
  const gif = await randomGif(searchString);

  if (gif.url) {
    return gif;
  }

  throw Error(`Couldn't find any GIFs for that search term`);
}

export function setGiphyData(editor: EditorType, element: GiphyElement, gif: any) {
  ReactEditor.focus(editor);

  const path = ReactEditor.findPath(editor, element);
  KitemakerTransforms.setNodes(
    editor,
    {
      url: gif.url,
      searchTerm: gif.searchTerm,
      contributor: gif.contributor,
      contributorUrl: gif.contributorUrl,
    },
    { at: path }
  );
  KitemakerTransforms.moveSelectionToPath(editor, path);
}

export function StaticGiphy({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: GiphyElement }) {
  const unmounted = useUnmounted();
  const { url, searchTerm, contributor, contributorUrl } = element;
  const ref = React.useRef<HTMLDivElement>(null);
  useResizeObserver(ref, url);

  useComponentDidMount(() => {
    return () => {
      unmounted.current = true;
    };
  });

  if (!url) {
    return <DummyNode element={element}>{children}</DummyNode>;
  }

  return (
    <div
      className={cn('block', 'rowCenter', 'fullWidth', 'relative', styles.container)}
      {...attributes}
    >
      <Tooltip content={<>Posted using /giphy {contributor && <>| GIF by {contributor}</>}</>}>
        <div>
          <div className={styles.searchTermStatic}>{searchTerm}</div>
          <div className={styles.giphy} ref={ref}>
            <div>
              <ExternalLink href={contributorUrl}>
                <img src={url} alt="Random Giphy" className={styles.gif} />
              </ExternalLink>
            </div>
          </div>
        </div>
      </Tooltip>
      {children}
    </div>
  );
}

export function Giphy({
  attributes,
  element,
  children,
}: RenderElementProps & { element: GiphyElement }) {
  const unmounted = React.useRef(false);
  const editor = useSlateStatic();
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const ref = React.useRef<HTMLDivElement>(null);
  const { url, searchTerm, contributor, contributorUrl } = element;

  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();
  const selectionCollapsedRef = React.useRef(selected && selectionCollapsed);
  selectionCollapsedRef.current = selected && selectionCollapsed;

  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();
  const requestInProgress = React.useRef(false);

  const sizeObserver = React.useRef(
    window.ResizeObserver
      ? new window.ResizeObserver(() => {
          if (selectionCollapsedRef.current && ref.current) {
            scrollIntoView(ref.current, {
              block: 'center',
              behavior: 'auto',
              scrollMode: 'if-needed',
            });
            ensureVisible();
          }
        })
      : null
  );

  React.useEffect(() => {
    if (!ref.current || !sizeObserver.current) {
      return;
    }
    const observer = sizeObserver.current;
    const observed = ref.current;
    observer.observe(observed);
    return () => {
      observer.unobserve(observed);
    };
  }, [sizeObserver, url]);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const shuffleGif = React.useCallback(async () => {
    if (requestInProgress.current) {
      return;
    }
    requestInProgress.current = true;
    try {
      const gif = await findGiphy(searchTerm);
      setGiphyData(editor, element, gif);
    } catch (e) {
      toast.error('There was an error fetching your gif. Please try again');
    } finally {
      requestInProgress.current = false;
    }
  }, [url, editor, element, searchTerm]);

  useOnKeyDownHandler((e: React.KeyboardEvent) => {
    if (e.key !== ' ' || metaKeyDown(e)) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();
    shuffleGif();
    return true;
  });

  if (!url) {
    return (
      <UploadPlaceholder
        attributes={attributes}
        element={element}
        icon="giphy"
        placeholder="Add a GIF from Giphy"
        focusedPlaceholder="Enter a search term"
        onSubmit={async search => {
          if (unmounted.current) {
            return;
          }

          try {
            const gif = await findGiphy(search);
            setGiphyData(editor, element, gif);
          } catch (e) {
            toast.error(e.message);
          }
        }}
      >
        {children}
      </UploadPlaceholder>
    );
  }

  return (
    <div
      {...attributes}
      {...dndAttributes}
      className={cn('block', 'relative', styles.container, dndClassName)}
    >
      {dndComponents}

      <div className="rowCenter">{children}</div>
      <VoidBlock
        element={element}
        className="relative"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          openExternalUrl(contributorUrl);
        }}
      >
        <div className={styles.searchTerm} contentEditable={false}>
          {searchTerm}
        </div>
        <Tooltip content={<>Posted using /giphy {contributor && <>| GIF by {contributor}</>}</>}>
          <div className={styles.giphy} ref={ref}>
            <VoidActions floating className={styles.actions}>
              {editor.entityId && editor.inlineComments && <CommentButton element={element} />}
              <Tooltip
                content={
                  <>
                    Shuffle <KeyboardShortcut shortcut="space" />
                  </>
                }
              >
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtle}
                  icon="shuffle"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    shuffleGif();
                  }}
                />
              </Tooltip>
            </VoidActions>
            <img src={url} alt="Random Giphy" className={styles.gif} />
          </div>
        </Tooltip>
      </VoidBlock>
    </div>
  );
}
