import { partition, sortBy } from 'lodash';
import { selector, selectorFamily } from 'recoil';
import { filterNotDeletedNotNull, notDeleted } from '../../../shared/utils/convenience';
import { organizationMembersByUser } from '../../../sync/__generated/indexes';
import {
  Actor,
  MemberRole,
  OrganizationMember,
  Space,
  User,
} from '../../../sync/__generated/models';
import { CurrentUser, CurrentUserMarker } from '../../graphql/smartLoad';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { organizationsSelector } from './organizations';
import { spaceSelector, spacesForOrganizationSelector } from './spaces';

export function isUser(user: Actor): user is User {
  return user.__typename === 'User';
}

export const currentUserMarkerState = selector({
  key: 'CurrentUserMarker',
  get: ({ get }) => {
    return notDeleted(get(syncEngineState(CurrentUserMarker.id)) as CurrentUser | null);
  },
});

export const currentUserState = selector({
  key: 'CurrentUser',
  get: ({ get }) => {
    const currentUserId = get(currentUserMarkerState);
    if (!currentUserId || !currentUserId.currentUserId) {
      return null;
    }
    return get(userSelector(currentUserId.currentUserId));
  },
});

export const currentUserMembershipsState = selector({
  key: 'CurrentUserMemberships',
  get: ({ get }) => {
    const user = get(currentUserState);
    if (!user) {
      return [];
    }

    const organizationIds = get(indexKeyState(indexKey(organizationMembersByUser, user.id)));
    return filterNotDeletedNotNull(
      organizationIds.map(organizationId => get(organizationMemberSelector(organizationId)))
    ).filter(membership => !membership.deactivated && !membership.invited);
  },
});

export const currentUserOrganizatonsState = selector({
  key: 'CurrentUserOrganizations',
  get: ({ get }) => {
    const organizationMemberships = get(currentUserMembershipsState);
    return sortBy(
      filterNotDeletedNotNull(
        organizationMemberships.map(membership =>
          get(organizationsSelector(membership.organizationId))
        )
      ),
      o => o.createdAt
    );
  },
});

export const currentUserMembershipState = selectorFamily({
  key: 'CurrentUserMembership',
  get:
    (organizationId: string | undefined | null) =>
    ({ get }) => {
      if (!organizationId) {
        return null;
      }
      const organizationMemberships = get(currentUserMembershipsState);
      return organizationMemberships.find(
        membership => membership.organizationId === organizationId
      );
    },
});

export const spacesForCurrentUserMembershipSelector = selectorFamily({
  key: 'SpacesForCurrentUserMembership',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const membership = get(currentUserMembershipState(organizationId));
      const spaces = get(spacesForOrganizationSelector(organizationId));

      if (!membership) {
        return { favoriteSpaces: [], nonFavoriteSpaces: spaces };
      }

      const favoriteSpaces = filterNotDeletedNotNull(
        (membership.favoriteSpaceIds ?? []).map(spaceId => get(spaceSelector(spaceId)))
      );
      const nonFavoriteSpaces = spaces.filter(
        space => !membership.favoriteSpaceIds?.includes(space.id)
      );
      return { favoriteSpaces, nonFavoriteSpaces };
    },
});

export const userMembershipsSelector = selectorFamily({
  key: 'UserMemberships',
  get:
    (userId: string) =>
    ({ get }) => {
      const organizationMembershipIds = get(
        indexKeyState(indexKey(organizationMembersByUser, userId))
      );
      return filterNotDeletedNotNull(
        organizationMembershipIds.map(membershipId => get(organizationMemberSelector(membershipId)))
      );
    },
});

export const userMembershipSelector = selectorFamily({
  key: 'UserMembership',
  get:
    ({ organizationId, userId }: { organizationId: string; userId: string }) =>
    ({ get }) => {
      const memberships = get(userMembershipsSelector(userId));
      return memberships.find(membership => membership.organizationId === organizationId);
    },
});

export const usersAndMemberSelector = selectorFamily({
  key: 'UsersAndMemberSelector',
  get:
    ({ organizationId, userIds }: { organizationId: string; userIds: string[] }) =>
    ({ get }) => {
      const user = get(currentUserState);
      const users = get(usersSelector(userIds));
      return sortBy(
        users.map(u => ({
          ...u,
          member: get(userMembershipSelector({ organizationId, userId: u.id })),
        })),
        u => {
          if (u.id === user?.id) {
            return 0;
          } else if (u.member?.role === MemberRole.Guest) {
            return 2;
          } else if (u.member?.invited) {
            return 3;
          } else if (u.member?.deactivated) {
            return 4;
          }
          return 1;
        }
      );
    },
});

export const userSelector = selectorFamily({
  key: 'UserSelector',
  get:
    (userId: string) =>
    ({ get }) => {
      return get(syncEngineState(userId)) as User | null;
    },
});

export const usersSelector = selectorFamily({
  key: 'UsersSelector',
  get:
    (userIds: string[]) =>
    ({ get }) => {
      return filterNotDeletedNotNull(userIds.map(userId => get(userSelector(userId))));
    },
});

export const organizationMemberSelector = selectorFamily({
  key: 'organizationMember',
  get:
    (orgMemberId: string) =>
    ({ get }) => {
      return get(syncEngineState(orgMemberId)) as OrganizationMember | null;
    },
});

export const animationsDisabledSelector = selector({
  key: 'AnimationsDisabled',
  get: ({ get }) => {
    const user = get(currentUserState);
    return (user?.preferences?.animationsDisabled ?? false) as boolean;
  },
});

export type UserAndMember = { user: User; member: OrganizationMember };
export function sortUsers(users: UserAndMember[], space?: Space | null): UserAndMember[] {
  function sort(user1: UserAndMember, user2: UserAndMember): number {
    if (user1.member.deactivated && !user2.member.deactivated) {
      return 1;
    }
    if (user2.member.deactivated && !user1.member.deactivated) {
      return -1;
    }
    if (user1.member.role === MemberRole.Guest && user2.member.role !== MemberRole.Guest) {
      return 1;
    }
    if (user2.member.role === MemberRole.Guest && user1.member.role !== MemberRole.Guest) {
      return -1;
    }

    if (space) {
      if (
        space.private &&
        space.members.includes(user1.user.id) &&
        !space.members.includes(user2.user.id)
      ) {
        return -1;
      }
      if (
        space.private &&
        !space.members.includes(user1.user.id) &&
        space.members.includes(user2.user.id)
      ) {
        return 1;
      }
      if (
        user1.member.favoriteSpaceIds?.includes(space.id) &&
        !user2.member.favoriteSpaceIds?.includes(space.id)
      ) {
        return -1;
      }
      if (
        !user1.member.favoriteSpaceIds?.includes(space.id) &&
        user2.member.favoriteSpaceIds?.includes(space.id)
      ) {
        return 1;
      }
    }

    if (user1.user.username.toLowerCase() < user2.user.username.toLowerCase()) {
      return -1;
    }
    if (user1.user.username.toLowerCase() > user2.user.username.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  const [deactivated, notDeactivated] = partition(users, u => u.member.deactivated);
  const [invited, notInvited] = partition(notDeactivated, u => u.member.invited);
  return [...notInvited.sort(sort), ...invited.sort(sort), ...deactivated.sort(sort)];
}
