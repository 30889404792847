import cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { GetRecoilValue, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { RoadmapInitiative } from '../../../../../graphql__generated__/graphql';
import { emptyDocument } from '../../../../shared/slate/utils';
import {
  IssueStatusSortMode,
  Roadmap as RoadmapModel,
  RoadmapType,
} from '../../../../sync/__generated/models';
import { CommandGroup } from '../../../commands';
import { RoadmapCommandGroupContext } from '../../../commands/state';
import { boardMetadataConfig } from '../../../components/metadataConfig';
import { CommandContext } from '../../../components/new/commandMenuContext';
import { useCopyEntitiesToClipboard } from '../../../components/new/copyAndPaste';
import { CustomCommand } from '../../../components/new/customCommand';
import { Filters as Filters2 } from '../../../components/new/filters2';
import { EditInitiativeCard, InitiativeCard } from '../../../components/new/initiativeCard';
import {
  EditInitiativeListItem,
  InitiativeListItem,
} from '../../../components/new/initiativeListItem';
import {
  KeyNavigationProvider,
  useDisableMissingKeyNavigationElementDetection,
  useEnableMissingKeyNavigationElementDetection,
  useKeyNavigationState,
  useSetKeyNavigationFocus,
  useSetKeyNavigationSelection,
} from '../../../components/new/keyNavigation';
import { FocusReason } from '../../../components/new/keyNavigation/state';
import { VirtualizedBoardView, useSizeCache } from '../../../components/new/virtualizedBoardView';
import { VirtualizedListView } from '../../../components/new/virtualizedListView';
import { ResizeItemsOnStateChange } from '../../../components/new/virtualizedListViewHelpers';
import { CommandMenuView, Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { FetchedMarker } from '../../../graphql/smartLoad';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useNewEntityModalArgs } from '../../../modals/newEntityModal/newEntityModal';
import {
  useDuplicateInitiatives,
  useMoveRoadmapInitiativesContext,
  useUpdateRoadmapInitiativeSorts,
} from '../../../syncEngine/actions/intiatives';
import { useDeleteRoadmapsContext } from '../../../syncEngine/actions/roadmaps';
import {
  filteredRoadmapInitiativeIdsForColumnSelector,
  initiativeCountForColumn,
  initiativeIdForRoadmapInitiativeSelector,
  initiativeIdsForRoadmapInitiativesSelector,
  roadmapInitiativesByColumnSelecctor,
  useGetInitiativeIdsForColumn,
  useInitiativeIdsInColumn,
} from '../../../syncEngine/selectors/intiatives';
import { sortModeToString } from '../../../syncEngine/selectors/issues';
import {
  roadmapColumnIdsForRoadmapSelector,
  roadmapColumnsForRoadmapSelector,
  roadmapPath,
  roadmapSelector,
  roadmapsForOrganizationSelector,
  useFindRoadmapInitiativeForInitiative,
} from '../../../syncEngine/selectors/roadmaps';
import { markerState } from '../../../syncEngine/selectors/smartLoader';
import { useSnippetForCallback } from '../../../syncEngine/selectors/snippets';
import { spacePath } from '../../../syncEngine/selectors/spaces';
import { StateEvents, offStateEvent, onStateEvent } from '../../../syncEngine/state';
import { SyncEngineObject } from '../../../syncEngine/types';
import { insertSnipppetKey, toggleListViewKey } from '../../../utils/config';
import { filterPropertiesSelector } from '../../../utils/filtering2';
import { NotFoundScreen } from '../../errorScreens';
import LoadingScreen from '../../loadingScreen';
import Timeline from '../../timelineViewScreen/timeline';
import { Placeholder } from '../workItemBoardScreen/placeholder';
import { NewInitiative } from './newInitiative';
import { NewColumnHeader, RoadmapColumnHeader } from './roadmapColumnHeader';
import { RoadmapSectionHeader } from './roadmapSectionHeader';
import styles from './roadmapsScreen.module.scss';
import { Mode, RoadmapTopBar, roadmapScreenMode } from './roadmapTopBar';

function ResizeCardsOnStateChange({ columnId }: { columnId: string }) {
  const getIds = useGetInitiativeIdsForColumn(columnId);
  const { invalidateSizeCache } = useSizeCache();

  useComponentDidMount(() => {
    function handleSet({ objects }: { objects: SyncEngineObject[]; get: GetRecoilValue }) {
      const ids = getIds();

      // TODO: do this properly
      for (const object of objects) {
        const cardIndex = ids.indexOf(object.id);
        if (cardIndex === -1) {
          continue;
        }
        invalidateSizeCache(cardIndex);
      }
    }

    onStateEvent(StateEvents.Set, handleSet);
    return () => {
      offStateEvent(StateEvents.Set, handleSet);
    };
  });
  return null;
}

function Card({
  id,
  roadmapId,
  columnId,
  onEdit,
  onEditComplete,
  moveToTopBottom,
}: {
  id: string;
  roadmapId: string;
  columnId: string;
  onEdit?: () => void;
  onEditComplete?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], columnId: string) => void;
}) {
  const initiativeIdsInColumn = useInitiativeIdsInColumn();

  const routingState = React.useMemo(
    () => ({
      filterId: roadmapId,
      siblingEntities: initiativeIdsInColumn(columnId),
    }),
    [columnId, initiativeIdsInColumn, roadmapId]
  );
  const metaConfig = useRecoilValue(boardMetadataConfig(roadmapId));

  if (onEditComplete) {
    return <EditInitiativeCard metadataConfig={metaConfig} id={id} onDone={onEditComplete} />;
  }

  return (
    <InitiativeCard
      metadataConfig={metaConfig}
      roadmapInitiativeId={id}
      onChangeTitle={onEdit}
      routingState={routingState}
      moveToTopBottom={moveToTopBottom}
    />
  );
}

function InitiativesBoardView({
  roadmapId,
  columnIds,
  keyNavFocus,
  disabledColumnDndMessages,
  copyEntities,
  onPaste,
  move,
}: {
  roadmapId: string;
  columnIds: string[];
  keyNavFocus?: React.RefObject<string | null>;
  disabledColumnDndMessages?: { [columnId: string]: string | React.ReactNode | null };
  copyEntities: (ids: string[]) => {
    id: string;
    name: string;
    url: string;
  }[];
  onPaste: (statusId: string, index: number, ids: string[]) => void;
  move: (rawIssueIdsToMove: string[], statusId: string, index: number) => Promise<void>;
}) {
  // TODO: better implementation at some point
  const getAllColumns = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const ret = Object.fromEntries(
          columnIds.map(id => [
            id,
            snapshot
              .getLoadable(
                filteredRoadmapInitiativeIdsForColumnSelector({ columnId: id, filterId: roadmapId })
              )
              .getValue(),
          ])
        );
        return ret;
      },
    [columnIds]
  );

  const getSelector = React.useCallback(
    (columnId: string) =>
      filteredRoadmapInitiativeIdsForColumnSelector({ columnId, filterId: roadmapId }),
    [roadmapId]
  );

  const renderNewColumn = React.useCallback(
    () => <NewColumnHeader roadmapId={roadmapId} />,
    [roadmapId]
  );

  const renderColumnAccessories = React.useCallback((columnId: string) => {
    return <ResizeCardsOnStateChange columnId={columnId} />;
  }, []);

  const moveToTopBottom = React.useCallback(
    (direction: 'top' | 'bottom', ids: string[], columnId: string) => {
      move(ids, columnId, direction === 'top' ? 0 : 99999999);
    },
    [move]
  );

  const renderCard = React.useCallback(
    (
      id: string,
      columnId: string,
      edit:
        | {
            start?: (() => void) | undefined;
            end?: (() => void) | undefined;
          }
        | undefined
    ) => (
      <Card
        id={id}
        roadmapId={roadmapId}
        columnId={columnId}
        onEdit={edit?.start}
        onEditComplete={edit?.end}
        moveToTopBottom={moveToTopBottom}
      />
    ),
    [roadmapId]
  );

  return (
    <VirtualizedBoardView
      dragEnabled
      disabledColumnDndMessages={disabledColumnDndMessages}
      className={styles.board}
      id={roadmapId}
      columnIds={columnIds}
      commandGroup={CommandGroup.Entities}
      getAllColumns={getAllColumns}
      getSelector={getSelector}
      renderNewColumn={renderNewColumn}
      spacerHeight={8}
      columnHeaderHeight={32}
      renderColumnHeader={(roadmapColumnId, onNewCard) => {
        return <RoadmapColumnHeader roadmapColumnId={roadmapColumnId} onNewCard={onNewCard} />;
      }}
      renderCard={renderCard}
      renderNewCard={(roadmapColumnId, index, onDone) => {
        return (
          <NewInitiative
            filterId={roadmapId}
            roadmapColumnId={roadmapColumnId}
            index={index}
            onDone={onDone}
          />
        );
      }}
      renderPlaceholder={(columnId, onCreateNew) => {
        return <Placeholder onCreateNew={onCreateNew} type="initiative" statusId={columnId} />;
      }}
      renderColumnAccessories={renderColumnAccessories}
      onMoveCards={(ids, toColumn, toIndex) => {
        move(ids, toColumn, toIndex);
      }}
      keyNavFocus={keyNavFocus}
      onCopy={copyEntities}
      onPaste={onPaste}
    />
  );
}

function ListItem({
  id,
  roadmapId,
  columnId,
  className,
  onEdit,
  onEditComplete,
  moveToTopBottom,
}: {
  id: string;
  roadmapId: string;
  columnId: string;
  className?: string;
  onEdit?: () => void;
  onEditComplete?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], columnId: string) => void;
}) {
  const initiaitiveId = useRecoilValue(initiativeIdForRoadmapInitiativeSelector(id));
  const initiativeIdsInColumn = useInitiativeIdsInColumn();
  const routingState = React.useMemo(
    () => ({
      filterId: roadmapId,
      siblingEntities: initiativeIdsInColumn(columnId),
    }),
    [columnId, initiativeIdsInColumn, roadmapId]
  );
  const metadataConfig = useRecoilValue(boardMetadataConfig(roadmapId));

  if (!initiaitiveId) {
    return null;
  }

  if (onEditComplete) {
    return (
      <EditInitiativeListItem
        metadataConfig={{ ...metadataConfig, roadmaps: false }}
        id={initiaitiveId}
        onDone={onEditComplete}
        className={className}
      />
    );
  }

  return (
    <InitiativeListItem
      metadataConfig={{ ...metadataConfig, roadmaps: false }}
      onChangeTitle={onEdit}
      keyNavId={id}
      id={initiaitiveId}
      className={className}
      routingState={routingState}
      roadmapId={roadmapId}
      moveToTopBottom={moveToTopBottom}
    />
  );
}

function InitiativesListView({
  roadmapId,
  columnIds,
  keyNavFocus,
  disabledColumnDndMessages,
  copyEntities,
  onPaste,
  move,
}: {
  roadmapId: string;
  columnIds: string[];
  keyNavFocus?: React.RefObject<string | null>;
  disabledColumnDndMessages?: { [columnId: string]: string | React.ReactNode | null };
  copyEntities: (ids: string[]) => {
    id: string;
    name: string;
    url: string;
  }[];
  onPaste: (statusId: string, index: number, ids: string[]) => void;
  move: (rawIssueIdsToMove: string[], statusId: string, index: number) => Promise<void>;
}) {
  const { filteredRoadmapInitiativeIds: itemIds } = useRecoilValue(
    roadmapInitiativesByColumnSelecctor({ roadmapId, filterId: roadmapId })
  );

  const getCountSelector = React.useCallback(
    (columnId: string) =>
      initiativeCountForColumn({
        filterId: roadmapId,
        columnId,
      }),
    [roadmapId]
  );

  const renderSectionHeader = React.useCallback(
    (columnId: string, collapsed: boolean, toggleCollapsed: () => void, onNewItem: () => void) => {
      return (
        <RoadmapSectionHeader
          getCountSelector={getCountSelector}
          columnId={columnId}
          collapsed={collapsed}
          onToggleCollapsed={toggleCollapsed}
          onNewCard={onNewItem}
        />
      );
    },
    [getCountSelector]
  );
  const moveToTopBottom = React.useCallback(
    (direction: 'top' | 'bottom', ids: string[], columnId: string) => {
      move(ids, columnId, direction === 'top' ? 0 : 99999999);
    },
    [move]
  );

  const renderItem = React.useCallback(
    (
      id: string,
      columnId: string,
      isFirst: boolean,
      isLast: boolean,
      edit:
        | {
            start?: (() => void) | undefined;
            end?: (() => void) | undefined;
          }
        | undefined
    ) => {
      return (
        <ListItem
          id={id}
          className={cn('listItem', {
            first: isFirst,
            last: isLast,
          })}
          roadmapId={roadmapId}
          columnId={columnId}
          onEdit={edit?.start}
          onEditComplete={edit?.end}
          moveToTopBottom={moveToTopBottom}
        />
      );
    },
    [roadmapId]
  );

  const renderNewItem = React.useCallback(
    (columnId: string, index: number, isFirst: boolean, isLast: boolean, onDone: () => void) => {
      return (
        <NewInitiative
          filterId={roadmapId}
          roadmapColumnId={columnId}
          index={index}
          onDone={onDone}
          className={cn('listItem', {
            first: isFirst,
            last: isLast,
          })}
          list
        />
      );
    },
    []
  );

  return (
    <VirtualizedListView
      id={roadmapId}
      disabledColumnDndMessages={disabledColumnDndMessages}
      className={styles.list}
      sectionIds={columnIds}
      itemIds={itemIds}
      sectionHeaderHeight={60}
      itemHeight={41}
      spacerHeight={32}
      interactiveHeaders
      commandGroup={CommandGroup.Entities}
      keyNavFocus={keyNavFocus}
      onMoveItems={(ids, toStatus, toIndex) => {
        move(ids, toStatus, toIndex);
      }}
      renderSectionHeader={renderSectionHeader}
      renderItem={renderItem}
      renderNewItem={renderNewItem}
      renderPlaceholder={(statusId: string, onCreateNew: () => void) => {
        return <Placeholder type="initiative" statusId={statusId} onCreateNew={onCreateNew} list />;
      }}
      renderAccessories={grid => {
        return <ResizeItemsOnStateChange ids={grid} />;
      }}
      canCreate={() => true}
      onCopy={copyEntities}
      onPaste={onPaste}
    />
  );
}

function InitiativeContext({
  roadmapId,
  columnIds,
  keyNavFocus,
}: {
  roadmapId: string;
  columnIds: string[];
  keyNavFocus: React.MutableRefObject<string | null>;
}) {
  const [mode, setMode] = useRecoilState(roadmapScreenMode(roadmapId));
  const { focused, selected } = useKeyNavigationState();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const setFocus = useSetKeyNavigationFocus();
  const setSelected = useSetKeyNavigationSelection();

  const roadmapInitiativeIds = (selected ?? (focused ? [focused] : []))
    .map(id => id.replace('cycle-', ''))
    .filter(id => !id.includes('-'));

  const initiativeIds = useRecoilValue(
    initiativeIdsForRoadmapInitiativesSelector(roadmapInitiativeIds)
  );

  const focusedInitiativeId = useRecoilValue(initiativeIdForRoadmapInitiativeSelector(focused));

  const getAllColumns = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const ret = Object.fromEntries(
          columnIds.map(columnId => [
            columnId,
            snapshot
              .getLoadable(
                filteredRoadmapInitiativeIdsForColumnSelector({ columnId, filterId: roadmapId })
              )
              .getValue(),
          ])
        );
        return ret;
      },
    [columnIds]
  );

  const undo = React.useCallback(() => {
    disableMissingElementDetection();
    setFocus(focused);
    if (selected) {
      setSelected(selected);
    }
    enableMissingElementDetection();
  }, [focused, selected]);

  useMoveRoadmapInitiativesContext({
    onMove: (roadmapInitiatives: RoadmapInitiative[], columnId: string) => {
      const focusedInitiative = roadmapInitiatives.find(i => i.id === focused);
      // if the destination status is where we already are, nothing to do
      if (!focused || !focusedInitiative || focusedInitiative.columnId === columnId) {
        return undo;
      }

      const itemsByStatus = getAllColumns();

      const idsByStatus = (itemsByStatus[focusedInitiative.columnId] ?? []).filter(
        id => id === focused || !roadmapInitiatives.find(i => i.id === id)
      );
      const indexOfFocused = idsByStatus.indexOf(focused ?? '');

      const idToFocus =
        idsByStatus[indexOfFocused + 1] ??
        idsByStatus[indexOfFocused - 1] ??
        `placeholder-${focusedInitiative.columnId}`;

      // Note: we have a performance fix where we pass the current focused element down as a ref instead
      // of reading the keynav state. Unfortunately, this causes a race condition where the board tries to ensure
      // the focused element is on the screen before we managed to update this ref. So we update it explicity here.
      keyNavFocus.current = idToFocus;

      disableMissingElementDetection();
      setFocus(idToFocus, FocusReason.Programmatic);
      enableMissingElementDetection();

      return undo;
    },
  });

  return (
    <>
      <CommandContext
        context={{
          group: CommandGroup.Entities,
          entityIds: initiativeIds,
          focusedEntityId: focusedInitiativeId,
          roadmapId: roadmapId,
        }}
      />
      <CustomCommand
        command={{
          id: `toggle-${mode}-view`,
          group: CommandGroup.Board,
          hotkey: toggleListViewKey,
          description: mode === Mode.Board ? 'Switch to list view' : 'Switch to board view',
          icon: mode === Mode.Board ? 'list_view' : 'board_view',
          handler: () => {
            setMode(previous => (previous === Mode.Board ? Mode.List : Mode.Board));
          },
          aliases: ['list', 'board'],
        }}
      />
    </>
  );
}

function Initiatives({
  roadmap,
  keyNavFocus,
}: {
  roadmap: RoadmapModel;
  keyNavFocus: React.MutableRefObject<string | null>;
}) {
  const roadmapId = roadmap.id;
  const columns = useRecoilValue(roadmapColumnsForRoadmapSelector(roadmapId));
  const columnIds = columns.map(c => c.id);
  const mode = useRecoilValue(roadmapScreenMode(roadmapId));
  const copyEntities = useCopyEntitiesToClipboard();
  const duplicateInitiatives = useDuplicateInitiatives();
  const setFocus = useSetKeyNavigationFocus();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const updateRoadmapInitiativeSorts = useUpdateRoadmapInitiativeSorts();

  const move = useRecoilCallback(
    ({ snapshot }) =>
      async (idsToMove: string[], columnId: string, index: number) => {
        const {
          filteredRoadmapInitiativeIds: filteredIds,
          roadmapInitiativeIdsByColumn: idsByColumn,
        } = snapshot
          .getLoadable(
            roadmapInitiativesByColumnSelecctor({
              roadmapId,
              filterId: roadmapId,
            })
          )
          .getValue();

        const idsForColumn = [...(idsByColumn[columnId] ?? [])].filter(
          id => !idsToMove.includes(id)
        );

        const filteredIssuesForStatus = [...(filteredIds[columnId] ?? [])].filter(
          id => !idsToMove.includes(id)
        );

        // we're inserting into the filtered list, but need to actually insert into the real list
        const filteredIndexToInsertAt = Math.min(index, filteredIssuesForStatus.length);

        // if we're moving to the top, let's just put it at the top of the real list
        let indexToInsertAt = 0;

        // if we're not moving to the top, find the card we're going to place the card after
        if (filteredIndexToInsertAt !== 0) {
          const initiativeToInsertAfter = filteredIssuesForStatus[filteredIndexToInsertAt - 1];
          indexToInsertAt = idsForColumn.indexOf(initiativeToInsertAfter) + 1;
        }

        idsForColumn.splice(indexToInsertAt, 0, ...idsToMove);
        const previousId: string | undefined = idsForColumn[indexToInsertAt - 1];
        const nextId: string | undefined = idsForColumn[indexToInsertAt + idsToMove.length];

        disableMissingElementDetection();
        updateRoadmapInitiativeSorts(columnId, idsToMove, previousId, nextId);
        enableMissingElementDetection();
      },
    [roadmapId]
  );

  const onPaste = useRecoilCallback(
    ({ snapshot }) =>
      (columnId: string, index: number, ids: string[]) => {
        const items = snapshot
          .getLoadable(
            filteredRoadmapInitiativeIdsForColumnSelector({ columnId, filterId: roadmapId })
          )
          .getValue();
        const current = items[index];
        const previous = items[index - 1];
        const results = duplicateInitiatives(ids, columnId, previous, current);
        if (results.length) {
          setTimeout(() => {
            setFocus(results[0]);
          });
        }
      },
    [setFocus, duplicateInitiatives]
  );

  const disabledColumnDndMessages = React.useMemo(
    () =>
      columns?.reduce((ret, column) => {
        if (column.sortMode !== IssueStatusSortMode.Manual) {
          ret[column.id] = (
            <>
              <span className="semiBold">{column.name}</span> is ordered by{' '}
              <span className="semiBold">{sortModeToString(column.sortMode)}</span>.
            </>
          );
        }
        return ret;
      }, {} as { [columnId: string]: React.ReactNode }),
    [columns]
  );

  return (
    <>
      <InitiativeContext roadmapId={roadmapId} keyNavFocus={keyNavFocus} columnIds={columnIds} />
      {mode === Mode.Board && (
        <InitiativesBoardView
          roadmapId={roadmapId}
          columnIds={columnIds}
          copyEntities={copyEntities}
          onPaste={onPaste}
          move={move}
          keyNavFocus={keyNavFocus}
          disabledColumnDndMessages={disabledColumnDndMessages}
        />
      )}
      {mode === Mode.List && (
        <InitiativesListView
          roadmapId={roadmapId}
          columnIds={columnIds}
          copyEntities={copyEntities}
          onPaste={onPaste}
          move={move}
          keyNavFocus={keyNavFocus}
          disabledColumnDndMessages={disabledColumnDndMessages}
        />
      )}
    </>
  );
}

function RoadmapContext({ roadmapId }: { roadmapId: string }) {
  const organization = useOrganization();
  const history = useHistory();
  const roadmapsForOrg = useRecoilValue(roadmapsForOrganizationSelector(organization.id));
  const currentIndex = roadmapsForOrg.findIndex(r => r.id === roadmapId);
  const { focusedColumn } = useKeyNavigationState();
  const maybeSpace = useMaybeSpace();
  const findRoadmapInitiative = useFindRoadmapInitiativeForInitiative();

  const modals = useModals();
  const getSnippet = useSnippetForCallback();
  const setFocus = useSetKeyNavigationFocus();

  const previous = roadmapsForOrg.at((currentIndex - 1) % roadmapsForOrg.length);

  const props = useRecoilValue(filterPropertiesSelector(roadmapId));

  useNewEntityModalArgs({
    type: 'Initiative',
    roadmapIds: [roadmapId],
    ...props,
    onCreated: initiativeId => {
      if (initiativeId) {
        const roadmapInitiative = findRoadmapInitiative(roadmapId, initiativeId);
        if (roadmapInitiative) {
          setFocus(roadmapInitiative.id);
        }
      }
    },
  });

  useDeleteRoadmapsContext({
    onDelete: () => {
      if (maybeSpace) {
        history.push(spacePath(organization, maybeSpace, 'initiatives'));
      } else if (currentIndex && roadmapsForOrg.length > 1 && previous) {
        history.push(roadmapPath(organization, previous));
      } else {
        history.push(roadmapPath(organization, 'all'));
      }
    },
  });

  return (
    <>
      <CommandContext<RoadmapCommandGroupContext>
        context={{
          group: CommandGroup.Roadmap,
          roadmapId,
          columnId: focusedColumn ?? undefined,
          spaceId: maybeSpace?.id,
        }}
      />
      <CustomCommand
        command={{
          id: `create-from-snippet`,
          group: CommandGroup.Board,
          priority: 9,
          hotkey: insertSnipppetKey,
          description: `Create new from snippet`,
          icon: 'none',
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.Snippets,
              context: {
                spaceId: maybeSpace?.id,
                trackingContext: 'Create initiative from snippet',
                onSnippetPicked: (snippetId: string) => {
                  const snippet = getSnippet(snippetId);
                  const content = snippet ? JSON.parse(snippet.contents) : emptyDocument();
                  modals.openModal(Modals.NewEntity, {
                    content,
                  });
                },
              },
            });
          },
        }}
      />
    </>
  );
}

export function Roadmap({ roadmapId }: { roadmapId: string }) {
  const roadmap = useRecoilValue(roadmapSelector(roadmapId));
  const columnIds = useRecoilValue(roadmapColumnIdsForRoadmapSelector(roadmapId));
  const mode = useRecoilValue(roadmapScreenMode(roadmapId));
  const fetched = useRecoilValue(markerState(FetchedMarker.id));

  const keyNavFocusRef = React.useRef<string | null>(null);

  if (!roadmap) {
    if (fetched) {
      return <NotFoundScreen />;
    }
    return <LoadingScreen />;
  }

  return (
    <KeyNavigationProvider
      columnIds={mode === Mode.Board ? columnIds : [roadmapId]}
      disableEnsureVisible
      multiSelect
      isMultiSelectable={id => !id.includes('-')}
    >
      <RoadmapContext roadmapId={roadmapId} />
      <RoadmapTopBar roadmap={roadmap} />
      <Filters2 id={roadmapId} />
      {roadmap.roadmapType !== RoadmapType.Timeline && (
        <Initiatives roadmap={roadmap} keyNavFocus={keyNavFocusRef} />
      )}
      {roadmap.roadmapType === RoadmapType.Timeline && <Timeline roadmapId={roadmapId} />}
    </KeyNavigationProvider>
  );
}
