import * as React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import { entityTypeString } from '../../syncEngine/selectors/entities';
import {
  addToCurrentCycleKey,
  addToInitiativeKey,
  alternateComboKey,
  assignIssueKey,
  labelIssueKey,
  searchKey,
  selfFilterHotKey,
  setEffortKey,
  setImpactKey,
  spaceInitiativeKey,
  watchIssueKey,
} from '../../utils/config';
import { FilterableEntityType } from '../../utils/filtering';
import { FilterType, filterChainState } from '../../utils/filtering2';
import { ButtonStyle } from './button';
import { ContentProps, FilterMenu, MenuMode, addToPickerFilter } from './filters2';
import { KeyNavigationProvider } from './keyNavigation';
import { LISTVIEW_ID, ListView, ListViewItem, NO_KEYNAV } from './listView';
import menuStyles from './menu/menu.module.scss';

export function InitiativeContents({
  id,
  onModeChanged,
  entityType,
  onClose,
  options,
}: ContentProps) {
  const setFilterChain = useSetRecoilState(filterChainState(id));
  const user = useCurrentUser();

  const items: ListViewItem[] = [
    {
      id: 'members',
      icon: 'member',
      contents: 'Members',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.Member,
        });
      },
      hotkey: assignIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'labels',
      icon: 'label',
      contents: 'Labels',
      onSelected: () => {
        onModeChanged(MenuMode.Labels);
      },
      hotkey: labelIssueKey,
      className: menuStyles.item,
    },
    ...(!options?.disableSpaces
      ? [
          {
            id: 'spaces',
            icon: 'workspace',
            contents: 'Spaces',
            onSelected: () => {
              onModeChanged(MenuMode.Space);
            },
            hotkey: spaceInitiativeKey,
            className: menuStyles.item,
          },
        ]
      : []),
    {
      id: NO_KEYNAV,
      key: 'sep-2',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'my',
      icon: 'my_work',
      contents: entityType ? `My ${entityTypeString(entityType)}s` : 'My items',
      onSelected: () => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(
            previous.filters,
            previous.filters.length,
            user.id,
            FilterType.Member
          );
          return { ...previous, filters };
        });
        onClose();
      },
      hotkey: selfFilterHotKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-3',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'creator',
      icon: 'creator',
      contents: 'Creator',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.Creator,
        });
      },
      hotkey: 'b',
      className: menuStyles.item,
    },
    {
      id: 'freeText',
      icon: 'search_content',
      contents: 'Title and ID',
      onSelected: () => {
        onModeChanged(MenuMode.FreeText);
      },
      hotkey: searchKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-4',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'createdSince',
      icon: 'none',
      contents: 'Created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'c',
      className: menuStyles.item,
    },
    {
      id: 'updatedSince',
      icon: 'none',
      contents: 'Updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'u',
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-5',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'clear',
      icon: 'exit',
      contents: 'Clear all filters',
      onSelected: () => {
        setFilterChain(previous => {
          return { ...previous, filters: [] };
        });
        onClose();
      },
      hotkey: 'backspace',
      className: menuStyles.item,
    },
  ];

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <ListView items={items} />
    </KeyNavigationProvider>
  );
}

export function FeedbackContents({
  id,
  onModeChanged,
  entityType,
  onClose,
  showStateFilter,
}: ContentProps) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const user = useCurrentUser();

  const items: ListViewItem[] = [
    ...(showStateFilter
      ? [
          {
            id: 'state',
            icon: 'status_done',
            contents: 'State',
            onSelected: () => {
              onModeChanged(MenuMode.State, {
                stateLabels: {
                  active: 'Unprocessed',
                  closed: 'Processed',
                },
              });
            },
            hotkey: `shift+p`,
            className: menuStyles.item,
          },
          {
            id: NO_KEYNAV,
            key: 'sep-type',
            contents: () => <div className={menuStyles.separator} />,
          },
        ]
      : []),
    {
      id: 'members',
      icon: 'member',
      contents: 'Owners',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.Member,
        });
      },
      hotkey: assignIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'people',
      icon: 'member',
      contents: 'People',
      onSelected: () => {
        onModeChanged(MenuMode.People);
      },
      hotkey: 'p',
      className: menuStyles.item,
    },
    {
      id: 'companies',
      icon: 'org',
      contents: 'Companies',
      onSelected: () => {
        onModeChanged(MenuMode.Companies);
      },
      hotkey: 'o',
      className: menuStyles.item,
    },
    {
      id: 'tags',
      icon: 'label',
      contents: 'Tags',
      onSelected: () => {
        onModeChanged(MenuMode.Tags);
      },
      hotkey: labelIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'freeText',
      icon: 'search_content',
      contents: 'Title',
      onSelected: () => {
        onModeChanged(MenuMode.FreeText);
      },
      hotkey: searchKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-2',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'my',
      icon: 'my_work',
      contents: entityType ? `My ${entityTypeString(entityType)}s` : 'My items',
      onSelected: () => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(
            previous.filters,
            previous.filters.length,
            user.id,
            FilterType.Member
          );
          return { ...previous, filters };
        });
        onClose();
      },
      hotkey: selfFilterHotKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-3',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'createdSince',
      icon: 'none',
      contents: 'Created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'c',
      className: menuStyles.item,
    },
    {
      id: 'updatedSince',
      icon: 'none',
      contents: 'Updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'u',
      className: menuStyles.item,
    },
  ];

  if (filterChain.filters.length > 0) {
    items.push(
      {
        id: NO_KEYNAV,
        key: 'sep-5',
        contents: () => <div className={menuStyles.separator} />,
      },
      {
        id: 'clear',
        icon: 'exit',
        contents: 'Clear all filters',
        onSelected: () => {
          setFilterChain(previous => {
            return { ...previous, filters: [] };
          });
          onClose();
        },
        hotkey: 'backspace',
        className: menuStyles.item,
      }
    );
  }

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <ListView items={items} />
    </KeyNavigationProvider>
  );
}

export function DefaultContents({
  id,
  onModeChanged,
  entityType,
  showStateFilter,
  onClose,
}: ContentProps) {
  const setFilterChain = useSetRecoilState(filterChainState(id));
  const user = useCurrentUser();
  const space = useMaybeSpace();

  const items: ListViewItem[] = [
    ...(showStateFilter
      ? []
      : [
          {
            id: 'state',
            icon: 'status_done',
            contents: 'State',
            onSelected: () => {
              onModeChanged(MenuMode.State);
            },
            hotkey: `${alternateComboKey}+s`,
            className: menuStyles.item,
          },
          {
            id: NO_KEYNAV,
            key: 'sep-type',
            contents: () => <div className={menuStyles.separator} />,
          },
        ]),
    {
      id: 'members',
      icon: 'member',
      contents: 'Members',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.Member,
        });
      },
      hotkey: assignIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'labels',
      icon: 'label',
      contents: 'Labels',
      onSelected: () => {
        onModeChanged(MenuMode.Labels);
      },
      hotkey: labelIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'impact',
      icon: 'impact',
      contents: 'Impact',
      onSelected: () => {
        onModeChanged(MenuMode.Impact);
      },
      hotkey: setImpactKey,
      className: menuStyles.item,
    },
    {
      id: 'effort',
      icon: 'effort',
      contents: 'Effort',
      onSelected: () => {
        onModeChanged(MenuMode.Effort);
      },
      hotkey: setEffortKey,
      className: menuStyles.item,
    },
    {
      id: 'initiatives',
      icon: 'initiative',
      contents: 'Initiatives',
      onSelected: () => {
        onModeChanged(MenuMode.Initiatives);
      },
      hotkey: addToInitiativeKey,
      className: menuStyles.item,
    },
    ...(space
      ? []
      : [
          {
            id: 'space',
            icon: 'workspace',
            contents: 'Space',
            onSelected: () => {
              onModeChanged(MenuMode.Space);
            },
            hotkey: 's',
            className: menuStyles.item,
          },
        ]),
    {
      id: 'cycles',
      icon: 'cycle_current',
      contents: 'Cycles',
      onSelected: () => {
        onModeChanged(MenuMode.Cycles);
      },
      hotkey: addToCurrentCycleKey.replace('shift', ''),
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-2',
      contents: () => <div className={menuStyles.separator} />,
    },
    ...(!entityType || entityType === 'Feedback'
      ? [
          {
            id: 'people',
            icon: 'member',
            contents: 'People',
            onSelected: () => {
              onModeChanged(MenuMode.People);
            },
            hotkey: 'shift+p',
            className: menuStyles.item,
          },
          {
            id: 'companies',
            icon: 'org',
            contents: 'Companies',
            onSelected: () => {
              onModeChanged(MenuMode.Companies);
            },
            hotkey: 'shift+c',
            className: menuStyles.item,
          },
          {
            id: 'tags',
            icon: 'label',
            contents: 'Tags',
            onSelected: () => {
              onModeChanged(MenuMode.Tags);
            },
            className: menuStyles.item,
            hotkey: 'shift+t',
          },
          {
            id: NO_KEYNAV,
            key: 'sep-feedback',
            contents: () => <div className={menuStyles.separator} />,
          },
        ]
      : []),
    {
      id: 'my',
      icon: 'my_work',
      contents: entityType ? `My ${entityTypeString(entityType)}s` : 'My items',
      onSelected: () => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(
            previous.filters,
            previous.filters.length,
            user.id,
            FilterType.Member
          );
          return { ...previous, filters };
        });
        onClose();
      },
      hotkey: selfFilterHotKey,
      className: menuStyles.item,
    },
    {
      id: 'watched',
      icon: 'watch',
      contents: 'Watched by me',
      onSelected: () => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(
            previous.filters,
            previous.filters.length,
            user.id,
            FilterType.WatchedBy
          );
          return { ...previous, filters };
        });
        onClose();
      },
      hotkey: watchIssueKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-3',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'creator',
      icon: 'creator',
      contents: 'Creator',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.Creator,
        });
      },
      hotkey: 'b',
      className: menuStyles.item,
    },
    {
      id: 'freeText',
      icon: 'search_content',
      contents: 'Title and ID',
      onSelected: () => {
        onModeChanged(MenuMode.FreeText);
      },
      hotkey: searchKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-4',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'createdSince',
      icon: 'none',
      contents: 'Created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'c',
      className: menuStyles.item,
    },
    {
      id: 'updatedSince',
      icon: 'none',
      contents: 'Updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'u',
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-5',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'clear',
      icon: 'exit',
      contents: 'Clear all filters',
      onSelected: () => {
        setFilterChain(previous => ({ ...previous, filters: [] }));
        onClose();
      },
      hotkey: 'backspace',
      className: menuStyles.item,
    },
  ];

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <ListView items={items} />
    </KeyNavigationProvider>
  );
}

export function EntityFilterMenu2({
  entityType,
  id,
  buttonStyle,
  className,
  style,
  compact,
}: {
  entityType?: FilterableEntityType;
  buttonStyle?: ButtonStyle;
  id: string;
  style?: React.CSSProperties;
  className?: string;
  compact?: boolean;
}) {
  return (
    <div className={className}>
      <FilterMenu
        id={id}
        entityType={entityType}
        buttonStyle={buttonStyle}
        style={style}
        DefaultContents={DefaultContents}
        compact={compact}
      />
    </div>
  );
}
