import cn from 'classnames';
import * as React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Descendant } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { Elements, VideoElement } from '../../../shared/slate/types';
import Hotkey from '../../components/hotkey';
import { CommentButton } from '../../components/new/commentButton';
import { DownloadButton } from '../../components/new/downloadButton';
import { useEnsureFocusedElementIsVisible } from '../../components/new/keyNavigation';
import { LoadingSpinner } from '../../components/new/loadingSpinner';
import { VoidActions } from '../../components/new/voidActions';
import { RetryingVideo } from '../../components/retryingVideo';
import { useConfiguration } from '../../contexts/configurationContext';
import UploadPlaceholder from '../../slate/components/uploadPlaceholder';
import { isIOS } from '../../utils/config';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { useResizeObserver } from '../staticSlateHelpers';
import { OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import { ErrorElement } from './error';
import styles from './video.module.scss';
import { VoidBlock } from './voidBlock';

export function StaticVideo({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: VideoElement }) {
  const { host } = useConfiguration();

  const { url } = element;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const ref = React.useRef<HTMLDivElement>(null);
  useResizeObserver(ref, url);

  if (!url) {
    return (
      <DummyNode element={element} attributes={attributes}>
        {children}
      </DummyNode>
    );
  }

  const fullUrl = url.startsWith('/') ? `${host}${url}` : url;
  const downloadUrl = url.startsWith('/') ? `${fullUrl}?forceDownload=true` : fullUrl;

  return (
    <div className="block rowCenter fullWidth" {...attributes}>
      <div
        className={cn(styles.video, {
          [styles.loading]: loading && !error,
        })}
        ref={ref}
      >
        {error && <ErrorElement error={error} />}
        {!error && (
          <RetryingVideo
            src={url}
            preload="metadata"
            onLoadedMetadata={() => {
              setLoading(false);
              ensureVisible();
            }}
            onLoadError={err => {
              setError(err);
            }}
            maxRetries={10}
            checkForUploadInProgress
            controls
            timeOffset={isIOS ? 0.1 : undefined}
          />
        )}
        <VoidActions floating className={styles.actions}>
          <DownloadButton url={downloadUrl} />
        </VoidActions>
        {loading && !error && (
          <div className="loadingContainer">
            <LoadingSpinner />
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export function Video({
  attributes,
  element,
  children,
}: RenderElementProps & { element: VideoElement }) {
  const ref = React.useRef<HTMLDivElement>(null);
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const { host } = useConfiguration();
  const editor = useSlateStatic();
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();
  const selectionCollapsedRef = React.useRef(selected && selectionCollapsed);
  selectionCollapsedRef.current = selected && selectionCollapsed;

  const { url } = element;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');

  const [playing, setPlaying] = React.useState(false);

  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  const sizeObserver = React.useRef(
    window.ResizeObserver
      ? new window.ResizeObserver(() => {
          if (selectionCollapsedRef.current && ref.current) {
            scrollIntoView(ref.current, {
              block: 'center',
              behavior: 'auto',
              scrollMode: 'if-needed',
            });
          }
          ensureVisible();
          KitemakerEditor.ensureFocusOnScreen(editor, 0);
        })
      : null
  );

  React.useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const video = videoRef.current;
    function onPlaying() {
      setPlaying(true);
    }

    function onEnded() {
      setPlaying(false);
    }

    function onPaused() {
      setPlaying(false);
    }

    video.addEventListener('playing', onPlaying);
    video.addEventListener('ended', onEnded);
    video.addEventListener('pause', onPaused);

    return () => {
      video.removeEventListener('playing', onPlaying);
      video.removeEventListener('ended', onEnded);
      video.removeEventListener('pause', onPaused);
    };
  }, [url]);

  React.useEffect(() => {
    if (!ref.current || !sizeObserver.current) {
      return;
    }
    const observer = sizeObserver.current;
    const observed = ref.current;
    observer.observe(observed);
    return () => {
      observer.unobserve(observed);
    };
  }, [sizeObserver, url]);

  if (!url) {
    return (
      <UploadPlaceholder
        requireUrl
        attributes={attributes}
        element={element}
        icon="video"
        placeholder="Add a video"
        focusedPlaceholder="Drop a video, upload from your computer (press enter), or paste a URL"
        onUploadPrepared={result => {
          ReactEditor.focus(editor);

          const path = ReactEditor.findPath(editor, element);
          const [first, ...rest] = result;
          KitemakerTransforms.setNodes(editor, first, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);

          if (rest.length) {
            const additionalNodes: Descendant[] = rest.map(video => ({
              type: Elements.Video,
              ...video,
              children: [{ type: 'text', text: '' }],
            }));
            KitemakerTransforms.insertNodes(editor, additionalNodes);
            KitemakerTransforms.move(editor, {
              distance: additionalNodes.length - 1,
              unit: 'line',
            });
          }
        }}
        onSubmit={result => {
          ReactEditor.focus(editor);

          const path = ReactEditor.findPath(editor, element);
          KitemakerTransforms.setNodes(editor, { url: result }, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);
        }}
      >
        {children}
      </UploadPlaceholder>
    );
  }

  const fullUrl = url.startsWith('/') ? `${host}${url}` : url;
  const downloadUrl = url.startsWith('/') ? `${fullUrl}?forceDownload=true` : fullUrl;

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <div className="rowCenter">{children}</div>
      <VoidBlock element={element} className={styles.videoContainer}>
        <div
          className={cn(styles.video, {
            [styles.voidElementSelected]: selected,
            [styles.voidElementInsight]: !!element.insightId,
            [styles.loading]: loading && !error,
          })}
          ref={ref}
        >
          {selected && selectionCollapsed && (
            <Hotkey
              command={{
                id: 'play-video',
                hotkey: 'space',
                global: true,
                handler: e => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  if (!playing) {
                    videoRef.current?.play();
                  } else {
                    videoRef.current?.pause();
                  }
                },
              }}
            />
          )}
          {error && <ErrorElement error={error} />}
          {!error && (
            <RetryingVideo
              ref={videoRef}
              src={url}
              preload="metadata"
              onLoadedMetadata={() => {
                ensureVisible();
                KitemakerEditor.ensureFocusOnScreen(editor, 0);
                setLoading(false);
              }}
              onLoadError={err => {
                setError(err);
              }}
              maxRetries={10}
              checkForUploadInProgress
              controls
              timeOffset={isIOS ? 0.1 : undefined}
            />
          )}
          <VoidActions floating className={styles.actions}>
            {editor.inlineComments && editor.entityId && <CommentButton element={element} />}
            <DownloadButton url={downloadUrl} />
          </VoidActions>
          {loading && !error && (
            <div className="loadingContainer">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </VoidBlock>
    </div>
  );
}
