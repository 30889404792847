import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Doc } from '../../../sync/__generated/models';
import { ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import {
  useCopyEntityLinksToClipboard,
  useCopyEntityNumbersToClipboard,
} from '../../components/new/copyAndPaste';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useIsTinyScreen } from '../../hooks/useResponsiveDesign';
import {
  useDeleteDocumentsAndFolders,
  useMoveDocumentsAndFoldersToFolder,
  useUpdateDocuments,
} from '../../syncEngine/actions/documents';
import { useToggleWatchersForEntities } from '../../syncEngine/actions/entities';
import { useToggleStarred } from '../../syncEngine/actions/starred';
import { documentsSelector, isDocument } from '../../syncEngine/selectors/documents';
import { foldersSelector, isFolder } from '../../syncEngine/selectors/folders';
import { isStarredSelector } from '../../syncEngine/selectors/starred';
import {
  archiveIssueKey,
  copyIssueNumberKey,
  deleteKey,
  editTitleKey,
  moveIssueKey,
  watchIssueKey,
} from '../../utils/config';
import { StarredType } from '../../utils/starred';
import { useKeyNavigationState } from './keyNavigation';
import { FolderPicker } from './pickers/folderPicker';

export function DocumentMenu({
  doc,

  onChangeTitle,
}: {
  doc: Doc;
  closeMenu: () => void;
  onChangeTitle?: () => void;
}) {
  const organization = useOrganization();
  const user = useCurrentUser();
  const deleteDocuments = useDeleteDocumentsAndFolders();
  const copyEntityNumber = useCopyEntityNumbersToClipboard();
  const copyEntityLink = useCopyEntityLinksToClipboard();
  const toggleWatching = useToggleWatchersForEntities();
  const updateDocuments = useUpdateDocuments();
  const moveDocuments = useMoveDocumentsAndFoldersToFolder();
  const toggleStarred = useToggleStarred();

  const tinyScreen = useIsTinyScreen();
  const { selected } = useKeyNavigationState();

  const selectedIds = selected?.includes(doc.id) ? selected : [doc.id];
  const docs = useRecoilValue(documentsSelector(selectedIds)).filter(i => isDocument(i));
  const docIds = docs.map(doc => doc.id);
  const folders = useRecoilValue(foldersSelector(selectedIds)).filter(f => isFolder(f));
  const folderIds = folders.map(folder => folder.id);

  const watching = docs.every(doc => doc.watcherIds.includes(user.id));
  const archived = docs.every(doc => doc.archivedAt);

  const isStarred = useRecoilValue(
    isStarredSelector({
      organizationId: organization.id,
      type: StarredType.Document,
      id: doc.id,
    })
  );

  return (
    <>
      {!tinyScreen && onChangeTitle && (
        <>
          <DropdownMenuItem icon="edit" shortcut={editTitleKey} onClick={onChangeTitle}>
            Change title
          </DropdownMenuItem>
        </>
      )}
      <Submenu>
        <SubmenuTrigger icon="move" shortcut={moveIssueKey}>
          Move
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuHuge">
          <FolderPicker
            omitFolders={folderIds}
            onPicked={folderId => {
              moveDocuments(docIds, folderIds, folderId);
            }}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="copy">Copy</SubmenuTrigger>
        <SubmenuContent className="menuMedium">
          <DropdownMenuItem
            icon="copy"
            shortcut={copyIssueNumberKey}
            onClick={() => {
              copyEntityNumber([doc.id]);
            }}
          >
            Copy number
          </DropdownMenuItem>
          <DropdownMenuItem
            icon="link"
            shortcut={copyIssueNumberKey}
            onClick={() => {
              copyEntityLink([doc.id]);
            }}
          >
            Copy link
          </DropdownMenuItem>
        </SubmenuContent>
      </Submenu>

      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="watch"
        shortcut={watchIssueKey}
        onClick={() => {
          toggleWatching(docIds, [user.id]);
        }}
      >
        {watching ? 'Stop watching' : 'Watch'}
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="starred"
        onClick={() => {
          toggleStarred({ type: StarredType.Document, id: doc.id });
        }}
      >
        {isStarred ? 'Unstar' : 'Star'}
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="archive"
        shortcut={archiveIssueKey}
        onClick={() => {
          for (const doc of docs) {
            if (doc.archivedAt) {
              updateDocuments([doc.id], { archivedAt: null });
            } else {
              updateDocuments([doc.id], { archivedAt: Date.now() });
            }
          }
        }}
      >
        {archived ? `Unarchive` : `Archive`}
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="delete"
        shortcut={deleteKey}
        onClick={() => {
          deleteDocuments(docIds, folderIds);
        }}
      >
        Delete
      </DropdownMenuItem>
    </>
  );
}

export function DocumentMenuButton({
  doc,
  size,
  buttonStyle,
  onOpenChange,
  onChangeTitle,
}: {
  doc: Doc;
  size?: ButtonSize;
  buttonStyle?: ButtonStyle;
  onOpenChange?: (isOpen: boolean) => void;
  onChangeTitle?: () => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  React.useEffect(() => {
    onOpenChange?.(menuOpen);
  }, [menuOpen, onOpenChange]);

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton buttonStyle={buttonStyle ?? ButtonStyle.BareSubtle} icon="more" size={size} />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
      >
        <DocumentMenu doc={doc} closeMenu={closeMenu} onChangeTitle={onChangeTitle} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
