import * as clipboard from 'clipboard-polyfill';
import { keyBy, uniq } from 'lodash';
import * as React from 'react';
import { useRecoilCallback } from 'recoil';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { useConfiguration } from '../../contexts/configurationContext';
import {
  entitiesSelector,
  entitySelector,
  entityType,
  isSpaceBoundEntity,
  orgEntityKey,
  spaceEntityKey,
  useEntityPath,
} from '../../syncEngine/selectors/entities';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import { mainComboKey } from '../../utils/config';
import { useGitBranchName } from '../gitBranch';
import LinkButton from '../linkButton';
import { toast } from '../toast';
import { Hotkey } from './hotkey';
import { useKeyNavigationState } from './keyNavigation';

export interface CopyAndPasteProperties {
  onCopy: (ids: string[]) => Array<{ id: string; name: string; url: string }>;
  onPaste: (pastedIds: string[]) => void;
}

function PasteHandler({ onPaste }: { onPaste: (ids: string[]) => void }) {
  React.useEffect(() => {
    function handlePaste(e: ClipboardEvent) {
      // look through the HTML data in the clipboard looking for links that
      // are pointing at valid issues
      const htmlData = e.clipboardData?.getData('text/html');
      if (htmlData) {
        try {
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlData, 'text/html');
          const links = doc.getElementsByTagName('a');
          const ids = filterNotNull(
            Array.from(links).map(link => {
              const id = link.getAttribute('data-id');
              if (!link.href || !id) {
                return null;
              }
              return id;
            })
          );
          onPaste(ids);
        } catch (e) {
          // garbage in the clipboard
        }
      }
    }
    document.body.addEventListener('paste', handlePaste);
    return () => document.body.removeEventListener('paste', handlePaste);
  }, [onPaste]);

  return null;
}

export function CopyAndPasteHotkeys({
  onCopy,
  onPaste,
}: {
  onCopy: (
    ids: string[]
  ) => Array<{ id: string; shortName?: string; type?: string; name: string; url: string }>;
  onPaste?: (pastedIds: string[]) => void;
}) {
  const { focused, selected } = useKeyNavigationState();
  const { host } = useConfiguration();
  const focusedOrSelected = filterNotNull(selected ? selected : [focused]);

  const copy = React.useCallback(
    (e?: KeyboardEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if ((!focused && !selected) || !onCopy) {
        return;
      }

      const focusedOrSelected = filterNotNull(selected ? selected : [focused]);
      const items = onCopy(focusedOrSelected);
      writeItemsToClipboard(items, host);
    },
    [focused, selected, onCopy]
  );

  return (
    <>
      {focusedOrSelected.length > 0 && <Hotkey hotkey={`${mainComboKey}+c`} handler={copy} />}
      {onPaste && <PasteHandler onPaste={onPaste} />}
    </>
  );
}

export function writeItemsToClipboard(
  items: Array<{ id: string; name: string; type?: string; shortName?: string; url: string }>,
  host: string
) {
  const data = new clipboard.ClipboardItem({
    'text/plain': items.map(i => `${i.shortName ? `${i.shortName} - ` : ''}${i.name}`).join('\n'),
    'text/html': items
      .map(
        item =>
          `<p><a data-id="${item.id}" href="${host}${item.url}">${
            item.shortName ? `${item.shortName} - ` : ''
          }${item.name}</a></p>`
      )
      .join(''),
  });
  clipboard.write([data]);

  if (items.length === 1) {
    const item = items[0];
    toast.info(
      <>
        Copied {item.type ? `${item.type} ` : ''}
        <LinkButton to={item.url}>{item.shortName ? item.shortName : item.name}</LinkButton> to the
        clipboard
      </>
    );
  } else {
    toast.info(`Copied ${items.length} items to the clipboard`);
  }
}

export function useCopyEntitiesToClipboard() {
  const entityPath = useEntityPath();

  return useRecoilCallback(({ snapshot }) => (ids: string[]) => {
    const entities = filterNotDeletedNotNull(
      ids.map(id => snapshot.getLoadable(entitySelector(id)).getValue())
    );

    const spaceIds = filterNotNull(
      uniq(entities.map(e => (isSpaceBoundEntity(e) ? e.spaceId : null)))
    );
    const spaces = filterNotDeletedNotNull(
      spaceIds.map(id => snapshot.getLoadable(spaceSelector(id)).getValue())
    );

    return entities.map(entity => {
      const space = isSpaceBoundEntity(entity)
        ? spaces.find(s => s.id === entity.spaceId) ?? null
        : null;
      return {
        id: entity.id,
        name: entity.title,
        shortName: `${space?.key}-${entity.number}`,
        url: entityPath(entity.id),
        type: entityType(entity),
      };
    });
  });
}

export function useCopyEntityNumbersToClipboard() {
  const entityPath = useEntityPath();
  return useRecoilCallback(({ snapshot }) => (ids: string[]) => {
    const entities = snapshot.getLoadable(entitiesSelector(ids)).getValue();

    const spaceIds = uniq(entities.map(e => (isSpaceBoundEntity(e) ? e.spaceId : undefined)));
    const spaces = filterNotDeletedNotNull(
      spaceIds.map(id => snapshot.getLoadable(spaceSelector(id)).getValue())
    );

    const spaceIndex = keyBy(spaces, 'id');

    const numbers = entities.map(entity => {
      return isSpaceBoundEntity(entity)
        ? spaceEntityKey(entity, spaceIndex[entity.spaceId])
        : orgEntityKey(entity);
    });
    clipboard.writeText(numbers.join('\n'));

    if (numbers.length === 1) {
      const type = entityType(entities[0]);
      toast.info(
        <>
          Number for {type} <LinkButton to={entityPath(entities[0].id)}>{numbers[0]}</LinkButton>{' '}
          copied to the clipboard
        </>
      );
    } else {
      toast.info(`Numbers for ${numbers.length} items copied to the clipboard`);
    }
  });
}

export function useCopyEntityLinksToClipboard() {
  const entityPath = useEntityPath();
  const { host } = useConfiguration();
  return useRecoilCallback(({ snapshot }) => (ids: string[]) => {
    const links = ids.map(entityId => `${host}${entityPath(entityId)}`);

    clipboard.writeText(links.join('\n'));

    if (links.length === 1) {
      const entity = snapshot.getLoadable(entitySelector(ids[0])).getValue();
      const space =
        entity && isSpaceBoundEntity(entity)
          ? snapshot.getLoadable(spaceSelector(entity?.spaceId)).getValue()
          : null;

      if (!entity || (isSpaceBoundEntity(entity) && !space)) {
        return;
      }

      const key = isSpaceBoundEntity(entity)
        ? spaceEntityKey(entity, space!)
        : orgEntityKey(entity);

      const type = entityType(entity);
      toast.info(
        <>
          Link for {type} <LinkButton to={entityPath(ids[0])}>{key}</LinkButton> copied to the
          clipboard
        </>
      );
    } else {
      toast.info(`Links for ${links.length} items copied to the clipboard`);
    }
  });
}

export function useCopyGitBranchToClipboard(entityId: string) {
  const gitBranch = useGitBranchName(entityId);

  const entityPath = useEntityPath();
  return useRecoilCallback(({ snapshot }) => () => {
    if (!gitBranch) {
      return;
    }
    const entity = snapshot.getLoadable(entitySelector(entityId)).getValue();
    const space =
      entity && isSpaceBoundEntity(entity)
        ? snapshot.getLoadable(spaceSelector(entity?.spaceId)).getValue()
        : null;
    clipboard.writeText(gitBranch);

    if (!entity || (isSpaceBoundEntity(entity) && !space)) {
      return;
    }
    const type = entityType(entity);
    toast.info(
      <>
        Git branch for {type}{' '}
        <LinkButton to={entityPath(entityId)}>
          {space ? `${space.key}-` : ''}
          {entity.number}
        </LinkButton>{' '}
        copied to the clipboard
      </>
    );
  });
}
