import { isEqual, partition, pick } from 'lodash';
import { atom, AtomEffect, selectorFamily, useRecoilCallback } from 'recoil';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { feedbackByOrganization } from '../../../sync/__generated/indexes';
import { Feedback, Organization } from '../../../sync/__generated/models';
import { toast } from '../../components/toast';
import { useOrganization } from '../../contexts/organizationContext';
import { filterEntities } from '../../utils/filtering2';
import { equalSelectorFamily } from '../../utils/recoil';
import { localStorageEffect } from '../effects';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import { organizationPath } from './organizations';

export enum FeedbackMode {
  Unprocessed = 'unprocessed',
  Processed = 'processed',
}

/* TODO: remove this when we remove FEATURE_TOGGLE_NEW_FEEDBACK */
export const feedbackScreenTabState = atom<FeedbackMode>({
  key: 'FeedbackScreenTabState',
  default: FeedbackMode.Unprocessed,
  effects: [localStorageEffect('__currentFeedbackTab')],
});

export function isFeedback(feedback: SyncEngineObject): feedback is Feedback {
  return feedback.__typename === 'Feedback';
}

export const openFeedbackState = atom<string>({
  key: 'OpenFeedback',
  default: '',
  effects: [
    ({ onSet, getLoadable }) => {
      onSet((newID, oldId) => {
        if (newID === 'deleted' || oldId === 'deleted') {
          return;
        }
        const id = oldId as string;
        const feedback = getLoadable(syncEngineState(id)).getValue() as SyncEngineObject | null;
        if (feedback?.deleted) {
          toast.error('The feedback you were on was deleted');
        }
      });
    },
  ],
});

export function feedbackPath(organization: Organization, feedback: Feedback, isNew?: boolean) {
  const newQueryParam = isNew ? `?new=true` : '';
  if (feedback.number.startsWith('T') || feedback.number.startsWith('U')) {
    return organizationPath(organization, `feedback/${feedback.id}${newQueryParam}`);
  }
  return organizationPath(organization, `feedback/${feedback.number}${newQueryParam}`);
}

export function useFeedbackPath() {
  const organization = useOrganization();
  return useRecoilCallback(({ snapshot }) => (feedbackId: string, isNew?: boolean) => {
    const feedback = snapshot.getLoadable(feedbackSelector(feedbackId)).getValue();
    if (!feedback) {
      return null;
    }

    return feedbackPath(organization, feedback, isNew);
  });
}

export function useFeedbackNumberToId() {
  const organization = useOrganization();
  return useRecoilCallback(({ snapshot }) => (feedbackNumber: string) => {
    const feedbackId = snapshot
      .getLoadable(feedbackIdByNumberSelector({ organizationId: organization.id, feedbackNumber }))
      .getValue();
    return feedbackId;
  });
}

export const feedbackIsOpenSelector = selectorFamily({
  key: 'FeedbackIsOpen',
  get:
    (feedbackId: string | undefined | null) =>
    ({ get }) => {
      if (!feedbackId) {
        return false;
      }
      return get(openFeedbackState) === feedbackId;
    },
});

export const feedbackSelector = selectorFamily({
  key: 'Feedback',
  get:
    (feedbackId: string | undefined | null) =>
    ({ get }) => {
      if (!feedbackId) {
        return null;
      }
      return get(syncEngineState(feedbackId)) as Feedback | null;
    },
});

export const feedbacksSelector = selectorFamily({
  key: 'Feedbacks',
  get:
    (feedbackIds: string[]) =>
    ({ get }) => {
      return filterNotDeletedNotNull(
        feedbackIds.map(feedbackId => get(feedbackSelector(feedbackId)))
      );
    },
});

export const feedbackByNumberSelector = selectorFamily({
  key: 'FeedbackByNumber',
  get:
    ({ organizationId, feedbackNumber }: { organizationId: string; feedbackNumber: string }) =>
    ({ get }) => {
      const feedbackIds = get(indexKeyState(indexKey(feedbackByOrganization, organizationId)));
      const feedbacks = filterNotDeletedNotNull(
        feedbackIds.map(feedbackId => get(feedbackSelector(feedbackId)))
      );
      return feedbacks.find(f => f.number === feedbackNumber || f.id === feedbackNumber);
    },
});

export const feedbackDeletedSelector = selectorFamily({
  key: 'FeedbackDeleted',
  get:
    (feedbackId: string | undefined | null) =>
    ({ get }) => {
      if (!feedbackId) {
        return null;
      }
      const feedback = get(syncEngineState(feedbackId)) as Feedback | null;
      return feedback?.deleted;
    },
});

export const feedbackForOrganizationSelector = selectorFamily({
  key: 'FeedbackForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const feedbackIds = get(indexKeyState(indexKey(feedbackByOrganization, organizationId)));
      return filterNotDeletedNotNull(
        feedbackIds.map(feedbackId => get(feedbackSelector(feedbackId)))
      );
    },
});

export const feedbackIdsForOrganizationSelector = selectorFamily({
  key: 'FeedbackIdsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const feedback = get(feedbackForOrganizationSelector(organizationId));
      return feedback.map(f => f.id);
    },
});

export const feedbackScreenSelector = selectorFamily({
  key: 'FeedbackScreenSelector',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const feedbackIds = get(indexKeyState(indexKey(feedbackByOrganization, organizationId)));
      const filteredFeedbackIds = filterEntities(feedbackIds, 'feedback', get);
      const filteredFeedback = filterNotDeletedNotNull(
        filteredFeedbackIds.map(feedbackId => get(feedbackSelector(feedbackId)))
      );

      const [unprocessed, processed] = partition(filteredFeedback, f => !f.processed);

      return {
        all: filteredFeedback.map(f => f.id),
        unprocessed: unprocessed.map(f => f.id),
        processed: processed.map(f => f.id),
      };
    },
});

export const feedbackIdByNumberSelector = selectorFamily({
  key: 'FeedbackIdByNumber',
  get:
    ({ organizationId, feedbackNumber }: { organizationId: string; feedbackNumber: string }) =>
    ({ get }) => {
      const feedbackIds = get(indexKeyState(indexKey(feedbackByOrganization, organizationId)));
      const feedbacks = filterNotNull(
        feedbackIds.map(feedbackId => get(feedbackSelector(feedbackId)))
      );
      return feedbacks.find(f => f.number === feedbackNumber || f.id === feedbackNumber)?.id;
    },
});

export const personCompanyIdsForFeedbackSelector = equalSelectorFamily({
  key: 'PersonCompanyIdsForFeedbackSelector',
  get:
    (feedbackId: string) =>
    ({ get }) => {
      const feedback = get(feedbackSelector(feedbackId));
      if (!feedback) {
        return {
          companyId: null,
          personId: null,
        };
      }
      return pick(feedback, ['personId', 'companyId']);
    },
  equals: isEqual,
});

export const viewEffect: (key: string) => AtomEffect<string> = (key: string) => p => {
  const { setSelf, onSet, node, getLoadable, trigger } = p;

  const paramKey = key.replace('__', '');
  if (trigger === 'get') {
    const localStorageValue = localStorage.getItem(key);
    const params = new URLSearchParams(window.location.search);
    const urlValue = params.get(paramKey);

    if (urlValue) {
      setSelf(urlValue);
    } else if (localStorageValue) {
      const parsed = JSON.parse(localStorageValue);
      setSelf(parsed);
    }
  }

  const params = new URLSearchParams(window.location.search);
  const loadable = getLoadable(node);
  if (loadable.state === 'hasValue') {
    params.set(paramKey, loadable.contents);
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  }

  onSet((newValue: any, _: any, isReset: any) => {
    isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    if (newValue) {
      const params = new URLSearchParams(window.location.search);
      const paramKey = key.replace('__', '');
      params.set(paramKey, newValue);
      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    }
  });
};

export const feedbackTabState = atom<string>({
  key: 'feedbackTab',
  default: 'inbox',
  effects: [viewEffect('__feedbackTab')],
});
