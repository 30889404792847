import { useRecoilValue } from 'recoil';
import { TodoStatus } from '../../sync/__generated/models';
import { writeToClipboard } from '../components/clipboardText';
import { useConfiguration } from '../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../contexts/modalContext';
import { useCurrentUser } from '../contexts/userContext';
import { useGitTodoBranchName } from '../slate/elements/smartTodo/useSmartTodoGitBranchName';
import { useToggleTodos, useUpdateTodoOutsideOfEditor } from '../syncEngine/actions/todos';
import {
  entitySelector,
  isSpaceBoundEntity,
  useEntityPath,
} from '../syncEngine/selectors/entities';
import { todoKeySelector, todoSelector } from '../syncEngine/selectors/todos';
import {
  assignIssueKey,
  copyIssueGitBranchKey,
  copyIssueLinkKey,
  copyIssueNumberKey,
  labelIssueKey,
  markTodoCompleted,
  markTodoInProgress,
  selfAssignIssueKey,
  setEffortKey,
  setImpactKey,
  todoDetailsKey,
} from '../utils/config';
import {
  CommandDefinition,
  CommandGroup,
  TodoCommandGroupContenxt,
  useCommandMenuContext,
} from './state';

export function useTodoCommandGroup(): CommandDefinition[] {
  const { host, featureFlags } = useConfiguration();
  const modals = useModals();
  const todoContext = useCommandMenuContext<TodoCommandGroupContenxt>(CommandGroup.Todos);
  const todo = useRecoilValue(todoSelector(todoContext?.focusedTodoId ?? ''));
  const key = useRecoilValue(todoKeySelector(todo?.id));
  const branchName = useGitTodoBranchName(todo?.id);
  const entityPath = useEntityPath();

  const entity = useRecoilValue(entitySelector(todo?.entityId));
  const spaceId = entity && isSpaceBoundEntity(entity) ? entity.spaceId : undefined;
  const updateTodo = useUpdateTodoOutsideOfEditor(entity?.id);
  const currentUser = useCurrentUser();

  const toggleTodos = useToggleTodos();

  if (!todoContext || !todo) {
    return [];
  }

  const commands: CommandDefinition[] = [
    {
      id: 'toggle-todo-completed',
      hotkey: markTodoCompleted,
      description: `Mark as ${todo.status === TodoStatus.Done ? 'not started' : 'completed'}`,
      handler: () => {
        toggleTodos([todo.id]);
      },
    },
    {
      id: 'toggle-todo-in-progress',
      hotkey: markTodoInProgress,
      description: `Mark as ${
        todo.status === TodoStatus.InProgress ? 'not in progress' : 'in progress'
      }`,
      handler: () => {
        toggleTodos([todo.id], true);
      },
    },
    {
      id: 'todo-assign-self',
      hotkey: selfAssignIssueKey,
      description: 'Assign self',
      icon: 'member',
      handler: () => {
        if (todo.memberIds.includes(currentUser.id)) {
          updateTodo(todo.id, {
            membersToRemove: [currentUser.id],
          });
        } else {
          updateTodo(todo.id, {
            membersToAdd: [currentUser.id],
          });
        }
      },
    },
    {
      id: 'change-todo-members',
      hotkey: assignIssueKey,
      description: 'Change members',
      icon: 'member',
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.TodoMembers,
          context: {
            todoId: todoContext.todoId,
            spaceId,
          },
        });
      },
    },
    {
      id: 'change-todo-labels',
      hotkey: labelIssueKey,
      description: 'Change labels',
      icon: 'label',
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.TodoLabels,
          context: {
            todoId: todoContext.todoId,
            spaceId,
          },
        });
      },
    },
    {
      id: 'set-todo-impact',
      hotkey: setImpactKey,
      description: 'Set impact',
      icon: 'impact',
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.TodoImpact,
          context: {
            todoId: todoContext.todoId,
            spaceId,
          },
        });
      },
    },
    {
      id: 'set-todo-effort',
      hotkey: setEffortKey,
      description: 'Set effort',
      icon: 'effort',
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.TodoEffort,
          context: {
            todoId: todoContext.todoId,
            spaceId,
          },
        });
      },
    },
    {
      id: 'copy-todo-number',
      hotkey: copyIssueNumberKey,
      description: `Copy todo number`,
      icon: 'copy',
      handler: () => {
        writeToClipboard(key ?? '', 'todo number');
      },
    },
    {
      id: 'copy-todo-link',
      hotkey: copyIssueLinkKey,
      description: `Copy todo link`,
      icon: 'link',
      handler: () => {
        const link = `${host}${entityPath(todo.entityId)}?focusDescription=true&focusSmartTodo=${
          todo.id
        }`;
        writeToClipboard(link, 'todo link');
      },
    },
    {
      id: 'copy-todo-branch',
      hotkey: copyIssueGitBranchKey,
      description: `Copy git branch for todo`,
      icon: 'branch',
      handler: () => {
        writeToClipboard(branchName ?? '', 'todo git branch name');
      },
    },
    ...(featureFlags.FEATURE_TOGGLE_TODO_ACTIVITIES
      ? [
          {
            id: 'show-todo-details',
            hotkey: todoDetailsKey,
            description: `Show todo details`,
            icon: 'activity',
            handler: () => {
              modals.openModal(Modals.TodoDetails, { todoId: todo.id });
            },
          },
        ]
      : []),
  ];

  return commands.map(c => ({
    ...c,
    group: c.group ?? CommandGroup.Todos,
  }));
}
