import cn from 'classnames';
import * as React from 'react';
import { useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { Button, ButtonSize, ButtonStyle } from './button';
import { Count } from './count';
import { Icon } from './icon';
import styles from './tabs.module.scss';

export enum TabStyle {
  Normal,
  Subtle,
}

export interface Tab {
  id: string;
  name: string;
  icon?: string | React.ReactNode;
  count?: number;
  disabled?: boolean;
}

export function Tabs({
  tabs,
  ariaLabel,
  className,
  style,
  size,
  currentTab,
  onTabChanged,
  tabStyle = TabStyle.Normal,
}: {
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tabId: string) => void;
  ariaLabel?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: ButtonSize;
  tabStyle?: TabStyle;
}) {
  const smallScreen = useIsSmallScreen();

  return (
    <div
      className={cn(
        styles.tabs,
        {
          [styles.subtle]: tabStyle === TabStyle.Subtle,
        },
        className
      )}
      style={style}
      aria-label={ariaLabel}
    >
      {tabs.map(tab => (
        <Button
          size={size}
          key={tab.id}
          value={tab.id}
          disabled={tab.disabled}
          buttonStyle={
            tabStyle === TabStyle.Normal && currentTab === tab.id
              ? ButtonStyle.SecondaryOverlay
              : ButtonStyle.BareSubtle
          }
          className={cn(styles.tab, {
            [styles.active]: tab.id === currentTab,
            [styles.subtle]: tabStyle === TabStyle.Subtle,
          })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onTabChanged(tab.id);
          }}
        >
          {!smallScreen && tab.icon && typeof tab.icon === 'string' && (
            <Icon icon={tab.icon} className="mr8" />
          )}
          {!smallScreen && tab.icon && typeof tab.icon !== 'string' && tab.icon}
          {tab.name}
          {tab.count !== undefined && <Count count={tab.count} className="ml6" />}
        </Button>
      ))}
    </div>
  );
}
