import { chain, groupBy, isEqual, keyBy, orderBy, snakeCase, uniq } from 'lodash';
import { selectorFamily } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { looksLikeId } from '../../../shared/utils/id';
import {
  releasesByIssue,
  releasesByOrganization,
  releasesBySpace,
} from '../../../sync/__generated/indexes';
import { Organization, Release, ReleaseStatus } from '../../../sync/__generated/models';
import { filterEntities } from '../../utils/filtering2';
import { equalSelectorFamily } from '../../utils/recoil';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import { issuesSelector, statusSelector } from './issues';
import { organizationPath } from './organizations';

export function isRelease(obj: SyncEngineObject): obj is Release {
  return obj.__typename === 'Release';
}

export function releasePath(organization: Organization, release: Release | string) {
  if (typeof release === 'string') {
    return organizationPath(organization, `release/${release}`);
  }
  if (release.number.startsWith('T') || release.number.startsWith('U')) {
    return organizationPath(organization, `release/${release.id}`);
  }
  return organizationPath(organization, `release/${release.number}`);
}

export const releaseSelector = selectorFamily({
  key: 'Release',
  get:
    (releaseIds: string | undefined) =>
    ({ get }) => {
      if (!releaseIds) {
        return null;
      }
      return notDeleted(get(syncEngineState(releaseIds)) as Release | null);
    },
});

export const releasesSelector = selectorFamily({
  key: 'Releases',
  get:
    (releaseIds: string[] | null) =>
    ({ get }) => {
      if (!releaseIds) {
        return [];
      }
      return filterNotNull(releaseIds.map(id => get(releaseSelector(id))));
    },
});

export const releaseByNumberSelector = selectorFamily({
  key: 'ReleasesByNumber',
  get:
    ({ organizationId, releaseNumber }: { organizationId: string; releaseNumber: string }) =>
    ({ get }) => {
      const releases = filterNotDeletedNotNull(
        get(releasesForOrganizationSelector(organizationId))
      );

      return releases.find(i => i.number === releaseNumber || i.id === releaseNumber);
    },
});

export const releasesForIssueSelector = selectorFamily({
  key: 'ReleasesForIssue',
  get:
    (issueId: string) =>
    ({ get }) => {
      const releaseIds = get(indexKeyState(indexKey(releasesByIssue, issueId)));
      return filterNotDeletedNotNull(releaseIds.map(id => get(releaseSelector(id)))).filter(
        r => r.archivedAt === null
      );
    },
});

export const releaseIdsForIssueSelector = equalSelectorFamily({
  key: 'ReleaseIdsForIssue',
  get:
    (issueId: string) =>
    ({ get }) => {
      return get(releasesForIssueSelector(issueId)).map(t => t.id);
    },
  equals: isEqual,
});

export const releasesForSpaceSelector = selectorFamily({
  key: 'ReleasesForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const releaseIds = get(indexKeyState(indexKey(releasesBySpace, spaceId)));
      return filterNotDeletedNotNull(releaseIds.map(id => get(releaseSelector(id)))).filter(
        r => r.archivedAt === null
      );
    },
});

export const releasesForOrganizationSelector = selectorFamily({
  key: 'ReleasesForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const releaseIds = get(indexKeyState(indexKey(releasesByOrganization, organizationId)));
      return filterNotDeletedNotNull(releaseIds.map(id => get(releaseSelector(id)))).filter(
        r => r.archivedAt === null
      );
    },
});

export const releasesForOrganizationByStatusSelector = selectorFamily({
  key: 'ReleasesForOrganizationByStatus',
  get:
    ({ organizationId, filterId }: { organizationId: string; filterId: string }) =>
    ({ get }) => {
      const releaseIds = get(filteredReleasesForOrganizationSelector({ organizationId, filterId }));
      const releases = get(releasesSelector(releaseIds));
      const releasesByStatus = groupBy(releases, release => release.releaseStatus);

      const result: Record<string, string[]> = {};
      for (const status of Object.keys(ReleaseStatus).map(i => snakeCase(i).toUpperCase())) {
        if (releasesByStatus[status]?.length) {
          result[status] = releasesByStatus[status].map(r => r.id);
        }
      }

      return result;
    },
});

export const filteredReleasesForOrganizationSelector = selectorFamily({
  key: 'FilteredReleasesForOrganization',
  get:
    ({ organizationId, filterId }: { organizationId: string; filterId: string }) =>
    ({ get }) => {
      const releaseIds = get(indexKeyState(indexKey(releasesByOrganization, organizationId)));
      const releases = filterNotDeletedNotNull(
        releaseIds.map(id => get(releaseSelector(id)))
      ).filter(r => !r.archivedAt);

      return filterEntities(
        releases.map(i => i.id),
        filterId,
        get
      );
    },
});

export const spacesIdsForReleasesSelector = selectorFamily({
  key: 'SpaceIdsForReleases',
  get:
    (releaseIds: string[]) =>
    ({ get }) =>
      get(releasesSelector(releaseIds)).reduce((result, release) => {
        result[release.id] = release.spaceIds;
        return result;
      }, {} as Record<string, string[]>),
});

export const releaseIdByNumberSelector = selectorFamily({
  key: 'ReleaseIdByNumber',
  get:
    ({
      organizationId,
      releaseNumber,
      includeDeleted,
    }: {
      organizationId: string;
      releaseNumber: string;
      includeDeleted?: boolean;
    }) =>
    ({ get }) => {
      if (looksLikeId(releaseNumber)) {
        return releaseNumber;
      }

      let releases: Release[];

      if (includeDeleted) {
        const releaseIds = get(indexKeyState(indexKey(releasesByOrganization, organizationId)));
        releases = filterNotNull(releaseIds.map(id => get(releaseSelector(id))));
      } else {
        releases = get(releasesForOrganizationSelector(organizationId));
      }

      return releases.find(r => r.number === releaseNumber)?.id;
    },
});

export const releaseIssuesByStatusSelector = equalSelectorFamily({
  key: 'ReleaseIssuesByStatusSelector',
  get:
    (releaseId: string) =>
    ({ get }) => {
      const release = get(releaseSelector(releaseId));
      if (!release) {
        return {};
      }
      const issues = get(issuesSelector(release.issueIds)) ?? [];
      const statuses = uniq(issues.map(i => i.statusId)).map(s => get(statusSelector(s)));
      const statusesById = keyBy(statuses, 'id');

      const issueIdsByStatus = chain(issues)
        .map(issue => ({ issue, status: statusesById[issue.statusId] }))
        .filter(({ status }) => !!status)
        .groupBy(({ status }) => `${status!.statusType}-${status!.name}`)
        .orderBy(g => g[0].status!.statusType, 'desc')
        .mapKeys(k => k[0].status!.id)
        .mapValues(v => v.map(v => v.issue.id))
        .value();

      return issueIdsByStatus;
    },
  equals: isEqual,
});

export const archivedReleasesSelector = equalSelectorFamily({
  key: 'ArchivedReleases',
  get:
    ({ spaceId, filterId }: { spaceId: string; filterId: string }) =>
    ({ get }) => {
      const releaseIds = get(indexKeyState(indexKey(releasesBySpace, spaceId)));
      const archivedReleases = filterNotDeletedNotNull(
        releaseIds.map(id => get(releaseSelector(id)))
      ).filter(r => r.archivedAt !== null);

      return filterEntities(
        archivedReleases.map(i => i.id),
        filterId,
        get
      );
    },
  equals: isEqual,
});

export const filteredArchivedReleasesForOrganizationSelector = selectorFamily({
  key: 'FilteredArchivedReleasesForOrganization',
  get:
    ({ organizationId, filterId }: { organizationId: string; filterId: string }) =>
    ({ get }) => {
      const releaseIds = get(indexKeyState(indexKey(releasesByOrganization, organizationId)));
      const releases = filterNotDeletedNotNull(
        releaseIds.map(id => get(releaseSelector(id)))
      ).filter(r => r.archivedAt !== null);

      const ids = orderBy(
        releases.filter(i => i.archivedAt),
        ['archivedAt'],
        ['desc']
      ).map(i => i.id);

      return filterEntities(ids, filterId, get);
    },
});
