import cn from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Initiative } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { useConfiguration } from '../../contexts/configurationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { ResponsiveDesignSize } from '../../hooks/useResponsiveDesign';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
} from '../../syncEngine/actions/entities';
import { useUpdateInitiatives } from '../../syncEngine/actions/intiatives';
import { orgEntityKey } from '../../syncEngine/selectors/entities';
import { initiativePath, initiativeSelector } from '../../syncEngine/selectors/intiatives';
import { HideIfSmallerThan } from '../hideIfSmallerThan';
import { MetadataConfig } from '../metadataConfig';
import { Button, ButtonSize, ButtonStyle } from './button';
import { CustomCommand } from './customCommand';
import { EntityDueDate } from './dueDateMetadata';
import {
  EntityListItem,
  EntityListItemMainContents,
  EntityListItemMembers,
  EntityListItemMenu,
  EntityListItemMetadata,
  EntityListItemTitle,
} from './entityListItem';
import listItemStyles from './entityListItem.module.scss';
import {
  EntityEffort,
  EntityImpact,
  EntityLabels,
  EntityMembers,
  EntityTodos,
  EntityWatching,
} from './entityMetadata';
import { EntityTitleEditor } from './entityTitleEditor';
import { InitiativeSpaces } from './initativeSpaces';
import { InitiativeMenu } from './initiativeMenu';
import { InitiativeRoadmaps } from './initiativeRoadmaps';
import { InitiativeStatus } from './initiativeStatus';
import { useHasKeyNavigationFocus, useKeyNavigationElement } from './keyNavigation';
import { InitiativeIcon } from './metadata/initiative';
import { MetadataSize } from './metadata/size';

function MetadataLeft({
  initiative,
  focused,
  metadataConfig,
}: {
  initiative: Initiative;
  focused: boolean;
  metadataConfig?: MetadataConfig;
}) {
  const { featureFlags } = useConfiguration();

  return (
    <EntityListItemMetadata
      className={cn('ml8', {
        overlappableMetadataContainer: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
        noShrink: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
      })}
    >
      {!featureFlags.FEATURE_TOGGLE_METADATA_RIGHT && (
        <div
          className={cn('row', 'metadataGap', 'overflowHidden', 'overlappableMetadata', {
            focused,
          })}
        >
          {metadataConfig?.labels !== false && (
            <EntityLabels
              interactable={focused}
              entity={initiative}
              orgLevel
              size={MetadataSize.Small}
              className="overlappable"
            />
          )}
        </div>
      )}
      {metadataConfig?.todos !== false && (
        <EntityTodos entity={initiative} interactable={focused} />
      )}
    </EntityListItemMetadata>
  );
}

function MetadataRight({
  initiative,
  focused,
  metadataConfig,
  editMode,
}: {
  initiative: Initiative;
  focused: boolean;
  metadataConfig?: MetadataConfig;
  editMode?: boolean;
}) {
  const { featureFlags } = useConfiguration();

  return (
    <EntityListItemMetadata
      className={cn('ml4', {
        overlappableMetadataContainer: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT || editMode,
        noShrink: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT && !editMode,
      })}
    >
      <div
        className={cn('row', 'metadataGap', 'overflowHidden', 'overlappableMetadata', {
          focused,
        })}
      >
        <EntityDueDate item={initiative} interactable={focused} />
        {metadataConfig?.roadmaps !== false && (
          <InitiativeRoadmaps
            initiativeId={initiative.id}
            size={MetadataSize.Small}
            className="overlappable"
          />
        )}
        {metadataConfig?.spaces !== false && (
          <InitiativeSpaces interactable initiative={initiative} className="overlappable" />
        )}
        {(featureFlags.FEATURE_TOGGLE_METADATA_RIGHT || editMode) &&
          metadataConfig?.labels !== false && (
            <EntityLabels
              interactable={focused}
              entity={initiative}
              orgLevel
              size={MetadataSize.Small}
              className="overlappable"
            />
          )}
      </div>
      <EntityWatching entity={initiative} />
      {metadataConfig?.impact !== false && <EntityImpact interactable entity={initiative} />}
      {metadataConfig?.effort !== false && <EntityEffort interactable entity={initiative} />}
      {metadataConfig?.status !== false && (
        <HideIfSmallerThan size={ResponsiveDesignSize.Tiny}>
          <InitiativeStatus initiativeId={initiative.id} />
        </HideIfSmallerThan>
      )}
    </EntityListItemMetadata>
  );
}

export function InitiativeListItem({
  id,
  routingState,
  className,
  style,
  keyNavId,
  metadataConfig,
  onChangeTitle,
  moveToTopBottom,
  roadmapId,
}: {
  id: string;
  routingState?: any;
  className?: string;
  style?: React.CSSProperties;
  keyNavId?: string;
  metadataConfig?: MetadataConfig;
  onChangeTitle?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], statusId: string) => void;
  roadmapId?: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const initiative = useRecoilValue(initiativeSelector(id));
  const organization = useOrganization();
  const focused = useHasKeyNavigationFocus(keyNavId ?? id);
  const history = useHistory();

  useKeyNavigationElement(keyNavId ?? id, ref);

  if (!initiative) {
    return null;
  }

  const link = {
    pathname: initiativePath(organization, initiative),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: id,
      ...routingState,
    },
  };

  return (
    <Link to={link}>
      <EntityListItem
        ref={ref}
        entityNumber={orgEntityKey(initiative)}
        className={className}
        style={style}
        statusIcon={<InitiativeIcon color={initiative.color} />}
      >
        <EntityListItemMainContents>
          <div className="row ellipsis">
            <EntityListItemTitle type={'initiative'}>{initiative.title}</EntityListItemTitle>
            {metadataConfig?.todos !== false && (
              <MetadataLeft
                initiative={initiative}
                focused={focused}
                metadataConfig={metadataConfig}
              />
            )}
          </div>
          <MetadataRight
            initiative={initiative}
            focused={focused}
            metadataConfig={metadataConfig}
          />
        </EntityListItemMainContents>
        {metadataConfig?.members !== false && (
          <EntityListItemMembers>
            <EntityMembers interactable entity={initiative} />
          </EntityListItemMembers>
        )}
        <EntityListItemMenu
          menuContents={closeMenu => (
            <InitiativeMenu
              initiative={initiative}
              closeMenu={closeMenu}
              roadmapId={roadmapId}
              moveToTopBottom={moveToTopBottom}
              onChangeTitle={onChangeTitle}
            />
          )}
          date={initiative.displayedUpdatedAt}
        />
        {focused && (
          <>
            <CustomCommand
              command={{
                id: 'open',
                hotkey: 'enter',
                group: CommandGroup.Entities,
                description: `Open`,
                priority: 100,
                handler: () => {
                  history.push(link);
                },
              }}
            />
            {onChangeTitle && (
              <CustomCommand
                command={{
                  id: 'edit-title',
                  hotkey: 't',
                  group: CommandGroup.Entities,
                  description: `Edit title`,
                  priority: 99,
                  handler: () => {
                    onChangeTitle();
                  },
                }}
              />
            )}
          </>
        )}
      </EntityListItem>
    </Link>
  );
}

export function EditInitiativeListItem({
  id,
  className,
  style,
  metadataConfig,
  onDone,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  metadataConfig?: MetadataConfig;
  onDone: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const initiative = useRecoilValue(initiativeSelector(id));

  const [title, setTitle] = React.useState(initiative?.title ?? '');
  useKeyNavigationElement(id, ref);

  const updateInitiatives = useUpdateInitiatives();
  const addLabels = useAddLabelsToEntities();
  const addMembers = useAddAssigneesToEntities();

  if (!initiative) {
    return null;
  }

  function save() {
    if (initiative?.title !== title && initiative) {
      updateInitiatives([initiative.id], { title });
    }
    onDone();
  }

  return (
    <EntityListItem
      ref={ref}
      entityNumber={orgEntityKey(initiative)}
      className={className}
      style={style}
      statusIcon={<InitiativeIcon color={initiative.color} />}
    >
      <EntityListItemMainContents>
        <div className="row ellipsis">
          <EntityTitleEditor
            className={cn('headingS', listItemStyles.titleEditor)}
            initialTitle={title}
            onChange={setTitle}
            onSubmit={save}
            onReset={onDone}
            autoFocus
            oneLine
            supportedMetadata={{
              orgLabels: true,
              users: true,
            }}
            onMetadataAdded={(type, metadataId) => {
              switch (type) {
                case 'label':
                  addLabels([initiative.id], [metadataId]);
                  break;
                case 'user':
                  addMembers([initiative.id], [metadataId]);
                  break;
              }
            }}
            placeholder={
              <span className="bodyM">Enter a title, use @ for members and # for labels</span>
            }
          />
        </div>
        <MetadataRight initiative={initiative} focused editMode metadataConfig={metadataConfig} />
      </EntityListItemMainContents>

      {metadataConfig?.members !== false && (
        <EntityListItemMembers>
          <EntityMembers interactable entity={initiative} />
        </EntityListItemMembers>
      )}
      <div className="rowEnd ml24">
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button size={ButtonSize.Small} onClick={save} buttonStyle={ButtonStyle.Primary}>
          Save
        </Button>
      </div>
    </EntityListItem>
  );
}
