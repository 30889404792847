import cn from 'classnames';
import { Location } from 'history';
import { pick } from 'lodash';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { atom, useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Space } from '../../../../sync/__generated/models';
import { handleLogout } from '../../../api/auth';
import { useConfiguration } from '../../../contexts/configurationContext';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useCurrentUser, useHasAdminRights, useIsGuest } from '../../../contexts/userContext';
import { isElectron } from '../../../electronIntegration';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { ResponsiveDesignSize, useIsTinyScreen } from '../../../hooks/useResponsiveDesign';
import { useUpdateOrganizationMembers } from '../../../syncEngine/actions/organizationMember';
import { useUpdateSpaceSorts, useUpdateSpaces } from '../../../syncEngine/actions/spaces';
import { boardsForSpaceSelector } from '../../../syncEngine/selectors/boards';
import { cycleNumberSelector } from '../../../syncEngine/selectors/cycles';
import { orgHasDependenciesSelector } from '../../../syncEngine/selectors/dependencies';
import {
  allUsersForOrganizationSelector,
  organizationPath,
  settingsPath,
} from '../../../syncEngine/selectors/organizations';
import { roadmapIdForSpaceSelector } from '../../../syncEngine/selectors/roadmaps';
import {
  spacePath,
  spaceSelector,
  spacesForOrganizationSelector,
  useSpaceSettingsPath,
} from '../../../syncEngine/selectors/spaces';
import { starredSelector } from '../../../syncEngine/selectors/starred';
import { unreadUpdatesCountForCurrentUserSelector } from '../../../syncEngine/selectors/updates';
import {
  currentUserMembershipState,
  currentUserOrganizatonsState,
  spacesForCurrentUserMembershipSelector,
} from '../../../syncEngine/selectors/users';
import { colorWithOpacity } from '../../../utils/color';
import {
  electronDownloadUrl,
  gotoStarredKey,
  isMobileOS,
  myWorkKey,
  searchKey,
  showActiveCycleKey,
  showArchiveKey,
  showBacklogBoardKey,
  showDependencyGraphKey,
  showDocumentsKey,
  showFeedbackKey,
  showInitiativesKey,
  showIssueBoardKey,
  showRoadmapKey,
  updatesScreenKey,
} from '../../../utils/config';
import { scrollIntoView } from '../../../utils/scrolling';
import { RenderedStarred } from '../../../utils/starred';
import ExternalLink from '../../externalLink';
import { HideIfSmallerThan } from '../../hideIfSmallerThan';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../button';
import { Count } from '../count';
import { CustomCommand } from '../customCommand';
import { Icon, IconSize } from '../icon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../menu/dropdownMenu';
import Avatar from '../metadata/avatar';
import OrganizationAvatar from '../metadata/organizationAvatar';
import { Tooltip } from '../tooltip';
import styles from './sidebar.module.scss';
import {
  SidebarContainer,
  SidebarFooter,
  SidebarHeader,
  SidebarNavigation,
  SidebarNavigationElement,
  SidebarSpaceElement,
  SidebarSpaceGroup,
  SidebarSpaces,
  sidebarGroupCollapsed,
  sidebarSpaceCollapsed,
} from './sidebarComponents';

const sidebarHidden = atom<boolean>({ key: 'sidebarHiden', default: false });

export function useSidebarHidden(hidden: boolean) {
  const set = useSetRecoilState(sidebarHidden);
  React.useEffect(() => {
    set(hidden);
  }, [hidden, set]);
}

function HelpAndSupport() {
  const modalManager = useModals();
  const { europeanDataResidency } = useConfiguration();
  const { intercomAppId } = useConfiguration();
  const [unreadIntercomMessageCount, setUnreadIntercomMessageCount] = React.useState(0);
  const tinyScreen = useIsTinyScreen();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  useComponentDidMount(() => {
    if (intercomAppId) {
      window.Intercom?.('onUnreadCountChange', setUnreadIntercomMessageCount);
    }
  });

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <div className={cn(styles.navigationElement, styles.helpText)}>
          <div className="row">
            <Icon icon="help" className={styles.icon} />
            <div>Help & Support</div>
            {unreadIntercomMessageCount > 0 && (
              <Count count={unreadIntercomMessageCount} className={styles.notificationIndicator} />
            )}
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent side={tinyScreen ? 'top' : 'right'} align={tinyScreen ? 'start' : 'end'}>
        <DropdownMenuItem
          standardWidth
          icon="keyboard"
          shortcut="?"
          onClick={() => {
            setDropdownOpen(false);
            modalManager.openModal(Modals.HotkeyMenu);
          }}
        >
          Keyboard shortcuts
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem icon="guide" standardWidth>
          <ExternalLink href="https://guide.kitemaker.co">Kitemaker guide</ExternalLink>
        </DropdownMenuItem>
        <DropdownMenuItem icon="roadmap" standardWidth>
          <ExternalLink href="https://toil.kitemaker.co/sharing/roadmaps/233f0de54da96800">
            Kitemaker roadmap
          </ExternalLink>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {!isElectron() && (
          <>
            <DropdownMenuItem icon="download" standardWidth>
              <ExternalLink href={electronDownloadUrl}>Desktop app</ExternalLink>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        )}
        {!europeanDataResidency && (
          <DropdownMenuItem
            standardWidth
            icon="chat"
            onClick={() => {
              window.Intercom?.('showMessages');
            }}
          >
            Support chat
            {unreadIntercomMessageCount > 0 && (
              <Count count={unreadIntercomMessageCount} className={styles.notificationIndicator} />
            )}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem icon="send_feedback" standardWidth>
          <ExternalLink href="mailto:hi@kitemaker.co">Send us feedback</ExternalLink>
        </DropdownMenuItem>
        <DropdownMenuItem icon="logo_slack" standardWidth>
          <ExternalLink href="https://join.slack.com/t/kitemakerco/shared_invite/zt-e7zjicw1-zbBpvLSRfOFKh3DutUAJ0A">
            Join our Slack community
          </ExternalLink>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function FreePlan() {
  const organization = useOrganization();
  const paidPlan = !!organization.activeProductId;

  if (paidPlan) {
    return null;
  }

  if (!organization.productTierExceeded) {
    return (
      <Link to={settingsPath(organization, 'billing')}>
        <div className={cn(styles.navigationElement, styles.helpText)}>
          <div className="row">
            <Icon icon="upgrade" className={styles.icon} />
            <div>Free plan</div>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div className={styles.freeTierExceeded}>
      <div className="headingS mb4">Free plan limit reached</div>
      <div className="bodyS mb8 grayed">
        You have reached the Free plan limit of 100 work items.
      </div>
      <Link to={settingsPath(organization, 'billing')}>
        <Button icon="upgrade" buttonStyle={ButtonStyle.Primary}>
          Upgrade
        </Button>
      </Link>
    </div>
  );
}

function InviteMembers() {
  const organization = useOrganization();
  const allUsersForOrg = useRecoilValue(
    allUsersForOrganizationSelector({ organizationId: organization.id })
  );

  if (allUsersForOrg.length > 1) {
    return null;
  }

  return (
    <Link to={organizationPath(organization, 'settings/members?invite=true')}>
      <div className={cn(styles.navigationElement, styles.helpText)}>
        <div className="row">
          <Icon icon="member" className={styles.icon} />
          <div>Invite members</div>
        </div>
      </div>
    </Link>
  );
}

function NotificationIndicator() {
  const organization = useOrganization();
  const count = useRecoilValue(unreadUpdatesCountForCurrentUserSelector(organization.id));
  if (count === 0) {
    return null;
  }

  return <Count count={count} className={styles.notificationIndicator} />;
}

function SpaceHotkey({
  space,
  index,
  inMySpaces,
}: {
  space: Space;
  index: number;
  inMySpaces: boolean;
}) {
  const history = useHistory();
  const organization = useOrganization();
  const setSpaceCollapsed = useSetRecoilState(sidebarSpaceCollapsed(space?.id ?? ''));
  const setGroupCollapsed = useSetRecoilState(sidebarGroupCollapsed(inMySpaces ? 'my' : 'other'));

  return (
    <CustomCommand
      command={{
        id: `goto-space-${space.id}`,
        description: `Go to ${space.name} space`,
        icon: 'workspace',
        hotkey: `${index + 1}`,
        priority: 11 - index,
        handler: () => {
          setSpaceCollapsed(false);
          setGroupCollapsed(false);
          history.push(spacePath(organization, space));

          setTimeout(() => {
            const el = document.getElementById(`space-${space.id}`);
            if (el) {
              scrollIntoView(el, { scrollMode: 'if-needed', block: 'center' });
            }
          });
        },
      }}
    />
  );
}
function SpaceHotkeys() {
  const organization = useOrganization();
  const { favoriteSpaces, nonFavoriteSpaces } = useRecoilValue(
    spacesForCurrentUserMembershipSelector(organization.id)
  );
  const spaces = (favoriteSpaces.length ? favoriteSpaces : nonFavoriteSpaces).slice(0, 10);

  return (
    <>
      {spaces.map((space, index) => (
        <SpaceHotkey
          key={space.id}
          space={space}
          index={index}
          inMySpaces={!!favoriteSpaces.length}
        />
      ))}
    </>
  );
}

function useOpenSpaceIfNeeded() {
  const history = useHistory();
  const organization = useOrganization();
  const member = useRecoilValue(currentUserMembershipState(organization.id));
  const { favoriteSpaces, nonFavoriteSpaces } = useRecoilValue(
    spacesForCurrentUserMembershipSelector(organization.id)
  );

  const firstSpace = [...favoriteSpaces, ...nonFavoriteSpaces][0];
  const firstSpaceGroup = favoriteSpaces.find(s => s.id === firstSpace?.id) ? 'my' : 'other';

  const callback = useRecoilCallback(({ set }) => () => {
    if (!firstSpace && firstSpaceGroup) {
      return;
    }

    function open(spaceId: string, groupId: string) {
      set(sidebarSpaceCollapsed(spaceId), false);
      set(sidebarGroupCollapsed(groupId), false);
      setTimeout(() => {
        const el = document.getElementById(`space-${spaceId}`);
        if (el) {
          scrollIntoView(el, { scrollMode: 'if-needed', block: 'center' });
        }
      });
    }

    // let's see if the one of the favorite spaces should be forced open
    for (const space of favoriteSpaces) {
      if (history.location.pathname.includes(`/${space.slug}`)) {
        open(space.id, 'my');
        return;
      }
    }
    // let's see if one of the non-favorite spaces should be forced open
    for (const space of nonFavoriteSpaces) {
      if (history.location.pathname.includes(`/${space.slug}`)) {
        open(space.id, 'other');
        return;
      }
    }

    // get the first space open the first time through the onboarding
    if (history.location.pathname.includes('/onboarding')) {
      open(firstSpace.id, firstSpaceGroup);
    }

    // if we're just at the bare organization path, we'll open the user's last space or just the first space
    if (!history.location.pathname.replace(/\/$/, '').endsWith(organization.slug)) {
      return;
    }

    if (member?.lastSpaceId) {
      open(
        member.lastSpaceId,
        favoriteSpaces.find(s => s.id === member.lastSpaceId) ? 'my' : 'other'
      );
    }

    open(firstSpace.id, firstSpaceGroup);
  });

  useComponentDidMount(() => {
    callback();
  });
}

export function Sidebar() {
  const organization = useOrganization();
  const hasAdminRights = useHasAdminRights();
  const modals = useModals();
  const user = useCurrentUser();
  const organizations = useRecoilValue(currentUserOrganizatonsState);
  const tinyScreen = useIsTinyScreen();
  const hasDependencies = useRecoilValue(orgHasDependenciesSelector(organization.id));
  const isGuest = useIsGuest();
  const hidden = useRecoilValue(sidebarHidden);
  const [orgDropdownOpen, setOrgDropdownOpen] = React.useState(false);
  const { featureFlags } = useConfiguration();

  useOpenSpaceIfNeeded();

  //FIXME: use real org color instead of blue when it exists in database
  return (
    <SidebarContainer
      className={cn({
        [styles.hidden]: hidden,
      })}
    >
      <SpaceHotkeys />
      <SidebarHeader mobile={isMobileOS}>
        <DropdownMenu open={orgDropdownOpen} onOpenChange={setOrgDropdownOpen}>
          <DropdownMenuTrigger asChild>
            <Button
              className="fullWidth"
              icon={<OrganizationAvatar name={organization.name} img={organization.avatar} />}
            >
              <div className="rowBetween fullWidth">
                <span className="ml2">{organization.name}</span>
                <Icon style={{ fill: 'var(--grayA8)' }} icon={'arrow_down'} />
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="menuStandard" side="bottom" align="start" sideOffset={6}>
            <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
              <DropdownMenuLabel>{organization.name}</DropdownMenuLabel>
              <DropdownMenuItem
                icon="settings"
                to={
                  isGuest
                    ? organizationPath(organization, 'settings/profile')
                    : organizationPath(organization, 'settings/organization')
                }
              >
                {isGuest ? 'Account settings' : 'Organization settings'}
              </DropdownMenuItem>
              {hasAdminRights && (
                <DropdownMenuItem
                  icon="member"
                  to={organizationPath(organization, 'settings/members')}
                >
                  Invite members
                </DropdownMenuItem>
              )}
              <DropdownMenuItem icon="archive" to={organizationPath(organization, 'archive')}>
                View archive
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </HideIfSmallerThan>
            <DropdownMenuLabel>Switch organization</DropdownMenuLabel>
            {organizations
              .filter(o => o.id !== organization.id)
              .map(o => (
                <DropdownMenuItem
                  onClick={() => (window.location.pathname = organizationPath(o))}
                  key={o.id}
                  icon={<OrganizationAvatar name={o.name} img={o.avatar} />}
                >
                  {o.name}
                </DropdownMenuItem>
              ))}
            <DropdownMenuItem
              icon="add"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setOrgDropdownOpen(false);
                modals.openModal(Modals.CreateOrganization);
              }}
            >
              Create new organization
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleLogout()} icon="signout">
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarHeader>
      <SidebarNavigation>
        <SidebarNavigationElement
          icon="search"
          hotkey={searchKey}
          to={organizationPath(organization, 'search')}
        >
          Search
        </SidebarNavigationElement>
        <SidebarNavigationElement
          icon="updates"
          hotkey={updatesScreenKey}
          to={organizationPath(organization, 'updates')}
        >
          Updates
          <NotificationIndicator />
        </SidebarNavigationElement>
        <SidebarNavigationElement
          icon="my_work"
          hotkey={myWorkKey}
          to={organizationPath(organization, 'my')}
        >
          My work
        </SidebarNavigationElement>
        <SidebarNavigationElement
          icon="feedback"
          hotkey={showFeedbackKey}
          to={organizationPath(organization, 'feedback')}
          exact={false}
        >
          Feedback
        </SidebarNavigationElement>

        {organization.newRoadmapsEnabled && (
          <SidebarNavigationElement
            icon="roadmap"
            hotkey={showRoadmapKey}
            to={organizationPath(organization, 'roadmaps')}
            exact={false}
          >
            Roadmaps
          </SidebarNavigationElement>
        )}
        {organization.documentsEnabled && (
          <>
            <SidebarNavigationElement
              hotkey={showDocumentsKey}
              isActive={(_, location: Location) => {
                return (
                  location.pathname.includes('/document') &&
                  !location.pathname.includes('/archive/')
                );
              }}
              icon="document"
              to={organizationPath(organization, `documents`)}
            >
              Documents
            </SidebarNavigationElement>
          </>
        )}
        {hasDependencies && (
          <SidebarNavigationElement
            icon="dependency_enables"
            hotkey={showDependencyGraphKey}
            to={organizationPath(organization, 'dependencies')}
            exact={false}
          >
            Dependencies
          </SidebarNavigationElement>
        )}
        {featureFlags.FEATURE_TOGGLE_RELEASES && (
          <SidebarNavigationElement
            icon="release"
            // hotkey={showDependencyGraphKey} // TODO: Hotkey
            to={organizationPath(organization, 'releases')}
            exact={false}
          >
            Releases
          </SidebarNavigationElement>
        )}
      </SidebarNavigation>
      <SidebarSpaces>
        <Starred />
        <MySpaceGroup />
        <OtherSpaceGroup />
      </SidebarSpaces>
      <Footer>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className="fullWidth"
              icon={<Avatar name={user.name || user.username} img={user.avatar} />}
            >
              <div className="rowBetween fullWidth ellipsis">
                <span className="ml2 ellipsis">{user.name || user.username}</span>
                <Icon style={{ fill: 'var(--grayA8)' }} icon={'arrow_down'} />
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            side={tinyScreen ? 'top' : 'right'}
            align={tinyScreen ? 'start' : 'end'}
            sideOffset={6}
          >
            <div className={styles.profileMenu}>
              <div className="headingS">{user.name || user.username}</div>
              <div className="bodyM">{user.primaryEmail}</div>
            </div>
            <DropdownMenuSeparator />
            <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
              <DropdownMenuItem
                standardWidth
                icon="settings"
                to={organizationPath(organization, 'settings/profile')}
              >
                Account settings
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </HideIfSmallerThan>
            <DropdownMenuItem onClick={() => handleLogout()} standardWidth icon="signout">
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Footer>
    </SidebarContainer>
  );
}

function SpaceNavigation({
  spaceId,
  index,
  hotkey,
}: {
  spaceId: string;
  index: number;
  hotkey?: string;
}) {
  const history = useHistory();
  const organization = useOrganization();
  const space = useRecoilValue(spaceSelector(spaceId));
  const boards = useRecoilValue(boardsForSpaceSelector(spaceId));
  const currentCycleNumber = useRecoilValue(cycleNumberSelector(space?.activeCycleId));
  const hasBacklog = !!boards.find(b => b.key === 'backlog');
  const current = !!space && history.location.pathname.includes(space.slug);
  const spaceRoadmapId = useRecoilValue(roadmapIdForSpaceSelector(spaceId));
  const { featureFlags } = useConfiguration();

  if (!space) {
    return null;
  }

  let roadmapInitiativeSidebar = null;

  if (!featureFlags.FEATURE_TOGGLE_BETTER_ROADMAP_SIDEBAR) {
    roadmapInitiativeSidebar = (
      <>
        <SidebarNavigationElement
          icon="initiative"
          hotkey={showInitiativesKey}
          spaceSubElement
          to={spacePath(organization, space, 'initiatives')}
        >
          Initiatives
        </SidebarNavigationElement>

        {spaceRoadmapId && organization.newRoadmapsEnabled && (
          <SidebarNavigationElement
            icon="roadmap"
            spaceSubElement
            to={spacePath(organization, space, 'roadmap')}
          >
            Roadmap
          </SidebarNavigationElement>
        )}
      </>
    );
  } else if (spaceRoadmapId && organization.newRoadmapsEnabled) {
    roadmapInitiativeSidebar = (
      <SidebarNavigationElement
        icon="roadmap"
        spaceSubElement
        to={spacePath(organization, space, 'roadmap')}
        accessory={
          <Tooltip content="Space initiatives">
            <NavLink to={spacePath(organization, space, `initiatives/`)}>
              <IconButton
                className={styles.menu}
                icon="list_view"
                size={ButtonSize.Small}
                buttonStyle={ButtonStyle.BareSubtleNoHover}
              />
            </NavLink>
          </Tooltip>
        }
      >
        Roadmap
      </SidebarNavigationElement>
    );
  } else {
    roadmapInitiativeSidebar = (
      <SidebarNavigationElement
        icon="initiative"
        hotkey={showInitiativesKey}
        spaceSubElement
        to={spacePath(organization, space, 'initiatives')}
      >
        Initiatives
      </SidebarNavigationElement>
    );
  }

  return (
    <div id={`space-${spaceId}`}>
      <SidebarSpaceElement
        id={space.id}
        index={index}
        name={
          <div className="ellipsis row">
            <div className="ellipsis">{space.name}</div>
            {space.private && (
              <Icon icon="private" size={IconSize.Size16} className={styles.private} />
            )}
          </div>
        }
        hotkey={hotkey}
        menuContents={() => <SpaceMenuContents spaceId={spaceId} current={current} />}
        to={spacePath(organization, space)}
        current={current}
      >
        <SidebarNavigationElement
          icon="current"
          hotkey={showIssueBoardKey}
          spaceSubElement
          to={spacePath(organization, space, 'boards/current')}
        >
          Current
        </SidebarNavigationElement>
        {hasBacklog && (
          <SidebarNavigationElement
            icon="backlog"
            hotkey={showBacklogBoardKey}
            spaceSubElement
            to={spacePath(organization, space, 'boards/backlog')}
          >
            Planning
          </SidebarNavigationElement>
        )}
        {space.cyclesEnabled && (
          <>
            <SidebarNavigationElement
              hotkey={showActiveCycleKey}
              isActive={(_, location: Location) => {
                return (
                  location.pathname.includes(space.slug) &&
                  (location.pathname.includes('/cycles/') || location.pathname.endsWith('/cycles'))
                );
              }}
              icon="cycle_current"
              spaceSubElement
              to={spacePath(organization, space, `cycles/${currentCycleNumber ?? ''}`)}
              accessory={
                <Tooltip content="All cycles">
                  <NavLink to={spacePath(organization, space, `cycles/`)}>
                    <IconButton
                      className={styles.menu}
                      icon="list_view"
                      size={ButtonSize.Small}
                      buttonStyle={ButtonStyle.BareSubtleNoHover}
                    />
                  </NavLink>
                </Tooltip>
              }
            >
              Cycles
            </SidebarNavigationElement>
          </>
        )}
        {roadmapInitiativeSidebar}
      </SidebarSpaceElement>
    </div>
  );
}

export function StarredItem({ starred, index }: { starred: RenderedStarred; index: number }) {
  const { darkMode } = useDarkMode();
  const iconColor = starred.iconColor
    ? colorWithOpacity(starred.iconColor, 10, darkMode)
    : undefined;

  return (
    <Draggable draggableId={starred.id} index={index} isDragDisabled={isMobileOS}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <SidebarNavigationElement
            isActive={starred.isActive}
            to={starred.link}
            icon={starred.icon}
            iconColor={iconColor}
            hotkey={index < 9 ? `${gotoStarredKey}${index + 1}` : undefined}
          >
            <div className="rowBetween maxWidth fullWidth ellipsis">
              <div className="ellipsis">{starred.name}</div>
            </div>
          </SidebarNavigationElement>
        </div>
      )}
    </Draggable>
  );
}

function Starred() {
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  const starred = useRecoilValue(starredSelector(organization.id));
  const [collapsed, setCollapsed] = useRecoilState(sidebarGroupCollapsed('starred'));

  const updateOrganizationMembers = useUpdateOrganizationMembers();

  if (!orgMembership || !starred.length) {
    return null;
  }

  return (
    <DragDropContext
      onDragEnd={async ({ source, destination }) => {
        if (!destination) {
          return;
        }

        const toMove = starred[source.index];
        const updatedStarred = starred.filter(s => s.id !== toMove.id);
        updatedStarred.splice(destination.index, 0, toMove);
        updateOrganizationMembers([orgMembership.id], {
          starred: updatedStarred.map(f => pick(f, 'id', 'type')),
        });
      }}
    >
      <Droppable ignoreContainerClipping droppableId={'starred'}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={cn(styles.spaceGroup, {
              [styles.dragging]: snapshot.isDraggingOver,
            })}
          >
            <div
              className={styles.spaceGroupHeader}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setCollapsed(c => !c);
              }}
            >
              <div className="row">
                Starred
                <Icon className="ml4" icon={collapsed ? 'arrow_forward' : 'arrow_down'} />
              </div>
            </div>
            {!collapsed && (
              <>
                {starred.map((f, index) => (
                  <StarredItem key={f.id} starred={f} index={index} />
                ))}
                {provided.placeholder}
              </>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function MySpaceGroup() {
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  const updateOrganizationMembers = useUpdateOrganizationMembers();

  if (!orgMembership?.favoriteSpaceIds?.length) {
    return null;
  }

  return (
    <SidebarSpaceGroup
      id="my"
      name="My spaces"
      onReordered={(from, to) => {
        if (!orgMembership.favoriteSpaceIds) {
          return;
        }

        const toMove = orgMembership.favoriteSpaceIds[from];
        const favoriteSpaceIds = orgMembership.favoriteSpaceIds.filter(
          spaceId => spaceId !== toMove
        );
        favoriteSpaceIds.splice(to, 0, toMove);
        updateOrganizationMembers([orgMembership.id], { favoriteSpaceIds });
      }}
    >
      {(orgMembership.favoriteSpaceIds ?? []).map((spaceId, index) => {
        const key = index === 9 ? 0 : index + 1;
        return (
          <SpaceNavigation
            index={index}
            key={spaceId}
            spaceId={spaceId}
            hotkey={key <= 9 ? `${key}` : undefined}
          />
        );
      })}
    </SidebarSpaceGroup>
  );
}

function OtherSpaceGroup() {
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const favoriteSpaceIds = orgMembership?.favoriteSpaceIds ?? [];
  const otherSpaces = spaces.filter(space => !favoriteSpaceIds.includes(space.id));
  const updateSpaceSorts = useUpdateSpaceSorts();

  return (
    <SidebarSpaceGroup
      id={'other'}
      name={favoriteSpaceIds.length ? 'Other spaces' : 'Spaces'}
      onReordered={(from, to) => {
        const toMove = otherSpaces[from];
        // we're showing a subset of the spaces (potentially) so we need to figure out where the moving space
        // wouldn't land in the overall list of spaces.
        const toMoveBefore = otherSpaces[to];
        const absoluteTo = !toMoveBefore
          ? spaces.length
          : spaces.findIndex(s => s.id === toMoveBefore.id);
        if (absoluteTo === -1) {
          return;
        }
        const tempSpaces = [...spaces].filter(s => s.id !== toMove.id);
        tempSpaces.splice(absoluteTo, 0, toMove);
        const previousSpace: Space | undefined = tempSpaces[absoluteTo - 1];
        const nextSpace: Space | undefined = tempSpaces[absoluteTo + 1];
        updateSpaceSorts([toMove.id], previousSpace?.id, nextSpace?.id);
      }}
    >
      {otherSpaces.map((space, index) => {
        const key = index === 9 ? 0 : index + 1;
        const hotkey = favoriteSpaceIds.length === 0 ? `${key}` : undefined;
        return <SpaceNavigation index={index} key={space.id} spaceId={space.id} hotkey={hotkey} />;
      })}
    </SidebarSpaceGroup>
  );
}

export function SpaceMenuContents({ spaceId, current }: { spaceId: string; current: boolean }) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const user = useCurrentUser();
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  const isInMySpaces = (orgMembership?.favoriteSpaceIds ?? []).includes(spaceId);
  const updateOrganizationMembers = useUpdateOrganizationMembers();
  const updateSpaces = useUpdateSpaces();
  const spaceSettingsPath = useSpaceSettingsPath();

  if (!space || !orgMembership) {
    return null;
  }
  return (
    <>
      {(!space.private || space.members.length > 1) && (
        <>
          <DropdownMenuItem
            standardWidth
            icon="my_space"
            onClick={() => {
              let favoriteSpaceIds = [...(orgMembership?.favoriteSpaceIds ?? [])];
              if (!favoriteSpaceIds.includes(space.id)) {
                favoriteSpaceIds.push(space.id);
              } else {
                favoriteSpaceIds = favoriteSpaceIds.filter(spaceId => spaceId !== space.id);
                if (space.private) {
                  updateSpaces([space.id], { members: space.members.filter(m => m !== user.id) });
                }
              }
              updateOrganizationMembers([orgMembership.id], { favoriteSpaceIds });
            }}
          >
            {isInMySpaces ? `Leave` : `Join`}
          </DropdownMenuItem>
          <DropdownMenuSeparator />
        </>
      )}
      <DropdownMenuItem standardWidth icon="settings" to={spaceSettingsPath(space.id, ``)}>
        Space settings
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="archive"
        to={spacePath(organization, space, 'archive')}
        shortcut={current ? showArchiveKey : undefined}
      >
        View archive
      </DropdownMenuItem>
    </>
  );
}

function Footer({ children }: { children: React.ReactNode }) {
  return (
    <SidebarFooter>
      <InviteMembers />
      <FreePlan />
      <HelpAndSupport />
      <div className="row grow">{children}</div>
    </SidebarFooter>
  );
}
