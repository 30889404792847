import { groupBy, isEqual, keyBy, max, sortBy, uniq, uniqBy } from 'lodash';
import React from 'react';
import {
  GetRecoilValue,
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useSetRecoilState,
} from 'recoil';
import { InitiativeStatus } from '../../../shared/initiativeStatus';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { issueTerm } from '../../../shared/utils/terms';
import {
  activitiesByEntity,
  commentsByEntity,
  issuesByLabel,
} from '../../../sync/__generated/indexes';
import {
  Activity,
  Comment,
  Cycle,
  CycleEntity,
  Dependency,
  Doc,
  Entity,
  Feedback,
  Initiative,
  Insight,
  Issue,
  IssueStatusType,
  Organization,
  Release,
  Space,
  Todo,
} from '../../../sync/__generated/models';
import { Breadcrumb } from '../../components/new/breadcrumbs';
import { useConfiguration } from '../../contexts/configurationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { filterEntities } from '../../utils/filtering';
import { filterEntities as filterEntities2 } from '../../utils/filtering2';
import { equalSelector, equalSelectorFamily } from '../../utils/recoil';
import { measureText } from '../../utils/text';
import { localStorageEffect } from '../effects';
import {
  StateEvents,
  indexKey,
  indexKeyState,
  offStateEvent,
  onStateEvent,
  syncEngineState,
} from '../state';
import { SyncEngineObject } from '../types';
import { ActivityFilterMode, activityFeedActiveFilterState } from './activities';
import { cycleByNumberSelector, cyclePath, isCycle } from './cycles';
import {
  documentByNumberSelector,
  documentPath,
  documentsForOrganizationSelector,
  isDocument,
} from './documents';
import {
  feedbackByNumberSelector,
  feedbackForOrganizationSelector,
  feedbackPath,
  isFeedback,
} from './feedback';
import { folderBreadcrumbsSelector } from './folders';
import {
  initiativeByNumberSelector,
  initiativePath,
  initiativeStatusSelector,
  initiativesForOrganizationSelector,
  isInitiative,
  scoreInitiative,
} from './intiatives';
import {
  hideSnoozedAtom,
  isIssue,
  issueArchivedSelector,
  issueByNumberSelector,
  issueIdByNumberSelector,
  issuePath,
  issuesForOrganizationSelector,
  issuesForSpaceSelector,
  issuesForStatusSelector,
  scoreStatus,
  statusSelector,
} from './issues';
import { organizationPath, organizationsSelector } from './organizations';
import {
  isRelease,
  releaseByNumberSelector,
  releasePath,
  releasesForOrganizationSelector,
} from './releases';
import { roadmapPath } from './roadmaps';
import { spacePath, spaceSelector, spacesForOrganizationSelector } from './spaces';
import { todoSelector, todosForEntitySelector } from './todos';
import { currentUserMembershipState, currentUserState } from './users';

const MAX_RECENT_ITEMS = 15;
const MAX_CACHED_RECENT_ITEMS = 100;
export const DEFAULT_ENTITY_NUMBER_WIDTH = 50;

export function useEntityPath() {
  return useRecoilCallback(({ transact_UNSTABLE }) => (entityId: string) => {
    let res = '';
    transact_UNSTABLE(({ get }) => {
      if (!entityId) {
        return;
      }
      const entity = get(syncEngineState(entityId)) as Entity | null;
      if (!entity || entity.deleted) {
        return;
      }

      if (isSpaceBoundEntity(entity)) {
        const space = isSpaceBoundEntity(entity)
          ? (get(syncEngineState(entity.spaceId)) as Space | null)
          : null;
        if (!space || space.deleted) {
          return;
        }
        const organization = get(syncEngineState(space.organizationId)) as Organization | null;

        if (!organization || organization.deleted) {
          return;
        }

        res = entityPath(organization, space, entity);
      } else if (isOrgBoundEntity(entity)) {
        const organization = get(syncEngineState(entity.organizationId)) as Organization | null;

        if (!organization || organization.deleted) {
          return;
        }

        res = entityPath(organization, null, entity);
      } else {
        return;
      }
    });
    return res;
  });
}

export function entityPath(
  organization: Organization,
  space: Space | null,
  entity: Entity
): string {
  if (isIssue(entity) && space) {
    return issuePath(organization, space, entity);
  }
  if (isCycle(entity) && space) {
    return cyclePath(organization, space, entity);
  }
  if (isDocument(entity)) {
    return documentPath(organization, entity);
  }
  if (isFeedback(entity)) {
    return feedbackPath(organization, entity);
  }
  if (isInitiative(entity)) {
    return initiativePath(organization, entity);
  }

  if (isRelease(entity)) {
    return releasePath(organization, entity);
  }

  throw `Unknown entity type for id: ${(entity as any)?.id}`;
}

export const entityPathSelector = selectorFamily({
  key: 'EntityPath',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      const space = get(spaceSelector((entity as any)?.spaceId));
      const organization = get(
        organizationsSelector(space?.organizationId ?? (entity as any)?.organizationId)
      );

      if (!organization || !entity) {
        return null;
      }

      return entityPath(organization, space, entity);
    },
});

export function spaceEntityKey(entity: SpaceBoundEntity, space: Space) {
  if (isCycle(entity)) {
    return `${space.key}-C${entity.number}`;
  }
  return `${space.key}-${entity.number}`;
}

export function orgEntityKey(entity: OrgBoundEntity) {
  if (isFeedback(entity)) {
    return `F-${entity.number}`;
  } else if (isInitiative(entity)) {
    return `I-${entity.number}`;
  } else if (isDocument(entity)) {
    return `D-${entity.number}`;
  } else if (isRelease(entity)) {
    return `R-${entity.number}`;
  } else {
    return `Unknown entity`;
  }
}

export const entityKeySelector = selectorFamily({
  key: 'EntityKey',
  get:
    (entityId: string | undefined) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return null;
      }

      if (isSpaceBoundEntity(entity)) {
        const space = get(spaceSelector(entity.spaceId));
        if (!space) {
          return null;
        }

        return spaceEntityKey(entity, space);
      }

      return orgEntityKey(entity);
    },
});

export function isEntityType(typeName: string) {
  return ['Issue', 'Cycle', 'Feedback', 'Initiative', 'Doc'].includes(typeName);
}

export function isEntity(entity: SyncEngineObject): entity is Entity {
  const e = entity as Entity;
  return isIssue(e) || isCycle(e) || isFeedback(e) || isInitiative(e) || isDocument(e);
}

export type SpaceBoundEntity = Issue | Cycle;
export function isSpaceBoundEntity(entity: SyncEngineObject): entity is SpaceBoundEntity {
  const e = entity as Entity;
  return isIssue(e) || isCycle(e);
}

export type MentionableEntity = Issue | Initiative | Release;
export function isMentionableEntity(entity: SyncEngineObject): entity is MentionableEntity {
  const e = entity as Entity;
  return isIssue(e) || isInitiative(e) || isRelease(e);
}

export type OrgBoundEntity = Feedback | Initiative | Doc | Release;
export function isOrgBoundEntity(entity: SyncEngineObject): entity is OrgBoundEntity {
  const e = entity as Entity;
  return isFeedback(e) || isInitiative(e) || isDocument(e) || isRelease(e);
}

export type LabelableEntity = Issue | Initiative;
export function isLabelableEntity(entity: SyncEngineObject): entity is LabelableEntity {
  const e = entity as Entity;
  return isIssue(e) || isInitiative(e);
}

export type AssignableEntity = Issue | Initiative | Release;
export function isAssignableEntity(entity: SyncEngineObject): entity is AssignableEntity {
  const e = entity as Entity;
  return isIssue(e) || isInitiative(e) || isRelease(e);
}

export type EffortImpactableEntity = Issue;
export function isEffortImpactableEntity(
  entity: SyncEngineObject
): entity is EffortImpactableEntity {
  const e = entity as Entity;
  return isIssue(e);
}

export type WatchableEntity = Issue | Release;
export function isWatchableEntity(entity: SyncEngineObject): entity is WatchableEntity {
  const e = entity as Entity;
  return isIssue(e) || isRelease(e);
}

export type CycleableEntity = Issue;
export function isCycleableEntity(entity: SyncEngineObject): entity is CycleableEntity {
  const e = entity as Entity;
  return isIssue(e);
}

export const isIssueSelector = selectorFamily({
  key: 'IsIssue',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return false;
      }
      return isIssue(entity);
    },
});

export const isCycleSelector = selectorFamily({
  key: 'IsCycle',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return false;
      }
      return isCycle(entity);
    },
});

export const isFeedbackSelector = selectorFamily({
  key: 'IsFeedback',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return false;
      }
      return isFeedback(entity);
    },
});

export const isDeletedSelector = selectorFamily({
  key: 'IsDeleted',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return true;
      }

      if (isSpaceBoundEntity(entity)) {
        const space = get(spaceSelector(entity?.spaceId));
        if (!space) {
          return true;
        }
      }

      return false;
    },
});

export const entityNumberSelector = selectorFamily({
  key: 'EntityNumber',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      return entity?.number;
    },
});

export const isPartialSelector = selectorFamily({
  key: 'IsPartial',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return false;
      }
      return isIssue(entity) && entity.partial;
    },
});

export const entityArchivedSelector = selectorFamily({
  key: 'EntityArchived',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(syncEngineState(entityId)) as Entity | null;
      if (!entity || entity.deleted) {
        return false;
      }

      if (isIssue(entity)) {
        return !!get(issueArchivedSelector(entityId));
      }
      if (isInitiative(entity) || isDocument(entity)) {
        return !!entity.archivedAt;
      }

      if (isFeedback(entity) || isCycle(entity) || isRelease(entity)) {
        return false;
      }

      throw `Unknown entity type for id: ${(entity as any)?.id}`;
    },
});

export function entityType(entity: Entity) {
  return entityTypeString(entity.__typename);
}

export function entityTypeString(
  entityType: 'Issue' | 'Cycle' | 'Feedback' | 'Initiative' | 'Doc' | 'Release'
) {
  if (entityType === 'Issue') {
    return issueTerm;
  }
  if (entityType === 'Doc') {
    return 'document';
  }
  return entityType.toLowerCase();
}

export const commentsForEntitySelector = selectorFamily({
  key: 'CommentsForEntity',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      if (!issueId) {
        return [];
      }
      const commentIds = get(indexKeyState(indexKey(commentsByEntity, issueId)));
      return filterNotNull(
        commentIds.map(commentId => get(syncEngineState(commentId)) as Comment | null)
      );
    },
});

export const activitiesForEntitySelector = selectorFamily({
  key: 'ActivitiesForEntity',
  get:
    (entityId: string | undefined | null) =>
    ({ get }) => {
      if (!entityId) {
        return [];
      }
      const activitiyIds = get(indexKeyState(indexKey(activitiesByEntity, entityId)));
      const activities = filterNotDeletedNotNull(
        activitiyIds.map(activitiyId => get(syncEngineState(activitiyId)) as Activity | null)
      );
      return activities;
    },
});

export const commentsAndActivitiesForEntitySelector = equalSelectorFamily({
  key: 'CommentsAndActivitiesForEntity',
  get:
    (entityId: string) =>
    ({ get }) => {
      const filter = get(activityFeedActiveFilterState);
      const rawComments = get(commentsForEntitySelector(entityId));
      const deletedThreads = new Set<string>();
      const commentsByThread = groupBy(rawComments, c => c.threadId);
      for (const [threadId, comments] of Object.entries(commentsByThread)) {
        if (comments.every(c => c.deleted)) {
          deletedThreads.add(threadId);
        }
      }
      const comments = rawComments.filter(c => !c.reply && !deletedThreads.has(c.threadId));

      const activities =
        filter != ActivityFilterMode.Comments
          ? [...get(activitiesForEntitySelector(entityId))]
          : [];

      // both of our above arrays are sorted, so we can merge them rather then resorting the whole thing
      const results: Array<Comment | Activity> = [];
      while (comments.length || activities.length) {
        if (!comments.length) {
          results.push(...activities.reverse());
          break;
        }
        if (!activities.length) {
          results.push(...comments.reverse());
          break;
        }

        if (comments[comments.length - 1].createdAt < activities[activities.length - 1].createdAt) {
          results.push(comments.pop()!);
        } else {
          results.push(activities.pop()!);
        }
      }

      return results.map(r => r.id);
    },
  equals: isEqual,
});

export const entitySelector = selectorFamily({
  key: 'Entity',
  get:
    (entityId: string | undefined | null) =>
    ({ get }) => {
      if (!entityId) {
        return null;
      }
      return notDeleted(get(syncEngineState(entityId)) as Entity | null);
    },
});

export const entityTitleSelector = selectorFamily({
  key: 'EntityTitle',
  get:
    (entityId: string | undefined) =>
    ({ get }) => {
      if (!entityId) {
        return null;
      }
      const entity = get(entitySelector(entityId));
      return entity?.title;
    },
});

export const entitiesSelector = selectorFamily({
  key: 'Entities',
  get:
    (entityIds: string[]) =>
    ({ get }) => {
      return filterNotDeletedNotNull(
        entityIds.map(entityId => get(syncEngineState(entityId)) as Entity)
      );
    },
});

export const areEntitiesSelector = selectorFamily({
  key: 'AreEntities',
  get:
    (maybeEntityIds: string[]) =>
    ({ get }) => {
      const maybeEntities = maybeEntityIds.map(
        entityId => get(syncEngineState(entityId)) as SyncEngineObject | null
      );
      return filterNotDeletedNotNull(maybeEntities)
        .filter(e => isEntity(e))
        .map(e => e.id);
    },
});

export const isEntitySelector = selectorFamily({
  key: 'IsEntity',
  get:
    (entityId?: string | null) =>
    ({ get }) => {
      if (!entityId) {
        return false;
      }
      const entity = get(syncEngineState(entityId)) as SyncEngineObject | null;
      if (!entity) {
        return false;
      }
      return isEntity(entity);
    },
});

interface RecentEntity {
  id: string;
  createdAt: number;
}

const recentEntities: {
  organizationEntities: RecentEntity[];
  spaceEntities: { [index: string]: RecentEntity[] };
} = { organizationEntities: [], spaceEntities: {} };
const visitedEntities = new Set<string>();

function updateRecentList(recentList: RecentEntity[], objects: Entity[]): RecentEntity[] {
  const result = [
    ...recentList,
    ...objects.map(object => ({
      id: object.id,
      createdAt: object.createdAt,
    })),
  ];

  return uniqBy(
    sortBy(result, r => -r.createdAt),
    'id'
  ).slice(0, MAX_CACHED_RECENT_ITEMS);
}

export function useUpdateRecentEntities() {
  function handleSet({ objects }: { objects: SyncEngineObject[] }) {
    const newEntities = objects.filter(
      object =>
        isEntity(object) &&
        isMentionableEntity(object) &&
        object.createdAt &&
        !visitedEntities.has(object.id)
    ) as Entity[];

    const newEntitiesBySpace = groupBy(
      newEntities.filter(e => isSpaceBoundEntity(e)),
      'spaceId'
    );
    for (const spaceId of Object.keys(newEntitiesBySpace)) {
      recentEntities.spaceEntities[spaceId] = updateRecentList(
        recentEntities.spaceEntities[spaceId] ?? [],
        newEntitiesBySpace[spaceId]
      );
    }

    recentEntities.organizationEntities = updateRecentList(
      recentEntities.organizationEntities,
      newEntities
    );
  }

  React.useEffect(() => {
    onStateEvent(StateEvents.Set, handleSet);
    onStateEvent(StateEvents.Load, handleSet);
    return () => {
      offStateEvent(StateEvents.Set, handleSet);
      offStateEvent(StateEvents.Load, handleSet);
    };
  }, []);
}

export function useRecentEntitiesForSpace() {
  return useRecoilCallback(({ snapshot }) => (spaceId: string): Entity[] => {
    return filterNotDeletedNotNull(
      (recentEntities.spaceEntities[spaceId] ?? []).map(recentEntity =>
        snapshot.getLoadable(entitySelector(recentEntity.id)).getValue()
      )
    ).slice(0, MAX_RECENT_ITEMS);
  });
}

export function useRecentEntitiesForOrganization(opts?: { filter?: (e: Entity) => boolean }) {
  return useRecoilCallback(({ snapshot }) => (_organizationId: string): Entity[] => {
    return filterNotDeletedNotNull(
      (recentEntities.organizationEntities ?? []).map(recentEntity =>
        snapshot.getLoadable(entitySelector(recentEntity.id)).getValue()
      )
    )
      .filter(e => opts?.filter?.(e) ?? true)
      .slice(0, MAX_RECENT_ITEMS);
  });
}

const spaceKeyWidths: Record<string, number> = {};
const longestNumberBySpace: Record<string, number> = {};
const numberWidthBySpace: Record<string, number> = {};
const visitedEntitiesForWidths = new Set<string>();

export enum GlobalNumberWidths {
  Documents = 'documents',
}
const globalNumberWidths: Partial<Record<GlobalNumberWidths, number>> = {};

export function useUpdateEntityNumberWidths() {
  function handleSet({ objects }: { objects: SyncEngineObject[] }) {
    for (const object of objects) {
      if (isEntity(object)) {
        if (isSpaceBoundEntity(object) && object.spaceId && object.createdAt) {
          if (
            visitedEntitiesForWidths.has(object.id) ||
            object.number.includes('T') ||
            object.number.includes('U')
          ) {
            continue;
          }

          let number = object.number.toString();
          if (isCycle(object)) {
            number = `C${object.number}`;
          }

          const length = number.length;
          if (length > (longestNumberBySpace[object.spaceId] ?? 0)) {
            longestNumberBySpace[object.spaceId] = length;
            // our numbers use tabular-nums so they should always be 8 pixels wide. The + 1 is for the hyphen which
            // also gets globbed together with the tabular numbers.
            numberWidthBySpace[object.spaceId] = (length + 1) * 8;
          }

          visitedEntitiesForWidths.add(object.id);
        } else {
          if (isDocument(object)) {
            const number = object.number.toString();
            const length = number.length;
            if (length > (globalNumberWidths[GlobalNumberWidths.Documents] ?? 0)) {
              globalNumberWidths[GlobalNumberWidths.Documents] = length;
            }
            visitedEntitiesForWidths.add(object.id);
          }
        }
      }
      if (object.__typename === 'Space') {
        spaceKeyWidths[object.id] =
          measureText(`${(object as Space).key}`, '12px Inter') || DEFAULT_ENTITY_NUMBER_WIDTH;
      }
    }
  }

  React.useEffect(() => {
    onStateEvent(StateEvents.Set, handleSet);
    onStateEvent(StateEvents.Load, handleSet);
    return () => {
      offStateEvent(StateEvents.Set, handleSet);
      offStateEvent(StateEvents.Load, handleSet);
    };
  }, []);
}

export function useEntityNumberWidths(spaceIds: string[]) {
  const widths = spaceIds.map(spaceId => {
    if (!spaceKeyWidths[spaceId] || !numberWidthBySpace[spaceId]) {
      return DEFAULT_ENTITY_NUMBER_WIDTH;
    }
    return spaceKeyWidths[spaceId] + numberWidthBySpace[spaceId];
  });
  return max(widths);
}

export function useGlobalEntityNumberWidths(type: GlobalNumberWidths) {
  return ((globalNumberWidths[type] ?? DEFAULT_ENTITY_NUMBER_WIDTH) + 2) * 8;
}

export function useDetectLinks(organization: Organization | null) {
  const { host } = useConfiguration();
  const currentUser = useCurrentUser();
  const actorId = currentUser.id;

  return useRecoilCallback(
    ({ snapshot }) =>
      (url: string): { todo?: Todo; entity: Entity; actorId?: string } | null => {
        if (!organization || !url.startsWith(host)) {
          return null;
        }

        const { searchParams } = new URL(url);
        const todoId = searchParams.get('focusSmartTodo');

        if (todoId) {
          const todo = snapshot.getLoadable(todoSelector(todoId)).getValue();
          const entity = snapshot.getLoadable(entitySelector(todo?.entityId)).getValue();
          if (!todo || !entity) {
            return null;
          }

          return { todo, entity, actorId };
        }

        const path = url.substring('https://toil.kitemaker.co'.length);
        const pathComponents = path.split('/').filter(p => !!p);

        if (pathComponents[1] === 'feedback') {
          if (pathComponents.length < 3) {
            return null;
          }

          const entity = snapshot
            .getLoadable(
              feedbackByNumberSelector({
                organizationId: organization.id,
                feedbackNumber: pathComponents[2],
              })
            )
            .getValue();
          return entity ? { entity, actorId } : null;
        } else if (pathComponents[1] === 'initiatives') {
          if (pathComponents.length < 3) {
            return null;
          }

          const entity = snapshot
            .getLoadable(
              initiativeByNumberSelector({
                organizationId: organization.id,
                initiativeNumber: pathComponents[2],
              })
            )
            .getValue();
          return entity ? { entity, actorId } : null;
        } else if (pathComponents[1] === 'document') {
          if (pathComponents.length < 3) {
            return null;
          }

          const entity = snapshot
            .getLoadable(
              documentByNumberSelector({
                organizationId: organization.id,
                docNumber: pathComponents[2],
              })
            )
            .getValue();
          return entity ? { entity, actorId } : null;
        } else if (pathComponents[1] === 'releases') {
          if (pathComponents.length < 3) {
            return null;
          }

          const entity = snapshot
            .getLoadable(
              releaseByNumberSelector({
                organizationId: organization.id,
                releaseNumber: pathComponents[2],
              })
            )
            .getValue();
          return entity ? { entity, actorId } : null;
        } else {
          if (pathComponents.length < 4) {
            return null;
          }

          const spaceSlug = pathComponents[1];
          const spaces = snapshot
            .getLoadable(spacesForOrganizationSelector(organization.id))
            .getValue();
          const space = spaces.find(s => s.slug === spaceSlug);

          if (!space) {
            return null;
          }

          if (pathComponents[2] === 'cycles') {
            const entity = snapshot
              .getLoadable(
                cycleByNumberSelector({
                  spaceId: space.id,
                  cycleNumber: pathComponents[3],
                })
              )
              .getValue();
            return entity ? { entity, actorId } : null;
          } else {
            const entity = snapshot
              .getLoadable(
                issueByNumberSelector({
                  spaceId: space.id,
                  issueNumber: pathComponents[3],
                })
              )
              .getValue();
            return entity ? { entity, actorId } : null;
          }
        }
      }
  );
}

export function useDetectMentions(organization: Organization | null) {
  const entityRegex = /([IFR]{1}|[A-Z0-9]{2,4})-([C]{1})?(\d+)([A-Z]+)?/gim;
  const currentUser = useCurrentUser();

  return useRecoilCallback(
    ({ snapshot }) =>
      (
        text: string
      ): {
        matchIndex: number;
        match: string;
        todo?: Todo;
        entity: Entity;
        actorId?: string;
      }[] => {
        if (!organization) {
          return [];
        }
        const matches = text.matchAll(entityRegex);

        const results: {
          matchIndex: number;
          match: string;
          todo?: Todo;
          entity: Entity;
          actorId?: string;
        }[] = [];

        for (const regexMatch of matches) {
          const [match, key, isCycle, number, todoKey] = regexMatch;

          let entity: Entity | undefined;
          switch (key.toUpperCase()) {
            case 'I':
              entity = snapshot
                .getLoadable(
                  initiativeByNumberSelector({
                    organizationId: organization.id,
                    initiativeNumber: number,
                  })
                )
                .getValue();
              break;

            case 'F':
              entity = snapshot
                .getLoadable(
                  feedbackByNumberSelector({
                    organizationId: organization.id,
                    feedbackNumber: number,
                  })
                )
                .getValue();
              break;

            case 'R':
              entity = snapshot
                .getLoadable(
                  releaseByNumberSelector({
                    organizationId: organization.id,
                    releaseNumber: number,
                  })
                )
                .getValue();
              break;

            default: {
              const space = snapshot
                .getLoadable(spacesForOrganizationSelector(organization.id))
                .getValue()
                .find(s => s.key === key.toUpperCase());
              if (!space) {
                continue;
              }
              entity = isCycle
                ? snapshot
                    .getLoadable(
                      cycleByNumberSelector({
                        spaceId: space.id,
                        cycleNumber: number,
                      })
                    )
                    .getValue()
                : snapshot
                    .getLoadable(
                      issueByNumberSelector({
                        spaceId: space.id,
                        issueNumber: number,
                      })
                    )
                    .getValue();
              break;
            }
          }

          if (!entity) {
            continue;
          }

          const todo = todoKey
            ? snapshot
                .getLoadable(todosForEntitySelector(entity.id))
                .getValue()
                .find(t => t.key === todoKey.toUpperCase())
            : undefined;

          if (todoKey && !todo) {
            continue;
          }

          results.push({
            match,
            matchIndex: regexMatch.index!,
            entity,
            todo,
            actorId: currentUser.id,
          });
        }

        return results;
      }
  );
}

export function useLookupEntity() {
  return useRecoilCallback(({ transact_UNSTABLE }) => (entityId: string) => {
    let res: Entity | null = null;
    transact_UNSTABLE(({ get }) => {
      res = get(syncEngineState(entityId)) as Entity | null;
    });
    return res as Entity | null;
  });
}

export function useFindRelevantEntities() {
  return useRecoilCallback(({ snapshot }) => (objects: SyncEngineObject[]) => {
    const results = new Set<string>();
    for (const o of objects) {
      // first see if the actual objects are entities
      if (isEntityType(o.__typename)) {
        results.add(o.id);
      }

      // next, if the objects are initiatives, that could influence issues
      if (o.__typename === 'Initiative') {
        const initiative = o as Initiative;
        for (const issueId of initiative.issueIds) {
          results.add(issueId);
        }
      }

      // if the objects are dependencies, that's interesting for the issues
      if (o.__typename === 'Dependency') {
        const dependency = o as Dependency;
        results.add(dependency.enablesId);
        results.add(dependency.dependsOnId);
      }

      // if the objects are labels, that's interesting for the entities that have them
      if (o.__typename === 'IssueLabel') {
        const issueIds =
          snapshot.getLoadable(indexKeyState(indexKey(issuesByLabel, o.id))).valueMaybe() ?? [];
        for (const id of issueIds) {
          results.add(id);
        }
      }

      if (o.__typename === 'CycleEntity') {
        const cycle = o as CycleEntity;
        results.add(cycle.entityId);
      }

      if (o.__typename === 'Insight') {
        const insight = o as Insight;
        for (const entityId of insight.entityIds) {
          results.add(entityId);
        }
      }
    }

    return Array.from(results);
  });
}

export const spaceForEntitySelector = selectorFamily({
  key: 'SpaceForEntity',
  get:
    (entityId: string | null | undefined) =>
    ({ get }) => {
      if (!entityId) {
        return null;
      }
      const entity = get(entitySelector(entityId));
      if (!entity || !isSpaceBoundEntity(entity)) {
        return null;
      }
      return get(spaceSelector(entity.spaceId));
    },
});

export const breadcrumbsForEntitySelector = selectorFamily({
  key: 'BreadcrumbsForEntity',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return null;
      }

      const breadcrumbs: Breadcrumb[] = [];

      if (isSpaceBoundEntity(entity)) {
        const space = get(spaceSelector(entity.spaceId));
        const organization = get(organizationsSelector(space?.organizationId));

        if (!space || !organization) {
          return null;
        }

        if (isIssue(entity)) {
          const status = get(statusSelector(entity.statusId));
          switch (status?.statusType) {
            case IssueStatusType.Archived:
              breadcrumbs.push({
                name: `Archive`,
                link: spacePath(organization, space, 'archive'),
              });
              break;
            case IssueStatusType.Backlog:
              breadcrumbs.push({
                name: `Planning`,
                link: spacePath(organization, space, 'boards/backlog'),
              });
              break;
            default:
              breadcrumbs.push({
                name: `Current`,
                link: spacePath(organization, space),
              });
              break;
          }
        } else if (isCycle(entity)) {
          breadcrumbs.push({
            name: 'Cycles',
            link: spacePath(organization, space, 'cycles'),
          });
        }
      } else if (isOrgBoundEntity(entity)) {
        const organization = get(organizationsSelector(entity.organizationId));
        if (!organization) {
          return null;
        }

        if (isFeedback(entity)) {
          breadcrumbs.push({
            name: 'Feedback',
          });
        } else if (isInitiative(entity)) {
          breadcrumbs.push({
            name: 'Initiatives',
            link: roadmapPath(organization, 'all'),
          });
        } else if (isDocument(entity)) {
          const folderBreadbrumbs = get(
            folderBreadcrumbsSelector({
              folderId: entity.folderId,
              organizationPath: organizationPath(organization),
              topLevelInteractive: true,
            })
          );
          breadcrumbs.push(...folderBreadbrumbs);
        } else if (isRelease(entity)) {
          breadcrumbs.push({
            name: 'Releases',
            link: organizationPath(organization, 'releases'),
          });
        }
      }

      return breadcrumbs;
    },
});

export const entityTypeSelector = selectorFamily({
  key: 'EntityType',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return '';
      }
      return entityType(entity);
    },
});

export const entityTypesSelector = selectorFamily({
  key: 'EntityTypes',
  get:
    (entityIds: string[]) =>
    ({ get }) => {
      return filterNotDeletedNotNull(get(entitiesSelector(entityIds))).map(e => entityType(e));
    },
});

export const entityExistsSelector = selectorFamily({
  key: 'EntityExists',
  get:
    (entityId: string | undefined | null) =>
    ({ get }) => {
      if (!entityId) {
        return false;
      }
      const entity = get(entitySelector(entityId));
      return !!entity;
    },
});

export const entityHasActivityFeedSelector = selectorFamily({
  key: 'EntityHasActivityFeed',
  get:
    (entityId: string | undefined | null) =>
    ({ get }) => {
      if (!entityId) {
        return false;
      }
      const entity = get(entitySelector(entityId));
      return entity && (isIssue(entity) || isInitiative(entity));
    },
});

export const entityTypeByNumberSelector = selectorFamily({
  key: 'EntityTypeByNumber',
  get:
    ({ spaceId, entityNumber }: { spaceId: string; entityNumber: string }) =>
    ({ get }) => {
      const entityId = get(issueIdByNumberSelector({ spaceId, issueNumber: entityNumber }));

      if (!entityId) {
        return null;
      }

      return get(entitySelector(entityId))?.__typename ?? null;
    },
});

export function useSortEntitiesInNumericOrder() {
  return useRecoilCallback(({ snapshot }) => (entityIds: string[]) => {
    const filteredIds = entityIds.filter(id => {
      const entity = snapshot.getLoadable(entitySelector(id)).getValue();
      if (!entity) {
        return false;
      }
      return (
        !isSpaceBoundEntity(entity) ||
        snapshot.getLoadable(spaceSelector(entity?.spaceId)).getValue()
      );
    });

    return filteredIds.sort((aId: string, bId: string) => {
      const a = snapshot.getLoadable(entitySelector(aId)).getValue()!;
      const b = snapshot.getLoadable(entitySelector(bId)).getValue()!;

      if (isSpaceBoundEntity(a) && isSpaceBoundEntity(b)) {
        const aSpace = snapshot.getLoadable(spaceSelector(a.spaceId)).getValue()!;
        const bSpace = snapshot.getLoadable(spaceSelector(b.spaceId)).getValue()!;
        if (aSpace.key > bSpace.key) {
          return -1;
        }

        if (aSpace.key < bSpace.key) {
          return 1;
        }
      }

      const aNumber = parseInt(a.number.replace('T', '').replace('U', ''), 10);
      const bNumber = parseInt(b.number.replace('T', '').replace('U', ''), 10);
      return bNumber - aNumber;
    });
  });
}

export const entityDeletedSelector = selectorFamily({
  key: 'EntityDeleted',
  get:
    (entityId: string | undefined) =>
    ({ get }) => {
      if (!entityId) {
        return false;
      }
      return (get(syncEngineState(entityId)) as SyncEngineObject | undefined)?.deleted ?? false;
    },
});

export const entitiesForSpaceSelector = selectorFamily({
  key: 'EntitiesForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      return get(issuesForSpaceSelector(spaceId));
    },
});

export const entitesForOrganizationSelector = selectorFamily({
  key: 'EntitiesForOrg',
  get:
    (orgId: string) =>
    ({ get }) => {
      const issues = get(issuesForOrganizationSelector(orgId));
      const initiatives = get(initiativesForOrganizationSelector(orgId));
      const feedback = get(feedbackForOrganizationSelector(orgId));

      return [...issues, ...initiatives, ...feedback];
    },
});

export const entityIdsForOrganizationSelector = selectorFamily({
  key: 'EntityIdsForOrg',
  get:
    (orgId: string) =>
    ({ get }) => {
      return get(entitesForOrganizationSelector(orgId)).map(e => e.id);
    },
});

function getScore(get: GetRecoilValue, entity: Entity): number {
  if (isIssue(entity)) {
    const status = get(statusSelector(entity.statusId));
    if (!status) {
      return 100;
    }
    return scoreStatus(status.statusType);
  }

  if (isInitiative(entity)) {
    const status = get(initiativeStatusSelector(entity.id));
    if (!status) {
      return 100;
    }
    return scoreInitiative(status);
  }

  // lower is better, so return something big since we can't figure it out
  return 100;
}

export const sortedEntityIdsForOrganizationSelector = selectorFamily({
  key: 'SortedEntityIdsForOrg',
  get:
    (orgId: string) =>
    ({ get }) => {
      const entities = get(entitesForOrganizationSelector(orgId));
      const sortedEntities = sortBy(entities, e => getScore(get, e));
      return sortedEntities.map(e => e.id);
    },
});

export const membersForEntitySelector = selectorFamily({
  key: 'MembersForEntity',
  get:
    (entityId: string) =>
    ({ get }) => {
      const user = get(currentUserState);
      const entity = get(entitySelector(entityId));
      if (!entity || !user) {
        return [];
      }

      if (isIssue(entity)) {
        return uniq([...entity.assigneeIds, ...entity.watcherIds]);
      }

      return [];
    },
});

export const recentLocalEntities = atom<string[]>({
  key: 'recentLocalEntities',
  default: [],
  effects: [localStorageEffect('__recent')],
});

export function useUpdateRecents(id: string) {
  const updateRecent = useSetRecoilState(recentLocalEntities);
  React.useEffect(() => {
    updateRecent(previous => uniq([id, ...previous]).slice(0, 10));
  }, [id]);
}

export function useUpdateRecentsCallback() {
  const updateRecent = useSetRecoilState(recentLocalEntities);

  return (id: string) => {
    updateRecent(previous => uniq([id, ...previous]).slice(0, 10));
  };
}

export const recentLocalEntitiesSelector = selector({
  key: 'RecentLocalEntitiesSelector',
  get: ({ get }) => {
    return get(entitiesSelector(get(recentLocalEntities)));
  },
});

export const recentLocalEntityIdsSelector = equalSelector({
  key: 'RecentLocalEntityIdsSelector',
  get: ({ get }) => {
    const entities = get(recentLocalEntitiesSelector);
    return entities.map(e => e.id);
  },
  equals: isEqual,
});

export const spaceIdForEntitySelector = selectorFamily({
  key: 'SpaceIdForEntity',
  get:
    (entityId: string | undefined | null) =>
    ({ get }) => {
      if (!entityId) {
        return null;
      }
      const entity = get(entitySelector(entityId));
      if (!entity || !isSpaceBoundEntity(entity)) {
        return null;
      }
      return entity?.spaceId;
    },
});

export const spaceIdsForEntitiesSelector = selectorFamily({
  key: 'SpaceIdForEntity',
  get:
    (entityIds: string[]) =>
    ({ get }) => {
      const entities: SpaceBoundEntity[] = filterNotDeletedNotNull(
        entityIds.map(entityId => get(entitySelector(entityId)))
      ).filter(entity => isSpaceBoundEntity(entity)) as SpaceBoundEntity[];
      return uniq(entities.map(entity => entity.spaceId));
    },
});

export const filteredEntitiesSelector = equalSelectorFamily({
  key: 'FilteredEntities',
  get:
    ({
      entityIds,
      filterId,
      entityType,
    }: {
      entityIds: string[];
      filterId: string;
      entityType?: string;
    }) =>
    ({ get }) => {
      return filterEntities2(entityIds, filterId, get, { entityType });
    },
  equals: isEqual,
});

export const siblingEntitySelector = equalSelectorFamily({
  key: 'SiblingEntities',
  get:
    ({
      entityId,
      filterId,
      fixedSiblingIds,
    }: {
      entityId: string;
      filterId?: string;
      fixedSiblingIds?: string[];
    }) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity || !['Issue', 'Initiative', 'Doc', 'Feedback', 'Release'].includes(entity.__typename)) {
        return [];
      }

      if (fixedSiblingIds) {
        return get(entitiesSelector(fixedSiblingIds)).map(e => e.id);
      }

      let siblingEntities: (Issue | Initiative | Doc | Release)[] = [];
      switch (entity.__typename) {
        case 'Issue':
          siblingEntities = get(issuesForStatusSelector(entity.statusId));
          break;
        case 'Initiative':
          siblingEntities = get(initiativesForOrganizationSelector(entity.organizationId));
          break;
        case 'Doc':
          siblingEntities = get(documentsForOrganizationSelector(entity.organizationId));
          break;
        case 'Release':
          siblingEntities = get(releasesForOrganizationSelector(entity.organizationId));
          break;
      }

      if (!filterId) {
        return siblingEntities.map(entity => entity.id);
      }

      const hideSnoozed = get(hideSnoozedAtom(filterId));
      if (hideSnoozed) {
        const organizationId = isSpaceBoundEntity(entity)
          ? get(spaceSelector(entity.spaceId))?.organizationId
          : entity.organizationId;

        const orgMembership = get(currentUserMembershipState(organizationId));

        if (orgMembership?.snoozed?.length) {
          const snoozedById = keyBy(orgMembership.snoozed, 'id');
          siblingEntities = siblingEntities.filter((e: Issue | Initiative | Doc | Release) => {
            const snoozed = snoozedById[e.id];
            if (!snoozed) {
              return true;
            }
            return snoozed.snoozedUntil < Date.now().valueOf();
          });
        }
      }

      return filterEntities(
        siblingEntities.map(entity => entity.id),
        filterId,
        get
      );
    },
  equals: isEqual,
});

export const currentUserWatchingEntitySelector = selectorFamily({
  key: 'CurrentUserWatchingEntity',
  get:
    (entityId: string | undefined) =>
    ({ get }) => {
      const user = get(currentUserState);
      if (!user) {
        return false;
      }

      const entity = get(entitySelector(entityId));
      if (
        entity &&
        (isIssue(entity) || isInitiative(entity) || isDocument(entity) || isFeedback(entity))
      ) {
        return entity.watcherIds?.includes(user.id); // TODO: KM-1738gq Remove null check when initiative.watcherIds is non-optional
      }
      return false;
    },
});

export enum EntityStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export const entityStatusSelector = selectorFamily({
  key: 'EntityStatus',
  get:
    (entityId: string | undefined) =>
    ({ get }) => {
      if (!entityId) {
        return null;
      }

      const entity = get(entitySelector(entityId));
      if (!entity) {
        return null;
      }

      if (isIssue(entity)) {
        const status = get(statusSelector(entity.statusId));
        return status?.statusType === IssueStatusType.Done ||
          status?.statusType === IssueStatusType.Archived
          ? EntityStatus.Closed
          : EntityStatus.Open;
      } else if (isInitiative(entity)) {
        const status = get(initiativeStatusSelector(entity.id));
        return status === InitiativeStatus.Completed || entity.archivedAt
          ? EntityStatus.Closed
          : EntityStatus.Open;
      }
      return null;
    },
});
