import {
  chain,
  countBy,
  isEqual,
  keyBy,
  maxBy,
  orderBy,
  partition,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash';
import { GetRecoilValue, selectorFamily, useRecoilCallback } from 'recoil';
import { RoadmapInitiative } from '../../../../graphql__generated__/graphql';
import { InitiativeStatus } from '../../../shared/initiativeStatus';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { looksLikeId } from '../../../shared/utils/id';
import {
  ImpactEffortScoringAlgorithm,
  defaultImpactEffortScoring,
  effortOnlyScoring,
  generateImpactEffortScoringMetadata,
  impactOnlyScoring,
} from '../../../shared/utils/impactAndEffortLevels';
import { between } from '../../../shared/utils/sorting';
import {
  initiativeSpacesByInitiative,
  initiativeSpacesBySpace,
  initiativesByIssue,
  initiativesByOrganization,
  roadmapInitiativesByColumn,
  roadmapInitiativesByInitiative,
} from '../../../sync/__generated/indexes';
import {
  Initiative,
  InitiativeSpace,
  IssueStatusSortMode,
  IssueStatusType,
  Organization,
  Roadmap,
  RoadmapColumn,
  RoadmapColumnStatusType,
} from '../../../sync/__generated/models';
import { filterEntities as filterEntities2 } from '../../utils/filtering2';
import { equalSelectorFamily } from '../../utils/recoil';
import { Item, oneDay, utcToLocalDate } from '../../utils/timelines';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import { statusesInBoardOrderSelector } from './boards';
import { globalEffortLevelsForOrganizationSelector } from './effortLevels';
import { globalImpactLevelsForOrganizationSelector } from './impactLevels';
import { issueSelector, statusSelector } from './issues';
import { organizationPath } from './organizations';
import {
  roadmapColumnSelector,
  roadmapColumnsForRoadmapSelector,
  roadmapIdForSpaceSelector,
  roadmapSelector,
} from './roadmaps';
import { spaceSelector, spacesSelector } from './spaces';

export function isInitiative(obj: SyncEngineObject): obj is Initiative {
  return obj.__typename === 'Initiative';
}

export function isRoadmapInitiative(
  roadmapInitiative: SyncEngineObject
): roadmapInitiative is RoadmapInitiative {
  return roadmapInitiative.__typename === 'RoadmapInitiative';
}

export function initiativePath(organization: Organization, initiative: Initiative | string) {
  if (typeof initiative === 'string') {
    return organizationPath(organization, `initiatives/${initiative}`);
  }
  if (initiative.number.startsWith('T') || initiative.number.startsWith('U')) {
    return organizationPath(organization, `initiatives/${initiative.id}`);
  }
  return organizationPath(organization, `initiatives/${initiative.number}`);
}

export function scoreInitiative(status: InitiativeStatus): number {
  switch (status) {
    case InitiativeStatus.Started:
      return 1;
    case InitiativeStatus.NotStarted:
      return 2;
    case InitiativeStatus.Completed:
      return 4;
    case InitiativeStatus.Archived:
      return 5;
  }
}

export const initiativeSelector = selectorFamily({
  key: 'Initiative',
  get:
    (initiativeId: string | undefined) =>
    ({ get }) => {
      if (!initiativeId) {
        return null;
      }
      return notDeleted(get(syncEngineState(initiativeId)) as Initiative | null);
    },
});

export const initiativesSelector = selectorFamily({
  key: 'Initiatives',
  get:
    (initiativeIds: string[] | null) =>
    ({ get }) => {
      if (!initiativeIds) {
        return [];
      }
      return filterNotNull(initiativeIds.map(id => get(initiativeSelector(id))));
    },
});

export const initiativeSpaceSelector = selectorFamily({
  key: 'InitiativeSpace',
  get:
    (initiativeSpaceId: string | undefined) =>
    ({ get }) => {
      if (!initiativeSpaceId) {
        return null;
      }
      return notDeleted(get(syncEngineState(initiativeSpaceId)) as InitiativeSpace | null);
    },
});

export const initiativeByNumberSelector = selectorFamily({
  key: 'InitiativeByNumber',
  get:
    ({ organizationId, initiativeNumber }: { organizationId: string; initiativeNumber: string }) =>
    ({ get }) => {
      const initiatives = filterNotDeletedNotNull(
        get(initiativesForOrganizationSelector(organizationId))
      );

      return initiatives.find(i => i.number === initiativeNumber || i.id === initiativeNumber);
    },
});

export const initiativeIdByNumberSelector = selectorFamily({
  key: 'InitiativeIdByNumber',
  get:
    ({
      organizationId,
      initiativeNumber,
      includeDeleted,
    }: {
      organizationId: string;
      initiativeNumber: string;
      includeDeleted?: boolean;
    }) =>
    ({ get }) => {
      if (looksLikeId(initiativeNumber)) {
        return initiativeNumber;
      }

      let initiatives: Initiative[];

      if (includeDeleted) {
        const initiativesIds = get(
          indexKeyState(indexKey(initiativesByOrganization, organizationId))
        );
        initiatives = filterNotNull(initiativesIds.map(id => get(initiativeSelector(id))));
      } else {
        initiatives = get(initiativesForOrganizationSelector(organizationId));
      }

      return initiatives.find(i => i.number === initiativeNumber)?.id;
    },
});

export const initiativesForOrganizationSelector = selectorFamily({
  key: 'InitiativesForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const initiativesIds = get(
        indexKeyState(indexKey(initiativesByOrganization, organizationId))
      );
      return filterNotDeletedNotNull(initiativesIds.map(id => get(initiativeSelector(id))));
    },
});

export const publicInitiativesForOrganizationSelector = selectorFamily({
  key: 'PublicInitiativesForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const initatives = get(initiativesForOrganizationSelector(organizationId));
      const initiativeVisibilities =
        get(initiativeVisibilitiesSelector(initatives.map(i => i.id))) ?? {};

      return initatives.filter(i => initiativeVisibilities[i.id] === 'public');
    },
});

export const initiativeIdsForOrganizationSelector = selectorFamily({
  key: 'InitiativeIdsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      return get(initiativesForOrganizationSelector(organizationId)).map(
        initiative => initiative.id
      );
    },
});

export const filteredInitiativesForOrganizationSelector = selectorFamily({
  key: 'FilteredInitiativesForOrganization',
  get:
    ({
      organizationId,
      filterId,
      status,
    }: {
      organizationId: string;
      filterId: string;
      status?: InitiativeStatus;
    }) =>
    ({ get }) => {
      const initiatives = get(initiativesForOrganizationSelector(organizationId));
      const ids = initiatives
        .filter(i => {
          if (i.archivedAt) {
            return false;
          }
          // PRIVATE-INITIATIVES-AND-ROADMAPS
          const privateSpace = get(privateSpaceForInitiativeSelector(i.id));
          if (privateSpace) {
            return false;
          }

          if (status === undefined) {
            return true;
          }
          const s = get(initiativeStatusSelector(i.id));
          return s === status;
        })
        .map(i => i.id);

      return filterEntities2(ids, filterId, get);
    },
});

export const filteredArchivedInitiativesForOrganizationSelector = selectorFamily({
  key: 'FilteredArchivedInitiativesForOrganization',
  get:
    ({ organizationId, filterId }: { organizationId: string; filterId: string }) =>
    ({ get }) => {
      const initiatives = get(initiativesForOrganizationSelector(organizationId));
      const ids = orderBy(
        initiatives.filter(i => i.archivedAt),
        ['archivedAt'],
        ['desc']
      ).map(i => i.id);

      return filterEntities2(ids, filterId, get);
    },
});

export const initiativeSpacesForSpaceSelector = selectorFamily({
  key: 'InitiativeSpacesForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const initiativeSpaceIds = get(indexKeyState(indexKey(initiativeSpacesBySpace, spaceId)));
      return filterNotDeletedNotNull(
        initiativeSpaceIds.map(
          initiativeId => get(initiativeSpaceSelector(initiativeId)) as InitiativeSpace | null
        )
      );
    },
});

export const initiativesForSpaceSelector = selectorFamily({
  key: 'InitiativesForSpace',
  get:
    (spaceId: string | undefined | null) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }

      const initiativeSpaces = get(initiativeSpacesForSpaceSelector(spaceId));
      return filterNotDeletedNotNull(
        initiativeSpaces.map(indexKey => get(initiativeSelector(indexKey.initiativeId)))
      ).filter(i => i.archivedAt === null);
    },
});

export const activeInitiativesForSpaceSelector = selectorFamily({
  key: 'ActiveInitiativesForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const roadmapId = get(roadmapIdForSpaceSelector(spaceId));
      const initiatives = get(initiativesForSpaceSelector(spaceId));

      return initiatives.filter(initiative => {
        if (initiative.archivedAt) {
          return false;
        }

        const id = initiative.id;
        const roadmapInitiatives = get(roadmapInitiativesForInitiativeSelector(id));
        const columnsIds = uniq(roadmapInitiatives.map(ri => ri.columnId));

        if (!columnsIds.length && !roadmapId) {
          const status = get(initiativeStatusSelector(id));
          return status === InitiativeStatus.Started;
        }
        let columns = columnsIds.map(columnId => get(roadmapColumnSelector(columnId)));
        if (roadmapId) {
          columns = columns.filter(c => c && c.roadmapId === roadmapId);
        }
        return columns.some(c => c?.columnType === RoadmapColumnStatusType.Present);
      });
    },
});

export const filteredInitiativesForSpaceSelector = selectorFamily({
  key: 'FilteredInitiativesForSpace',
  get:
    ({
      spaceId,
      filterId,
      status,
    }: {
      spaceId: string;
      filterId: string;
      status?: InitiativeStatus;
    }) =>
    ({ get }) => {
      const initiatives = get(initiativesForSpaceSelector(spaceId));
      const ids = initiatives
        .filter(i => {
          if (i.archivedAt) {
            return false;
          }

          if (status === undefined) {
            return true;
          }
          const s = get(initiativeStatusSelector(i.id));
          return s === status;
        })
        .map(i => i.id);

      return filterEntities2(ids, filterId, get);
    },
});

export const spacesIdsForInitiativeSelector = selectorFamily({
  key: 'SpaceIdsForInitiative',
  get:
    (initiativeId: string) =>
    ({ get }) => {
      const initiativeSpaceIds = get(
        indexKeyState(indexKey(initiativeSpacesByInitiative, initiativeId))
      );
      const initiativeSpaces = filterNotDeletedNotNull(
        initiativeSpaceIds.map(initiativeId => get(initiativeSpaceSelector(initiativeId)))
      );
      return initiativeSpaces.map(i => i.spaceId);
    },
});

export const spacesIdsForInitiativesSelector = selectorFamily({
  key: 'SpaceIdsForInitiatives',
  get:
    (initiativeIds: string[]) =>
    ({ get }) => {
      return initiativeIds.reduce((result, initiativeId) => {
        const initiativeSpaceIds = get(
          indexKeyState(indexKey(initiativeSpacesByInitiative, initiativeId))
        );
        const initiativeSpaces = filterNotDeletedNotNull(
          initiativeSpaceIds.map(initiativeId => get(initiativeSpaceSelector(initiativeId)))
        );
        result[initiativeId] = initiativeSpaces.map(i => i.spaceId);
        return result;
      }, {} as Record<string, string[]>);
    },
});

export const initiativesForColumnSelector = selectorFamily({
  key: 'InitiativesForColumn',
  get:
    (columnId: string) =>
    ({ get }) => {
      const roadmapInitiatives = get(roadmapInitiativesForColumnSelector(columnId));
      return filterNotDeletedNotNull(
        roadmapInitiatives.map(roadmapInitiative =>
          get(initiativeSelector(roadmapInitiative.initiativeId))
        )
      );
    },
});

export const initiativeIdsForColumnSelector = selectorFamily({
  key: 'InitiativeIdsForColumn',
  get:
    (columnId: string) =>
    ({ get }) => {
      const initiatives = get(initiativesForColumnSelector(columnId));
      return initiatives.map(initiative => initiative.id);
    },
});

export const roadmapInitiativeSelector = selectorFamily({
  key: 'RoadmapInitiative',
  get:
    (roadmapInitiativeId: string | undefined) =>
    ({ get }) => {
      if (!roadmapInitiativeId) {
        return null;
      }
      return notDeleted(get(syncEngineState(roadmapInitiativeId)) as RoadmapInitiative | null);
    },
});

export const roadmapInitiativesSelector = selectorFamily({
  key: 'RoadmapInitiatives',
  get:
    (roadmapInitiativeIds: string[] | null) =>
    ({ get }) => {
      if (!roadmapInitiativeIds) {
        return [];
      }
      return filterNotNull(roadmapInitiativeIds.map(id => get(roadmapInitiativeSelector(id))));
    },
});

export const roadmapInitiativesForColumnSelector = selectorFamily({
  key: 'RoadmapInitiativesForColumn',
  get:
    (columnId: string) =>
    ({ get }) => {
      const roadmapInitiativeIds = get(
        indexKeyState(indexKey(roadmapInitiativesByColumn, columnId))
      );
      const roadmapInitiatives = filterNotDeletedNotNull(
        roadmapInitiativeIds.map(id => get(roadmapInitiativeSelector(id)))
      );

      return roadmapInitiatives.filter(ri => {
        const initiative = get(initiativeSelector(ri.initiativeId));
        if (!initiative) {
          return false;
        }
        return !initiative?.archivedAt;
      });
    },
});

export const roadmapInitiativesForInitiativeSelector = selectorFamily({
  key: 'RoadmapInitiativesForInitiative',
  get:
    (initiativeId: string) =>
    ({ get }) => {
      const roadmapInitiativeIds = get(
        indexKeyState(indexKey(roadmapInitiativesByInitiative, initiativeId))
      );
      return filterNotDeletedNotNull(
        roadmapInitiativeIds.map(id => get(roadmapInitiativeSelector(id)))
      );
    },
});

export const roadmapInitiativeForInitiativeAndRoadmap = selectorFamily({
  key: 'RoadmapInitiativeForInitiativeAndRoadmap',
  get:
    ({ initiativeId, roadmapId }: { initiativeId: string; roadmapId: string }) =>
    ({ get }) => {
      const roadmapInitiatives = get(roadmapInitiativesForRoadmapSelector(roadmapId));
      return roadmapInitiatives.find(ri => ri.initiativeId === initiativeId);
    },
});

export const roadmapInitiativesForRoadmapSelector = selectorFamily({
  key: 'RoadmapInitiativesForRoadmap',
  get:
    (roadmapId: string) =>
    ({ get }) => {
      const columns = get(roadmapColumnsForRoadmapSelector(roadmapId));
      return columns.flatMap(column => get(roadmapInitiativesForColumnSelector(column.id)));
    },
});

export const roadmapInitiativeIdsForColumnSelector = selectorFamily({
  key: 'RoadmapInitiativeIdsForColumn',
  get:
    (columnId: string) =>
    ({ get }) => {
      return get(roadmapInitiativesForColumnSelector(columnId)).map(ri => ri.id);
    },
});

export const filteredRoadmapInitiativeIdsForColumnSelector = selectorFamily({
  key: 'FilteredRoadmapInitiativeIdsForColumn',
  get:
    ({ columnId, filterId }: { columnId: string; filterId: string }) =>
    ({ get }) => {
      const column = get(roadmapColumnSelector(columnId));
      if (!column) {
        return [];
      }

      const roadmapInitiatives = getSortedRoadmapColumn(get, column);
      const initiativeIds = get(
        initiativeIdsForRoadmapInitiativesSelector(roadmapInitiatives.map(ri => ri.id))
      );

      const roadmapInitiativesByInitiativeId = keyBy<RoadmapInitiative>(
        roadmapInitiatives,
        'initiativeId'
      );

      const filteredInitiatives = filterEntities2(initiativeIds, filterId, get);
      return filteredInitiatives.map(id => roadmapInitiativesByInitiativeId[id].id);
    },
});

export const initiativeCountForColumn = selectorFamily({
  key: 'initiativeCountForColumn',
  get:
    ({ columnId, filterId }: { columnId: string; filterId: string }) =>
    ({ get }) => {
      const roadmapInitiatives = get(
        filteredRoadmapInitiativeIdsForColumnSelector({ columnId, filterId })
      );
      return roadmapInitiatives.length;
    },
});

export const issuesForInitaitiveSelector = selectorFamily({
  key: 'IssuesForInitiative',
  get:
    (initiativeId: string | undefined) =>
    ({ get }) => {
      if (!initiativeId) {
        return [];
      }
      const initiative = get(initiativeSelector(initiativeId));
      if (!initiative) {
        return [];
      }

      return filterNotNull(initiative.issueIds.map(id => get(issueSelector(id))));
    },
});

export const initiativeIssuesStatusCountsSelector = selectorFamily({
  key: 'InitiativeIssuesStatusCounts',
  get:
    (initiativeId: string) =>
    ({ get }) => {
      const issues = get(issuesForInitaitiveSelector(initiativeId));
      const statuses = uniq(issues.map(i => i.statusId)).map(s => get(statusSelector(s)));

      const statusesById = keyBy(statuses, 'id');

      const statusTypeCounts = countBy(issues, i => statusesById[i.statusId]?.statusType) as Record<
        IssueStatusType,
        number
      >;

      Object.values(IssueStatusType).forEach(k => {
        const type = k as IssueStatusType;
        if (statusTypeCounts[type] === undefined) {
          statusTypeCounts[type] = 0;
        }
      });
      return statusTypeCounts;
    },
});

export const initiativeIssueCountSelector = selectorFamily({
  key: 'InitiativeIssueCount',
  get:
    (initiativeId: string) =>
    ({ get }) => {
      const statusTypeCounts = get(initiativeIssuesStatusCountsSelector(initiativeId));

      return {
        completed: statusTypeCounts[IssueStatusType.Done] + statusTypeCounts[IssueStatusType.Todo],
        total:
          statusTypeCounts[IssueStatusType.Backlog] +
          statusTypeCounts[IssueStatusType.InProgress] +
          statusTypeCounts[IssueStatusType.Todo],
      };
    },
});

export const issueIdsByStatusForInitiativeSelector = equalSelectorFamily({
  key: 'IssueIdsByStatusForInitiativeSelector',
  get:
    ({ initiativeId, spaceId }: { initiativeId: string; spaceId?: string }) =>
    ({ get }) => {
      const issues = get(issuesForInitaitiveSelector(initiativeId)).filter(
        i => !spaceId || i.spaceId === spaceId
      );
      const statuses = uniq(issues.map(i => i.statusId)).map(s => get(statusSelector(s)));
      const statusesById = keyBy(statuses, 'id');

      const issueIdsByStatus = chain(issues)
        .map(issue => ({ issue, status: statusesById[issue.statusId] }))
        .filter(({ status }) => !!status)
        .groupBy(({ status }) => `${status!.statusType}-${status!.name}`)
        .orderBy(g => g[0].status!.statusType, 'desc')
        .mapKeys(k => k[0].status!.id)
        .mapValues(v => v.map(v => v.issue.id))
        .value();

      if (spaceId) {
        const spaceStatuses = filterNotNull(get(statusesInBoardOrderSelector({ spaceId })));
        // FIXME: This (above) is somehow causing the following line to throw an error where s is undefined
        return Object.fromEntries(spaceStatuses.map(s => [s.id, issueIdsByStatus[s.id] || []]));
      }
      return issueIdsByStatus;
    },
  equals: isEqual,
});

export const issueIdsForStatusAndInitiativeSelector = equalSelectorFamily({
  key: 'IssueIdsForStatusAndinitiativeSelectorsByIdsIssuesByStatus',
  get:
    ({
      initiativeId,
      spaceId,
      statusId,
    }: {
      initiativeId: string;
      spaceId?: string;
      statusId: string;
    }) =>
    ({ get }) => {
      return get(issueIdsByStatusForInitiativeSelector({ initiativeId, spaceId }))[statusId] || [];
    },
  equals: isEqual,
});

export const spaceIdsForIssuesInInitiativeSelector = selectorFamily({
  key: 'SpaceIdsForIssuesInInitiative',
  get:
    (initiativeId: string) =>
    ({ get }) => {
      const issues = get(issuesForInitaitiveSelector(initiativeId));
      const spaceIds = uniq(issues.map(i => i.spaceId));
      return spaceIds;
    },
});

export function initiativeStatusToName(status: InitiativeStatus) {
  switch (status) {
    case InitiativeStatus.Completed:
      return 'Completed';
    case InitiativeStatus.NotStarted:
      return 'Not started';
    case InitiativeStatus.Started:
      return 'Started';
    case InitiativeStatus.Archived:
      return 'Archived';
  }
}

export const initiativeStatusSelector = selectorFamily({
  key: 'InitiativeStatus',
  get:
    (initiativeId: string) =>
    ({ get }) => {
      if (!initiativeId) {
        return InitiativeStatus.NotStarted;
      }

      const statusTypeCounts = get(initiativeIssuesStatusCountsSelector(initiativeId));
      const totalCount = Object.values(statusTypeCounts).reduce((a, b) => a + b, 0);

      if (
        statusTypeCounts[IssueStatusType.Done] + statusTypeCounts[IssueStatusType.Archived] ===
          totalCount &&
        totalCount > 0
      ) {
        return InitiativeStatus.Completed;
      }

      if (statusTypeCounts[IssueStatusType.InProgress] > 0) {
        return InitiativeStatus.Started;
      }

      return InitiativeStatus.NotStarted;
    },
});

export const initiativeStatusCountsForOrganizationSelector = selectorFamily({
  key: 'InitiativeStatusCountsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const initiatives = get(initiativesForOrganizationSelector(organizationId));
      const [archived, unarchived] = partition(initiatives, i => !!i.archivedAt);

      const statusCounts = countBy(unarchived, i => get(initiativeStatusSelector(i.id)));
      statusCounts[InitiativeStatus.Archived] = archived.length;
      return statusCounts;
    },
});

export function initiativeStatusToIcon(status: InitiativeStatus) {
  switch (status) {
    case InitiativeStatus.Completed:
      return 'status_initiative_done';
    case InitiativeStatus.NotStarted:
      return 'status_initiative_next';
    case InitiativeStatus.Started:
      return 'status_initiative_now';
    case InitiativeStatus.Archived:
      return 'archive';
  }
}

export function useGetInitiativeIdsForColumn(columnId: string) {
  const callback = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(initiativeIdsForColumnSelector(columnId));
      },
    [columnId]
  );
  return () => callback().getValue();
}

export function getSortedRoadmapColumn(get: GetRecoilValue, roadmapColumn: RoadmapColumn) {
  const sortMode = roadmapColumn.sortMode ?? IssueStatusSortMode.Manual;
  const roadmapInitaitives = get(roadmapInitiativesForColumnSelector(roadmapColumn.id));
  const initiatives = filterNotNull(
    roadmapInitaitives.map(ri => get(initiativeSelector(ri.initiativeId)))
  );

  const roadmapInitiativesByInitiativeId = keyBy(roadmapInitaitives, 'initiativeId');

  const organizationId = initiatives[0]?.organizationId;
  if (!organizationId) {
    return [];
  }
  let scoreIssue: ImpactEffortScoringAlgorithm | null = null;

  switch (sortMode) {
    case IssueStatusSortMode.Manual:
      break;
    case IssueStatusSortMode.LastStatusAsc:
      initiatives.sort((a: Initiative, b: Initiative) => {
        const roadmapInitiativeA = roadmapInitiativesByInitiativeId[a.id];
        const roadmapInitiativeB = roadmapInitiativesByInitiativeId[b.id];
        return (
          (roadmapInitiativeB.lastColumnUpdate ?? 0) - (roadmapInitiativeA.lastColumnUpdate ?? 0)
        );
      });
      break;
    case IssueStatusSortMode.LastStatusDesc:
      initiatives.sort((a: Initiative, b: Initiative) => {
        const roadmapInitiativeA = roadmapInitiativesByInitiativeId[a.id];
        const roadmapInitiativeB = roadmapInitiativesByInitiativeId[b.id];
        return (
          (roadmapInitiativeA.lastColumnUpdate ?? 0) - (roadmapInitiativeB.lastColumnUpdate ?? 0)
        );
      });
      break;
    case IssueStatusSortMode.CreatedAsc:
      initiatives.sort((a: Initiative, b: Initiative) => b.createdAt - a.createdAt);
      break;
    case IssueStatusSortMode.CreatedDesc:
      initiatives.sort((a: Initiative, b: Initiative) => a.createdAt - b.createdAt);
      break;
    case IssueStatusSortMode.UpdatedDesc:
      initiatives.sort((a: Initiative, b: Initiative) => a.updatedAt - b.updatedAt);
      break;
    case IssueStatusSortMode.UpdatedAsc:
      initiatives.sort((a: Initiative, b: Initiative) => b.updatedAt - a.updatedAt);
      break;
    case IssueStatusSortMode.EffortDesc:
    case IssueStatusSortMode.EffortAsc:
      scoreIssue = effortOnlyScoring;
      break;
    case IssueStatusSortMode.ImpactDesc:
    case IssueStatusSortMode.ImpactAsc:
      scoreIssue = impactOnlyScoring;
      break;
    case IssueStatusSortMode.ImpactEffortDesc:
    case IssueStatusSortMode.ImpactEffortAsc:
      scoreIssue = defaultImpactEffortScoring;
      break;
  }

  if (scoreIssue) {
    const impactLevels = get(globalImpactLevelsForOrganizationSelector(organizationId));
    const effortLevels = get(globalEffortLevelsForOrganizationSelector(organizationId));

    const scoringMetadata = generateImpactEffortScoringMetadata({ impactLevels, effortLevels });

    initiatives.sort((a: Initiative, b: Initiative) => {
      const aImpact = impactLevels.find(impact => a.impactId === impact.id);
      const aEffort = effortLevels.find(effort => a.effortId === effort.id);

      // TODO: remove the ternary when we upgrade typescript
      const aScore = scoreIssue
        ? scoreIssue(scoringMetadata, {
            impactLevels,
            effortLevels,
            impact: aImpact,
            effort: aEffort,
          })
        : 0;

      const bImpact = impactLevels.find(impact => b.impactId === impact.id);
      const bEffort = effortLevels.find(effort => b.effortId === effort.id);

      // TODO: remove the ternary when we upgrade typescript
      const bScore = scoreIssue
        ? scoreIssue(scoringMetadata, {
            impactLevels,
            effortLevels,
            impact: bImpact,
            effort: bEffort,
          })
        : 0;

      if (
        [
          IssueStatusSortMode.ImpactEffortAsc,
          IssueStatusSortMode.ImpactAsc,
          IssueStatusSortMode.EffortAsc,
        ].includes(sortMode)
      ) {
        return bScore - aScore;
      }
      return aScore - bScore;
    });
  }

  return initiatives.map(i => roadmapInitiativesByInitiativeId[i.id]);
}

export const roadmapInitiativesByColumnSelecctor = equalSelectorFamily({
  key: 'RoadmapInitiativesByColumn',
  get:
    ({ roadmapId, filterId }: { roadmapId: string | undefined; filterId: string }) =>
    ({ get }) => {
      const roadmap = get(roadmapSelector(roadmapId));
      if (!roadmap) {
        return {
          filteredRoadmapInitiativeIds: {},
          roadmapInitiativeIdsByColumn: {},
        };
      }

      const columns = get(roadmapColumnsForRoadmapSelector(roadmapId));

      const roadmapInitiativeIdsByColumn: Record<string, string[]> = {};
      const filteredRoadmapInitiativeIds: Record<string, string[]> = {};

      for (const column of columns) {
        const ids = getSortedRoadmapColumn(get, column).map(ri => ri.id);
        roadmapInitiativeIdsByColumn[column.id] = ids;
        const filteredIds = get(
          filteredRoadmapInitiativeIdsForColumnSelector({ columnId: column.id, filterId })
        );
        filteredRoadmapInitiativeIds[column.id] = filteredIds;
      }

      return {
        roadmapInitiativeIdsByColumn,
        filteredRoadmapInitiativeIds,
      };
    },
  equals: isEqual,
});

export const initiativeIdForRoadmapInitiativeSelector = selectorFamily({
  key: 'InitiativeIdForRoadmapInitiative',
  get:
    (roadmapInitiativeId: string | undefined | null) =>
    ({ get }) => {
      if (!roadmapInitiativeId) {
        return null;
      }
      const roadmapIntitaive = get(roadmapInitiativeSelector(roadmapInitiativeId));
      return roadmapIntitaive?.initiativeId;
    },
});

export const initiativeIdsForRoadmapInitiativesSelector = selectorFamily({
  key: 'InitaitiveIdsForRoadmapInitiatives',
  get:
    (roadmapInitiativeIds: string[]) =>
    ({ get }) => {
      return filterNotNull(
        roadmapInitiativeIds.map(id => get(initiativeIdForRoadmapInitiativeSelector(id)))
      );
    },
});

export const initiativesForIssueSelector = selectorFamily({
  key: 'InitiativesForIssue',
  get:
    (issueId: string) =>
    ({ get }) => {
      const initiativeIds = get(indexKeyState(indexKey(initiativesByIssue, issueId)));
      return filterNotDeletedNotNull(
        initiativeIds.map(initiativeId => get(initiativeSelector(initiativeId)))
      );
    },
});

export const initiativeIdsForIssueSelector = equalSelectorFamily({
  key: 'InitiativesForIssue',
  get:
    (issueId: string) =>
    ({ get }) => {
      const initiatives = get(initiativesForIssueSelector(issueId));
      return initiatives.map(t => t.id);
    },
  equals: isEqual,
});

export const roadmapsWithStatusForInitiativeSelector = selectorFamily({
  key: 'InitaitiveIdsForRoadmapInitiatives',
  get:
    (initiativeId: string) =>
    ({ get }) => {
      const roadmapInitiatives = get(roadmapInitiativesForInitiativeSelector(initiativeId));
      const columnsIds = uniq(roadmapInitiatives.map(ri => ri.columnId));

      const roadmapColumns = filterNotDeletedNotNull(
        columnsIds.map(id => get(roadmapColumnSelector(id)))
      );
      const roadmapsWithStatus = sortBy(
        roadmapColumns
          .map(column => ({
            column,
            roadmap: get(roadmapSelector(column.roadmapId)),
          }))
          .filter(({ roadmap }) => !!roadmap && !roadmap.deleted) as {
          column: RoadmapColumn;
          roadmap: Roadmap;
        }[],
        r => r.roadmap.sort
      );

      return roadmapsWithStatus;
    },
});

export function useInitiativeIdsInColumn() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (columnId: string) => {
        const ret = snapshot
          .getLoadable(roadmapInitiativesForColumnSelector(columnId))
          .getValue()
          .map(ri => ri.initiativeId);
        return ret;
      },
    []
  );
}

export const initiativesToRoadmapStateSelector = selectorFamily({
  key: 'InitiativesToRoadmapsStateSelector',
  get:
    (initiativeIds: string[]) =>
    ({ get }) => {
      const roadmapIdsByInitiativeId = initiativeIds.reduce((result, id) => {
        result[id] = [];
        return result;
      }, {} as Record<string, string[]>);
      for (const initiativeId of initiativeIds) {
        const roadmapInitiatives = get(roadmapInitiativesForInitiativeSelector(initiativeId));
        const columnsIds = uniq(roadmapInitiatives.map(ri => ri.columnId));

        const roadmapColumns = filterNotDeletedNotNull(
          columnsIds.map(id => get(roadmapColumnSelector(id)))
        );
        const roadmapIds = uniq(roadmapColumns.map(c => c.roadmapId));
        roadmapIdsByInitiativeId[initiativeId] = roadmapIds;
      }
      return roadmapIdsByInitiativeId;
    },
});

export function compareInitiatives(get: GetRecoilValue, a: Initiative, b: Initiative): number {
  const statusScores: Record<InitiativeStatus, number> = {
    [InitiativeStatus.Started]: 4,
    [InitiativeStatus.NotStarted]: 3,
    [InitiativeStatus.Completed]: 2,
    [InitiativeStatus.Archived]: 1,
  };

  const aStatus = get(initiativeStatusSelector(a.id));
  const bStatus = get(initiativeStatusSelector(b.id));
  const diff = statusScores[bStatus] - statusScores[aStatus];

  if (diff === 0) {
    return parseInt(b.number, 10) - parseInt(a.number, 10);
  }

  return diff;
}

export const initiativeVisibilitySelector = selectorFamily({
  key: 'InitiativeVisibility',
  get:
    (initiativeId: string | undefined) =>
    ({ get }) => {
      const initiative = get(initiativeSelector(initiativeId));
      if (!initiative) {
        return null;
      }

      const spaceIds = get(spacesIdsForInitiativeSelector(initiative.id));
      const spaces = get(spacesSelector(spaceIds));
      return spaces.some(s => s.private) ? 'private' : 'public';
    },
});

// PRIVATE-INITIATIVES-AND-ROADMAPS
export const privateSpaceForInitiativeSelector = selectorFamily({
  key: 'PrivateSpaceForInitiative',
  get:
    (initiativeId: string | undefined) =>
    ({ get }) => {
      const initiative = get(initiativeSelector(initiativeId));
      if (!initiative) {
        return null;
      }

      const spaceIds = get(spacesIdsForInitiativeSelector(initiative.id));
      const spaces = get(spacesSelector(spaceIds));
      return spaces.find(s => s.private)?.id ?? null;
    },
});

export const initiativeVisibilitiesSelector = selectorFamily({
  key: 'InitiativeVisibilities',
  get:
    (initiativeIds: string[] | null) =>
    ({ get }) => {
      if (!initiativeIds) {
        return {};
      }

      const result: Record<string, 'public' | 'private' | null> = {};
      for (const initiativeId of initiativeIds) {
        result[initiativeId] = get(initiativeVisibilitySelector(initiativeId));
      }

      return result;
    },
});

export const initiativeSuggestionsForOrgSelector = equalSelectorFamily({
  key: 'InitiativeSuggestions',
  get:
    ({
      organizationId,
      spaceId,
    }: {
      organizationId: string | undefined;
      spaceId: string | undefined;
    }) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }

      const space = get(spaceSelector(spaceId));
      const isPrivate = !!space?.private;

      const initiatives = get(initiativesForOrganizationSelector(organizationId))
        .filter(i => !i.archivedAt)
        .filter(i => {
          // PRIVATE-INITIATIVES-AND-ROADMAPS
          const privateSpaceId = get(privateSpaceForInitiativeSelector(i.id));
          return (isPrivate && privateSpaceId === spaceId) || (!isPrivate && !privateSpaceId);
        })
        .sort((a, b) => compareInitiatives(get, a, b));
      return initiatives.map(initiative => ({
        id: initiative.id,
        title: initiative.title,
        color: initiative.color,
        horizon: null,
      }));
    },
  equals: isEqual,
});

export const archivedInitiativesSelector = equalSelectorFamily({
  key: 'ArchivedInitiatives',
  get:
    ({ spaceId, filterId }: { spaceId: string; filterId: string }) =>
    ({ get }) => {
      const initiativeSpaces = get(initiativeSpacesForSpaceSelector(spaceId));
      const archivedInitiatives = orderBy(
        filterNotDeletedNotNull(
          initiativeSpaces.map(indexKey => get(initiativeSelector(indexKey.initiativeId)))
        ).filter(i => i.archivedAt !== null),
        ['archivedAt'],
        ['desc']
      );

      return filterEntities2(
        archivedInitiatives.map(i => i.id),
        filterId,
        get
      );
    },
  equals: isEqual,
});

// PRIVATE-INITIATIVES-AND-ROADMAPS
export function useFilterInitiativesForSpace() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (initiatives: Initiative[], spaceId: string | undefined) => {
        const space = snapshot.getLoadable(spaceSelector(spaceId)).getValue();
        const isPrivate = !!space?.private;
        return initiatives.filter(i => {
          const privateSpaceId = snapshot
            .getLoadable(privateSpaceForInitiativeSelector(i.id))
            .getValue();
          return (isPrivate && privateSpaceId === spaceId) || (!isPrivate && !privateSpaceId);
        });
      },
    []
  );
}

export function useFindSharedInitiatives() {
  return useRecoilCallback(({ snapshot }) => (spaceId: string) => {
    const initiativeSpaces = snapshot
      .getLoadable(initiativeSpacesForSpaceSelector(spaceId))
      .getValue();
    const initatives = snapshot
      .getLoadable(initiativesSelector(initiativeSpaces.map(i => i.initiativeId)))
      .getValue();
    return initatives.filter(i => {
      const spaces = snapshot.getLoadable(spacesIdsForInitiativeSelector(i.id)).getValue();
      return spaces.length > 1;
    });
  });
}

// TIMELINE

export interface InitiativeWithDates extends Initiative {
  startDate: number;
  dueDate: number;
}

export const initiativesForTimelineSelector = selectorFamily({
  key: 'InitiativesWithDatesForOrganization',
  get:
    (roadmapId: string) =>
    ({ get }) => {
      const roadmapInitiatives = get(roadmapInitiativesForRoadmapSelector(roadmapId));
      const initiativesWithRoadmapInitiative = roadmapInitiatives.map(ri => {
        const initiative = get(initiativeSelector(ri.initiativeId));
        return initiative ? { ...initiative, sort: ri.sort } : null;
      });
      return initiativesWithRoadmapInitiative
        .filter(i => i !== null && i.startDate && i.dueDate)
        .map(i => ({
          ...i,
          dueDate: utcToLocalDate(i!.dueDate!) + oneDay - 1, // end of current day, not start
          startDate: utcToLocalDate(i!.startDate!),
        })) as InitiativeWithDates[];
    },
});

export const itemsAndGroupsForTimelineSelector = selectorFamily({
  key: 'ItemsAndGroupsForTime',
  get:
    ({ edit, roadmapId }: { edit: string[]; roadmapId: string }) =>
    ({ get }) => {
      const initiatives = get(initiativesForTimelineSelector(roadmapId));
      const items: Item[] = [];

      const sortedInitiatives = sortBy(initiatives, ['sort', 'startDate']);

      let previousSort = null;
      let group = 0;

      for (const initiative of sortedInitiatives) {
        const startDate = initiative.startDate;
        const endDate = initiative.dueDate;

        if (initiative.sort != previousSort) {
          group++;
        }
        items.push({
          id: initiative.id,
          group: group * 1000,
          title: initiative.title,
          start_time: startDate,
          end_time: endDate,
          color: initiative.color,
          object: initiative,
          edit: edit.includes(initiative.id),
          canMove: !edit.includes(initiative.id),
        });
        previousSort = initiative.sort;
      }

      const groups = uniqBy(items, 'group').map(v => ({ id: v.group, sort: v.object?.sort }));
      if (groups.length) {
        groups.push({
          id: (groups.length + 1) * 1000,
          sort: between({ after: maxBy(groups, 'sort')?.sort }),
        });
      } else {
        groups.unshift({ id: 0, sort: between({}) });
      }

      return { items, groups };
    },
});

export function useGetInitiativeForTimelineObject() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (entityId: string, roadmapId: string) => {
        const object = snapshot
          .getLoadable(syncEngineState(entityId))
          .getValue() as Initiative | null;

        if (object?.__typename === 'Initiative') {
          const roadmapInitiatives = snapshot
            .getLoadable(roadmapInitiativesForRoadmapSelector(roadmapId))
            .getValue();
          const ri = roadmapInitiatives.find(ri => ri.initiativeId === entityId);

          return {
            ...object,
            startDate: object.startDate ? utcToLocalDate(object.startDate) : null,
            dueDate: object.dueDate ? utcToLocalDate(object.dueDate) + oneDay - 1 : null, //end of day, not start
            sort: ri?.sort,
          };
        } else {
          return object;
        }
      },
    []
  );
}
