import cn from 'classnames';
import * as clipboard from 'clipboard-polyfill';
import { sortBy } from 'lodash';
import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Descendant, Element as SlateElement } from 'slate';
import { flattenDocument } from '../../../shared/slate/utils';
import { capitalize } from '../../../shared/utils/utils';
import {
  Cycle as CycleModel,
  Doc,
  Feedback,
  Initiative,
  Insight as InsightModel,
  MemberRole,
  Release,
  Space,
} from '../../../sync/__generated/models';
import { WorkItem } from '../../../sync/types';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { StaticEntityMention } from '../../slate/elements/entityMention';
import { useSerializeToMarkdown } from '../../slate/hooks/useSerializeToMarkdown';
import { StaticSlateDocument } from '../../slate/staticSlate';
import { DocumentLike, Elements, ParagraphElement } from '../../slate/types';
import { useAddEntitiesToCycle, useRemoveEntitiesFromCycle } from '../../syncEngine/actions/cycles';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
  useRemoveAssigneesFromEntities,
  useRemoveLabelsFromEntities,
  useUpdateEntities,
} from '../../syncEngine/actions/entities';
import {
  currentCycleSelector,
  cycleEntityForCycleAndEntity,
  cyclePath,
  cycleSelector,
} from '../../syncEngine/selectors/cycles';
import { effortSelector } from '../../syncEngine/selectors/effortLevels';
import {
  entityPath,
  entitySelector,
  spaceForEntitySelector,
} from '../../syncEngine/selectors/entities';
import { feedbackSelector, isFeedback, useFeedbackPath } from '../../syncEngine/selectors/feedback';
import { impactSelector } from '../../syncEngine/selectors/impactLevels';
import { insightsForEntity } from '../../syncEngine/selectors/insights';
import { isInitiative } from '../../syncEngine/selectors/intiatives';
import { labelSelector } from '../../syncEngine/selectors/labels';
import { isRelease } from '../../syncEngine/selectors/releases';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import {
  todoCountForEntitySelector,
  todosForEntitySelector,
} from '../../syncEngine/selectors/todos';
import {
  currentUserMembershipState,
  usersAndMemberSelector,
} from '../../syncEngine/selectors/users';
import { renderDate } from '../../utils/datetime';
import { isDescendant } from '../../utils/dom';
import { writeToClipboard } from '../clipboardText';
import ExternalLink from '../externalLink';
import LinkButton from '../linkButton';
import { toast } from '../toast';
import { Button, ButtonSize, ButtonStyle, IconButton } from './button';
import { CompanyPerson } from './companyPerson';
import styles from './entityMetadata.module.scss';
import { Hotkey } from './hotkey';
import { Icon, IconSize } from './icon';
import {
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useKeyNavigationElement,
} from './keyNavigation';
import { CycleMenu } from './menu/cycleMenu';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './menu/dropdownMenu';
import { EffortMenu } from './menu/effortMenu';
import { ImpactMenu } from './menu/impactMenu';
import { AvatarSize } from './metadata/avatar';
import AvatarGroup from './metadata/avatarGroup';
import Cycle, { CycleIcon } from './metadata/cycle';
import Effort from './metadata/effort';
import Impact from './metadata/impact';
import Insight from './metadata/insight';
import Label from './metadata/label';
import { MetadataSize } from './metadata/size';
import TodoCount from './metadata/todoCount';
import Watching from './metadata/watching';
import { LabelPicker, entitiesToLabelPickerState } from './pickers/labelPicker';
import { MemberPicker, entitiesToMemberPickerState } from './pickers/memberPicker';
import Placeholder from './placeholder';
import { DisableTooltips, FollowTooltipContent, MetadataTooltip, Tooltip } from './tooltip';

export function extractFirstParagraph(content: Descendant[]): Descendant[] {
  const firstParagraph = content.find(c =>
    SlateElement.isElementType<ParagraphElement>(c, Elements.Paragraph)
  ) as ParagraphElement | undefined;
  if (!firstParagraph) {
    return content;
  }

  const strippedContent: Descendant[] = [];

  for (const c of firstParagraph.children) {
    if (c.text) {
      if (c.text.includes('\n')) {
        const lineSplitNode = { ...c, text: c.text.split('\n', 1)[0] };
        strippedContent.push(lineSplitNode);
        return strippedContent;
      } else {
        strippedContent.push(c);
      }
    } else {
      strippedContent.push(c);
    }
  }

  return strippedContent;
}

export function EntityMembers({
  entity,
  max,
  interactable,
  size,
}: {
  entity: WorkItem | Feedback | Initiative | Release;
  max?: number | null;
  interactable?: boolean;
  size?: AvatarSize;
}) {
  const organization = useOrganization();

  let memberIds;
  if (isInitiative(entity) || isRelease(entity)) {
    memberIds = entity.memberIds;
  } else if (isFeedback(entity)) {
    memberIds = entity.ownerIds;
  } else {
    memberIds = entity.assigneeIds;
  }

  const userMembers = useRecoilValue(
    usersAndMemberSelector({ organizationId: organization.id, userIds: memberIds })
  );
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const addAssignees = useAddAssigneesToEntities();
  const removeAssignees = useRemoveAssigneesFromEntities();

  if (!userMembers.length) {
    return null;
  }

  const content = (
    <AvatarGroup
      disableTooltip={!interactable}
      max={max}
      size={size}
      avatarData={userMembers.map(user => ({
        name: user.name || user.username,
        img: user.avatar,
        id: user.id,
        deactivated: user.member?.deactivated,
        invited: user.member?.invited,
        guest: user.member?.role === MemberRole.Guest,
      }))}
    />
  );

  if (!interactable) {
    return content;
  }

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        asChild
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {content}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
        className="menuPicker menuMedium"
      >
        <MemberPicker
          state={entitiesToMemberPickerState([entity])}
          onMemberAdded={(issueIds, memberId) => {
            addAssignees(issueIds, [memberId]);
          }}
          onMemberRemoved={(issueIds, memberId) => {
            removeAssignees(issueIds, [memberId]);
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function EntityLabel({
  id,
  entity,
  size,
  interactable,
  orgLevel,
  className,
}: {
  id: string;
  entity: WorkItem | Initiative;
  size: MetadataSize;
  interactable?: boolean;
  orgLevel?: boolean;
  className?: string;
}) {
  const label = useRecoilValue(labelSelector(id));
  const addLabelsToEntities = useAddLabelsToEntities();
  const removeLabelsFromEntities = useRemoveLabelsFromEntities();
  const [menuOpen, _setMenuOpen] = React.useState(false);

  const onDone = React.useCallback(() => {
    if (removeSelfRef.current && label) {
      removeLabelsFromEntities([entity.id], [label.id]);
    }
  }, [entity.id, label, removeLabelsFromEntities]);

  const setMenuOpen = React.useCallback(
    (open: boolean) => {
      _setMenuOpen(open);
      if (!open) {
        onDone();
      }
    },
    [onDone]
  );
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const [removeSelf, setRemoveSelf] = React.useState(false);
  const removeSelfRef = React.useRef(false);

  if (!label) {
    return null;
  }
  const state = entitiesToLabelPickerState([entity]);
  if (removeSelf) {
    state[entity.id] = state[entity.id].filter(t => t !== label.id);
  }

  const content = (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={cn({ invisible: removeSelf }, className)}
    >
      <Label
        noHover={!interactable}
        key={label.id}
        name={label.name}
        color={label.color}
        size={size}
      />
    </div>
  );

  if (!interactable) {
    return content;
  }

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>{content}</DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuMedium"
      >
        <LabelPicker
          state={state}
          orgLevel={orgLevel}
          onLabelAdded={(issueIds, labelId) => {
            if (labelId === label.id) {
              removeSelfRef.current = false;
              setRemoveSelf(false);
            } else {
              addLabelsToEntities(issueIds, [labelId]);
            }
          }}
          onLabelRemoved={(issueIds, labelId) => {
            if (labelId === label.id) {
              removeSelfRef.current = true;
              setRemoveSelf(true);
            } else {
              removeLabelsFromEntities(issueIds, [labelId]);
            }
          }}
          onDone={() => {
            onDone();
            closeMenu();
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function EntityLabels({
  entity,
  size,
  interactable,
  orgLevel,
  className,
}: {
  entity: WorkItem | Initiative;
  size: MetadataSize;
  interactable?: boolean;
  orgLevel?: boolean;
  className?: string;
}) {
  return (
    <>
      {entity.labelIds.map(labelId => (
        <EntityLabel
          orgLevel={orgLevel}
          interactable={interactable}
          entity={entity}
          key={labelId}
          id={labelId}
          size={size}
          className={className}
        />
      ))}
    </>
  );
}

export function EntityImpact({
  entity,
  size = MetadataSize.Small,
  interactable,
}: {
  entity: WorkItem | Initiative;
  size?: MetadataSize;
  interactable?: boolean;
}) {
  const updateEntities = useUpdateEntities();

  const impact = useRecoilValue(impactSelector(entity.impactId ?? ''));
  if (!impact) {
    return null;
  }

  const content = (
    <Impact
      color={impact.color}
      size={size}
      longName={impact.name}
      shortName={impact.abbrevation}
      disableTooltip={!interactable}
    />
  );

  if (!interactable) {
    return content;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {content}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuTiny"
      >
        <ImpactMenu
          onSelect={impactId => updateEntities([entity.id], { impactId })}
          impactId={entity.impactId}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function EntityEffort({
  entity,
  size = MetadataSize.Small,
  interactable,
}: {
  entity: WorkItem | Initiative;
  size?: MetadataSize;
  interactable?: boolean;
}) {
  const updateEntities = useUpdateEntities();

  const effort = useRecoilValue(effortSelector(entity.effortId ?? ''));
  if (!effort) {
    return null;
  }

  const content = (
    <Effort
      color={effort.color}
      size={size}
      longName={effort.name}
      shortName={effort.abbrevation}
      disableTooltip={!interactable}
    />
  );

  if (!interactable) {
    return content;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {content}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuTiny"
      >
        <EffortMenu
          onSelect={effortId => updateEntities([entity.id], { effortId })}
          effortId={entity.effortId}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function EntityWatching({
  entity,
  noBorder,
}: {
  entity: WorkItem | Initiative | Doc | Release;
  noBorder?: boolean;
}) {
  const user = useCurrentUser();
  if (entity.watcherIds?.includes(user.id)) {
    return <Watching noBorder={noBorder} />;
  }

  return null;
}

export function EntitySnoozed({ entity }: { entity: WorkItem | Initiative }) {
  const organization = useOrganization();
  const orgMember = useRecoilValue(currentUserMembershipState(organization.id));

  const snoozed = orgMember?.snoozed?.find(
    snooze => snooze.id === entity.id && snooze.snoozedUntil > Date.now().valueOf()
  );

  if (snoozed) {
    return (
      <Tooltip
        content={`Snoozed until ${renderDate(snoozed.snoozedUntil, {
          compact: true,
        })}`}
      >
        <div style={{ display: 'inherit' }}>
          <Icon size={IconSize.Size16} icon="snooze" className="grayIcon" />
        </div>
      </Tooltip>
    );
  }
  return null;
}

function TodoToolTipContent({ entity }: { entity: WorkItem | Initiative }) {
  const todos = useRecoilValue(todosForEntitySelector(entity.id));
  const items = sortBy(todos, t => t.sort).map(todo => ({
    type: 'smartTodo',
    todoId: todo.id,
    children: extractFirstParagraph(todo.todoContents),
  })) as Descendant[];

  return (
    <DisableTooltips>
      <span className={styles.header}>Todos</span>
      <div className={cn(styles.todos)}>
        <StaticSlateDocument value={items} />
      </div>
    </DisableTooltips>
  );
}

export function EntityTodos({
  entity,
  interactable,
}: {
  entity: WorkItem | Initiative;
  interactable?: boolean;
}) {
  const { completed, total } = useRecoilValue(todoCountForEntitySelector(entity.id));
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  if (total === 0) {
    return null;
  }

  const content = (
    <TodoCount
      total={total}
      ref={ref}
      completed={completed}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
      }}
    />
  );
  if (!interactable) {
    return content;
  }

  return (
    <MetadataTooltip
      open={open}
      onPointerDownOutside={e => {
        if (
          ref.current &&
          e.target &&
          e.target instanceof Element &&
          isDescendant(ref.current, e.target)
        ) {
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      }}
      onOpenChange={setOpen}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
      side="bottom"
      align="start"
      content={<TodoToolTipContent entity={entity} />}
    >
      {content}
    </MetadataTooltip>
  );
}

function InsightBlock({
  insight,
  entityId,
  onClose,
}: {
  insight: InsightModel;
  entityId: string;
  onClose: () => void;
}) {
  const organization = useOrganization();
  const feedback = useRecoilValue(feedbackSelector(insight.feedbackId));
  const entity = useRecoilValue(entitySelector(entityId));
  const space = useRecoilValue(spaceForEntitySelector(entityId));
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [clamped, setClamped] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setClamped(true);
    }
  }, []);

  const contents = React.useMemo(() => {
    return JSON.parse(insight.contents) as DocumentLike;
  }, [insight.contents]);

  if (!feedback || !entity) {
    return null;
  }

  const link = {
    pathname: entityPath(organization, space, entity),
    search: `insightId=${insight.id}`,
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
    },
  };

  return (
    <Link to={link} onClick={onClose}>
      <div className={cn('col', styles.insight, styles.limitHeight, 'fs-exclude')}>
        <div className="row grayed fullWidth metadataGap">
          <CompanyPerson size={AvatarSize.Size20} feedbackId={feedback.id} />
          <span className={styles.date}>{capitalize(renderDate(insight.createdAt))}</span>
        </div>
        <StaticSlateDocument
          ref={ref}
          value={contents}
          className={styles.context}
          voidBlockPlaceholders
        />
        {clamped && (
          <LinkButton size={ButtonSize.Small} className="grayed" to={link}>
            Show more
          </LinkButton>
        )}
      </div>
    </Link>
  );
}

function InsightTooltipContent({
  insights,
  entityId,
  onClose,
}: {
  insights: InsightModel[];
  entityId: string;
  onClose: () => void;
}) {
  return (
    <div className={styles.container}>
      <span className={styles.header}>Insights</span>
      <div className={styles.insights}>
        {insights.map(i => (
          <InsightBlock key={i.id} insight={i} entityId={entityId} onClose={onClose} />
        ))}
      </div>
    </div>
  );
}

export function EntityInsights({
  entity,
  size,
  interactable,
}: {
  entity: WorkItem | Initiative | Doc | Feedback;
  size?: MetadataSize;
  interactable?: boolean;
}) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const closeTooltip = React.useCallback(() => setTooltipOpen(false), [setTooltipOpen]);
  const insights = useRecoilValue(insightsForEntity(entity.id));
  const history = useHistory();
  const space = useRecoilValue(spaceForEntitySelector(entity.id));
  const organization = useOrganization();
  if (!insights.length) {
    return null;
  }

  const link = {
    pathname: entityPath(organization, space, entity) ?? '',
    search: `insightId=${insights[0].id}`,
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: entity.id,
    },
  };

  const content = (
    <Insight
      onClick={e => {
        if (e.button === 0) {
          e.preventDefault();
          e.stopPropagation();
          history.push(link);
        }
      }}
      size={size}
    />
  );

  if (!interactable) {
    return content;
  }

  return (
    <MetadataTooltip
      content={
        <InsightTooltipContent insights={insights} entityId={entity.id} onClose={closeTooltip} />
      }
      side="bottom"
      align="start"
      open={tooltipOpen}
      onOpenChange={setTooltipOpen}
    >
      {content}
    </MetadataTooltip>
  );
}

export function EntityCycleTooltip({ cycle, ghost }: { cycle: CycleModel; ghost?: boolean }) {
  const organization = useOrganization();
  const space = useRecoilValue(spaceForEntitySelector(cycle.id));

  const link = {
    pathname: cyclePath(organization, space!, cycle),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
    },
  };

  if (!ghost) {
    return (
      <FollowTooltipContent link={link}>
        <CycleIcon cycleStatus={cycle.cycleStatus} />
        <span className="ml6 bodyM">{cycle.title}</span>
      </FollowTooltipContent>
    );
  }
  return (
    <div>
      Todos in<LinkButton to={link}>{cycle.title}</LinkButton>
    </div>
  );
}

function EntityCycleMenu({
  entityId,
  space,
  inCurrentCycle,
  inUpcomingCycle,
  closeMenu,
}: {
  entityId: string;
  space: Space;
  inCurrentCycle: boolean;
  inUpcomingCycle: boolean;
  closeMenu: () => void;
}) {
  const addEntitiesToCycle = useAddEntitiesToCycle();
  const removeEntitiesFromCycle = useRemoveEntitiesFromCycle();

  return (
    <CycleMenu
      inCurrentCycle={inCurrentCycle}
      inUpcomingCycle={inUpcomingCycle}
      spaceId={space.id}
      onSelect={(cycleId: string | null) => {
        if (cycleId === null) {
          if (inUpcomingCycle && space.upcomingCycleId) {
            removeEntitiesFromCycle([entityId], space.upcomingCycleId);
          }
          if (inCurrentCycle && space.activeCycleId) {
            removeEntitiesFromCycle([entityId], space.activeCycleId);
          }
          closeMenu();
          return;
        }
        if (cycleId === space.activeCycleId && !inCurrentCycle) {
          addEntitiesToCycle([entityId], cycleId);
        }
        if (cycleId === space.upcomingCycleId && !inUpcomingCycle) {
          addEntitiesToCycle([entityId], cycleId);
        }
        closeMenu();
      }}
    />
  );
}

export function EntityCycleIndicator({
  entity,
  size,
  interactable,
}: {
  entity: WorkItem;
  size?: MetadataSize;
  interactable?: boolean;
}) {
  const space = useRecoilValue(spaceSelector(entity.spaceId));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const currentCycleEntity = useRecoilValue(
    cycleEntityForCycleAndEntity({ cycleId: space?.activeCycleId, entityId: entity.id })
  );
  const upcomingCycleEntity = useRecoilValue(
    cycleEntityForCycleAndEntity({ cycleId: space?.upcomingCycleId, entityId: entity.id })
  );

  const [hoverOpen, setHoverOpen] = React.useState(false);
  React.useEffect(() => {
    setHoverOpen(false);
  }, [menuOpen]);

  const currentCycle = useRecoilValue(currentCycleSelector(space?.id));
  const upcomingCycle = useRecoilValue(cycleSelector(space?.upcomingCycleId));

  if (!space?.cyclesEnabled || (!currentCycleEntity && !upcomingCycleEntity)) {
    return null;
  }

  const inCurrentCycle = currentCycleEntity?.ghost === false;
  const inUpcomingCycle = upcomingCycleEntity?.ghost === false;

  const cycle = currentCycleEntity ? currentCycle : upcomingCycle;

  if (!cycle || !space) {
    return null;
  }

  const ghost = currentCycleEntity?.ghost ?? upcomingCycleEntity?.ghost;

  const content = (
    <Cycle size={size} cycleStatus={cycle.cycleStatus} ghost={ghost} name={cycle.title} />
  );

  if (!interactable) {
    return content;
  }

  return (
    <MetadataTooltip
      disabled={menuOpen}
      open={hoverOpen}
      side="bottom"
      align="start"
      className={cn({ followTooltip: !ghost })}
      onOpenChange={setHoverOpen}
      content={<EntityCycleTooltip cycle={cycle} ghost={ghost} />}
    >
      <div>
        <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
          <DropdownMenuTrigger
            asChild
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div>{content}</div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
            }}
            side="bottom"
            align="start"
            className="menuHuge"
          >
            <EntityCycleMenu
              entityId={entity.id}
              space={space}
              closeMenu={closeMenu}
              inUpcomingCycle={inUpcomingCycle}
              inCurrentCycle={inCurrentCycle}
            />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </MetadataTooltip>
  );
}

function InsightsTabItem({
  insight,
  feedbackMode,
}: {
  insight: InsightModel;
  feedbackMode?: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  useKeyNavigationElement(insight.id, ref);
  const focused = useHasKeyNavigationFocus(insight.id);
  const feedback = useRecoilValue(feedbackSelector(insight.feedbackId));
  const feedbackPath = useFeedbackPath();
  const toMarkdown = useSerializeToMarkdown();
  const history = useHistory();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const encodedContent = React.useMemo(() => {
    return window.btoa(
      encodeURIComponent(JSON.stringify(flattenDocument(JSON.parse(insight.contents))))
    );
  }, [insight.contents]);

  if (!feedback) {
    return null;
  }

  const link = {
    pathname: feedbackPath(feedback.id) ?? '',
    state: { backUrl: location.pathname, backSearch: location.search },
  };

  return (
    <div ref={ref} className={styles.insightTabItem}>
      <div className={cn(styles.insightTabHeader, { [styles.menuOpen]: menuOpen })}>
        <CompanyPerson size={AvatarSize.Size20} feedbackId={feedback.id} />
        <span className={styles.date}>{capitalize(renderDate(insight.createdAt))}</span>
        <div
          className={styles.menu}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Button
            buttonStyle={ButtonStyle.BareSubtle}
            className="mr8"
            icon="copy"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              let html: any = undefined;
              if (contentRef.current) {
                html = new Blob([contentRef.current.innerHTML], { type: 'text/html' });
              }

              const data = new clipboard.ClipboardItem({
                'text/plain': toMarkdown(JSON.parse(insight.contents), true),
                'text/html': html ?? '',
                'application/x-slate-fragment': encodedContent,
              });
              clipboard.write([data]);
              toast.info('Insight contents copied to clipboard');
            }}
          >
            Copy content
          </Button>
          <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
            <DropdownMenuTrigger asChild>
              <IconButton buttonStyle={ButtonStyle.BareSubtle} icon="more" />
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="menuSmall"
              onClick={e => {
                e.stopPropagation();
              }}
              side="bottom"
              align="end"
              sideOffset={4}
            >
              {!feedbackMode && (
                <DropdownMenuItem to={link} icon="full_arrow_forward">
                  Open original feedback
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                icon="copy"
                onClick={() => {
                  writeToClipboard(`${window.location}?insightId=${insight.id}`);
                }}
              >
                Copy link
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      {/*  We re-use the same hack with data-slate-fragment that slate does internally to get copying to work in Safari */}
      <div ref={contentRef} data-slate-fragment={encodedContent}>
        <StaticSlateDocument
          value={JSON.parse(insight.contents)}
          className={cn(styles.context, styles.insightTabContent, 'fs-exclude')}
        />
      </div>
      <div className={styles.insightTabFooter}>
        {!feedbackMode && (
          <>
            <span className="bodyS noWrap">Created from</span>
            <LinkButton className="grayed ellipsis" size={ButtonSize.Small} to={link}>
              {feedback.title || 'Untitled feedback'}
            </LinkButton>
          </>
        )}
        {feedbackMode && (
          <>
            {insight.entityIds.map(entityId => (
              <StaticEntityMention
                key={entityId}
                element={{
                  type: Elements.Entity,
                  entityId: entityId,
                  mentionId: '',
                  children: [],
                }}
              >
                {null}
              </StaticEntityMention>
            ))}
          </>
        )}
      </div>
      {focused && (
        <Hotkey
          hotkey="enter"
          handler={() => {
            history.push(link);
          }}
        />
      )}
    </div>
  );
}

export function InsightsTab({
  entityId,
  entityType,
  feedbackMode,
}: {
  entityId: string;
  entityType: string;
  feedbackMode?: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const insights = useRecoilValue(insightsForEntity(entityId));
  useKeyNavigationColumn(
    `right-${entityId}`,
    insights.map(i => i.id),
    ref
  );

  return (
    <div className={styles.insightTab} ref={ref}>
      {insights.map(insight => (
        <InsightsTabItem key={insight.id} insight={insight} feedbackMode={feedbackMode} />
      ))}
      {insights.length === 0 && (
        <div className={styles.insightTabPlaceholder}>
          <Placeholder icon="insights" title={'No insights'}>
            <span className="grayed textCenter">
              <p>
                Insights connected to this {entityType} will appear here <br />
                Learn more in the{' '}
                <ExternalLink
                  href="https://guide.kitemaker.co/feedback-and-insights"
                  className="link hoverOnly headingS"
                >
                  Kitemaker Guide.
                </ExternalLink>
              </p>
            </span>
          </Placeholder>
        </div>
      )}
    </div>
  );
}
