import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Issue, Release, ReleaseStatus } from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import { ReleaseStatusIndicator } from '../../../screens/releaseScreen/releaseStatusIndicator';
import {
  releaseSelector,
  releasesForOrganizationSelector,
} from '../../../syncEngine/selectors/releases';
import { FilteredListViewHandle } from '../filteredListView';
import { Picker, PickerState } from '../picker';

export function issuesToReleasePickerState(
  issues: Array<Issue>,
  releases: Array<Release>
): PickerState {
  const releaseIdsByIssueId = issues.reduce((result, issue) => {
    result[issue.id] = [];
    return result;
  }, {} as Record<string, string[]>);

  for (const release of releases) {
    for (const issueId of release.issueIds) {
      if (releaseIdsByIssueId[issueId]) {
        releaseIdsByIssueId[issueId].push(release.id);
      }
    }
  }
  return releaseIdsByIssueId;
}

function ReleaseItem({ releaseId }: { releaseId: string }) {
  const release = useRecoilValue(releaseSelector(releaseId));
  if (!release) {
    return null;
  }

  return (
    <div className="row metadataGap">
      <span className="ellipsis">{release.title}</span>
      <ReleaseStatusIndicator release={release} />
    </div>
  );
}

export function ReleasePicker({
  state,
  filterPlaceholder,
  filterClassName,
  onDone,
  onReleaseAdded,
  onReleaseRemoved,
  statusFilter = [ReleaseStatus.Planned, ReleaseStatus.InDevelopment],
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  onDone: () => void;
  onReleaseAdded: (issueIds: string[], releaseId: string) => void;
  onReleaseRemoved: (issueIds: string[], releaseId: string) => void;
  statusFilter?: ReleaseStatus[];
}) {
  const organization = useOrganization();
  const releases = useRecoilValue(releasesForOrganizationSelector(organization.id));
  const filteredReleases =
    statusFilter === null ? releases : releases.filter(r => statusFilter.includes(r.releaseStatus));

  const items = filteredReleases.map(r => ({
    id: r.id,
    contents: <ReleaseItem releaseId={r.id} />,
  }));

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  return (
    <Picker
      filterLabel="Pick release"
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder ?? 'Pick releases'}
      placeholder={'No releases found'}
      ref={filterRef}
      multi
      state={state}
      items={items}
      propertiesToSearch={['title', 'number']}
      onAdd={onReleaseAdded}
      onRemove={onReleaseRemoved}
      onDone={onDone}
    />
  );
}
