import { fuzzy } from 'fast-fuzzy';
import { uniq } from 'lodash';
import moment from 'moment';
import { GetRecoilValue, atomFamily, selectorFamily, useRecoilCallback } from 'recoil';
import { Effort, Impact, InitiativeSpace } from '../../../graphql__generated__/graphql';
import {
  AndFilter,
  ArchivedFilter,
  CompanyFilter,
  CreatedAtFilter,
  CreatedByFilter,
  CycleFilter,
  EffortFilter,
  Filter,
  FilterType,
  FreeTextFilter,
  ImpactFilter,
  InitiativeFilter,
  InitiativeStatusFilter,
  LabelFilter,
  MemberFilter,
  OrFilter,
  PersonFilter,
  SpaceFilter,
  TagFilter,
  TodosAssignedFilter,
  UpdatedAtFilter,
  WatchingFilter,
} from '../../shared/filtering';
import { filterNotDeletedNotNull } from '../../shared/utils/convenience';
import { initiativeSpacesByInitiative, initiativesByIssue } from '../../sync/__generated/indexes';
import {
  Entity,
  Feedback,
  Initiative,
  Issue,
  IssueLabel,
  Person,
  Space,
} from '../../sync/__generated/models';
import { cycleEntitiesForCycleSelector } from '../syncEngine/selectors/cycles';
import {
  entitiesSelector,
  isCycleableEntity,
  isEffortImpactableEntity,
  isLabelableEntity,
  isSpaceBoundEntity,
  isWatchableEntity,
} from '../syncEngine/selectors/entities';
import { isFeedback } from '../syncEngine/selectors/feedback';
import { initiativeStatusSelector, isInitiative } from '../syncEngine/selectors/intiatives';
import { isIssue } from '../syncEngine/selectors/issues';
import { todosForMemberSelector } from '../syncEngine/selectors/todos';
import { indexKey, syncEngineState } from '../syncEngine/state';
import { SyncEngineIndexValue } from '../syncEngine/types';
import { filterChainState } from './filtering2';
export type {
  AndFilter,
  Filter,
  HasFilter,
  IsFilter,
  NotFilter,
  OrFilter,
} from '../../shared/filtering';

export type FilterableEntityType = 'Issue' | 'Feedback' | 'Initiative';
type FilterableEntity = Issue | Feedback | Initiative;

export const FILTER_ANY_ID = 'any';
export const FILTER_NONE_ID = 'none';

export function getFilterUrlParam(id: string, newFilter?: boolean): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  if (newFilter) {
    return urlParams.get(id.includes('filters') ? id : `filters_${id}`);
  }
  return urlParams.get(id.includes('filter') ? id : `filter_${id}`);
}

export function decodeFilterUrlValue(value: string) {
  const decodedValue = atob(value);
  return JSON.parse(decodedValue);
}

export function encodeFilterUrlValue(value: Filter) {
  const stringifiedJson = JSON.stringify(value);
  return btoa(stringifiedJson);
}

export const filterEffect =
  (key: string, newFilter?: boolean) =>
  ({
    setSelf,
    onSet,
  }: {
    setSelf: (v: any) => void;
    onSet: any; //FIXME cannot figure out the typescript syntax to type this
  }) => {
    const savedValue = getFilterUrlParam(key, newFilter);
    if (savedValue != null) {
      setSelf(decodeFilterUrlValue(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      const url = new URL(window.location.toString());
      const searchParams = url.searchParams;
      if (isReset || newValue === null || newValue?.filters?.length === 0) {
        searchParams.delete(key);
      } else {
        const encoded = encodeFilterUrlValue(newValue);
        searchParams.set(key, encoded);
      }
      window.history.replaceState({}, '', url);
    });
  };

export const filterState = atomFamily<Filter | null, string>({
  key: 'FilterState',
  default: null,
  effects: key => [filterEffect(`filter_${key}`)],
});

export const hasFiltersSelector = selectorFamily({
  key: 'HasFiltersSelector',
  get:
    (filterId: string) =>
    ({ get }) => {
      const filters = get(filterChainState(filterId))?.filters?.length;
      return !!filters;
    },
});

function findFilters<T extends Filter>(
  filter: Filter | null,
  match: (filter: Filter) => boolean
): T[] {
  if (filter?.type === FilterType.And) {
    return (filter.filters.filter(f => match(f)) ?? null) as T[];
  }

  return [];
}

export function filterToProperties<T extends Filter>(filter: T | null) {
  const impactId: string | null =
    findFilters<ImpactFilter>(filter, f => f.type === FilterType.Impact)[0]?.id ?? null;
  const effortId: string | null =
    findFilters<EffortFilter>(filter, f => f.type === FilterType.Effort)[0]?.id ?? null;
  const labelIds: string[] = [
    ...findFilters<LabelFilter>(filter, f => f.type === FilterType.Label).map(f => f.id),
    ...findFilters<TagFilter>(filter, f => f.type === FilterType.Tag).map(f => f.id),
  ];
  const assigneeIds: string[] = findFilters<MemberFilter>(
    filter,
    f => f.type === FilterType.Member
  ).map(f => f.id);
  const initiativeIds: string[] = findFilters<InitiativeFilter>(
    filter,
    f => f.type === FilterType.Initiative
  ).map(f => f.id);
  const personId: string | null =
    findFilters<ImpactFilter>(filter, f => f.type === FilterType.Person)[0]?.id ?? null;
  const companyId: string | null =
    findFilters<EffortFilter>(filter, f => f.type === FilterType.Company)[0]?.id ?? null;

  return {
    impactId,
    effortId,
    labelIds,
    assigneeIds,
    initiativeIds,
    personId,
    companyId,
  };
}

export function useGetFilter() {
  return useRecoilCallback(({ snapshot }) => (filterId: string) => {
    return snapshot.getLoadable(filterState(filterId)).getValue();
  });
}

function getObject<T>(id: string, get: GetRecoilValue, objectCache: Record<string, unknown>) {
  if (objectCache[id]) {
    return objectCache[id] as T;
  }

  const obj = get(syncEngineState(id)) as T | null;
  if (!obj) {
    return null;
  }
  objectCache[id] = obj;
  return obj;
}

function applyAndFilter<T>(
  filter: AndFilter,
  applyFilter: (
    filter: Filter,
    entities: T[],
    get: GetRecoilValue,
    objectCache: Record<string, unknown>
  ) => T[],
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  let results: T[] = entities;
  for (const f of filter.filters) {
    results = applyFilter(f, results, get, objectCache);
  }
  return results;
}

function applyOrFilter<T extends FilterableEntity>(
  filter: OrFilter,
  applyFilter: (
    filter: Filter,
    entities: T[],
    get: GetRecoilValue,
    objectCache: Record<string, unknown>
  ) => T[],
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  const resultsSet = new Set<string>();

  for (const f of filter.filters) {
    const results = applyFilter(f, entities, get, objectCache).map(r => r.id);
    results.forEach(r => resultsSet.add(r));
  }
  return entities.filter(e => resultsSet.has(e.id));
}

function applySpaceFilter(
  filter: SpaceFilter,
  entities: FilterableEntity[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): FilterableEntity[] {
  return entities.filter(entity => {
    if (isSpaceBoundEntity(entity)) {
      return entity.spaceId === filter.id;
    }

    if (isInitiative(entity)) {
      const indexValues = get(
        syncEngineState(indexKey(initiativeSpacesByInitiative, entity.id))
      ) as SyncEngineIndexValue[] | null;
      if (!indexValues) {
        return false;
      }
      const initiativeSpaces = filterNotDeletedNotNull(
        indexValues.map(i => getObject<InitiativeSpace>(i.value, get, objectCache))
      );
      return initiativeSpaces.some(i => i.spaceId === filter.id);
    }

    return false;
  });
}

type MemberFilterable =
  | { assigneeIds: string[]; ownerIds?: never; memberIds?: never }
  | { ownerIds: string[]; assigneeIds?: never; memberIds?: never }
  | { memberIds: string[]; ownerIds?: never; assigneeIds?: never };

function applyMemberFilter<T extends MemberFilterable>(
  filter: MemberFilter,
  entities: T[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  if (filter.id === FILTER_ANY_ID) {
    return entities.filter(
      entity => !!(entity.assigneeIds ?? entity.ownerIds ?? entity.memberIds).length
    );
  }
  if (filter.id === FILTER_NONE_ID) {
    return entities.filter(
      entity => !(entity.assigneeIds ?? entity.ownerIds ?? entity.memberIds).length
    );
  }

  return entities.filter(entity =>
    (entity.assigneeIds ?? entity.ownerIds ?? entity.memberIds).includes(filter.id)
  );
}

function applyCreatedByFilter(
  filter: CreatedByFilter,
  entities: FilterableEntity[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): FilterableEntity[] {
  if (filter.id === FILTER_ANY_ID) {
    return entities.filter(entity => !!entity.actorId.length);
  }
  if (filter.id === FILTER_NONE_ID) {
    return entities.filter(entity => !entity.actorId.length);
  }
  return entities.filter(entity => entity.actorId.includes(filter.id));
}

function applyLabelFilter(
  filter: LabelFilter,
  entities: FilterableEntity[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): FilterableEntity[] {
  if (filter.id === FILTER_ANY_ID) {
    return entities.filter(entity => isLabelableEntity(entity) && !!(entity.labelIds ?? []).length);
  }
  if (filter.id === FILTER_NONE_ID) {
    return entities.filter(entity => isLabelableEntity(entity) && !entity.labelIds?.length);
  }

  const label = getObject<IssueLabel>(filter.id, get, objectCache);
  if (!label) {
    return [];
  }

  return entities.filter(entity => {
    if (!isLabelableEntity(entity)) {
      return false;
    }

    const labels = filterNotDeletedNotNull(
      entity.labelIds?.map(labelId => getObject<IssueLabel>(labelId, get, objectCache))
    );
    return labels.find(l => l.id === label.id || l.name === label.name);
  });
}

function applyInitiativeFilter(
  filter: InitiativeFilter,
  entities: FilterableEntity[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): FilterableEntity[] {
  if (filter.id === FILTER_ANY_ID) {
    return entities.filter(entity => {
      const indexValues = get(syncEngineState(indexKey(initiativesByIssue, entity.id))) as
        | SyncEngineIndexValue[]
        | null;
      return !!indexValues?.length;
    });
  }
  if (filter.id === FILTER_NONE_ID) {
    return entities.filter(entity => {
      const indexValues = get(syncEngineState(indexKey(initiativesByIssue, entity.id))) as
        | SyncEngineIndexValue[]
        | null;
      return !indexValues?.length;
    });
  }

  const initiative = getObject<Initiative>(filter.id, get, objectCache);
  if (!initiative) {
    return [];
  }

  return entities.filter(entity => initiative.issueIds.includes(entity.id));
}

function applyImpactFilter(
  filter: ImpactFilter,
  entities: FilterableEntity[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): FilterableEntity[] {
  const filterImpact = getObject<Impact>(filter.id ?? '', get, objectCache);
  return entities.filter(entity => {
    if (!isEffortImpactableEntity(entity)) {
      return false;
    }
    const entityImpact = getObject<Impact>(entity.impactId ?? '', get, objectCache);
    return (
      entity.impactId === filter.id ||
      (filterImpact && entityImpact && filterImpact.name === entityImpact.name)
    );
  });
}

function applyEffortFilter(
  filter: EffortFilter,
  entities: FilterableEntity[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): FilterableEntity[] {
  const filterEffort = getObject<Effort>(filter.id ?? '', get, objectCache);
  return entities.filter(entity => {
    if (!isEffortImpactableEntity(entity)) {
      return false;
    }
    const entityEffort = getObject<Impact>(entity.effortId ?? '', get, objectCache);
    return (
      entity.effortId === filter.id ||
      (filterEffort && entityEffort && filterEffort.name === entityEffort.name)
    );
  });
}

function applyWatchingFilter(
  filter: WatchingFilter,
  entities: FilterableEntity[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): FilterableEntity[] {
  return entities.filter(
    entity => isWatchableEntity(entity) && entity.watcherIds.includes(filter.id)
  );
}

interface FreeTextFilterable {
  spaceId?: string;
  number: string;
  title: string;
}

function applyFreeTextFilter<T extends FreeTextFilterable>(
  filter: FreeTextFilter,
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  return entities.filter(obj => {
    let toSearch;
    if (obj.spaceId) {
      const space = getObject<Space>(obj.spaceId, get, objectCache);
      toSearch = `${space?.key}-${obj.number} ${obj.title}`;
    } else {
      toSearch = `${obj.number} ${obj.title}`;
    }
    const score = fuzzy(filter.text, toSearch);
    return score > 0.7;
  });
}

interface DateFliterable {
  createdAt: number;
  displayedUpdatedAt?: number;
  updatedAt: number;
}

function applyDateFilter<T extends DateFliterable>(
  filter: CreatedAtFilter | UpdatedAtFilter,
  entities: T[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    const today = new Date(new Date().toDateString());
    const now = moment(today);
    let entityTime: Date;

    if (filter.type === FilterType.CreatedAt) {
      entityTime = new Date(new Date(entity.createdAt).toDateString());
    } else {
      entityTime = new Date(new Date(entity.displayedUpdatedAt ?? entity.updatedAt).toDateString());
    }
    const then = moment(entityTime);
    const daysDiff = now.diff(then, 'days');

    if (filter.direction === 'before') {
      return daysDiff > filter.offset;
    } else {
      return daysDiff <= filter.offset;
    }
  });
}

function applyCompanyFilter(
  filter: CompanyFilter,
  objects: FilterableEntity[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): FilterableEntity[] {
  return objects.filter(object => {
    if (!isFeedback(object)) {
      return false;
    }
    let companyId = object.companyId;
    if (object.personId) {
      const person = getObject<Person>(object.personId, get, objectCache);
      if (person) {
        companyId = person.companyId;
      }
    }
    return companyId === filter.id;
  });
}

function applyTagFilter(
  filter: TagFilter,
  objects: FilterableEntity[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): FilterableEntity[] {
  return objects.filter(object => {
    if (!isFeedback(object)) {
      return false;
    }
    return object.tagIds.includes(filter.id);
  });
}

function applyPersonFilter(
  filter: PersonFilter,
  objects: FilterableEntity[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): FilterableEntity[] {
  return objects.filter(object => {
    if (!isFeedback(object)) {
      return false;
    }
    return object.personId === filter.id;
  });
}

function applyArchivedFilter(
  filter: ArchivedFilter,
  objects: FilterableEntity[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): FilterableEntity[] {
  return objects.filter(object => {
    if (isFeedback(object)) {
      return false;
    }

    if (filter.archived) {
      return !!object.archivedAt;
    }
    return !object.archivedAt;
  });
}

function applyInitiativeStatusFilter(
  filter: InitiativeStatusFilter,
  objects: FilterableEntity[],
  get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): FilterableEntity[] {
  return objects.filter(object => {
    if (!isInitiative(object)) {
      return false;
    }
    return filter.status === get(initiativeStatusSelector(object.id));
  });
}

interface TodosAssignedFilterable {
  id: string;
}

function applyTodosAssignedFilter<T extends TodosAssignedFilterable>(
  filter: TodosAssignedFilter,
  objects: T[],
  get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  const entitiesWithTodos = uniq(get(todosForMemberSelector(filter.id)).map(t => t.entityId));

  return objects.filter(object => {
    return entitiesWithTodos.includes(object.id);
  });
}

function applyCycleFilter(
  filter: CycleFilter,
  objects: FilterableEntity[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): FilterableEntity[] {
  if (filter.id) {
    const cycleEntities = get(cycleEntitiesForCycleSelector(filter.id));
    const entityIdsInCycle = new Set(cycleEntities.map(e => e.entityId));

    return objects.filter(object => entityIdsInCycle.has(object.id));
  }

  const spaces = uniq(objects.map(o => (isCycleableEntity(o) ? o.spaceId : null)));
  const cycleIds = uniq(
    spaces.flatMap(spaceId => {
      if (!spaceId) {
        return [];
      }
      const space = getObject<Space>(spaceId, get, objectCache);
      if (space) {
        return [space.activeCycleId, space.upcomingCycleId];
      }
      return [];
    })
  ).filter(id => !!id);

  const cycleEntityIds = new Set(
    cycleIds.flatMap(cycleId => {
      const cycleEntities = get(cycleEntitiesForCycleSelector(cycleId!));
      return cycleEntities.map(e => e.entityId);
    })
  );

  return objects.filter(object => !cycleEntityIds.has(object.id));
}

export function applyFilterableEntitiesFilters(
  filter: Filter,
  entities: FilterableEntity[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): FilterableEntity[] {
  switch (filter.type) {
    case FilterType.Or:
      return applyOrFilter(filter, applyFilterableEntitiesFilters, entities, get, objectCache);
    case FilterType.And:
      return applyAndFilter(filter, applyFilterableEntitiesFilters, entities, get, objectCache);
    case FilterType.Member:
      return applyMemberFilter(filter, entities, get, objectCache);
    case FilterType.Label:
      return applyLabelFilter(filter, entities, get, objectCache);
    case FilterType.Initiative:
      return applyInitiativeFilter(filter, entities, get, objectCache);
    case FilterType.Impact:
      return applyImpactFilter(filter, entities, get, objectCache);
    case FilterType.Effort:
      return applyEffortFilter(filter, entities, get, objectCache);
    case FilterType.Watching:
      return applyWatchingFilter(filter, entities, get, objectCache);
    case FilterType.Space:
      return applySpaceFilter(filter, entities, get, objectCache);
    case FilterType.FreeText:
      return applyFreeTextFilter(filter, entities, get, objectCache);
    case FilterType.Company:
      return applyCompanyFilter(filter, entities, get, objectCache);
    case FilterType.Tag:
      return applyTagFilter(filter, entities, get, objectCache);
    case FilterType.Person:
      return applyPersonFilter(filter, entities, get, objectCache);
    case FilterType.Archived:
      return applyArchivedFilter(filter, entities, get, objectCache);
    case FilterType.InitiativeStatus:
      return applyInitiativeStatusFilter(filter, entities, get, objectCache);
    case FilterType.CreatedAt:
    case FilterType.UpdatedAt:
      return applyDateFilter(filter, entities, get, objectCache);
    case FilterType.CreatedBy:
      return applyCreatedByFilter(filter, entities, get, objectCache);
    case FilterType.TodosAssigned:
      return applyTodosAssignedFilter(filter, entities, get, objectCache);
    case FilterType.Cycle:
      return applyCycleFilter(filter, entities, get, objectCache);
    // Handle filter types that makes no sense in the current implementation (will probably never run)
    case FilterType.Has:
    case FilterType.Is:
    case FilterType.In:
    case FilterType.Not:
      return entities;
    /* default: // Uncomment this if you want the linter to spit out all missing filter types. Should just automatically work without comments always if we upgrade Typescript.
        filter.type satisfies never;*/
  }
}

export function filterLoadedEntities(
  entities: Entity[],
  filterId: string,
  get: GetRecoilValue
): Entity[] {
  const filter = get(filterState(filterId));
  if (!filter) {
    return entities;
  }

  return applyFilterableEntitiesFilters(
    filter,
    entities.filter(e => isIssue(e) || isInitiative(e)) as FilterableEntity[],
    get,
    {}
  );
}

export function filterEntities(
  entityIds: string[],
  filterId: string,
  get: GetRecoilValue
): string[] {
  const filter = get(filterState(filterId));
  if (!filter) {
    return entityIds;
  }

  const entities = get(entitiesSelector(entityIds)).filter(
    e => isIssue(e) || isFeedback(e) || isInitiative(e)
  ) as FilterableEntity[];

  return applyFilterableEntitiesFilters(filter, entities, get, {}).map(e => e.id);
}
