import cn from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Doc } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { useConfiguration } from '../../contexts/configurationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useUpdateDocuments } from '../../syncEngine/actions/documents';
import { documentPath, documentSelector } from '../../syncEngine/selectors/documents';
import { orgEntityKey } from '../../syncEngine/selectors/entities';
import { Button, ButtonSize, ButtonStyle } from './button';
import { CustomCommand } from './customCommand';
import { DocumentMenu } from './documentMenu';
import {
  EntityListItem,
  EntityListItemMainContents,
  EntityListItemMetadata,
  EntityListItemMenu,
  EntityListItemTitle,
} from './entityListItem';
import listItemStyles from './entityListItem.module.scss';
import { EntityWatching } from './entityMetadata';
import { EntityTitleEditor } from './entityTitleEditor';
import { Icon } from './icon';
import { useHasKeyNavigationFocus, useKeyNavigationElement } from './keyNavigation';

function MetadataLeft({ focused }: { doc: Doc; focused: boolean }) {
  const { featureFlags } = useConfiguration();
  return (
    <EntityListItemMetadata
      className={cn('ml8', {
        overlappableMetadataContainer: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
        noShrink: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
      })}
    >
      {!featureFlags.FEATURE_TOGGLE_METADATA_RIGHT && (
        <div
          className={cn('row', 'metadataGap', 'overflowHidden', {
            focused,
            overlappableMetadata: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT,
          })}
        ></div>
      )}
    </EntityListItemMetadata>
  );
}

function MetadataRight({
  doc,
  focused,
  editMode,
}: {
  doc: Doc;
  focused: boolean;
  editMode?: boolean;
}) {
  const { featureFlags } = useConfiguration();

  return (
    <EntityListItemMetadata
      className={cn('ml4', {
        overlappableMetadataContainer: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT || editMode,
        noShrink: !featureFlags.FEATURE_TOGGLE_METADATA_RIGHT && !editMode,
      })}
    >
      <div
        className={cn('row', 'metadataGap', 'overflowHidden', {
          focused,
          overlappableMetadata: featureFlags.FEATURE_TOGGLE_METADATA_RIGHT || editMode,
        })}
      ></div>
      <EntityWatching entity={doc} />
    </EntityListItemMetadata>
  );
}

export function DocumentListItem({
  id,
  routingState,
  className,
  style,
  keyNavId,
  showIcon,
  onChangeTitle,
}: {
  id: string;
  routingState?: any;
  className?: string;
  style?: React.CSSProperties;
  keyNavId?: string;
  showIcon?: boolean;
  onChangeTitle?: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const doc = useRecoilValue(documentSelector(id));
  const organization = useOrganization();
  const focused = useHasKeyNavigationFocus(keyNavId ?? id);
  const history = useHistory();

  useKeyNavigationElement(keyNavId ?? id, ref);

  if (!doc) {
    return null;
  }

  const link = {
    pathname: documentPath(organization, doc),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: id,
      ...routingState,
    },
  };

  return (
    <Link to={link}>
      <EntityListItem
        ref={ref}
        entityNumber={orgEntityKey(doc)}
        className={className}
        style={style}
        statusIcon={
          showIcon ? (
            <Icon
              icon="document"
              style={{ '--status-icon-color': 'var(--gray12)' } as React.CSSProperties}
            />
          ) : undefined
        }
      >
        <EntityListItemMainContents>
          <div className="row ellipsis">
            <EntityListItemTitle type={'document'}>{doc.title}</EntityListItemTitle>
            <MetadataLeft doc={doc} focused={focused} />
          </div>
          <MetadataRight doc={doc} focused={focused} />
        </EntityListItemMainContents>
        <EntityListItemMenu
          date={doc.updatedAt}
          menuContents={closeMenu => (
            <DocumentMenu doc={doc} closeMenu={closeMenu} onChangeTitle={onChangeTitle} />
          )}
        />
        {focused && (
          <>
            <CustomCommand
              command={{
                id: 'open',
                hotkey: 'enter',
                group: CommandGroup.Entities,
                description: `Open`,
                priority: 100,
                handler: () => {
                  history.push(link);
                },
              }}
            />
            {onChangeTitle && (
              <CustomCommand
                command={{
                  id: 'edit-title',
                  hotkey: 't',
                  group: CommandGroup.Entities,
                  description: `Edit title`,
                  priority: 99,
                  handler: () => {
                    onChangeTitle();
                  },
                }}
              />
            )}
          </>
        )}
      </EntityListItem>
    </Link>
  );
}

export function EditDocumentListItem({
  id,
  className,
  style,
  keyNavId,
  onDone,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  keyNavId?: string;
  onDone: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const doc = useRecoilValue(documentSelector(id));
  const updateDocuments = useUpdateDocuments();

  const [title, setTitle] = React.useState(doc?.title ?? '');

  useKeyNavigationElement(keyNavId ?? id, ref);

  function save() {
    if (doc?.title !== title && doc) {
      updateDocuments([doc.id], { title });
    }
    onDone();
  }

  if (!doc) {
    return null;
  }

  return (
    <EntityListItem ref={ref} entityNumber={orgEntityKey(doc)} className={className} style={style}>
      <EntityListItemMainContents>
        <div className="row ellipsis">
          <EntityTitleEditor
            className={cn('headingS', listItemStyles.titleEditor)}
            initialTitle={title}
            onChange={setTitle}
            onSubmit={save}
            onReset={onDone}
            autoFocus
            oneLine
            placeholder={<span className="bodyM">Enter a title</span>}
          />
        </div>
      </EntityListItemMainContents>
      <MetadataRight doc={doc} focused editMode />
      <div className="rowEnd ml24">
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button size={ButtonSize.Small} onClick={save} buttonStyle={ButtonStyle.Primary}>
          Save
        </Button>
      </div>
    </EntityListItem>
  );
}
