import { fuzzy } from 'fast-fuzzy';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { GetRecoilValue, atomFamily, selectorFamily } from 'recoil';
import { filterNotDeletedNotNull } from '../../shared/utils/convenience';
import { initiativesByIssue } from '../../sync/__generated/indexes';
import {
  Effort,
  Entity,
  FeedbackTag,
  Impact,
  Issue,
  IssueLabel,
  IssueStatus,
  IssueStatusType,
  Person,
} from '../../sync/__generated/models';
import { cycleEntitiesForEntitySelector } from '../syncEngine/selectors/cycles';
import { isDocument } from '../syncEngine/selectors/documents';
import { entitiesSelector, entityKeySelector } from '../syncEngine/selectors/entities';
import { isFeedback } from '../syncEngine/selectors/feedback';
import { isInitiative, spacesIdsForInitiativeSelector } from '../syncEngine/selectors/intiatives';
import { isIssue } from '../syncEngine/selectors/issues';
import { isRelease } from '../syncEngine/selectors/releases';
import { spaceSelector } from '../syncEngine/selectors/spaces';
import { indexKey, syncEngineState } from '../syncEngine/state';
import { SyncEngineIndexValue } from '../syncEngine/types';
import { FILTER_ANY_ID, FILTER_NONE_ID, filterEffect } from './filtering';

export enum FilterType {
  Member = 'members',
  WatchedBy = 'watched by',
  Creator = 'creator',
  Label = 'labels',
  Space = 'spaces',
  Impact = 'impact',
  Effort = 'effort',
  Initiative = 'initiatives',
  Cycle = 'cycles',
  Title = 'title',
  CreatedAt = 'created',
  UpdatedAt = 'updated',
  Tag = 'tag',
  Person = 'person',
  Company = 'companies',
  State = 'state',
}

export interface BaseFilter {
  type: FilterType;
}

export interface SingleFilter extends BaseFilter {
  modifier: 'any-of' | 'not-any-of';
  ids: string[];
}

export interface MultiFilter extends BaseFilter {
  modifier: 'any-of' | 'all-of' | 'not-any-of';
  ids: string[];
}

export interface DateFilter extends BaseFilter {
  modifier: 'is' | 'is-not' | 'is-before' | 'is-after' | 'is-not-before' | 'is-not-after';
  duration: number;
}

export interface FreeTextFilter extends BaseFilter {
  text: string;
  modifier: 'contains' | 'not-contains';
}

export interface MemberFilter extends MultiFilter {
  type: FilterType.Member;
}

export interface WatchedByFilter extends MultiFilter {
  type: FilterType.WatchedBy;
}

export interface LabelsFilter extends MultiFilter {
  type: FilterType.Label;
}

export interface SpacesFilter extends MultiFilter {
  type: FilterType.Space;
}

export interface InitiativesFilter extends MultiFilter {
  type: FilterType.Initiative;
}

export interface TitleFilter extends FreeTextFilter {
  type: FilterType.Title;
}

export interface CreatedAtFilter extends DateFilter {
  type: FilterType.CreatedAt;
}

export interface UpdatedAtFilter extends DateFilter {
  type: FilterType.UpdatedAt;
}

export interface TagFilter extends MultiFilter {
  type: FilterType.Tag;
}

export interface CompanyFilter extends SingleFilter {
  type: FilterType.Company;
}

export interface CreatorFilter extends SingleFilter {
  type: FilterType.Creator;
}

export interface ImpactFilter extends SingleFilter {
  type: FilterType.Impact;
}

export interface EffortFilter extends SingleFilter {
  type: FilterType.Effort;
}
export interface CyclesFilter extends SingleFilter {
  type: FilterType.Cycle;
}
export interface PersonFilter extends SingleFilter {
  type: FilterType.Person;
}

export interface StateFilter extends SingleFilter {
  type: FilterType.State;
}

export function isMultiFilter(filter: BaseFilter): filter is MultiFilter {
  return (filter as MultiFilter).ids !== undefined && !isSingleFilter(filter);
}

export function isSingleFilter(filter: BaseFilter): filter is SingleFilter {
  return [
    FilterType.Creator,
    FilterType.Impact,
    FilterType.Effort,
    FilterType.Cycle,
    FilterType.Person,
    FilterType.State,
  ].includes((filter as SingleFilter).type);
}

export function isTitleFilter(filter: Filter): filter is TitleFilter {
  return (filter as FreeTextFilter).text !== undefined;
}

export function isDateFilter(filter: BaseFilter): filter is DateFilter {
  return (filter as DateFilter).duration !== undefined;
}

export type Filter =
  | MemberFilter
  | WatchedByFilter
  | CreatorFilter
  | LabelsFilter
  | SpacesFilter
  | ImpactFilter
  | EffortFilter
  | InitiativesFilter
  | CyclesFilter
  | TitleFilter
  | CreatedAtFilter
  | UpdatedAtFilter
  | CompanyFilter
  | TagFilter
  | StateFilter
  | PersonFilter;

export type FilterOperation = 'and' | 'or';

export interface FilterChain {
  filters: Filter[];
  operation: FilterOperation;
}
export function findFilters<T extends Filter>(
  filters: Filter[],
  match: (filter: Filter) => boolean
): T[] {
  return filters.filter(f => match(f)) as T[];
}

function extractMultiFilterProperties(filters: Filter[], filterType: FilterType): string[] {
  return findFilters<MultiFilter & Filter>(
    filters,
    f => f.type === filterType && f.modifier !== 'not-any-of'
  )
    .flatMap(f => f.ids)
    .filter(id => id !== FILTER_NONE_ID && id !== FILTER_ANY_ID);
}

function extractSingleFilterProperties(filters: Filter[], filterType: FilterType): string | null {
  return (
    findFilters<SingleFilter & Filter>(
      filters,
      f => f.type === filterType && f.modifier !== 'not-any-of'
    )
      .flatMap(f => f.ids)
      .filter(id => id !== FILTER_NONE_ID && id !== FILTER_ANY_ID)?.[0] ?? null
  );
}

function filtersToProperties(filters: Filter[]) {
  const impactId = extractSingleFilterProperties(filters, FilterType.Impact);
  const effortId = extractSingleFilterProperties(filters, FilterType.Effort);

  const labelIds: string[] = [
    ...extractMultiFilterProperties(filters, FilterType.Label),
    ...extractMultiFilterProperties(filters, FilterType.Tag),
  ];
  const assigneeIds = extractMultiFilterProperties(filters, FilterType.Member);
  const initiativeIds = extractMultiFilterProperties(filters, FilterType.Initiative);
  const spaceIds = extractMultiFilterProperties(filters, FilterType.Space);

  const personId = extractSingleFilterProperties(filters, FilterType.Person);
  const companyId = extractSingleFilterProperties(filters, FilterType.Company);

  return {
    impactId,
    effortId,
    labelIds,
    assigneeIds,
    initiativeIds,
    spaceIds,
    personId,
    companyId,
  };
}

export const filterPropertiesSelector = selectorFamily({
  key: 'FilterPropertiesSelector',
  get:
    (filterId: string) =>
    ({ get }) => {
      const filterChain = get(filterChainState(filterId));
      return filtersToProperties(filterChain.filters);
    },
});

function getObject<T>(id: string, get: GetRecoilValue, objectCache: Record<string, unknown>) {
  if (objectCache[id]) {
    return objectCache[id] as T;
  }

  const obj = get(syncEngineState(id)) as T | null;
  if (!obj) {
    return null;
  }
  objectCache[id] = obj;
  return obj;
}

function applyMultiFilter(
  toFilter: string[],
  filterIds: string[],
  modifier: MultiFilter['modifier']
) {
  if (!filterIds.length) {
    return [];
  }

  let toFilterIds = toFilter;

  if (!toFilter.length) {
    toFilterIds = [FILTER_NONE_ID];
  }

  if (modifier === 'any-of') {
    return toFilterIds.some(id => filterIds.includes(id));
  }
  if (modifier === 'all-of') {
    return filterIds.every(id => toFilterIds.includes(id));
  }
  if (modifier === 'not-any-of') {
    return !toFilterIds.some(id => filterIds.includes(id));
  }

  return false;
}

function applySingleFilter(
  toFilter: string | null,
  filterIds: string[],
  modifier: SingleFilter['modifier']
) {
  if (!filterIds.length) {
    return false;
  }

  if (modifier === 'any-of') {
    if (!toFilter) {
      return filterIds.includes(FILTER_NONE_ID);
    }
    return filterIds.includes(toFilter);
  }
  if (modifier === 'not-any-of') {
    if (!toFilter) {
      return !filterIds.includes(FILTER_NONE_ID);
    }
    return !filterIds.includes(toFilter);
  }

  return false;
}

function applyFreeTextFilter(toFilter: string, filterText: string) {
  const score = fuzzy(filterText, toFilter);
  return score > 0.7;
}

function applyDateFilter(toFilter: Date, duration: number, modifier: DateFilter['modifier']) {
  const now = moment();
  const daysDiff = now.diff(toFilter, 'days');

  switch (modifier) {
    case 'is':
      throw 'Not implemented';
    case 'is-not':
      throw 'Not implemented';
    case 'is-before':
      return daysDiff > duration;
    case 'is-after':
      return daysDiff <= duration;
    case 'is-not-before':
      throw 'Not implemented';
    case 'is-not-after':
      throw 'Not implemented';
    default:
      return false;
  }
}

function applyMemberFilter<T extends Entity>(
  filter: MemberFilter,
  entities: T[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    let memberIds: string[] = [];
    if (isIssue(entity)) {
      memberIds = entity.assigneeIds;
    } else if (isInitiative(entity) || isRelease(entity)) {
      memberIds = entity.memberIds;
    } else if (isFeedback(entity)) {
      memberIds = entity.ownerIds;
    }
    return applyMultiFilter(memberIds, filter.ids, filter.modifier);
  });
}

function applyWatchedByFilter<T extends Entity>(
  filter: WatchedByFilter,
  entities: T[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    let watcherIds: string[] = [];
    if (isIssue(entity)) {
      watcherIds = entity.watcherIds;
    } else if (isInitiative(entity) || isRelease(entity)) {
      watcherIds = entity.watcherIds ?? [];
    }

    return applyMultiFilter(watcherIds, filter.ids, filter.modifier);
  });
}

function applyCreatorFilter<T extends Entity>(
  filter: CreatorFilter,
  entities: T[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    const creatorId: string = entity.actorId;
    return applySingleFilter(creatorId, filter.ids, filter.modifier);
  });
}

function applyLabelsFilter<T extends Entity>(
  filter: LabelsFilter | TagFilter,
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  const filterLabels = filterNotDeletedNotNull(
    filter.ids.map(id => getObject<IssueLabel | FeedbackTag>(id, get, objectCache))
  );

  return entities.filter(entity => {
    if (
      (filter.type === FilterType.Tag && !isFeedback(entity)) ||
      (filter.type === FilterType.Label && isFeedback(entity))
    ) {
      return false;
    }

    let labelIds: string[] = [];
    if (isIssue(entity)) {
      labelIds = entity.labelIds;
    } else if (isInitiative(entity)) {
      labelIds = entity.labelIds;
    } else if (isFeedback(entity)) {
      labelIds = entity.tagIds;
    }

    const labels = filterNotDeletedNotNull(
      labelIds.map(id => getObject<IssueLabel | FeedbackTag>(id, get, objectCache))
    );

    const filterLabelNames = filterLabels.map(l => l.name);
    if (filter.ids.includes(FILTER_NONE_ID)) {
      filterLabelNames.push(FILTER_NONE_ID);
    }

    return applyMultiFilter(
      labels.map(l => l.name),
      filterLabelNames,
      filter.modifier
    );
  });
}

function applySpacesFilter<T extends Entity>(
  filter: SpacesFilter,
  entities: T[],
  get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    let spaceIds: string[] = [];
    if (isIssue(entity)) {
      spaceIds = [entity.spaceId];
    } else if (isInitiative(entity)) {
      spaceIds = get(spacesIdsForInitiativeSelector(entity.id));
    } else if (isRelease(entity)) {
      spaceIds = entity.spaceIds;
    }

    return applyMultiFilter(spaceIds, filter.ids, filter.modifier);
  });
}

function applyImpactFilter<T extends Entity>(
  filter: ImpactFilter,
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  const filterImpacts = filterNotDeletedNotNull(
    filter.ids.map(id => getObject<Impact>(id, get, objectCache))
  );
  const filterImpactNames = filterImpacts.map(i => i.name);
  if (filter.ids.includes(FILTER_NONE_ID)) {
    filterImpactNames.push(FILTER_NONE_ID);
  }

  return entities.filter(entity => {
    let impactId: string | null = null;
    if (isIssue(entity)) {
      impactId = entity.impactId;
    } else if (isInitiative(entity)) {
      impactId = entity.impactId;
    } else {
      return false;
    }

    const impact = impactId ? getObject<Impact>(impactId, get, objectCache) : null;
    return applySingleFilter(impact?.name ?? null, filterImpactNames, filter.modifier);
  });
}

function applyEffortFilter<T extends Entity>(
  filter: EffortFilter,
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  const filterEfforts = filterNotDeletedNotNull(
    filter.ids.map(id => getObject<Effort>(id, get, objectCache))
  );
  const filterEffortNames = filterEfforts.map(i => i.name);
  if (filter.ids.includes(FILTER_NONE_ID)) {
    filterEffortNames.push(FILTER_NONE_ID);
  }

  return entities.filter(entity => {
    let effortId: string | null = null;
    if (isIssue(entity)) {
      effortId = entity.effortId;
    } else if (isInitiative(entity)) {
      effortId = entity.effortId;
    } else {
      return false;
    }

    const effort = effortId ? getObject<Effort>(effortId, get, objectCache) : null;

    return applySingleFilter(effort?.name ?? null, filterEffortNames, filter.modifier);
  });
}

function applyStateFilter<T extends Entity>(
  filter: StateFilter,
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    if (isFeedback(entity)) {
      return applySingleFilter(entity.processed ? 'closed' : 'active', filter.ids, filter.modifier);
    }
    if (isInitiative(entity) || isRelease(entity)) {
      return applySingleFilter(
        entity.archivedAt ? 'closed' : 'active',
        filter.ids,
        filter.modifier
      );
    }
    if (isIssue(entity)) {
      const status = getObject<IssueStatus>(entity.statusId, get, objectCache);
      if (!status) {
        return false;
      }
      return applySingleFilter(
        status.statusType === IssueStatusType.Done || status.statusType === IssueStatusType.Archived
          ? 'closed'
          : 'active',
        filter.ids,
        filter.modifier
      );
    }
    return false;
  });
}

function applyInitiativeFilter<T extends Entity>(
  filter: InitiativesFilter,
  entities: T[],
  get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    if (!isIssue(entity)) {
      return false;
    }

    const indexValues = (get(syncEngineState(indexKey(initiativesByIssue, entity.id))) ??
      []) as SyncEngineIndexValue[];

    const initiativeIds = indexValues.map(v => v.value);
    return applyMultiFilter(initiativeIds, filter.ids, filter.modifier);
  });
}

function applyCyclesFilter<T extends Entity>(
  filter: CyclesFilter,
  entities: T[],
  get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    if (!isIssue(entity)) {
      return filter.modifier === 'not-any-of';
    }
    const cycleEntities = get(cycleEntitiesForEntitySelector(entity.id));
    const cycleId = cycleEntities.length ? cycleEntities[cycleEntities.length - 1].cycleId : null;
    const space = get(spaceSelector(entity.spaceId));

    const ids = filter.ids.map(id => {
      if (id === 'current') {
        return space?.activeCycleId ?? id;
      }
      if (id === 'upcoming') {
        return space?.upcomingCycleId ?? id;
      }
      return id;
    });

    return applySingleFilter(cycleId, ids, filter.modifier);
  });
}

function applyPersonPicker<T extends Entity>(
  filter: PersonFilter,
  entities: T[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    if (!isFeedback(entity)) {
      return false;
    }

    return applySingleFilter(entity.personId, filter.ids, filter.modifier);
  });
}

function applyCompanyFilter<T extends Entity>(
  filter: CompanyFilter,
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    if (!isFeedback(entity)) {
      return false;
    }

    let companyId = entity.companyId;
    if (entity.personId) {
      const person = getObject<Person>(entity.personId, get, objectCache);
      if (person) {
        companyId = person?.companyId;
      }
    }

    return applySingleFilter(companyId, filter.ids, filter.modifier);
  });
}

function applyTitleFilter<T extends Entity>(
  filter: TitleFilter,
  entities: T[],
  get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    const key = get(entityKeySelector(entity.id));
    return applyFreeTextFilter(`${key} ${entity.title}`, filter.text);
  });
}

function applyCreatedAtFilter<T extends Entity>(
  filter: CreatedAtFilter,
  entities: T[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    const createdAt = new Date(entity.createdAt);
    return applyDateFilter(createdAt, filter.duration, filter.modifier);
  });
}

function applyUpdatedAtFilter<T extends Entity>(
  filter: UpdatedAtFilter,
  entities: T[],
  _get: GetRecoilValue,
  _objectCache: Record<string, unknown>
): T[] {
  return entities.filter(entity => {
    const updatedAt = new Date((entity as Issue).displayedUpdatedAt ?? entity.updatedAt);
    return applyDateFilter(updatedAt, filter.duration, filter.modifier);
  });
}

function applyFilter<T extends Entity>(
  filter: Filter,
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  switch (filter.type) {
    case FilterType.Member:
      return applyMemberFilter(filter as MemberFilter, entities, get, objectCache);
    case FilterType.WatchedBy:
      return applyWatchedByFilter(filter as WatchedByFilter, entities, get, objectCache);
    case FilterType.Creator:
      return applyCreatorFilter(filter as CreatorFilter, entities, get, objectCache);
    case FilterType.Label:
      return applyLabelsFilter(filter as LabelsFilter, entities, get, objectCache);
    case FilterType.Impact:
      return applyImpactFilter(filter as ImpactFilter, entities, get, objectCache);
    case FilterType.Effort:
      return applyEffortFilter(filter as EffortFilter, entities, get, objectCache);
    case FilterType.Initiative:
      return applyInitiativeFilter(filter as InitiativesFilter, entities, get, objectCache);
    case FilterType.Cycle:
      return applyCyclesFilter(filter as CyclesFilter, entities, get, objectCache);
    case FilterType.Title:
      return applyTitleFilter(filter as TitleFilter, entities, get, objectCache);
    case FilterType.CreatedAt:
      return applyCreatedAtFilter(filter as CreatedAtFilter, entities, get, objectCache);
    case FilterType.UpdatedAt:
      return applyUpdatedAtFilter(filter as UpdatedAtFilter, entities, get, objectCache);
    case FilterType.Space:
      return applySpacesFilter(filter as SpacesFilter, entities, get, objectCache);
    case FilterType.State:
      return applyStateFilter(filter as StateFilter, entities, get, objectCache);
    case FilterType.Tag:
      return applyLabelsFilter(filter as TagFilter, entities, get, objectCache);
    case FilterType.Person:
      return applyPersonPicker(filter as PersonFilter, entities, get, objectCache);
    case FilterType.Company:
      return applyCompanyFilter(filter as CompanyFilter, entities, get, objectCache);
  }
}

function applyFilterChain<T extends Entity>(
  chain: FilterChain,
  entities: T[],
  get: GetRecoilValue,
  objectCache: Record<string, unknown>
): T[] {
  const and = chain.operation === 'and';
  const andedResults: T[][] = [];
  const filters = chain.filters;

  for (const filter of filters) {
    const results = applyFilter(filter, entities, get, objectCache);
    if (and && andedResults.length) {
      const prevResult = andedResults.pop()!;
      andedResults.push(prevResult.filter(e => results.includes(e)));
    } else {
      andedResults.push(results);
    }
  }
  return uniqBy(andedResults.flat(), 'id');
}

export function getFilterUrlParam(id: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(id.includes('filters') ? id : `filters_${id}`);
}

export const filterChainState = atomFamily<FilterChain, string>({
  key: 'FilterChainState',
  default: { filters: [], operation: 'and' },
  effects: key => [filterEffect(`filters_${key}`, true)],
});

export function filterLoadedEntities(
  entities: Entity[],
  filterId: string,
  get: GetRecoilValue
): Entity[] {
  const filterChain = get(filterChainState(filterId));
  if (!filterChain.filters.length) {
    return entities;
  }

  return applyFilterChain(filterChain, entities, get, {});
}

export function filterEntities(
  entityIds: string[],
  filterId: string,
  get: GetRecoilValue,
  options?: {
    entityType?: string;
  }
): string[] {
  let entities = get(entitiesSelector(entityIds));
  if (options?.entityType) {
    entities = entities.filter(e => {
      switch (options.entityType) {
        case 'work-items':
          return isIssue(e);
        case 'initiatives':
          return isInitiative(e);
        case 'feedback':
          return isFeedback(e);
        case 'documents':
          return isDocument(e);
        case 'releases':
          return isRelease(e);
        default:
          return true;
      }
    });
  }

  const filterChain = get(filterChainState(filterId));
  if (!filterChain.filters.length) {
    return entities.map(e => e.id);
  }

  return applyFilterChain(filterChain, entities, get, {}).map(e => e.id);
}
