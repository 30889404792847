import cn from 'classnames';
import * as React from 'react';
import upLeft from '../../assets/images/new/arrow_up_left.svg?url';
import upRight from '../../assets/images/new/arrow_up_right.svg';
import upRightCurvy from '../../assets/images/new/arrow_up_right_curvy.svg';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Hotkey } from '../../components/new/hotkey';
import { Icon, IconSize } from '../../components/new/icon';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { trackerPageLoad } from '../../tracker';
import styles from './onboardingScreen.module.scss';

export function FeedbackStep({ onNext }: { onNext: () => void }) {
  useComponentDidMount(() => {
    trackerPageLoad('TutorialFeedback');
  });

  return (
    <div className={cn('colAlignCenter')} style={{ maxWidth: 1200 }}>
      <div className={cn('headingXL', styles.heading)}>
        Connect user feedback directly to your work
      </div>
      <div className={styles.feedbackGrid}>
        <div className={cn(styles.image, styles.rowSpanImage)}>
          <img
            className={styles.onboardingImage}
            src="https://assets.kitemaker.co/onboarding/feedback_screen_20231102.png"
          />
        </div>
        <div className={styles.image}>
          <img
            className={styles.onboardingImage}
            src="https://assets.kitemaker.co/onboarding/link_insights_20231102.png"
          />
        </div>
        <div className="fullWidth bodyXL slightlyGrayed relative">
          Link insights directly to work items.
          <img
            className={styles.hideOnSmall}
            src={upLeft}
            style={{
              position: 'absolute',
              left: 60,
              bottom: 'calc(100% + 20px)',
              transform: 'rotate(45deg)',
            }}
          />
        </div>
        <div className={cn(styles.image, styles.bottom)}>
          <img
            className={styles.onboardingImage}
            src="https://assets.kitemaker.co/onboarding/insights_tab_20231102.png"
          />
        </div>
        <div className="fullWidth bodyXL slightlyGrayed relative">
          Collect all of your user feedback. Bug reports, feature requests, support messages, notes
          from user research.
          <div className={cn('row mt10 metadataGapL', styles.logos)}>
            <Icon icon="intercom" size={IconSize.Size32} />
            <Icon icon="slack" size={IconSize.Size32} />
            <Icon icon="send_feedback" size={IconSize.Size32} />
          </div>
          <img
            className={styles.hideOnSmall}
            src={upRightCurvy}
            style={{
              position: 'absolute',
              right: 'calc(100% - 35px)',
              bottom: '100%',
            }}
          />
        </div>
        <div className="fullWidth bodyXL slightlyGrayed relative">
          Let user feedback be accessible to everyone and connected to the actual work.
          <img
            src={upRight}
            className={styles.hideOnSmall}
            style={{
              position: 'absolute',
              right: 'calc(100% - 30px)',
              bottom: 'calc(100% + 10px)',
            }}
          />
        </div>
      </div>

      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onNext();
        }}
      >
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          className="fullWidth"
        >
          Continue
        </Button>
      </form>
      <Hotkey
        hotkey="enter"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onNext();
        }}
      />
    </div>
  );
}
