import cn from 'classnames';
import { keyBy } from 'lodash';
import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { mapColor } from '../../../shared/utils/colors';
import { capitalize } from '../../../shared/utils/utils';
import { CommandGroup } from '../../commands';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace, useSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { companiesSelector } from '../../syncEngine/selectors/companies';
import {
  activeCyclesSelector,
  cycleSelector,
  cyclesSelector,
  upcomingCyclesSelector,
} from '../../syncEngine/selectors/cycles';
import { effortsSelector } from '../../syncEngine/selectors/effortLevels';
import { entityTypeString } from '../../syncEngine/selectors/entities';
import { impactsSelector } from '../../syncEngine/selectors/impactLevels';
import {
  activeInitiativesForSpaceSelector,
  initiativesSelector,
} from '../../syncEngine/selectors/intiatives';
import { labelsSelector } from '../../syncEngine/selectors/labels';
import { peopleSelector } from '../../syncEngine/selectors/people';
import { spacesSelector } from '../../syncEngine/selectors/spaces';
import { tagsSelector } from '../../syncEngine/selectors/tags';
import { usersAndMemberSelector } from '../../syncEngine/selectors/users';
import { cycleFilterHotkey, filterHotKey, selfFilterHotKey } from '../../utils/config';
import { FILTER_NONE_ID, FilterableEntityType } from '../../utils/filtering';
import {
  CyclesFilter,
  DateFilter as DateFilterType,
  EffortFilter as EffortFilterType,
  Filter,
  FilterChain,
  FilterType,
  ImpactFilter as ImpactFilterType,
  InitiativesFilter,
  MemberFilter,
  MultiFilter,
  PersonFilter as PersonFilterType,
  SingleFilter,
  SpacesFilter,
  StateFilter as StateFilterType,
  TagFilter as TagFilterType,
  TitleFilter,
  filterChainState,
  isDateFilter,
  isMultiFilter,
  isSingleFilter,
  isTitleFilter,
} from '../../utils/filtering2';
import { Button, ButtonSize, ButtonStyle, IconButton } from './button';
import { ButtonGroup } from './buttonGroup';
import { Company, Person } from './companyPerson';
import { CustomCommand } from './customCommand';
import { EntityCycleTooltip } from './entityMetadata';
import { FilteredListView } from './filteredListView';
import styles from './filters2.module.scss';
import { Icon } from './icon';
import { KeyboardShortcut } from './keyboardShortcut';
import { KeyNavigationProvider } from './keyNavigation';
import { LISTVIEW_ID } from './listView';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './menu/dropdownMenu';
import menuStyles from './menu/menu.module.scss';
import Avatar, { AvatarSize } from './metadata/avatar';
import { CycleIcon } from './metadata/cycle';
import { InitiativeIcon } from './metadata/initiative';
import Pill from './metadata/pill';
import { MetadataSize } from './metadata/size';
import { CompanyPersonPicker, CompanyPersonPickerOptions } from './pickers/companyPersonPicker';
import { CyclePicker } from './pickers/cyclesPicker';
import { EffortPicker } from './pickers/effortPicker';
import { ImpactPicker } from './pickers/impactPicker';
import { InitiativePicker } from './pickers/initiativePicker';
import { LabelPicker } from './pickers/labelPicker';
import { MemberPicker } from './pickers/memberPicker';
import { SpacePicker } from './pickers/spacePicker';
import { StatePicker, iconForState } from './pickers/statePicker';
import { TagPicker } from './pickers/tagPicker';
import Popover from './popover';
import { TextInput } from './textInput';
import { MetadataTooltip, Tooltip } from './tooltip';
import { User } from './user';
import { WorkItemInitiativeTooltip } from './workItemMetadata';

export enum MenuMode {
  Default,
  Users,
  Labels,
  Impact,
  Effort,
  Initiatives,
  Space,
  FreeText,
  RelativeDate,
  People,
  Companies,
  Tags,
  Cycles,
  State,
}

export interface MenuArgs {
  userFilterType?: FilterType.Member | FilterType.Creator;
  dateFilterType?: FilterType.CreatedAt | FilterType.UpdatedAt;
  stateLabels?: {
    active: string;
    closed: string;
  };
}

export interface ContentProps {
  id: string;
  entityType?: FilterableEntityType;
  showStateFilter?: boolean;
  args: MenuArgs | null;
  onClose: () => void;
  onModeChanged: (mode: MenuMode, args?: MenuArgs) => void;
  className?: string;
  options?: {
    disableSpaces?: boolean;
    orgLevel?: boolean;
    freeTextTitleOnly?: boolean;
  };
}

export function addToPickerFilter(
  previousFilters: Filter[],
  index: number,
  id: string,
  filterType: FilterType
) {
  const filter = previousFilters[index] as MultiFilter | SingleFilter | undefined;
  if (!filter) {
    return [...previousFilters, { type: filterType, ids: [id], modifier: 'any-of' }] as Filter[];
  }
  const ret = [
    ...previousFilters.slice(0, index),
    { ...filter, ids: [...filter.ids, id] },
    ...previousFilters.slice(index + 1),
  ] as Filter[];
  return ret;
}

export function removeFromPickerFilter(
  previousFilters: Filter[],
  index: number,
  match: (value: string) => boolean
): Filter[] {
  const filter = previousFilters[index] as MultiFilter | SingleFilter | undefined;

  if (!filter) {
    return previousFilters;
  }

  const newIds = filter.ids.filter(id => !match(id));

  if (newIds.length === 0) {
    const res = [...previousFilters];
    res.splice(index, 1);
    return res;
  }

  return [
    ...previousFilters.slice(0, index),
    { ...filter, ids: newIds },
    ...previousFilters.slice(index + 1),
  ] as Filter[];
}

export function findFilter<T extends Filter>(
  _filter: Filter | null,
  _match: (filter: Filter) => boolean
): T | null {
  return null;
}

function MemberPickerWrapper({
  id,
  index,
  filterType,
  placeholder,
  onClose,
}: {
  id: string;
  index: number;
  filterType: FilterType;
  placeholder?: string;
  onClose: () => void;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as MemberFilter)?.ids ?? [] };

  return (
    <MemberPicker
      disableCreation
      additionalItemsAtStart
      additionalItems={[
        {
          id: FILTER_NONE_ID,
          name: 'none',
          contents: (
            <div className="row">
              <Icon icon="member" className="mr8" /> No {filterType}
            </div>
          ),
        },
      ]}
      filterPlaceholder={placeholder ?? capitalize(filterType)}
      placeholder={`No users found`}
      state={state}
      onDone={onClose}
      onMemberAdded={(_, memberId) => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(previous.filters, index, memberId, filterType);
          return { ...previous, filters };
        });
      }}
      onMemberRemoved={(_, memberId) => {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(previous.filters, index, id => id === memberId);
          return { ...previous, filters };
        });
      }}
    />
  );
}

function UserContents({ onClose, id, args }: ContentProps) {
  const filterType = args?.userFilterType ?? FilterType.Member;
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;
  const index = React.useMemo(() => {
    return filters.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MemberPickerWrapper index={index} id={id} onClose={onClose} filterType={filterType} />;
}

function LabelPickerWrapper({
  id,
  index,
  orgLevel,
  onClose,
}: {
  id: string;
  index: number;
  orgLevel?: boolean;
  onClose: () => void;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as MultiFilter)?.ids ?? [] };

  return (
    <LabelPicker
      disableCreation
      orgLevel={orgLevel}
      filterPlaceholder="Labels"
      state={state}
      additionalItemsFirst
      additionalItems={[
        {
          id: FILTER_NONE_ID,
          name: 'none',
          contents: (
            <div className="row">
              <Icon icon="label" className="mr8" /> No labels
            </div>
          ),
        },
      ]}
      onDone={onClose}
      onLabelAdded={(_, labelId) => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(previous.filters, index, labelId, FilterType.Label);
          return { ...previous, filters };
        });
      }}
      onLabelRemoved={(_, labelId) => {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(previous.filters, index, id => id === labelId);
          return { ...previous, filters };
        });
      }}
    />
  );
}

function LabelContents({ onClose, id, options }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;
  const index = React.useMemo(() => {
    return filters.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LabelPickerWrapper orgLevel={options?.orgLevel} id={id} index={index} onClose={onClose} />
  );
}

function InitiativePickerWrapper({
  onClose,
  index,
  id,
}: {
  onClose: () => void;
  index: number;
  id: string;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as MultiFilter)?.ids ?? [] };

  return (
    <InitiativePicker
      filterPlaceholder="Initiatives"
      disableCreation
      state={state}
      additionalItemsFirst
      additionalItems={[
        {
          id: FILTER_NONE_ID,
          title: 'none',
          contents: (
            <div className="row">
              <Icon icon="initiative" className="mr8" /> No initiatives
            </div>
          ),
        },
      ]}
      onDone={onClose}
      onInitiativeAdded={(_, initiativeId) => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(
            previous.filters,
            index,
            initiativeId,
            FilterType.Initiative
          );
          return { ...previous, filters };
        });
      }}
      onInitiativeRemoved={(_, initiativeId) => {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(
            previous.filters,
            index,
            id => id === initiativeId
          );
          return { ...previous, filters };
        });
      }}
    />
  );
}

function InitiativeContents({ onClose, id }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;

  const index = React.useMemo(() => {
    return filters.length;
  }, []);

  return <InitiativePickerWrapper onClose={onClose} id={id} index={index} />;
}

function ImpactPickerWrapper({
  onClose,
  index,
  id,
}: {
  onClose: () => void;
  index: number;
  id: string;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as ImpactFilterType)?.ids ?? [] };

  return (
    <ImpactPicker
      filterPlaceholder="Impact"
      state={state}
      additionalItemsFirst
      additionalItems={[
        {
          id: FILTER_NONE_ID,
          title: 'none',
          contents: (
            <div className="row">
              <Icon icon="impact" className="mr8" /> No impact
            </div>
          ),
        },
      ]}
      onDone={onClose}
      onImpactAdded={(_, impactId) => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(previous.filters, index, impactId, FilterType.Impact);
          return { ...previous, filters };
        });
      }}
      onImpactRemoved={(_, impactId) => {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(previous.filters, index, id => id === impactId);
          return { ...previous, filters };
        });
      }}
    />
  );
}

function ImpactContents({ onClose, id }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;

  const index = React.useMemo(() => {
    return filters.length;
  }, []);

  return <ImpactPickerWrapper onClose={onClose} index={index} id={id} />;
}

function EffortPickerWrapper({
  onClose,
  index,
  id,
}: {
  onClose: () => void;
  index: number;
  id: string;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as EffortFilterType)?.ids ?? [] };

  return (
    <EffortPicker
      filterPlaceholder="Effort"
      state={state}
      onDone={onClose}
      additionalItems={[
        {
          id: FILTER_NONE_ID,
          title: 'none',
          contents: (
            <div className="row">
              <Icon icon="effort" className="mr8" /> No effort
            </div>
          ),
        },
      ]}
      additionalItemsFirst
      onEffortAdded={(_, effortId) => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(previous.filters, index, effortId, FilterType.Effort);
          return { ...previous, filters };
        });
      }}
      onEffortRemoved={(_, effortId) => {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(previous.filters, index, id => id === effortId);
          return { ...previous, filters };
        });
      }}
    />
  );
}

function EffortContents({ onClose, id }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;

  const index = React.useMemo(() => {
    return filters.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EffortPickerWrapper onClose={onClose} index={index} id={id} />;
}

function SpacePickerWraper({
  onClose,
  index,
  id,
}: {
  onClose: () => void;
  index: number;
  id: string;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as SpacesFilter)?.ids ?? [] };

  return (
    <SpacePicker
      multi
      filterPlaceholder="Space"
      additionalItemsFirst
      additionalItems={[
        {
          id: FILTER_NONE_ID,
          title: 'none',
          contents: <div className="row">No space</div>,
        },
      ]}
      state={state}
      onDone={onClose}
      onSpaceAdded={(_, spaceId) => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(previous.filters, index, spaceId, FilterType.Space);
          return { ...previous, filters };
        });
      }}
      onSpaceRemoved={(_, spaceId) => {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(previous.filters, index, id => id === spaceId);
          return { ...previous, filters };
        });
      }}
    />
  );
}

function SpacesContents({ onClose, id }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;

  const index = React.useMemo(() => {
    return filters.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SpacePickerWraper onClose={onClose} index={index} id={id} />;
}

function FreeTextContents({ id, onClose }: ContentProps) {
  const setFilterChain = useSetRecoilState(filterChainState(id));
  const [value, setValue] = React.useState('');

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        setFilterChain(previous => {
          return {
            ...previous,
            filters: [
              ...previous.filters,
              { type: FilterType.Title, text: value, modifier: 'contains' } as TitleFilter,
            ],
          };
        });
        onClose();
      }}
    >
      <div className={styles.freeTextFilter}>
        <div className={cn('bodyM', 'oneLine', styles.label)}>Filter by</div>
        <div className="fullWidth mt12"></div>
        <TextInput
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          className="fullWidth"
          autoFocus
        />
        <div className="rowEnd mt8">
          <Button
            size={ButtonSize.Small}
            type="button"
            onClick={onClose}
            buttonStyle={ButtonStyle.Bare}
          >
            Cancel
          </Button>
          <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Primary}>
            Apply
          </Button>
        </div>
      </div>
    </form>
  );
}

const defaultItems = [
  { id: '0', contents: <>Today</>, name: 'Today', aliases: ['0'], value: 0 },
  { id: '1', contents: <>Yesterday</>, name: 'Yesterday', aliases: ['1'], value: 1 },
  { id: '7', contents: <>7 days</>, aliases: ['week'], value: 7 },
  { id: '14', contents: <>14 days</>, aliases: ['fortnight'], value: 14 },
  { id: '30', contents: <>30 days</>, aliases: ['month'], value: 30 },
];

function DatePicker({
  direction,
  filterType,
  onClose,
  onSelected,
}: {
  direction: 'before' | 'after';
  filterType: FilterType;
  onClose: () => void;
  onSelected: (value: number) => void;
}) {
  const [filterText, setFilterText] = React.useState('');
  const [items, setItems] = React.useState(defaultItems);

  React.useEffect(() => {
    if (filterText === '') {
      setItems(defaultItems);
      return;
    }

    const options = [...defaultItems];

    const [firstComponent] = filterText.split(' ');
    const filterAsNumber = Number(firstComponent);
    if (filterAsNumber && filterAsNumber > 1) {
      options.push({
        id: `${filterAsNumber}`,
        value: filterAsNumber,
        contents: <>{`${filterAsNumber} days ago`}</>,
        name: `${filterAsNumber} days ago`,
        aliases: [],
      });
      options.push({
        id: `${filterAsNumber * 7}`,
        value: filterAsNumber * 7,
        contents: <>{`${filterAsNumber} weeks ago`}</>,
        aliases: [],
      });
      options.push({
        id: `${filterAsNumber * 30}`,
        value: filterAsNumber * 30,
        contents: <>{`${filterAsNumber} months ago`}</>,
        aliases: [],
      });
    }
    setItems(options);
  }, [filterText]);

  const placeholder = `${direction === 'before' ? 'not ' : ''}${
    filterType === FilterType.CreatedAt ? 'created' : 'updated'
  } since "x" days ago`;

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <FilteredListView
        filterPlaceholder={capitalize(placeholder)}
        onFilterChanged={setFilterText}
        itemClassName={menuStyles.item}
        propertiesToSearch={['name', 'aliases']}
        items={items.map(i => ({
          ...i,
          onSelected: () => {
            onSelected(i.value);
            onClose();
          },
        }))}
      />
    </KeyNavigationProvider>
  );
}

function RelativeDateContents({ id, onClose, args }: ContentProps) {
  const setFilterChain = useSetRecoilState(filterChainState(id));
  const filterType = args!.dateFilterType!;

  return (
    <DatePicker
      direction={'after'}
      filterType={filterType}
      onClose={onClose}
      onSelected={(value: number) => {
        setFilterChain(previous => ({
          ...previous,
          filters: [
            ...previous.filters,
            { type: filterType, duration: value, modifier: 'is-after' },
          ],
        }));
      }}
    />
  );
}

function TagPickerWrapper({
  onClose,
  index,
  id,
}: {
  onClose: () => void;
  index: number;
  id: string;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as TagFilterType)?.ids ?? [] };

  return (
    <TagPicker
      filterPlaceholder="Tags"
      state={state}
      onDone={onClose}
      additionalItemsFirst
      additionalItems={[
        {
          id: FILTER_NONE_ID,
          title: 'none',
          contents: <div className="row">No tags</div>,
        },
      ]}
      onTagAdded={(_, tagId) => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(previous.filters, index, tagId, FilterType.Tag);
          return { ...previous, filters };
        });
      }}
      onTagRemoved={(_, tagId) => {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(previous.filters, index, id => id === tagId);
          return { ...previous, filters };
        });
      }}
    />
  );
}

function TagContents({ onClose, id }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;
  const index = React.useMemo(() => {
    return filters.length;
  }, []);

  return <TagPickerWrapper onClose={onClose} index={index} id={id} />;
}

function PersonPickerWrapper({
  onClose,
  index,
  id,
}: {
  onClose: () => void;
  index: number;
  id: string;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as PersonFilterType)?.ids ?? [] };

  return (
    <CompanyPersonPicker
      disableCreation
      noEdit
      state={state}
      onDone={onClose}
      onPersonAdded={personId => {
        if (personId) {
          setFilterChain(previous => {
            const filters = addToPickerFilter(previous.filters, index, personId, FilterType.Person);
            return { ...previous, filters };
          });
        }
      }}
      onPersonRemoved={personId => {
        if (personId) {
          setFilterChain(previous => {
            const filters = removeFromPickerFilter(previous.filters, index, id => id === personId);
            return { ...previous, filters };
          });
        }
      }}
      onCompanyAdded={() => {
        throw new Error('Function not implemented.');
      }}
      onCompanyRemoved={() => {
        throw new Error('Function not implemented.');
      }}
      options={CompanyPersonPickerOptions.People}
    />
  );
}

function PeopleContents({ onClose, id }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));

  const filters = filterChain.filters;
  const index = React.useMemo(() => {
    return filters.length;
  }, []);

  return <PersonPickerWrapper onClose={onClose} index={index} id={id} />;
}

function CompanyPickerWrapper({
  onClose,
  index,
  id,
}: {
  onClose: () => void;
  index: number;
  id: string;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as MultiFilter)?.ids ?? [] };

  return (
    <CompanyPersonPicker
      disableCreation
      noEdit
      state={state}
      onDone={onClose}
      onCompanyAdded={companyId => {
        if (companyId) {
          setFilterChain(previous => {
            const filters = addToPickerFilter(
              previous.filters,
              index,
              companyId,
              FilterType.Company
            );
            return { ...previous, filters };
          });
        }
      }}
      onCompanyRemoved={companyId => {
        if (companyId) {
          setFilterChain(previous => {
            const filters = removeFromPickerFilter(previous.filters, index, id => id === companyId);
            return { ...previous, filters };
          });
        }
      }}
      onPersonAdded={() => {
        throw new Error('Function not implemented.');
      }}
      onPersonRemoved={() => {
        throw new Error('Function not implemented.');
      }}
      options={CompanyPersonPickerOptions.Companies}
    />
  );
}

function CompanyContents({ onClose, id }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;
  const index = React.useMemo(() => {
    return filters.length;
  }, []);

  return <CompanyPickerWrapper onClose={onClose} index={index} id={id} />;
}

function CyclePickerWrapper({
  onClose,
  index,
  id,
}: {
  onClose: () => void;
  index: number;
  id: string;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as CyclesFilter)?.ids ?? [] };

  return (
    <CyclePicker
      state={state}
      onDone={onClose}
      onCycleAdded={(_, cycleId: string) => {
        setFilterChain(previous => {
          const filters = addToPickerFilter(previous.filters, index, cycleId, FilterType.Cycle);
          return { ...previous, filters };
        });
      }}
      onCycleRemoved={(_, cycleId: string) => {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(previous.filters, index, id => id === cycleId);
          return { ...previous, filters };
        });
      }}
    />
  );
}

function CycleContents({ onClose, id }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;

  const index = React.useMemo(() => {
    return filters.length;
  }, []);

  return <CyclePickerWrapper onClose={onClose} index={index} id={id} />;
}

function StatePickerWrapper({
  onClose,
  index,
  id,
  args,
}: {
  onClose: () => void;
  index: number;
  id: string;
  args?: MenuArgs | null;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  const state = { DUMMY: (filters[index] as StateFilterType)?.ids ?? [] };

  return (
    <StatePicker
      state={state}
      labels={args?.stateLabels}
      onDone={onClose}
      onStateAdded={function (_, state: string): void {
        setFilterChain(previous => {
          const filters = addToPickerFilter(previous.filters, index, state, FilterType.State);
          return { ...previous, filters };
        });
      }}
      onStateRemoved={function (_, state: string): void {
        setFilterChain(previous => {
          const filters = removeFromPickerFilter(previous.filters, index, id => id === state);
          return { ...previous, filters };
        });
      }}
    />
  );
}
function StateContents({ onClose, id, args }: ContentProps) {
  const filterChain = useRecoilValue(filterChainState(id));
  const filters = filterChain.filters;

  const index = React.useMemo(() => {
    return filters.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StatePickerWrapper args={args} onClose={onClose} index={index} id={id} />;
}

export function MenuContents({
  mode,
  DefaultContents,
  ...rest
}: { mode: MenuMode; DefaultContents: React.ComponentType<ContentProps> } & ContentProps) {
  switch (mode) {
    case MenuMode.Users:
      return <UserContents {...rest} />;
    case MenuMode.Labels:
      return <LabelContents {...rest} />;
    case MenuMode.Impact:
      return <ImpactContents {...rest} />;
    case MenuMode.Effort:
      return <EffortContents {...rest} />;
    case MenuMode.Initiatives:
      return <InitiativeContents {...rest} />;
    case MenuMode.FreeText:
      return <FreeTextContents {...rest} />;
    case MenuMode.RelativeDate:
      return <RelativeDateContents {...rest} />;
    case MenuMode.Space:
      return <SpacesContents {...rest} />;
    case MenuMode.Tags:
      return <TagContents {...rest} />;
    case MenuMode.Companies:
      return <CompanyContents {...rest} />;
    case MenuMode.People:
      return <PeopleContents {...rest} />;
    case MenuMode.Cycles:
      return <CycleContents {...rest} />;
    case MenuMode.State:
      return <StateContents {...rest} />;
    case MenuMode.Default:
      return <DefaultContents {...rest} />;
  }
}

export function FilterMenu({
  entityType,
  showStateFilter,
  id,
  DefaultContents,
  buttonStyle,
  compact,
  className,
  style,
  options,
  icon = 'filter',
}: {
  entityType?: FilterableEntityType;
  showStateFilter?: boolean;
  buttonStyle?: ButtonStyle;
  id: string;
  DefaultContents: React.ComponentType<ContentProps>;
  compact?: boolean;
  style?: React.CSSProperties;
  className?: string;
  options?: {
    disableSpaces?: boolean;
    freeTextTitleOnly?: boolean;
    orgLevel?: boolean;
  };
  icon?: string;
}) {
  const user = useCurrentUser();
  const setFilterChain = useSetRecoilState(filterChainState(id));
  const [mode, setMode] = React.useState(MenuMode.Default);
  const [args, setArgs] = React.useState<MenuArgs | null>(null);

  const filterChain = useRecoilValue(filterChainState(id));

  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const space = useMaybeSpace();

  React.useEffect(() => {
    if (!menuOpen) {
      setTimeout(() => {
        setMode(MenuMode.Default);
        setArgs(null);
      }, 300);
    }
  }, [menuOpen]);

  const popoverContent = (
    <div
      className={cn('menuMedium', styles.popover, {
        menuPicker: [
          MenuMode.Labels,
          MenuMode.Users,
          MenuMode.Companies,
          MenuMode.People,
          MenuMode.Tags,
          MenuMode.Effort,
          MenuMode.Impact,
          MenuMode.Cycles,
        ].includes(mode),
      })}
    >
      <MenuContents
        mode={mode}
        entityType={entityType}
        showStateFilter={showStateFilter}
        id={id}
        args={args}
        onModeChanged={(mode, args) => {
          setArgs(args ?? null);
          setMode(mode);
        }}
        onClose={closeMenu}
        DefaultContents={DefaultContents}
        options={options}
      />
    </div>
  );

  return (
    <>
      <Popover
        content={popoverContent}
        asChild
        contentOptions={{
          onClick: e => {
            e.stopPropagation();
          },
          side: 'bottom',
          align: 'start',
        }}
        open={menuOpen}
        onOpenChange={setMenuOpen}
      >
        <div>
          {!compact && (
            <Tooltip
              asChild
              content={
                <div>
                  Set filters <KeyboardShortcut shortcut={filterHotKey} />
                </div>
              }
            >
              <Button
                icon={icon}
                className={className}
                style={style}
                buttonStyle={buttonStyle ?? ButtonStyle.BareSubtle}
                fauxActive={menuOpen}
                onClick={() => setMenuOpen(true)}
              >
                {filterChain.filters.length > 0 ? 'Add Filter' : 'Filter'}
              </Button>
            </Tooltip>
          )}
          {compact && (
            <IconButton
              icon={icon}
              className={className}
              style={style}
              buttonStyle={buttonStyle ?? ButtonStyle.BareSubtle}
              fauxActive={menuOpen}
              onClick={() => setMenuOpen(true)}
            />
          )}
        </div>
      </Popover>
      <CustomCommand
        command={{
          id: `filter-board-${id}`,
          icon: 'filter',
          group: CommandGroup.Board,
          description: 'Filter',
          hotkey: filterHotKey,
          handler: () => {
            setMenuOpen(true);
          },
        }}
      />
      <CustomCommand
        command={{
          id: `filter-board-my-${id}`,
          group: CommandGroup.Board,
          description: `Filter my work`,
          icon: 'my_work',
          hotkey: selfFilterHotKey,
          handler: () => {
            setFilterChain(previous => {
              if (previous.filters.length === 0) {
                const filters = addToPickerFilter(previous.filters, 0, user.id, FilterType.Member);
                return { ...previous, filters };
              }
              const filter = previous.filters[0] as MultiFilter | SingleFilter | undefined;
              if (filter?.type === FilterType.Member && filter?.ids.includes(user.id)) {
                return { ...previous, filters: [] };
              }
              return previous;
            });
          },
        }}
      />
      {space?.cyclesEnabled && space?.activeCycleId && (
        <CustomCommand
          command={{
            id: `filter-board-cycle-${id}`,
            group: CommandGroup.Board,
            description: entityType
              ? `Show cycle ${entityTypeString(entityType)}s`
              : 'Show cycle items',
            icon: 'cycle_current',
            hotkey: cycleFilterHotkey,
            handler: () => {
              setFilterChain(previous => {
                if (previous.filters.length === 0) {
                  const filters = addToPickerFilter(
                    previous.filters,
                    0,
                    space.activeCycleId!,
                    FilterType.Cycle
                  );
                  return { ...previous, filters };
                }
                const filter = previous.filters[0] as MultiFilter | SingleFilter | undefined;
                if (
                  filter?.type === FilterType.Cycle &&
                  filter?.ids.includes(space.activeCycleId!)
                ) {
                  return { ...previous, filters: [] };
                }
                return previous;
              });
            },
          }}
        />
      )}
    </>
  );
}

function FilterComponentContents({
  ids,
  typeSingular,
  typePlural,
  render,
  children,
}: {
  ids: string[];
  typeSingular: string;
  typePlural: string;
  children: (closeMenu: () => void) => React.ReactNode;
  render: (id: string, iconOnly: boolean) => React.ReactNode;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const ref = React.useRef<HTMLDivElement>(null);
  const yRef = React.useRef(0);
  const [initialX, setInitialX] = React.useState(0);
  const [offset, setOffset] = React.useState(0);

  // this is some magic to keep the dropdown menu in one place even as the trigger changes location
  React.useLayoutEffect(() => {
    if (yRef.current !== ref.current?.getBoundingClientRect().top) {
      setOffset(0);
    } else {
      setOffset(initialX - (ref.current?.getBoundingClientRect().left ?? 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids.length, initialX]);

  if (!ids.length) {
    return null;
  }

  return (
    <DropdownMenu
      open={menuOpen}
      onOpenChange={open => {
        if (open) {
          yRef.current = ref.current?.getBoundingClientRect().top ?? 0;
          setInitialX(ref.current?.getBoundingClientRect().left ?? 0);
        }
        setMenuOpen(open);
      }}
    >
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <Button className={cn(styles.component, styles.filterButton)}>
          <div className="row gap8 noMinWidth overflowHidden" ref={ref}>
            {ids.length < 3 &&
              ids.map(id => {
                if (id === FILTER_NONE_ID) {
                  return (
                    <div key={id} className="row noMinWidth overflowHidden">
                      No {typeSingular}
                    </div>
                  );
                }

                const rendered = render(id, false);
                if (!rendered) {
                  return null;
                }
                return (
                  <div key={id} className="row noMinWidth overflowHidden">
                    {rendered}
                  </div>
                );
              })}
            {ids.length >= 3 && (
              <div className="row">
                <div className="row mr4">
                  {ids.filter(id => id !== FILTER_NONE_ID).map(id => render(id, true))}
                </div>
                <span>
                  {ids.length} {typePlural}
                </span>
              </div>
            )}
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" onClick={e => e.stopPropagation()} alignOffset={offset}>
        {children(closeMenu)}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function SimpleFilter({
  name,
  color,
  icon,
  iconOnly,
}: {
  name: string;
  color: string;
  icon: string;
  iconOnly: boolean;
}) {
  return (
    <div className={cn(styles.simpleFilter, styles.truncatedFilter)}>
      <Icon icon={icon} style={{ fill: color }} />
      {!iconOnly && <span className="ellipsis ml4">{name}</span>}
    </div>
  );
}

function UserFilter({
  ids,
  type,
  index,
  filterId,
}: {
  ids: string[];
  type: FilterType;
  index: number;
  filterId: string;
}) {
  const organization = useOrganization();

  const members = useRecoilValue(
    usersAndMemberSelector({ organizationId: organization.id, userIds: ids })
  );
  const membersById = keyBy(members, 'id');

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular={type}
      typePlural="users"
      render={(id, iconOnly) => {
        const member = membersById[id];
        if (!member) {
          return null;
        }

        return (
          <div className={cn(styles.truncatedFilter, 'row')}>
            {iconOnly ? (
              <Avatar name={member.name || member.username} img={member.avatar} />
            ) : (
              <User
                member={member.member!}
                className="headingS oneLine ellipsis ml4"
                user={member}
              />
            )}
          </div>
        );
      }}
    >
      {closeMenu => (
        <MemberPickerWrapper index={index} id={filterId} onClose={closeMenu} filterType={type} />
      )}
    </FilterComponentContents>
  );
}

function LabelFilter({ ids, index, filterId }: { ids: string[]; index: number; filterId: string }) {
  const labels = useRecoilValue(labelsSelector(ids));
  const labelsById = keyBy(labels, 'id');

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="label"
      typePlural="labels"
      render={(id, iconOnly) => {
        const label = labelsById[id];
        const color = mapColor(label.color);
        return (
          <SimpleFilter
            key={label.id}
            icon="label"
            color={color}
            name={label.name}
            iconOnly={iconOnly}
          />
        );
      }}
    >
      {closeMenu => <LabelPickerWrapper id={filterId} index={index} onClose={closeMenu} />}
    </FilterComponentContents>
  );
}

function TagFilter({ ids, index, filterId }: { ids: string[]; index: number; filterId: string }) {
  const tags = useRecoilValue(tagsSelector(ids));
  const tagsById = keyBy(tags, 'id');
  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="tag"
      typePlural="tags"
      render={(id, iconOnly) => {
        const tag = tagsById[id];
        const color = mapColor(tag.color);
        return (
          <SimpleFilter
            key={tag.id}
            icon="label"
            color={color}
            name={tag.name}
            iconOnly={iconOnly}
          />
        );
      }}
    >
      {closeMenu => <TagPickerWrapper id={filterId} index={index} onClose={closeMenu} />}
    </FilterComponentContents>
  );
}

function CompanyFilter({
  ids,
  index,
  filterId,
}: {
  ids: string[];
  index: number;
  filterId: string;
}) {
  const companies = useRecoilValue(companiesSelector(ids));
  const companiesById = keyBy(companies, 'id');

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="company"
      typePlural="companies"
      render={id => {
        const company = companiesById[id];
        return (
          <div key={company.id} className={cn(styles.simpleFilter, 'gap4')}>
            <Company companyId={id} size={AvatarSize.Size20} />
          </div>
        );
      }}
    >
      {closeMenu => <CompanyPickerWrapper id={filterId} index={index} onClose={closeMenu} />}
    </FilterComponentContents>
  );
}

function PersonFilter({
  ids,
  index,
  filterId,
}: {
  ids: string[];
  index: number;
  filterId: string;
}) {
  const people = useRecoilValue(peopleSelector(ids));
  const peopleById = keyBy(people, 'id');

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="person"
      typePlural="people"
      render={id => {
        const person = peopleById[id];
        return (
          <div key={person.id} className={cn(styles.simpleFilter, 'gap4')}>
            <Person personId={id} size={AvatarSize.Size20} />
          </div>
        );
      }}
    >
      {closeMenu => <PersonPickerWrapper id={filterId} index={index} onClose={closeMenu} />}
    </FilterComponentContents>
  );
}

function InitiativeFilter({
  ids,
  index,
  filterId,
}: {
  ids: string[];
  index: number;
  filterId: string;
}) {
  const initiatives = useRecoilValue(initiativesSelector(ids));
  const initiativesById = keyBy(initiatives, 'id');

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="initiative"
      typePlural="initiatives"
      render={(id, iconOnly) => {
        const initiative = initiativesById[id];
        const color = mapColor(initiative.color);
        return (
          <SimpleFilter
            key={initiative.id}
            icon="initiative"
            color={color}
            name={initiative.title}
            iconOnly={iconOnly}
          />
        );
      }}
    >
      {closeMenu => <InitiativePickerWrapper onClose={closeMenu} index={index} id={filterId} />}
    </FilterComponentContents>
  );
}

function ImpactFilter({
  ids,
  index,
  filterId,
}: {
  ids: string[];
  index: number;
  filterId: string;
}) {
  const impacts = useRecoilValue(impactsSelector(ids));
  const impactsById = keyBy(impacts, 'id');

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="impact"
      typePlural="impacts"
      render={(id, iconOnly) => {
        const impact = impactsById[id];
        const color = mapColor(impact.color);
        return (
          <SimpleFilter
            key={impact.id}
            icon="impact"
            color={color}
            name={impact.abbrevation}
            iconOnly={iconOnly}
          />
        );
      }}
    >
      {closeMenu => <ImpactPickerWrapper onClose={closeMenu} index={index} id={filterId} />}
    </FilterComponentContents>
  );
}

function EffortFilter({
  ids,
  index,
  filterId,
}: {
  ids: string[];
  index: number;
  filterId: string;
}) {
  const efforts = useRecoilValue(effortsSelector(ids));
  const effortsById = keyBy(efforts, 'id');

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="effort"
      typePlural="efforts"
      render={(id, iconOnly) => {
        const effort = effortsById[id];
        const color = mapColor(effort.color);
        return (
          <SimpleFilter
            key={effort.id}
            icon="effort"
            color={color}
            name={effort.abbrevation}
            iconOnly={iconOnly}
          />
        );
      }}
    >
      {closeMenu => <EffortPickerWrapper onClose={closeMenu} index={index} id={filterId} />}
    </FilterComponentContents>
  );
}

function StateFilter({
  ids,
  index,
  filterId,
  args,
}: {
  ids: string[];
  index: number;
  filterId: string;
  args?: MenuArgs | null;
}) {
  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="type"
      typePlural="types"
      render={id => (
        <SimpleFilter
          key={id}
          icon={iconForState(id)}
          name={capitalize(args?.stateLabels?.[id as 'active' | 'closed'] ?? id)}
          color="gray"
          iconOnly={false}
        />
      )}
    >
      {closeMenu => (
        <StatePickerWrapper args={args} onClose={closeMenu} index={index} id={filterId} />
      )}
    </FilterComponentContents>
  );
}

function SpaceFilter({ ids, index, filterId }: { ids: string[]; index: number; filterId: string }) {
  const spaces = useRecoilValue(spacesSelector(ids));
  const spacesById = keyBy(spaces, 'id');

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="space"
      typePlural="spaces"
      render={(id, iconOnly) => {
        const space = spacesById[id];
        return (
          <SimpleFilter
            key={space.id}
            icon="workspace"
            name={space?.name ?? ''}
            color="gray"
            iconOnly={iconOnly}
          />
        );
      }}
    >
      {closeMenu => <SpacePickerWraper onClose={closeMenu} index={index} id={filterId} />}
    </FilterComponentContents>
  );
}

function CycleFilter({ ids, index, filterId }: { ids: string[]; index: number; filterId: string }) {
  const organization = useOrganization();
  const cycles = useRecoilValue(cyclesSelector(ids));
  const cycleById = keyBy(cycles, 'id');
  const maybeSpace = useMaybeSpace();
  const activeCycle = useRecoilValue(
    activeCyclesSelector({ spaceId: maybeSpace?.id ?? '', organizationId: organization.id })
  );
  const upcomingCycle = useRecoilValue(
    upcomingCyclesSelector({ spaceId: maybeSpace?.id ?? '', organizationId: organization.id })
  );

  return (
    <FilterComponentContents
      ids={ids}
      typeSingular="cycle"
      typePlural="cycles"
      render={(id, iconOnly) => {
        let cycle;
        let icon;
        if (id === 'current') {
          cycle = activeCycle[0]?.cycle;
          icon = 'cycle_current';
        } else if (id === 'upcoming') {
          cycle = upcomingCycle[0].cycle;
          icon = 'cycle_upcoming';
        } else {
          cycle = cycleById[id];
          icon = id === activeCycle[0]?.cycle?.id ? 'cycle_current' : 'cycle_upcoming';
        }
        return (
          <SimpleFilter
            key={cycle.id}
            icon={icon}
            name={cycle?.title ?? 'Unknown cycle'}
            color={''}
            iconOnly={iconOnly}
          />
        );
      }}
    >
      {closeMenu => <CyclePickerWrapper onClose={closeMenu} index={index} id={filterId} />}
    </FilterComponentContents>
  );
}

function DateFilter({
  duration,
  type,
  index,
  setFilterChain,
}: {
  duration: number;
  type: FilterType;
  index: number;
  setFilterChain: (previous: (previous: FilterChain) => FilterChain) => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  let adjustedOffset = duration;
  let timeString;

  if (adjustedOffset === 0) {
    timeString = 'today';
  } else if (adjustedOffset === 1) {
    timeString = 'yesterday';
  } else if (adjustedOffset % 30 === 0) {
    adjustedOffset = adjustedOffset / 30;
    timeString = `${adjustedOffset} month${adjustedOffset > 0 ? 's' : ''} ago`;
  } else if (adjustedOffset % 7 === 0) {
    adjustedOffset = adjustedOffset / 7;
    timeString = `${adjustedOffset} week${adjustedOffset > 0 ? 's' : ''} ago`;
  } else {
    timeString = `${adjustedOffset} day${adjustedOffset > 0 ? 's' : ''} ago`;
  }

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <Button className={cn(styles.component, styles.filterButton)}>
          <div className={styles.simpleFilter}>{timeString}</div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" onClick={e => e.stopPropagation()} className="menuSmall">
        <DatePicker
          direction={'before'}
          filterType={type}
          onClose={closeMenu}
          onSelected={(value: number) => {
            setFilterChain(previous => {
              const newFilter = { ...(previous.filters[index] as DateFilterType), duration: value };
              const filters = [
                ...previous.filters.slice(0, index),
                newFilter,
                ...previous.filters.slice(index + 1),
              ];
              return {
                ...previous,
                filters,
              } as FilterChain;
            });
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function FilterComponent({
  filter,
  filterId,
  setFilterChain,
  index,
  args,
}: {
  filter: Filter;
  filterId: string;
  setFilterChain: (filter: (previous: FilterChain) => FilterChain) => void;
  index: number;
  args?: MenuArgs | null;
}) {
  switch (filter.type) {
    case FilterType.Member:
      return <UserFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Label:
      return <LabelFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Initiative:
      return <InitiativeFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Creator:
      return <UserFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Impact:
      return <ImpactFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Effort:
      return <EffortFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.State:
      return <StateFilter {...filter} index={index} filterId={filterId} args={args} />;
    case FilterType.Space:
      return <SpaceFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Tag:
      return <TagFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Person:
      return <PersonFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Company:
      return <CompanyFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.Cycle:
      return <CycleFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.WatchedBy:
      return <UserFilter {...filter} index={index} filterId={filterId} />;
    case FilterType.CreatedAt:
      return <DateFilter {...filter} index={index} setFilterChain={setFilterChain} />;
    case FilterType.UpdatedAt:
      return <DateFilter {...filter} index={index} setFilterChain={setFilterChain} />;
    case FilterType.Title:
      return (
        <div className={cn(styles.titleFilter, styles.truncatedFilter)}>
          <div className="ellipsis">{filter.text}</div>
        </div>
      );
    default:
      return <>Unknown filter</>;
  }
}

function FilterButton({
  filter,
  index,
  filterId,
  args,
  setFilterChain,
  onRemove,
  changeModifier,
}: {
  filter: Filter;
  index: number;
  filterId: string;
  args?: MenuArgs | null;
  setFilterChain: (filter: (previous: FilterChain) => FilterChain) => void;
  onRemove: () => void;
  changeModifier: (modifier: string) => void;
}) {
  let menuOptions: React.ReactNode | null = null;
  let modifier;

  if (isSingleFilter(filter)) {
    if (filter.ids.length === 1) {
      menuOptions = (
        <>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => changeModifier('any-of')}>is</DropdownMenuItem>
            <DropdownMenuItem onClick={() => changeModifier('not-any-of')}>is not</DropdownMenuItem>
          </DropdownMenuContent>
          <DropdownMenuTrigger asChild></DropdownMenuTrigger>
        </>
      );
      modifier = filter.modifier === 'not-any-of' ? 'is not' : 'is';
    } else {
      modifier = filter.modifier === 'not-any-of' ? 'is none of' : 'is any of';

      menuOptions = (
        <>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => changeModifier('any-of')}>is any of</DropdownMenuItem>
            <DropdownMenuItem onClick={() => changeModifier('not-any-of')}>
              is none of
            </DropdownMenuItem>
          </DropdownMenuContent>
        </>
      );
    }
  } else if (isMultiFilter(filter)) {
    if (filter.ids.length === 1) {
      menuOptions = (
        <>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => changeModifier('any-of')}>include</DropdownMenuItem>
            <DropdownMenuItem onClick={() => changeModifier('not-any-of')}>
              don't include
            </DropdownMenuItem>
          </DropdownMenuContent>
          <DropdownMenuTrigger asChild></DropdownMenuTrigger>
        </>
      );
      modifier = filter.modifier === 'not-any-of' ? `don't include` : 'include';
    } else {
      switch (filter.modifier) {
        case 'any-of':
          modifier = 'include any of';
          break;
        case 'not-any-of':
          modifier = `don't include`;
          break;
        case 'all-of':
          modifier = 'include all of';
          break;
      }
      menuOptions = (
        <>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => changeModifier('any-of')}>
              include any of
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => changeModifier('not-any-of')}>
              don't include
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => changeModifier('all-of')}>
              include all of
            </DropdownMenuItem>
          </DropdownMenuContent>
        </>
      );
    }
  } else if (isTitleFilter(filter)) {
    menuOptions = (
      <>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => changeModifier('contains')}>contains</DropdownMenuItem>
          <DropdownMenuItem onClick={() => changeModifier('not-contains')}>
            doesn't contain
          </DropdownMenuItem>
        </DropdownMenuContent>
      </>
    );
    modifier = filter.modifier === 'contains' ? 'contains' : `doesn't contain`;
  } else if (isDateFilter(filter)) {
    menuOptions = (
      <>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => changeModifier('is-after')}>since</DropdownMenuItem>
          <DropdownMenuItem onClick={() => changeModifier('is-before')}>before</DropdownMenuItem>
        </DropdownMenuContent>
      </>
    );
    modifier = filter.modifier === 'is-after' ? 'since' : 'before';
  }

  return (
    <ButtonGroup className={styles.filterButtonGroup}>
      <div className={styles.filterPrefix}>{capitalize(filter.type)}</div>
      <DropdownMenu>
        {menuOptions}
        <DropdownMenuTrigger asChild>
          <Button className={cn(styles.modifier, styles.filterButton)}>{modifier}</Button>
        </DropdownMenuTrigger>
      </DropdownMenu>
      <FilterComponent
        filterId={filterId}
        filter={filter}
        index={index}
        setFilterChain={setFilterChain}
        args={args}
      />
      <Tooltip
        content={
          <>
            Remove last filter
            <KeyboardShortcut shortcut={'shift+backspace'} />
          </>
        }
      >
        <IconButton
          icon="exit"
          className={cn('ml4', styles.filterButton, styles.removeFilter)}
          size={ButtonSize.Small}
          onClick={onRemove}
        />
      </Tooltip>
    </ButtonGroup>
  );
}

function FilterOperationButton({ id }: { id: string }) {
  const [filterChain, setFilter] = useRecoilState(filterChainState(id));

  const handleOperationChange = React.useCallback(() => {
    setFilter(previous => ({
      ...previous,
      operation: previous.operation === 'and' ? 'or' : 'and',
    }));
  }, [setFilter]);

  return (
    <Button
      size={ButtonSize.Small}
      buttonStyle={ButtonStyle.SecondarySubtle}
      onClick={handleOperationChange}
      className={styles.filterButton}
    >
      {filterChain.operation.toLocaleUpperCase()}
    </Button>
  );
}

export function Filters({
  id,
  className,
  rightButton,
  children,
  args,
}: {
  id: string;
  className?: string;
  rightButton?: React.ReactNode;
  children?: React.ReactNode;
  args?: MenuArgs | null;
}) {
  const [filterChain, setFilter] = useRecoilState(filterChainState(id));
  const filters = filterChain.filters;

  if (!filters.length) {
    return null;
  }

  const filterButtons = filters.map((fc, index) => (
    <React.Fragment key={index}>
      <FilterButton
        setFilterChain={setFilter}
        index={index}
        filterId={id}
        filter={fc}
        args={args}
        changeModifier={(modifier: string) => {
          setFilter(previous => {
            const filters = previous.filters;
            const filter = filters[index];

            if ((filter as SingleFilter | MultiFilter).modifier) {
              const newFilter = { ...filter, modifier };
              const newfilters = [
                ...filters.slice(0, index),
                newFilter,
                ...filters.slice(index + 1),
              ] as Filter[];
              return { ...previous, filters: newfilters };
            }
            return previous;
          });
        }}
        onRemove={() => {
          setFilter(previous => {
            const filters = previous.filters;
            const newValue = [...filters];
            newValue.splice(index, 1);
            return { ...previous, filters: newValue };
          });
        }}
      />
      {index !== filters.length - 1 && <FilterOperationButton id={id} />}
    </React.Fragment>
  )) as React.ReactNode[];

  if (children) {
    filterButtons.push(children);
  }

  return (
    <div className={cn(styles.filters, 'fullWidth', className ?? '')}>
      <div className={styles.filterButtons}>{filterButtons}</div>
      <Tooltip
        content={
          <>
            Clear all filters
            <KeyboardShortcut shortcut={'backspace'} />
          </>
        }
      >
        <Button
          className="flexAlignStart"
          buttonStyle={ButtonStyle.BareSubtle}
          icon="exit"
          onClick={() => setFilter(previous => ({ ...previous, filters: [] }))}
        >
          Clear all filters
        </Button>
      </Tooltip>
      {rightButton}
      <CustomCommand
        command={{
          // FIXME: group for the board?
          id: `clear-filter-board-${id}`,
          group: CommandGroup.Entities,
          description: 'Clear all filters',
          hotkey: 'backspace',
          handler: () => {
            setFilter(previous => ({ ...previous, filters: [] }));
          },
        }}
      />
      <CustomCommand
        command={{
          // FIXME: group for the board?
          id: `clear-last-filter-board-${id}`,
          group: CommandGroup.Entities,
          description: 'Clear last filter',
          hotkey: 'shift+backspace',
          handler: () => {
            setFilter(previous => ({
              ...previous,
              filters: previous.filters.slice(0, previous.filters.length - 1),
            }));
          },
        }}
      />
    </div>
  );
}

export function QuickFilters({ id, className }: { id: string; className?: string }) {
  const space = useSpace();
  const [filterChain, setFilterChain] = useRecoilState(filterChainState(id));
  const smallScreen = useIsSmallScreen();

  const filters = filterChain.filters;

  const initiatives = useRecoilValue(activeInitiativesForSpaceSelector(space.id));
  const currentCycle = useRecoilValue(cycleSelector(space.activeCycleId));

  if (!initiatives.length || filters.length || smallScreen) {
    return null;
  }

  return (
    <div className={cn(styles.filters, 'overflowScrollX', className ?? '')}>
      {space.cyclesEnabled && currentCycle && (
        <MetadataTooltip
          key={currentCycle.id}
          side="bottom"
          align="start"
          className="followTooltip"
          content={<EntityCycleTooltip cycle={currentCycle} />}
        >
          <Pill
            className={cn('clickable', styles.filterButton)}
            size={MetadataSize.Medium}
            onClick={() => {
              setFilterChain(previous => {
                return {
                  ...previous,
                  filters: [
                    ...previous.filters,
                    {
                      type: FilterType.Cycle,
                      ids: ['current'],
                      modifier: 'any-of',
                    } as CyclesFilter,
                  ],
                };
              });
            }}
          >
            <CycleIcon cycleStatus={currentCycle.cycleStatus} />
            <span>{currentCycle.title}</span>
          </Pill>
        </MetadataTooltip>
      )}
      {initiatives.map(initiative => (
        <MetadataTooltip
          key={initiative.id}
          className="followTooltip"
          side="bottom"
          align="start"
          content={<WorkItemInitiativeTooltip initiative={initiative} />}
        >
          <Pill
            className={cn('clickable', styles.filterButton)}
            size={MetadataSize.Medium}
            onClick={() => {
              setFilterChain(previous => {
                return {
                  ...previous,
                  filters: [
                    ...previous.filters,
                    {
                      type: FilterType.Initiative,
                      ids: [initiative.id],
                      modifier: 'any-of',
                    } as InitiativesFilter,
                  ],
                };
              });
            }}
          >
            <InitiativeIcon color={initiative.color} />
            <span>{initiative.title}</span>
          </Pill>
        </MetadataTooltip>
      ))}
    </div>
  );
}
