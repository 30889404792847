import cn from 'classnames';
import * as React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Descendant, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useReadOnly, useSlateStatic } from 'slate-react';
import { Elements, ImageElement } from '../../../shared/slate/types';
import { CommentButton } from '../../components/new/commentButton';
import { DownloadButton } from '../../components/new/downloadButton';
import { useEnsureFocusedElementIsVisible } from '../../components/new/keyNavigation';
import { LoadingSpinner } from '../../components/new/loadingSpinner';
import { VoidActions } from '../../components/new/voidActions';
import { RetryingImage } from '../../components/retryingImage';
import { useConfiguration } from '../../contexts/configurationContext';
import { Modals, useModals } from '../../contexts/modalContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import UploadPlaceholder from '../../slate/components/uploadPlaceholder';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useInteractivityDisabled } from '../hooks/useInteractivityDisabled';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { useResizeObserver } from '../staticSlateHelpers';
import { OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import { ErrorElement } from './error';
import styles from './image.module.scss';
import { VoidBlock } from './voidBlock';

export function StaticImage({
  element,
  attributes,
  children,
}: OptionalAttributesRenderElementProps & { element: ImageElement }) {
  const modals = useModals();
  const { host } = useConfiguration();
  const { url, width } = element;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const ref = React.useRef<HTMLDivElement>(null);
  useResizeObserver(ref, url);

  if (!url) {
    return (
      <DummyNode element={element} attributes={attributes}>
        {children}
      </DummyNode>
    );
  }

  const fullUrl = url.startsWith('/') ? `${host}${url}` : url;
  const downloadUrl = url.startsWith('/') ? `${fullUrl}?forceDownload=true` : fullUrl;

  return (
    <div className="block rowCenter fullWidth" {...attributes}>
      <div
        className={cn(styles.image, {
          [styles.loading]: loading && !error,
          [styles.manuallySized]: !!width,
        })}
        ref={ref}
      >
        {error && <ErrorElement error={error} />}
        {!error && (
          <RetryingImage
            className={styles.mainImage}
            src={url}
            style={{ width }}
            onLoad={() => {
              setLoading(false);
              ensureVisible();
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              modals.openModal(Modals.Image, {
                url,
              });
            }}
            onLoadError={err => {
              setError(err);
            }}
            maxRetries={10}
            checkForUploadInProgress
          />
        )}
        <VoidActions floating className={styles.actions}>
          <DownloadButton url={downloadUrl} />
        </VoidActions>
        {loading && !error && (
          <div className="loadingContainer">
            <LoadingSpinner />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

function Resizer({
  position,
  onSizeChange,
  onSizeChangeCommitted,
  imageRef,
}: {
  imageRef: React.RefObject<HTMLImageElement>;
  onSizeChange: (width: number) => void;
  onSizeChangeCommitted: (width: number) => void;
  position: 'left' | 'right';
}) {
  const downPosition = React.useRef(-1);
  const startingWidth = React.useRef(-1);
  const newWidth = React.useRef(-1);

  useComponentDidMount(() => {
    function onMouseMove(e: MouseEvent) {
      if (downPosition.current === -1 || startingWidth.current === -1) {
        return;
      }

      const sizeChange =
        position === 'left' ? downPosition.current - e.clientX : e.clientX - downPosition.current;
      newWidth.current = Math.round(startingWidth.current + sizeChange * 2);
      onSizeChange(newWidth.current);
    }

    function onMouseUp() {
      if (newWidth.current !== -1) {
        onSizeChangeCommitted(newWidth.current);
      }
      downPosition.current = -1;
      startingWidth.current = -1;
      newWidth.current = -1;
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  return (
    <div
      className={cn(styles.resizer, {
        [styles.left]: position === 'left',
        [styles.right]: position === 'right',
      })}
      onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
        startingWidth.current = imageRef.current?.getBoundingClientRect().width ?? -1;
        downPosition.current = e.clientX;
      }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={styles.handle} />
    </div>
  );
}
export function Image({
  attributes,
  element,
  children,
}: RenderElementProps & { element: ImageElement }) {
  const interactivityDisabled = useInteractivityDisabled();
  const ref = React.useRef<HTMLDivElement>(null);
  const imageRef = React.useRef<HTMLImageElement>(null);

  const editor = useSlateStatic();
  const readonly = useReadOnly();
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();
  const modals = useModals();
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const { host } = useConfiguration();
  const { url, width } = element;
  const [loading, setLoading] = React.useState(true);
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  const selectionCollapsedRef = React.useRef(selected && selectionCollapsed);
  selectionCollapsedRef.current = selected && selectionCollapsed;

  const [error, setError] = React.useState('');

  const sizeObserver = React.useRef(
    window.ResizeObserver
      ? new window.ResizeObserver(() => {
          if (selectionCollapsedRef.current && ref.current) {
            scrollIntoView(ref.current, {
              block: 'center',
              behavior: 'auto',
              scrollMode: 'if-needed',
            });
          }
          ensureVisible();
          KitemakerEditor.ensureFocusOnScreen(editor, 0);
        })
      : null
  );

  React.useEffect(() => {
    if (!ref.current || !sizeObserver.current) {
      return;
    }
    const observer = sizeObserver.current;
    const observed = ref.current;
    observer.observe(observed);
    return () => {
      observer.unobserve(observed);
    };
  }, [sizeObserver, url]);

  if (!url) {
    return (
      <UploadPlaceholder
        requireUrl
        attributes={attributes}
        element={element}
        icon="image"
        placeholder="Add an image"
        focusedPlaceholder="Drop an image, upload from your computer (press enter), or paste a URL"
        onUploadPrepared={result => {
          if (!result.length) {
            return;
          }

          ReactEditor.focus(editor);

          const path = ReactEditor.findPath(editor, element);
          const [first, ...rest] = result;
          KitemakerTransforms.setNodes(editor, first, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);

          if (rest.length) {
            const additionalNodes: Descendant[] = rest.map(image => ({
              type: Elements.Image,
              ...image,
              children: [{ type: 'text', text: '' }],
            }));
            KitemakerTransforms.insertNodes(editor, additionalNodes);
            KitemakerTransforms.move(editor, {
              distance: additionalNodes.length - 1,
              unit: 'line',
            });
          }
        }}
        onSubmit={result => {
          ReactEditor.focus(editor);

          const path = ReactEditor.findPath(editor, element);
          KitemakerTransforms.setNodes(editor, { url: result }, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);
        }}
      >
        {children}
      </UploadPlaceholder>
    );
  }

  const fullUrl = url.startsWith('/') ? `${host}${url}` : url;
  const downloadUrl = url.startsWith('/') ? `${fullUrl}?forceDownload=true` : fullUrl;
  const showResizers = !loading && !readonly && !error && !interactivityDisabled;

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <div className="rowCenter">{children}</div>
      <VoidBlock
        element={element}
        onClick={e => {
          if (url && !editor.disableModals) {
            e.preventDefault();
            e.stopPropagation();
            ReactEditor.deselect(editor);
            modals.openModal(Modals.Image, {
              url,
              onClose: () => {
                ReactEditor.focus(editor);
                Transforms.select(editor, ReactEditor.findPath(editor, element));
              },
            });
          }
        }}
      >
        <div
          className={cn(styles.image, {
            [styles.hasCollapsedSelection]: !editor.disableModals && selected && selectionCollapsed,
            [styles.loading]: loading && !error,
            [styles.manuallySized]: !!width,
          })}
          ref={ref}
        >
          {showResizers && (
            <Resizer
              position="left"
              onSizeChange={newWidth => {
                if (imageRef.current) {
                  imageRef.current.style.width = `${newWidth}px`;
                }
              }}
              onSizeChangeCommitted={newWidth => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { width: newWidth }, { at: path });
              }}
              imageRef={imageRef}
            />
          )}
          {error && <ErrorElement error={error} />}
          {!error && (
            <RetryingImage
              ref={imageRef}
              className={styles.mainImage}
              src={url}
              style={{ width }}
              onLoad={() => {
                setLoading(false);
                ensureVisible();
                KitemakerEditor.ensureFocusOnScreen(editor, 0);
              }}
              onLoadError={err => {
                setError(err);
              }}
              maxRetries={10}
              checkForUploadInProgress
            />
          )}
          {showResizers && (
            <Resizer
              position="right"
              onSizeChange={newWidth => {
                if (imageRef.current) {
                  imageRef.current.style.width = `${newWidth}px`;
                }
              }}
              onSizeChangeCommitted={newWidth => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { width: newWidth }, { at: path });
              }}
              imageRef={imageRef}
            />
          )}

          <VoidActions floating className={styles.actions}>
            {editor.inlineComments && editor.entityId && <CommentButton element={element} />}
            <DownloadButton url={downloadUrl} />
          </VoidActions>
          {loading && !error && (
            <div className="loadingContainer">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </VoidBlock>
    </div>
  );
}
