import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { IssueStatusSortMode } from '../../../../sync/__generated/models';
import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '../../../components/new/menu/dropdownMenu';
import { Tooltip } from '../../../components/new/tooltip';
import { toast } from '../../../components/toast';
import { useConfiguration } from '../../../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { useMoveIssues } from '../../../syncEngine/actions/issues';
import {
  useDeleteStatuses,
  useUpdateStatusSortMode,
  useWatchStatuses,
} from '../../../syncEngine/actions/issueStatuses';
import { useCreateRelease } from '../../../syncEngine/actions/releases';
import { statusSelector, useGetFilterdIssuesForStatus } from '../../../syncEngine/selectors/issues';
import {
  archivedStatusForSpaceSelector,
  closedStatusForSpaceSelector,
  currentUserWatchingStatusSelector,
} from '../../../syncEngine/selectors/issueStatuses';
import { releasePath } from '../../../syncEngine/selectors/releases';
import { useSpaceSettingsPath } from '../../../syncEngine/selectors/spaces';
import { LocationState } from '../../../utils/history';

export function StatusMenu({
  statusId,
  boardId,
  disableEditing,
  onClose,
  onEdit,
}: {
  statusId: string;
  boardId?: string;
  disableEditing?: boolean;
  onClose: () => void;
  onEdit?: () => void;
}) {
  const modals = useModals();
  const space = useSpace();
  const archivedStatus = useRecoilValue(archivedStatusForSpaceSelector(space.id));
  const doneStatus = useRecoilValue(closedStatusForSpaceSelector(space.id));
  const moveIssues = useMoveIssues();
  const getFilteredIssuesForStatus = useGetFilterdIssuesForStatus(space.id, statusId, boardId);
  const status = useRecoilValue(statusSelector(statusId));
  const updateStatusSortMode = useUpdateStatusSortMode();
  const isWatchingStatus = useRecoilValue(currentUserWatchingStatusSelector(statusId));
  const watchStatuses = useWatchStatuses();
  const spaceSettingsPath = useSpaceSettingsPath();
  const deleteStatuses = useDeleteStatuses();
  const createRelease = useCreateRelease();
  const history = useHistory();
  const organization = useOrganization();
  const location = useLocation<LocationState>();
  const { featureFlags } = useConfiguration();

  if (!status) {
    return null;
  }

  return (
    <>
      <DropdownMenuItem
        icon="full_arrow_forward"
        onClick={() => {
          const issueIds = getFilteredIssuesForStatus();
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.ChangeStatus,
            context: {
              entityIds: issueIds,
              spaceId: space.id,
            },
          });
          onClose();
        }}
      >
        Move all {issueTerm}s to ...
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="status_done"
        onClick={() => {
          if (doneStatus) {
            const issueIds = getFilteredIssuesForStatus();
            moveIssues(issueIds, doneStatus.id);
          }
          onClose();
        }}
      >
        Move all {issueTerm}s to <span className="semiBold">{doneStatus?.name ?? 'Done'}</span>
      </DropdownMenuItem>

      {featureFlags.FEATURE_TOGGLE_RELEASES && (
        <>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            icon="release"
            onClick={() => {
              const issueIds = getFilteredIssuesForStatus();
              const release = createRelease('Draft release', {
                issueIds,
                spaceIds: [space.id],
                suppressToast: true,
              });

              if (!release) {
                toast.error('Unable to create release');
                onClose();
                return;
              }

              history.push(releasePath(organization, release.id), {
                backUrl: location.pathname,
                backSearch: location.search,
                backBreadcrumbs: location.state?.breadcrumbs,
              });

              onClose();
            }}
          >
            Create release from all {issueTerm}s
          </DropdownMenuItem>
        </>
      )}

      {!disableEditing && (
        <>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            icon="set_limit"
            onClick={() => {
              modals.openModal(Modals.IssueLimit, { statusId });
              onClose();
            }}
          >
            Set {issueTerm} limit
          </DropdownMenuItem>
          <Tooltip
            side="right"
            sideOffset={10}
            content={`Watching a status will notify you when new ${issueTerm}s are added`}
          >
            <DropdownMenuItem
              icon={isWatchingStatus ? 'watch_filled' : 'watch'}
              onClick={() => {
                watchStatuses([statusId], !isWatchingStatus);
                onClose();
              }}
            >
              {isWatchingStatus ? 'Stop watching' : 'Watch'} status column
            </DropdownMenuItem>
          </Tooltip>
          <DropdownMenuSeparator />
          {onEdit && (
            <DropdownMenuItem icon="edit" onClick={onEdit}>
              Edit status
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            icon="delete"
            onClick={() => {
              deleteStatuses([statusId]);
            }}
          >
            Delete status
          </DropdownMenuItem>
          <DropdownMenuItem to={spaceSettingsPath(status.spaceId, 'statuses')} icon="settings">
            Workflow settings
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>Ordering</DropdownMenuLabel>
          <DropdownMenuItem
            icon={
              status.sortMode === IssueStatusSortMode.Manual || status.sortMode === undefined
                ? 'select_checkmark'
                : 'none'
            }
            onClick={() => {
              updateStatusSortMode(status.id, IssueStatusSortMode.Manual);
              onClose();
            }}
          >
            Manual
          </DropdownMenuItem>
          <DropdownMenuItem
            icon={
              status.sortMode === IssueStatusSortMode.ImpactEffortAsc ||
              status.sortMode === IssueStatusSortMode.ImpactEffortDesc
                ? 'select_checkmark'
                : 'none'
            }
            onClick={() => {
              updateStatusSortMode(status.id, IssueStatusSortMode.ImpactEffortAsc);
              onClose();
            }}
          >
            Impact and effort
          </DropdownMenuItem>
          <DropdownMenuItem
            icon={
              status.sortMode === IssueStatusSortMode.ImpactAsc ||
              status.sortMode === IssueStatusSortMode.ImpactDesc
                ? 'select_checkmark'
                : 'none'
            }
            onClick={() => {
              updateStatusSortMode(status.id, IssueStatusSortMode.ImpactAsc);
              onClose();
            }}
          >
            Impact
          </DropdownMenuItem>
          <DropdownMenuItem
            icon={
              status.sortMode === IssueStatusSortMode.EffortAsc ||
              status.sortMode === IssueStatusSortMode.EffortDesc
                ? 'select_checkmark'
                : 'none'
            }
            onClick={() => {
              updateStatusSortMode(status.id, IssueStatusSortMode.EffortAsc);
              onClose();
            }}
          >
            Effort
          </DropdownMenuItem>
          <DropdownMenuItem
            icon={
              status.sortMode === IssueStatusSortMode.CreatedAsc ||
              status.sortMode === IssueStatusSortMode.CreatedDesc
                ? 'select_checkmark'
                : 'none'
            }
            onClick={() => {
              updateStatusSortMode(status.id, IssueStatusSortMode.CreatedAsc);
              onClose();
            }}
          >
            Created
          </DropdownMenuItem>
          <DropdownMenuItem
            icon={
              status.sortMode === IssueStatusSortMode.UpdatedAsc ||
              status.sortMode === IssueStatusSortMode.UpdatedDesc
                ? 'select_checkmark'
                : 'none'
            }
            onClick={() => {
              updateStatusSortMode(status.id, IssueStatusSortMode.UpdatedAsc);
              onClose();
            }}
          >
            Updated
          </DropdownMenuItem>
          <DropdownMenuItem
            icon={
              status.sortMode === IssueStatusSortMode.LastStatusAsc ||
              status.sortMode === IssueStatusSortMode.LastStatusDesc
                ? 'select_checkmark'
                : 'none'
            }
            onClick={() => {
              updateStatusSortMode(status.id, IssueStatusSortMode.LastStatusAsc);
              onClose();
            }}
          >
            Added to status
          </DropdownMenuItem>
          {space.cyclesEnabled && (
            <DropdownMenuItem
              icon={
                status.sortMode === IssueStatusSortMode.CycleAsc ||
                status.sortMode === IssueStatusSortMode.CycleDesc
                  ? 'select_checkmark'
                  : 'none'
              }
              onClick={() => {
                updateStatusSortMode(status.id, IssueStatusSortMode.CycleAsc);
                onClose();
              }}
            >
              Current cycle
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            icon={
              status.sortMode === IssueStatusSortMode.DueDateAsc ||
              status.sortMode === IssueStatusSortMode.DueDateDesc
                ? 'time'
                : 'none'
            }
            onClick={() => {
              updateStatusSortMode(status.id, IssueStatusSortMode.DueDateAsc);
              onClose();
            }}
          >
            Due date
          </DropdownMenuItem>
        </>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="archive"
        onClick={() => {
          if (archivedStatus) {
            const issueIds = getFilteredIssuesForStatus();
            moveIssues(issueIds, archivedStatus.id, { top: true });
          }
          onClose();
        }}
      >
        Archive all {issueTerm}s
      </DropdownMenuItem>
    </>
  );
}
