import React from 'react';
import { Setting, SettingsPage } from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { useOrganization } from '../../../contexts/organizationContext';
import { useUpdateOrganization } from '../../../syncEngine/actions/organizations';

export function DocumentsSettingsScreen() {
  const organization = useOrganization();
  const updateOrg = useUpdateOrganization();

  const [documentsEnabled, setDocumentsEnabled] = React.useState(organization.documentsEnabled);

  function toggleDocuments(enabled: boolean) {
    setDocumentsEnabled(enabled);
    updateOrg({ documentsEnabled: enabled });
  }

  return (
    <SettingsPage
      title="Documents"
      description={`Documents provide a flexible place for any long-lived documentation for your team. Design documents, playbooks, and more.`}
    >
      <Setting
        title={'Documents enabled'}
        description="Enables making documents inside this organization."
      >
        <Switch checked={documentsEnabled} onChange={v => toggleDocuments(v)} />
      </Setting>
    </SettingsPage>
  );
}
