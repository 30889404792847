import cn from 'classnames';
import { capitalize, isEqual, isFunction } from 'lodash';
import * as React from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  documentFromString,
  emptyDocument,
  isDocumentEmpty,
  stringifyDocument,
} from '../../../shared/slate/utils';
import { View } from '../../../sync/__generated/models';
import { writeToClipboard } from '../../components/clipboardText';
import { HideIfSmallerThan } from '../../components/hideIfSmallerThan';
import { Breadcrumbs } from '../../components/new/breadcrumbs';
import {
  Button,
  ButtonSize,
  ButtonStyle,
  IconButton,
  IconButtonTrigger,
} from '../../components/new/button';
import { ColorPickerContent } from '../../components/new/colorPicker';
import { FeedbackContents } from '../../components/new/entityFilters2';
import { FilterMenu, Filters } from '../../components/new/filters2';
import { Icon } from '../../components/new/icon';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import { useSetKeyNavigationFocus } from '../../components/new/keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '../../components/new/menu/dropdownMenu';
import { ScreenHeader } from '../../components/new/screenHeader';
import { StarredIcon } from '../../components/new/starredIcon';
import { Tab } from '../../components/new/tabs';
import tabStyles from '../../components/new/tabs.module.scss';
import { Tooltip } from '../../components/new/tooltip';
import { ReorderableTabs } from '../../components/reorderableTabs';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { ViewIcon } from '../../components/viewIcon';
import { useConfiguration } from '../../contexts/configurationContext';
import { Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import {
  ResponsiveDesignSize,
  useIsSmallScreen,
  useIsTinyScreen,
} from '../../hooks/useResponsiveDesign';
import TextArea, { TextAreaHandle } from '../../slate/textArea';
import { useCreateFeedback } from '../../syncEngine/actions/feedback';
import { useToggleStarred } from '../../syncEngine/actions/starred';
import {
  useCreateView,
  useDeleteViews,
  useUpdateViews,
  useUpdateViewSorts,
} from '../../syncEngine/actions/views';
import {
  feedbackPath,
  feedbackScreenSelector,
  feedbackTabState,
} from '../../syncEngine/selectors/feedback';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { isStarredSelector } from '../../syncEngine/selectors/starred';
import { viewSelector, viewsForOrganizationSelector } from '../../syncEngine/selectors/views';
import { createNewEntityFromAnywhere, deleteKey, isMobileOS } from '../../utils/config';
import { filterChainState, FilterType } from '../../utils/filtering2';
import { StarredType } from '../../utils/starred';
import { FeedbackList } from './feedbackList';
import styles from './feedbackScreen.module.scss';

function ViewTabs() {
  const organization = useOrganization();
  const currentTab = useRecoilValue(feedbackTabState);
  const setFilterChain = useSetRecoilState(filterChainState('feedback'));
  const createView = useCreateView();

  const history = useHistory();

  const views = useRecoilValue(viewsForOrganizationSelector(organization.id));
  const updateViewSorts = useUpdateViewSorts();
  const isSmallScreen = useIsSmallScreen();

  const staticTabs = !isSmallScreen ? (
    <>
      <Button
        key={'inbox'}
        value={'inbox'}
        buttonStyle={ButtonStyle.BareSubtle}
        className={cn(tabStyles.tab, tabStyles.subtle, 'mr1', {
          [tabStyles.active]: 'inbox' === currentTab,
        })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setFilterChain({ filters: [], operation: 'and' });
          history.push(organizationPath(organization, 'feedback?feedbackTab=inbox'));
        }}
        icon="status_inbox"
      >
        <span>Inbox</span>
      </Button>
      <Button
        key={'processed'}
        value={'processed'}
        buttonStyle={ButtonStyle.BareSubtle}
        className={cn(tabStyles.tab, tabStyles.subtle, 'mr1', {
          [tabStyles.active]: 'processed' === currentTab,
        })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setFilterChain({ filters: [], operation: 'and' });
          history.push(organizationPath(organization, 'feedback?feedbackTab=processed'));
        }}
        icon="select_checkmark"
      >
        <span>Processed</span>
      </Button>
    </>
  ) : null;

  const onNewClick = () => {
    setFilterChain({ filters: [], operation: 'and' });
    const view = createView('', { filters: [], operation: 'and' });
    history.push(organizationPath(organization, `feedback?feedbackTab=${view.id}`));
  };
  const onTabClick = (view: Tab) => {
    history.push(organizationPath(organization, `feedback?feedbackTab=${view.id}`));
    setFilterChain((view as View).filters);
  };

  const tabs = views.map(view => {
    return {
      ...view,
      name: view.name.length === 0 ? 'Untitled view' : view.name,
      icon: <ViewIcon color={view.color} className="mr6" />,
    };
  }) as Tab[];

  const onDragEnd = async ({ source, destination }: DropResult) => {
    const from = source.index;
    const to = destination?.index;

    if (to === undefined || to === null) {
      return;
    }
    const toMove = views[from];

    const tempViews = [...views].filter(s => s.id !== toMove.id);
    tempViews.splice(to, 0, toMove);
    const previousView = tempViews[to - 1];
    const nextView = tempViews[to + 1];
    updateViewSorts(toMove.id, previousView?.id, nextView?.id);
  };

  return (
    <ReorderableTabs
      currentTab={currentTab}
      tabs={tabs}
      typeLabel={'view'}
      staticTabs={staticTabs}
      onTabClick={onTabClick}
      onNewClick={onNewClick}
      onDragEnd={onDragEnd}
    />
  );
}

function NewFeedbackButton() {
  const history = useHistory();
  const organization = useOrganization();
  const modals = useModals();
  const isSmallScreen = useIsSmallScreen();
  const createFeedback = useCreateFeedback();
  const setFocus = useSetKeyNavigationFocus();

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isMobileOS) {
      e.preventDefault();
      e.stopPropagation();
      const feedback = createFeedback('New feedback');
      history.push(feedbackPath(organization, feedback, true));
      return;
    }

    modals.openModal(Modals.NewEntity, {
      type: 'Feedback',
      onCreated: entityId => {
        if (entityId) {
          setFocus(entityId);
        }
      },
    });
  };

  if (isSmallScreen) {
    return (
      <>
        <IconButton
          className="mr8"
          icon="status_inbox"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            history.push(organizationPath(organization, 'feedback?feedbackTab=inbox'));
          }}
        />
        <IconButton
          className="mr8"
          icon="select_checkmark"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            history.push(organizationPath(organization, 'feedback?feedbackTab=processed'));
          }}
        />
        <Tooltip content="New initiative">
          <IconButton icon="add" onClick={onClick} />
        </Tooltip>
      </>
    );
  }

  return (
    <Tooltip
      content={
        <>
          Create initiative <KeyboardShortcut shortcut={createNewEntityFromAnywhere} />
        </>
      }
    >
      <Button className="mr8" onClick={onClick} icon="add">
        New feedback
      </Button>
    </Tooltip>
  );
}

function Header() {
  const isSmallScreen = useIsSmallScreen();

  return (
    <ScreenHeader
      className={styles.header}
      showSidebarOpener
      compensateForMacOSTrafficLights="auto"
      rightSideContent={<NewFeedbackButton />}
    >
      {!isSmallScreen && <Breadcrumbs breadcrumbs={[{ name: 'Feedback' }]} className="noShrink" />}
      <ViewTabs />
    </ScreenHeader>
  );
}

function FeedbackTitleSetter() {
  const organization = useOrganization();
  const title = `${organization.name} · Feedback`;
  return <TitleSetter title={title} />;
}

function TitleComponent(
  { viewId }: { viewId?: string },
  ref?: React.ForwardedRef<TextAreaHandle | null>
) {
  const view = useRecoilValue(viewSelector(viewId ?? ''));
  const inititalTitle = React.useMemo(() => {
    if (view?.name) {
      return documentFromString(view.name);
    } else if (viewId === 'inbox' || viewId === 'processed') {
      return documentFromString(capitalize(viewId));
    } else {
      return emptyDocument();
    }
  }, []);
  const [title, setTitle] = React.useState(inititalTitle);
  const titleRef = React.useRef(title);
  titleRef.current = title;
  const textAreaRef = React.useRef<TextAreaHandle | null>(null);
  const [hasFocus, setHasFocus] = React.useState(false);

  const updateView = useUpdateViews();

  function submit() {
    if (viewId) {
      updateView([viewId], { name: stringifyDocument(titleRef.current) });
    }
  }

  return (
    <div
      className={cn(styles.title, {
        [styles.focused]: hasFocus,
        [styles.empty]: isDocumentEmpty(title),
      })}
    >
      <TextArea
        oneLine
        readOnly={viewId === 'inbox' || viewId === 'processed'}
        maxLength={1024}
        tabIndex={-1}
        ref={r => {
          textAreaRef.current = r;
          if (ref) {
            if (isFunction(ref)) {
              ref(r);
            } else {
              ref.current = r;
            }
          }
        }}
        initialValue={title}
        onChange={v => {
          setTitle(v);
        }}
        autoFocus={view?.name === ''}
        className={'fs-exclude'}
        placeholder="Give this view a title"
        onFocus={() => {
          scrollTo({ top: 0 });
          setHasFocus(true);
        }}
        onBlur={() => {
          submit();
          setHasFocus(false);
        }}
        onKeyDown={e => {
          if (e.key === 'ArrowDown') {
            e.stopPropagation();
          }
          if (e.key === 'ArrowUp') {
            e.stopPropagation();
          }

          if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            const resetValue = documentFromString('Inbox');
            textAreaRef.current?.prepareForNewValue(resetValue);
            setTitle(resetValue);
            setTimeout(() => textAreaRef.current?.blur(), 50);
          }

          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            textAreaRef.current?.blur();
          }
        }}
      />
    </div>
  );
}
const Title = React.forwardRef(TitleComponent);

function ViewMenu({ closeMenu, onEdit }: { closeMenu: () => void; onEdit: () => void }) {
  const id = useRecoilValue(feedbackTabState);
  const organization = useOrganization();
  const deleteViews = useDeleteViews();
  const { host } = useConfiguration();
  const [tab, setTab] = useRecoilState(feedbackTabState);

  const isStarred = useRecoilValue(
    isStarredSelector({
      organizationId: organization.id,
      type: StarredType.FeedbackView,
      id,
    })
  );
  const toggleStarred = useToggleStarred();

  const defaultView = id === 'inbox' || id === 'processed';

  return (
    <>
      {!defaultView && (
        <DropdownMenuItem
          icon="edit"
          onClick={() => {
            onEdit();
            closeMenu();
          }}
        >
          Edit title
        </DropdownMenuItem>
      )}
      <DropdownMenuItem
        icon="link"
        onClick={() => {
          closeMenu();
          const searchParam = tab ? `?feedbackTab=${tab}` : '';
          writeToClipboard(
            `${host}${organizationPath(organization, `feedback${searchParam}`)}`,
            'Feedback view link'
          );
        }}
      >
        Copy link
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="starred"
        onClick={() => {
          toggleStarred({ type: StarredType.FeedbackView, id });
        }}
      >
        {isStarred ? 'Unstar' : 'Star'}
      </DropdownMenuItem>
      {!defaultView && (
        <>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            icon="delete"
            shortcut={deleteKey}
            onClick={async () => {
              const deleted = await deleteViews([id]);
              if (deleted.length) {
                setTab('inbox');
              }
            }}
          >
            Delete
          </DropdownMenuItem>
        </>
      )}
    </>
  );
}

function ViewMenuButton({ onEdit, className }: { onEdit: () => void; className?: string }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <IconButtonTrigger
        size={ButtonSize.Medium}
        buttonStyle={ButtonStyle.BareSubtle}
        icon="more"
        className={className}
      />
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
      >
        <ViewMenu closeMenu={closeMenu} onEdit={onEdit} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function TopBar({
  filterOpen,
  setFiltersOpen,
}: {
  filterOpen: boolean;
  setFiltersOpen: (open: boolean) => void;
}) {
  const tinyScreen = useIsTinyScreen();
  const ref = React.useRef<TextAreaHandle | null>(null);
  const tab = useRecoilValue(feedbackTabState);

  const { filters } = useRecoilValue(filterChainState('feedback'));
  const isNewMode = tab === 'new';
  const isStaticView = tab === 'inbox' || tab === 'processed';
  const showFilterExpandButton = !isNewMode && !isStaticView && filters.length > 0;
  const view = useRecoilValue(viewSelector(tab ?? ''));

  const updateViews = useUpdateViews();

  let icon;
  if (tab === 'inbox') {
    icon = 'status_inbox';
  } else if (tab === 'processed') {
    icon = 'select_checkmark';
  } else {
    icon = <ViewIcon color={view?.color ?? 'gray'} />;
  }

  return (
    <div className={styles.topbar}>
      <div className="row noMinWidth">
        {!isStaticView && (
          <DropdownMenu>
            <IconButtonTrigger buttonStyle={ButtonStyle.Bare} icon={icon} className="mr4" />
            <DropdownMenuContent>
              <ColorPickerContent
                onColorPicked={(color: string | null) => {
                  if (tab && color) {
                    updateViews([tab], { color });
                  }
                }}
              />
            </DropdownMenuContent>
          </DropdownMenu>
        )}
        {isStaticView && <Icon icon={icon as string} className="mr10 ml6" />}
        <Title key={tab} viewId={tab ?? undefined} ref={ref} />
        <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
          <StarredIcon
            starred={{
              type: StarredType.FeedbackView,
              id: tab,
            }}
            className="ml8"
          />
        </HideIfSmallerThan>
        <ViewMenuButton
          className="ml8"
          onEdit={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
        <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
          {!showFilterExpandButton && (
            <FilterMenu
              className="ml8"
              id={'feedback'}
              compact={tinyScreen}
              showStateFilter={!isStaticView}
              options={{
                orgLevel: true,
              }}
              DefaultContents={FeedbackContents}
            />
          )}
          {showFilterExpandButton && (
            <Button
              icon="filter"
              size={ButtonSize.Small}
              className="ml8"
              buttonStyle={ButtonStyle.BareSubtle}
              onClick={() => setFiltersOpen(!filterOpen)}
            >
              Filter
              <Icon
                icon={filterOpen ? 'arrow_up' : 'arrow_down'}
                style={{ fill: 'var(--grayA8)' }}
              />
            </Button>
          )}
        </HideIfSmallerThan>
      </div>
    </div>
  );
}

function FeedbackItems() {
  const organization = useOrganization();
  const tab = useRecoilValue(feedbackTabState);
  const { processed, unprocessed, all } = useRecoilValue(feedbackScreenSelector(organization.id));
  const view = useRecoilValue(viewSelector(tab));

  let feedbackIds = all;

  if (tab === 'inbox') {
    feedbackIds = unprocessed;
  } else if (tab === 'processed') {
    feedbackIds = processed;
  }

  return (
    <FeedbackList
      key={tab}
      feedbackIds={feedbackIds}
      breadcrumbs={[
        { name: 'Feedback' },
        {
          link: organizationPath(organization, `feedback?feedbackTab=${tab}`),
          name: view?.name ?? capitalize(tab),
        },
      ]}
    />
  );
}

function FiltersWrapper({
  feedbackTab,
  filtersOpen,
  setFiltersOpen,
}: {
  feedbackTab: string;
  filtersOpen: boolean;
  setFiltersOpen: (open: boolean) => void;
}) {
  const [filterChain, setFilterChain] = useRecoilState(filterChainState('feedback'));
  const view = useRecoilValue(viewSelector(feedbackTab));
  const updateViews = useUpdateViews();
  const createView = useCreateView();
  const filtersModified = !isEqual(view?.filters, filterChain);
  const tinyScreen = useIsTinyScreen();
  const setTab = useSetRecoilState(feedbackTabState);

  if (
    feedbackTab === 'new' ||
    (!filtersOpen && feedbackTab !== 'inbox' && feedbackTab !== 'processed')
  ) {
    return null;
  }

  let rightButton = null;

  if (feedbackTab === 'inbox' || feedbackTab === 'processed') {
    rightButton = (
      <Button
        onClick={() => {
          setFilterChain(old => {
            return {
              filters: [
                {
                  type: FilterType.State,
                  modifier: 'any-of',
                  ids: [feedbackTab === 'inbox' ? 'active' : 'closed'],
                },
                ...old.filters,
              ],
              operation: old.operation,
            };
          });
          const view = createView('', filterChain);
          setTab(view.id);
        }}
        buttonStyle={ButtonStyle.SecondarySubtle}
        size={ButtonSize.Small}
        className="ml8"
      >
        Save as view
      </Button>
    );
  } else if (filtersModified) {
    rightButton = (
      <Button
        onClick={() => {
          if (view) {
            updateViews([view.id], {
              filters: filterChain,
            });
            setFiltersOpen(false);
          }
        }}
        buttonStyle={ButtonStyle.SecondarySubtle}
        size={ButtonSize.Small}
        className="ml8"
      >
        Save
      </Button>
    );
  }

  return (
    <Filters
      id={'feedback'}
      rightButton={rightButton}
      args={{ stateLabels: { active: 'Unprocessed', closed: 'Processed' } }}
    >
      {view && (
        <FilterMenu
          icon="add_circle"
          id={'feedback'}
          compact={tinyScreen}
          options={{
            orgLevel: true,
          }}
          DefaultContents={FeedbackContents}
          showStateFilter={feedbackTab !== 'unprocessed' && feedbackTab !== 'inbox'}
        />
      )}
    </Filters>
  );
}

export function FeedbackScreen() {
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const feedbackTab = useRecoilValue(feedbackTabState);

  return (
    <Screen>
      <FeedbackTitleSetter />
      <Header />
      <TopBar filterOpen={filtersOpen} setFiltersOpen={setFiltersOpen} />
      <FiltersWrapper
        feedbackTab={feedbackTab}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
      />
      <FeedbackItems />
    </Screen>
  );
}
