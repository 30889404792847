import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Range } from 'slate';
import { useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { documentFromString, stringifyDocument } from '../../../../shared/slate/utils';
import { EntityProvenanceType, IntegrationType, Todo } from '../../../../sync/__generated/models';
import { writeToClipboard } from '../../../components/clipboardText';
import { toast } from '../../../components/toast';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useCurrentUser } from '../../../contexts/userContext';
import { useIsProductTierExceededAndNag } from '../../../index/billingChecks';
import { useAddTodosToCycle, useRemoveTodosFromCycle } from '../../../syncEngine/actions/cycles';
import { useToggleTodos } from '../../../syncEngine/actions/todos';
import { todoInCycleSelector } from '../../../syncEngine/selectors/cycles';
import { todoKeySelector } from '../../../syncEngine/selectors/todos';
import {
  isAddToCurrentCycleHotkey,
  isAddToUpcomingCycleHotkey,
  isCommentHotkey,
  isCopyGitBranchHotkey,
  isCopyNumberHotkey,
  isCreateHotkey,
  metaKeyDown,
} from '../../../utils/keyEvents';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { Elements, FormatHoverMode, SmartTodoElement } from '../../types';
import { copyNumberToClipboard } from './smartTodoHelpers';
import { useGitTodoBranchName } from './useSmartTodoGitBranchName';

export function useOpenNewEntityModalFromTodo(
  todo: Todo | null,
  onCreated?: (entityId: string | null) => void
) {
  const modals = useModals();
  const space = useMaybeSpace();
  const checkProductTierExceeded = useIsProductTierExceededAndNag();

  return React.useCallback(() => {
    if (checkProductTierExceeded()) {
      return;
    }
    if (!todo) {
      return;
    }
    const innerContents = todo.todoContents;
    if (!innerContents) {
      toast.error('Failed to grab selected text');
    }
    const stringified = stringifyDocument(innerContents, '\n');
    const split = stringified.split('\n');
    modals.openModal(Modals.NewEntity, {
      title: split[0],
      type: 'Issue',
      spaceId: space?.id,
      content: documentFromString(split.slice(1).join('\n')),
      labelIds: todo.labelIds,
      assigneeIds: todo.memberIds,
      impactId: todo.impactId,
      effortId: todo.effortId,
      provenance: {
        provenanceType: EntityProvenanceType.CreatedFrom,
        entityId: todo.entityId,
        createdFromTodoId: todo.id,
      },
      onCreated,
    });
  }, [onCreated, todo]);
}

export function useOpenNewExternalIssueModalFromTodo(
  todo: Todo | null,
  onCreated?: (type: IntegrationType, externalIssueId: string | null) => void
) {
  const modals = useModals();

  return React.useCallback(
    (type: IntegrationType) => {
      if (!todo) {
        return;
      }
      const innerContents = todo.todoContents;
      if (!innerContents) {
        toast.error('Failed to grab selected text');
      }
      const stringified = stringifyDocument(innerContents, '\n');
      const split = stringified.split('\n');
      modals.openModal(Modals.NewExternalIssue, {
        title: split[0],
        type,
        description: documentFromString(split.slice(1).join('\n')),
        onCreated: externalIssueId => {
          onCreated?.(type, externalIssueId);
        },
      });
    },
    [onCreated, todo]
  );
}

export function useSmartTodoHotkeys(element: SmartTodoElement, todo: Todo | null) {
  const user = useCurrentUser();
  const toggleTodos = useToggleTodos('Document Hotkeys');
  const branchName = useGitTodoBranchName(todo?.id ?? '');
  const addTodosToCycle = useAddTodosToCycle();
  const removeTodosFromCycle = useRemoveTodosFromCycle();
  const todoKey = useRecoilValue(todoKeySelector(todo?.id));

  const space = useMaybeSpace();
  const editor = useSlateStatic();

  const onCreated = React.useCallback(
    (entityId: string | null) => {
      if (entityId) {
        KitemakerTransforms.selectElement(editor, element);
        KitemakerTransforms.insertNodes(editor, {
          type: Elements.Entity,
          entityId,
          mentionId: uuid.v4(),
          actorId: user.id,
          children: [{ text: '' }],
        });
      }
    },
    [editor, element]
  );
  const openNewEntityModalFromTodo = useOpenNewEntityModalFromTodo(todo, onCreated);

  const onCommented = React.useCallback(() => {
    editor.emit('forceFormatHoverMode', FormatHoverMode.Comment);
    KitemakerTransforms.selectElement(editor, element);
  }, [editor, element]);

  const todoInCurrentCycle = useRecoilValue(
    todoInCycleSelector({
      cycleId: space?.activeCycleId,
      todoId: todo?.id,
    })
  );
  const todoInCurrentCycleRef = React.useRef(todoInCurrentCycle);
  todoInCurrentCycleRef.current = todoInCurrentCycle;

  const todoInUpcomingCycle = useRecoilValue(
    todoInCycleSelector({
      cycleId: space?.upcomingCycleId,
      todoId: todo?.id,
    })
  );
  const todoInUpcomingCycleRef = React.useRef(todoInUpcomingCycle);
  todoInUpcomingCycleRef.current = todoInUpcomingCycle;

  return React.useCallback(
    (e: React.KeyboardEvent) => {
      if (!todo) {
        return false;
      }
      const { selection } = editor;
      if (!selection || !Range.isCollapsed(selection)) {
        return false;
      }

      if (todoKey && isCopyNumberHotkey(e)) {
        e.preventDefault();
        e.stopPropagation();
        copyNumberToClipboard(todoKey);
      }

      if (isCopyGitBranchHotkey(e)) {
        e.preventDefault();
        e.stopPropagation();
        if (branchName) {
          writeToClipboard(branchName, 'Todo git branch name');
        }
      }

      if (isAddToCurrentCycleHotkey(e)) {
        e.preventDefault();
        e.stopPropagation();
        if (!space?.activeCycleId) {
          return false;
        }

        if (todoInCurrentCycleRef.current) {
          removeTodosFromCycle([todo.id], space.activeCycleId);
        } else {
          addTodosToCycle([todo.id], space.activeCycleId);

          if (todoInUpcomingCycleRef.current && space.upcomingCycleId) {
            removeTodosFromCycle([todo.id], space.upcomingCycleId);
          }
        }
      }

      if (isAddToUpcomingCycleHotkey(e)) {
        e.preventDefault();
        e.stopPropagation();
        if (!space?.upcomingCycleId) {
          return false;
        }

        if (todoInUpcomingCycleRef.current) {
          removeTodosFromCycle([todo.id], space.upcomingCycleId);
        } else {
          addTodosToCycle([todo.id], space.upcomingCycleId);

          if (todoInCurrentCycleRef.current && space.activeCycleId) {
            removeTodosFromCycle([todo.id], space.activeCycleId);
          }
        }
      }

      if (isCommentHotkey(e)) {
        e.preventDefault();
        e.stopPropagation();
        onCommented();
        return true;
      }

      if (e.key.toLowerCase() === 'enter' && metaKeyDown(e) && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        toggleTodos([todo.id]);
        return true;
      }

      if (e.key.toLowerCase() === 'enter' && metaKeyDown(e) && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        toggleTodos([todo.id], true);
        return true;
      }

      if (isCreateHotkey(e)) {
        e.preventDefault();
        e.stopPropagation();
        openNewEntityModalFromTodo();
        return true;
      }

      return false;
    },
    [todo, todoKey, element]
  );
}
